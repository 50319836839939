import React from "react";
import styled from "styled-components";
import {SBExchangeInfo} from "../../../models/swap_bridge/SwapBridgeModel";

interface SwapBridgeExchangeDetailProps {
  sbExchangeInfo?: SBExchangeInfo;
}

const SwapBridgeExchangeDetail: React.FC<SwapBridgeExchangeDetailProps> = ({
                                                                             sbExchangeInfo
                                                                           }) => {
  return (
    <Container>
      <ExchangeRowInfoArea>
        <ExchangeRowLeftArea>
          <ExchangeRowTitle>Exchange Route</ExchangeRowTitle>
        </ExchangeRowLeftArea>
        <ExchangeRowRightArea>
          {
            sbExchangeInfo
            ? (
              <>
                <ExchangeSupplierLogo src={sbExchangeInfo.supplier.logo} />
                <ExchangeRowValue>{sbExchangeInfo.supplier.name}</ExchangeRowValue>
              </>
              )
              : (
                <ExchangeRowValue>-</ExchangeRowValue>
              )
          }

        </ExchangeRowRightArea>
      </ExchangeRowInfoArea>

      <ExchangeRowInfoArea>
        <ExchangeRowLeftArea>
          <ExchangeRowTitle>Fee</ExchangeRowTitle>
        </ExchangeRowLeftArea>
        <ExchangeRowRightArea>
          <ExchangeRowValue>{sbExchangeInfo ? `${sbExchangeInfo.feeList.map(feeInfo => `${parseFloat(feeInfo.feeAmount.amountInETH.toFixed(6))} ${feeInfo.feeSymbol}`).join(' + ')}` : '-'}</ExchangeRowValue>
        </ExchangeRowRightArea>
      </ExchangeRowInfoArea>

      <ExchangeRowInfoArea>
        <ExchangeRowLeftArea>
          <ExchangeRowTitle>Estimate amount</ExchangeRowTitle>
        </ExchangeRowLeftArea>
        <ExchangeRowRightArea>
          <ExchangeRowValue>{sbExchangeInfo ? sbExchangeInfo.estimateAmount.amountInETH.toFixed(6) : '-'}</ExchangeRowValue>
        </ExchangeRowRightArea>
      </ExchangeRowInfoArea>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: #fff;
  width: 100%;
  font-family: 'Plus Jakarta Sans', sans-serif;
  padding: 16px;
  box-sizing: border-box;
  gap: 8px;
`;

const ExchangeRowInfoArea = styled.div`
  display: flex;
  gap: 4px;
`

const ExchangeRowLeftArea = styled.div`
  display: flex;
  gap: 4px;
`

const ExchangeRowTitle = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #8c8c8c;
`

const ExchangeRowRightArea = styled.div`
  display: flex;
  gap: 4px;
  margin-left: auto;
  align-items: center;
`

const ExchangeSupplierLogo = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  object-fit: fill;
  object-position: center;
`

const ExchangeRowValue = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #262626;
`


export default SwapBridgeExchangeDetail;
