import UserAPI, {IUserAPI} from "./UserAPI";
import OTPAPI, {IOTPAPI} from "./OTPAPI";
import BackupAPI, {IBackupAPI} from "./BackupAPI";
import {IService} from "../IService";
import Web3API, {IWeb3API} from "./Web3API";
import SwapBridgeAPI, {ISwapBridgeAPI} from "./SwapBridgeAPI";


export interface IAPIService extends IService {
  user: IUserAPI;
  otp: IOTPAPI;
  backup: IBackupAPI;
  web3: IWeb3API;
  swapBridge: ISwapBridgeAPI;
}

class APIService implements IAPIService {
  public user: IUserAPI;
  public otp: IOTPAPI;
  public backup: IBackupAPI;
  public web3: IWeb3API;
  public swapBridge: ISwapBridgeAPI;

  constructor() {
    this.user = new UserAPI();
    this.otp = new OTPAPI();
    this.backup = new BackupAPI();
    this.web3 = new Web3API();
    this.swapBridge = new SwapBridgeAPI();
  }

  startService(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      resolve(true);
    });
  }

  stopService(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      resolve(true);
    });
  }
}

export default APIService;
