import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import NavigateItem, {NavigateItemProps} from "./NavigateItem";
import {BizContext} from "../../contexts/BizContext";
import {TGContext} from "../../contexts/TGContext";

interface NavigationBarProps {
  onClickExplore: () => void;
  onClickChangeAccount: () => void;
  onClickSetting: () => void;
}

const NavigationBar: React.FC<NavigationBarProps> = ({
                                                       onClickExplore,
                                                       onClickChangeAccount,
                                                       onClickSetting
                                                     }) => {
  const bizContext = useContext(BizContext);
  const tgContext = useContext(TGContext);
  if (!bizContext || !tgContext) {
    throw new Error("Context isn't existed");
  }

  const {homeBizModel} = bizContext;
  const {safeAreaBottom} = tgContext;

  const [selectedWalletAvaUrl, setSelectedWalletAvaUrl] = useState<string>('');

  useEffect(() => {

  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const selectedAva = await homeBizModel.getSelectedAccountAvatarUrl();
      setSelectedWalletAvaUrl(selectedAva);
    }
    fetchData();
  }, [homeBizModel]);

  return (
    <NavigationBarContainer bottom={safeAreaBottom}>
      <NavigationWrapper>
        <HomeNavigateItem src={'/icons/ic_home.svg'} title={'Home'} onClick={() => {
        }}/>
        <ExploreNavigateItem src={'/icons/ic_explore.svg'} title={'Explore'} onClick={onClickExplore}/>
        <SettingNavigateItem src={'/icons/ic_setting.svg'} title={'Setting'} onClick={onClickSetting}/>
      </NavigationWrapper>
      <AccountSelectionWrapper onClick={onClickChangeAccount}>
        <AccountSelectionAvatar src={selectedWalletAvaUrl}/>
      </AccountSelectionWrapper>
    </NavigationBarContainer>
  )
};

interface NavigationBarContainerProps {
  bottom: number;
}

const NavigationBarContainer = styled.div<NavigationBarContainerProps>`
  position: absolute;
  left: 0px;
  bottom: ${(props) => (`${props.bottom / 2}px`)};
  display: flex;
  padding: 16px;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
`

const NavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 28px;
  height: 64px;
  background-color: #fff;
  box-shadow: 0 4px 20px 0 #00000008;
  flex: 1;
  padding: 0 16px;
`

const AccountSelectionWrapper = styled.div`
  display: flex;
  border-radius: 100%;
  background-color: #fff;
  padding: 4px;
  box-shadow: 0 4px 20px 0 #00000008;
  box-sizing: content-box;
  cursor: pointer;
`

const AccountSelectionAvatar = styled.img`
  width: 56px;
  height: 56px;
  object-fit: contain;
  object-position: center;
  border-radius: 100%;
`

const HomeNavigateItem: React.FC<NavigateItemProps> = (props) => {
  return <NavigateItem {...props} />;
};

const ExploreNavigateItem: React.FC<NavigateItemProps> = (props) => {
  return <NavigateItem {...props} />;
};

const SettingNavigateItem: React.FC<NavigateItemProps> = (props) => {
  return <NavigateItem {...props} />;
};

export default NavigationBar;
