export enum NotifyType {
   Transaction    = 1,
   Alert          = 2,
}

export enum NotifyTabType {
   All            = 0,
   Transaction    = NotifyType.Transaction,
   Alert          = NotifyType.Alert,
}

export enum NotifyCTAType {
   Backup         = 1,
}

export enum NotifyTransactionType {
   SwapBridge     = 1,
   Approve        = 2,
   Send           = 3,
   Receive        = 4,
   Trade          = 5,
}

export enum NotifyAlertType {
   SecureWallet   = 1,
   GasFee         = 2,
}
