export interface IDatabase {
  db: IDBDatabase | undefined;
  loadDBPromise: Promise<boolean>;
}

class Database implements IDatabase {
  public db: IDBDatabase | undefined;
  public loadDBPromise: Promise<boolean>;
  constructor() {
    this.loadDBPromise = new Promise<boolean>(resolve => {
      resolve(true);
    });
  }
}

export default Database;
