import ServiceManagerIns from "../../ServiceManager";
import {AccountWalletDetail, AccountWalletInfo} from "../../../models/wallet/AccountModel";

export interface ITaskExecutor {
  delay: number;
  interval: number;

  run(): Promise<void>;
}

export class TaskExecutor implements ITaskExecutor {
  public delay: number;
  public interval: number;

  constructor() {
    this.delay = 0;
    this.interval = 0;
  }

  run(): Promise<void> {
    return new Promise(resolve => {
      resolve();
    });
  }
}

export class BlockTaskExecutor extends TaskExecutor{
  protected block: () => void;

  constructor(delay: number, interval: number, block: () => void) {
    super();
    this.delay = delay;
    this.interval = interval;
    this.block = block;
  }

  run(): Promise<void> {
    return new Promise(resolve => {
      this.block();
    });
  }
}

export class UpToDateAccountDetailTaskExecutor extends TaskExecutor {
  constructor() {
    super();
    this.interval = 15000;
  }

  run(): Promise<void> {
    return new Promise(async resolve => {
      const accountList: AccountWalletInfo[] = await ServiceManagerIns.cacheService.accountCache.getCachedAccounts();
      for (const account of accountList) {
        const walletBalance = await ServiceManagerIns.web3Service.getWalletBalanceDetail(account.wallet);
        const accountDetail: AccountWalletDetail = {
          ...account,
          walletBalance: walletBalance
        }

        await ServiceManagerIns.cacheService.accountCache.cacheAccountBalance(account.id, walletBalance);
        ServiceManagerIns.sessionService.replaceAccountWalletDetail(accountDetail);
      }

      ServiceManagerIns.observerService.accountData.notifyOnUptodateAccountDetail();
      resolve();
    });
  }
}
