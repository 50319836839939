import {BalAddrConfig, ChainWalletMap, CryptoChainInfo, RPCConfig} from "../models/Web3Model";
import {ISingleChainWallet} from "./single_wallet/SingleChainWallet";
import {createWallet} from "../helpers/WalletFactory";

export interface IMultiChainWallet {
  chainWallet(chain: CryptoChainInfo): ISingleChainWallet | undefined;
}

class MultiChainWallet implements IMultiChainWallet {
  protected chainWalletMap: ChainWalletMap;
  protected rpcConfig: RPCConfig;
  protected balAddrConfig: BalAddrConfig;
  protected mnemonic: string;

  constructor(mnemonic: string, rpcConfig: RPCConfig, balanceAddrConfig: BalAddrConfig) {
    this.chainWalletMap = {};
    this.rpcConfig = rpcConfig;
    this.balAddrConfig = balanceAddrConfig;
    this.mnemonic = mnemonic;
  }

  chainWallet(chain: CryptoChainInfo): ISingleChainWallet | undefined {
    if (chain.id in this.chainWalletMap) {
      return this.chainWalletMap[chain.id];
    }

    const chainRPC = this.rpcConfig[chain.id].rpc;
    if (!chainRPC) {
      return undefined;
    }

    const balAddr = this.balAddrConfig && this.balAddrConfig[chain.id] ? this.balAddrConfig[chain.id].address : undefined;

    let chainWallet = createWallet(chain, {
      rpc: chainRPC,
      mnemonic: this.mnemonic,
      balanceContractAddr: balAddr
    });

    if (chainWallet) {
      this.chainWalletMap[chain.id] = chainWallet;
    }

    return chainWallet;
  }
}

export default MultiChainWallet;
