import React from "react";
import styled from "styled-components";
import NotifyCTAButton from "../NotifyCTAButton";
import {
   AlertNotifyModel,
   NFAlertAccountSecurityData,
} from "../../../../models/notification/AlertNotifyModel";

const genAddressText = (address?: string): string => {
   if (!address) {
      return "";
   }
   const trimmedText = `${address.slice(0, 6)}...${address.slice(-6)}`;
   const result = `From: ${trimmedText}`;
   return result;
}

export interface AlertAccountSecurityItemProps {
   item: AlertNotifyModel<NFAlertAccountSecurityData>;
}

const AlertAccountSecurityItem: React.FC<AlertAccountSecurityItemProps> = ({
   item
}) => {
   const itemData = item.data as NFAlertAccountSecurityData;

   return (
      <ContentWrapper>
         <AlertIcon src={item.logo} />

         <DetailContainer>
            <TitleText>{item.title}</TitleText>
            <DescriptionText>{item.description}</DescriptionText>

            <AccountSecurityWrapper>
               <AccountAvatar src={itemData.avatar} />
               <AccountAddress>
                  {genAddressText(itemData.address)}
               </AccountAddress>
            </AccountSecurityWrapper>

            {itemData.ctaButton && (
               <NotifyCTAButton info={itemData.ctaButton} />
            )}
         </DetailContainer>

      </ContentWrapper>
   )
};

const ContentWrapper = styled.div`
   display: flex;
   flex-direction: row;
   box-sizing: border-box;
   width: 100%;
   padding: 16px;
   gap: 8px;
   background-color: #FFF7E6;
`;

const AlertIcon = styled.img`
   display: flex;
   width: 40px;
   height: 40px;
   border-radius: 100%; 
`;

const DetailContainer = styled.div`
   display: flex;
   flex-direction: column;
   font-family: 'Plus Jakarta Sans', sans-serif;
   gap: 8px;
`;

const TitleText = styled.span`
   font-weight: 600;
   font-size: 14px;
   color: #000000;
`;

const DescriptionText = styled.span`
   font-weight: 400;
   font-size: 12px;
   color: #595959;
`;

const AccountSecurityWrapper = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   max-width: 100%;
   overflow: hidden;
   gap: 8px;
   box-sizing: border-box;
   font-family: 'Plus Jakarta Sans', sans-serif;
`;

const AccountAvatar = styled.img`
   width: 24px;
   height: 24px;
   border-radius: 100%;
`;

const AccountAddress = styled.span`
   font-weight: 500;
   font-size: 12px;
   line-height: 20px;
   color: #262626;
`;

export default AlertAccountSecurityItem;