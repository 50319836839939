import {IService} from "../IService";
import {IStartupLoader, StartupLoader} from "./StartupLoader";
import ServiceManagerIns from "../ServiceManager";
import {UpToDateAccountDetailTaskExecutor} from "../SchedulerService/TaskExecutor/TaskExecutor";

export interface IStartupService extends IService {
  loadData(): Promise<boolean>;
  startScheduler(): Promise<boolean>;
}

class StartupService implements IStartupService {
  protected loader: IStartupLoader;
  constructor() {
    this.loader = new StartupLoader();
  }

  startService(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      await this.loadData();
      await this.startScheduler();
      resolve(true);
    });
  }

  stopService(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      resolve(true);
    });
  }

  loadData(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      const startTime = Date.now();
      await this.loader.loadWeb3Config();
      const web3ConfigTime = Date.now();
      console.log(`Load web3 config. Time: ${web3ConfigTime - startTime}`);
      await this.loader.loadSupportedTokens();
      const supportedTokenTime = Date.now();
      console.log(`Load supported tokens. Time: ${supportedTokenTime - web3ConfigTime}`);
      await this.loader.loadCachedAccounts();
      const accountTime = Date.now();
      console.log(`Load accounts. Time: ${accountTime - supportedTokenTime}`);
      resolve(true);
    });
  }

  startScheduler(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      ServiceManagerIns.schedulerService.addTask("up_to_date_balance", new UpToDateAccountDetailTaskExecutor());
      resolve(true);
    });
  }
}

export default StartupService;
