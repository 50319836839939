export enum RoutePath {
  Onboarding = '/onboarding',
  Ob_CreateWalletRoutes = '/onboarding/createWallet/*',
  Ob_ImportWalletRoutes = '/onboarding/importWallet/*',
  Ob_RestoreWalletRoutes = '/onboarding/restoreWallet/*',
  Ob_RocketLaunch = '/onboarding/rocket/*',

  Home = '/home',
  SwapBridge = '/swapbridge',
  Setting = '/setting',
  Receive = '/receive',
  Notification = '/notification',
  SendAction = '/send',
  SendPreview= '/send_preview',
}

export enum ErrorType {
  Common = 'common',
  Network = 'network',
  Blockchain = 'blockchain',
  Data = 'data'
};

export enum AlertType {
  Default = 'Default',
  Info = 'Info',
  Success = 'Success',
  Warning = 'Warning',
  Error = 'Error',
}