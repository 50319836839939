import {AssetToken, CryptoChain} from "../../models/web3/Web3Model";
import ServiceManagerIns from "../../services/ServiceManager";

export interface ICommonBizModel extends ISearchChainBSBiz, ISearchTokenBSBiz {

}

interface ISearchChainBSBiz {
  getChains(showNoBalance?: boolean): Promise<CryptoChain[]>;
}

interface ISearchTokenBSBiz {
  getTokens(chain?: CryptoChain, showNoBalance?: boolean): Promise<AssetToken[]>;
}

class CommonBizModel implements ICommonBizModel {
  getChains(showNoBalance?: boolean): Promise<CryptoChain[]> {
    return new Promise<CryptoChain[]>(async resolve => {
      if (showNoBalance) {
        const chains = await ServiceManagerIns.web3Service.getSupportedChains();
        resolve(chains);
      } else {
        const chains = await ServiceManagerIns.accountService.getActiveChainList();
        resolve(chains);
      }
    });
  }

  getTokens(chain?: CryptoChain, showNoBalance?: boolean): Promise<AssetToken[]> {
    return new Promise<AssetToken[]>(async resolve => {
      const assetTokenList = await ServiceManagerIns.accountService.getActiveTokenList(chain);
      if (!assetTokenList && !showNoBalance) {
        resolve([]);
        return;
      }

      const sortedAssetTokenList = assetTokenList ? assetTokenList.sort((tokenA, tokenB) => tokenB.quote.quote - tokenA.quote.quote) : [];
      if (!showNoBalance) {
        resolve(sortedAssetTokenList);
        return;
      }

      const tokenList = await ServiceManagerIns.web3Service.getSupportedTokens(chain);
      if (!assetTokenList) {
        resolve(tokenList.map(token => <AssetToken>{
            ...token,
            balance: {
              amount: 0n,
              amountInETH: 0
            },
            quote: {
              quote: 0,
              beforeQuote: 0,
              quoteChangePerc: 0
            }
          }
        ));

        return;
      }

      const noBalanceTokenList = tokenList.filter(token => {
        const findIndex = assetTokenList.findIndex(assetToken => assetToken.chain.id === token.chain.id && assetToken.address === token.address);
        if (findIndex === -1) {
          return true;
        }
        return false;
      });

      const noBLAssetTokenList = noBalanceTokenList.map(token => <AssetToken>{
          ...token,
          balance: {
            amount: 0n,
            amountInETH: 0
          },
          quote: {
            quote: 0,
            beforeQuote: 0,
            quoteChangePerc: 0
          }
        }
      )

      const mergedAssetTokenList = sortedAssetTokenList.concat(noBLAssetTokenList);
      resolve(mergedAssetTokenList);
    });
  }
}

export default CommonBizModel;
