import React from "react";
import styled from "styled-components";
import {SBTransaction} from "../../../models/swap_bridge/SwapBridgeModel";
import SwapBridgeHistoryList from "./SwapBridgeHistoryList";

interface SwapBridgeHistory {
  sbTransactions?: SBTransaction[];
}

const SwapBridgeHistory: React.FC<SwapBridgeHistory> = ({
                                                          sbTransactions
                                                        }) => {

  return (
    <Container>
      <Header>
        <Title>History</Title>
      </Header>
      <MainContent>
        {
          !sbTransactions || sbTransactions.length === 0
            ? (
              <EmptyContent>
                <EmptyIcon src='/icons/ic_swapbridge_history_empty.svg'/>
                <EmptyDesc>No transaction record at present</EmptyDesc>
              </EmptyContent>
            )
            : (
              <TransactionListArea>
                <SeparatorLine />
                <SwapBridgeHistoryList sbTransactions={sbTransactions}/>
              </TransactionListArea>
            )
        }
      </MainContent>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: #fff;
  width: 100%;
  font-family: 'Plus Jakarta Sans', sans-serif;
  padding: 16px;
  box-sizing: border-box;
  gap: 16px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Title = styled.span`
  color: #262626;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`

const MainContent = styled.div`
  display: flex;
  width: 100%;
`

const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  align-items: center;
`

const EmptyIcon = styled.img`
  object-position: center;
  object-fit: none;
`

const EmptyDesc = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #8c8c8c;
`

const TransactionListArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`

const SeparatorLine = styled.div`
  background-color: #f3f3f3;
  width: 100%;
  height: 1px;
`

export default SwapBridgeHistory;
