import React, {useContext, useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import {RoutePath} from "./enums/CommonEnums";
import {Buffer} from 'buffer';
import styled from "styled-components";

import HomeScreen from "./screens/home/HomeScreen";
import OnboardingScreen from "./screens/onboarding/OnboardingScreen";
import CreateWalletRoutes from "./screens/onboarding/create/CreateWalletRoutes";
import ImportWalletRoutes from "./screens/onboarding/import/ImportWalletRoutes";
import RestoreWalletRoutes from "./screens/onboarding/restore/RestoreWalletRoutes";
import RocketLaunchRoutes from "./screens/onboarding/rocketlaunch/RocketLaunchRoutes";
import SwapBridgeScreen from "./screens/swap_bridge/SwapBridgeScreen";
import ReceiveScreen from './screens/receive/ReceiveWalletScreen';
import NotificationScreen from './screens/notification/NotificationScreen';
import SendActionScreen from './screens/send/action/SendActionScreen';
import SendPreviewScreen from './screens/send/preview/SendPreviewScreen';

import LaunchingScreen from "./screens/launching/LaunchingScreen";
import ServiceManagerIns from "./services/ServiceManager";
import {SettingScreen} from "./screens/setting/SettingScreen";
import {AppContext} from "./contexts/AppContext";
import {SBBizModelProvider} from "./contexts/biz_model/SBBizModelContext";
import {HomeBizModelProvider} from "./contexts/biz_model/HomeBizModelContext";

// Ensure Buffer is available globally
if (!global.Buffer) {
  global.Buffer = Buffer;
}

function App() {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw new Error("Context isn't existed");
  }

  const {hasActiveAccount, setHasActiveAccount} = appContext;

  useEffect(() => {
    const loadData = async () => {
      await ServiceManagerIns.startupService.startService();
      const accountDetail = await ServiceManagerIns.accountService.getActiveAccountDetail();
      setHasActiveAccount(accountDetail ? true : false);
      setIsLoaded(true);
    }

    loadData();

    const handleBlur = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

    document.addEventListener('focusout', handleBlur);

    return () => {
      document.removeEventListener('focusout', handleBlur);
    };
  }, []);

  return (
    <AppWrapper>
      <AppContainer>
        <div id="safe-area-inset-bottom" style={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          height: 'env(safe-area-inset-bottom, 0)'
        }}></div>
        {
          isLoaded
            ? (
              <BrowserRouter>
                <AppContent hasActiveAccount={hasActiveAccount}/>
              </BrowserRouter>
            )
            : <LaunchingScreen></LaunchingScreen>
        }
      </AppContainer>
    </AppWrapper>
  );
}

interface AppContentProps {
  hasActiveAccount: boolean;
}

const AppContent: React.FC<AppContentProps> = ({
                                                 hasActiveAccount
                                               }) => {
  const checkNavigateScreen: (screen: React.ReactNode) => React.ReactNode = (screen) => {
    return hasActiveAccount ? screen : <Navigate to="/onboarding"/>;
  }

  return (
    <>
      <Routes>
        <Route path="/" element={
          checkNavigateScreen(
            <HomeBizModelProvider>
              <HomeScreen/>
            </HomeBizModelProvider>
          )}
        />
        <Route path={RoutePath.Onboarding} element={<OnboardingScreen/>}/>
        <Route path={RoutePath.Ob_CreateWalletRoutes} element={<CreateWalletRoutes/>}/>
        <Route path={RoutePath.Ob_ImportWalletRoutes} element={<ImportWalletRoutes/>}/>
        <Route path={RoutePath.Ob_RestoreWalletRoutes} element={<RestoreWalletRoutes/>}/>
        <Route path={RoutePath.Ob_RocketLaunch} element={<RocketLaunchRoutes/>}/>

        <Route path={RoutePath.Home} element={
          checkNavigateScreen(
            <HomeBizModelProvider>
              <HomeScreen/>
            </HomeBizModelProvider>
          )}
        />
        <Route path={RoutePath.SwapBridge} element={
          checkNavigateScreen(
            <SBBizModelProvider>
              <SwapBridgeScreen/>
            </SBBizModelProvider>
          )}
        />
        <Route path={RoutePath.Setting} element={checkNavigateScreen(<SettingScreen/>)}/>
        <Route path={RoutePath.Receive} element={checkNavigateScreen(<ReceiveScreen/>)}/>
        <Route path={RoutePath.Notification} element={checkNavigateScreen(<NotificationScreen/>)}/>
        <Route path={RoutePath.SendAction} element={checkNavigateScreen(<SendActionScreen />)}/>
        <Route path={RoutePath.SendPreview} element={checkNavigateScreen(<SendPreviewScreen />)}/>
      </Routes>
    </>
  )
}

const AppWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const AppContainer = styled.div`
  width: 420px;

  @media (max-width: 500px) {
    width: calc(100vw);
  }
`

export default App;
