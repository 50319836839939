import {AssetToken, CryptoChain, CryptoToken} from "../../models/web3/Web3Model";
import {
  SBEstimateFailed,
  SBEstimateFailedOverLimitData,
  SBEstimateInfo,
  SBExchangeFee,
  SBSupplier
} from "../../models/swap_bridge/SwapBridgeModel";
import {
  SBAPICrossInfo,
  SBAPIGetCrossTransactionDetailRequest,
  SBAPIGetSwapTransactionDetailRequest,
  SBAPISwapExchange,
  SBAPISwapInfo,
  SBAPITransactionDetail
} from "../APIService/models/APIModel";
import ServiceManagerIns from "../ServiceManager";
import {ethers} from "ethers";
import {SBActionType, SBEstimateErrorType, SBExchangeType, SBTransactionStatusType} from "../../enums/SwapBridgeEnums";
import {SBCacheTransaction} from "../CacheService/models/SwapBridgeCacheModel";
import {CryptoAmount} from "../../core/wallet/models/Web3Model";
import {
  TSEVMTransationData,
  TSSOLTransactionData,
  TSTONTransactionData
} from "../TransitSwapService/models/TransitSwapAPIModel";
import {CryptoNetworkType} from "../../enums/Web3Enums";
import {EVMDataBuilder, SOLDataBuilder, TONDataBuilder} from "../TransitSwapService/helpers/TransitSwapDataBuilder";
import {ISingleChainWallet} from "../../core/wallet/multichain_wallet/single_wallet/SingleChainWallet";
import {TransitSwapHelper} from "../TransitSwapService/helpers/TransitSwapHelper";

export interface ISwapBridgeService {
  startGettingEstimateInfo(
    fromToken: CryptoToken,
    toToken: CryptoToken,
    fromAmountInETH: number,
    toAddress: string,
    onUpdate: (estimateInfo: SBEstimateInfo | SBEstimateFailed<any>) => void
  ): Promise<boolean>;

  stopGettingEstimateInfo(): Promise<boolean>;

  startTrackingSBHistory(accountId: number, onUpdate:(sbTransactions: SBCacheTransaction[]) => void): Promise<boolean>;
  stopTrackingSBHistory(): Promise<boolean>;

  swap(fromToken: CryptoToken, toToken: CryptoToken, fromAmount: CryptoAmount, toAddress: string): Promise<boolean>;
}

class SwapBridgeService implements ISwapBridgeService {
  protected latestInfo: SBAPISwapInfo | SBAPICrossInfo | undefined;
  protected loopEstimateInfoTimeout: NodeJS.Timeout | undefined;

  protected sbCacheTransactions: SBCacheTransaction[];
  protected trackingSBTransactionList: SBCacheTransaction[];
  protected onUpdateSBHistory?: (sbTransactions: SBCacheTransaction[]) => void;
  protected loopTrackingTransactionTimeout: NodeJS.Timeout | undefined;

  constructor() {
    this.sbCacheTransactions = [];
    this.trackingSBTransactionList = [];
  }

  startGettingEstimateInfo(
    fromToken: CryptoToken,
    toToken: CryptoToken,
    fromAmountInETH: number,
    toAddress: string,
    onUpdate: (estimateInfo: SBEstimateInfo | SBEstimateFailed<any>) => void
  ): Promise<boolean> {
    if (this.loopEstimateInfoTimeout) {
      clearTimeout(this.loopEstimateInfoTimeout);
    }

    return new Promise<boolean>(resolve => {
      const checkBalance = async () => {
        const fromAssetToken = await ServiceManagerIns.accountService.findAssetToken(fromToken);
        if (!fromAssetToken) {
          return false;
        }

        const fromTokenBalanceInETH = fromAssetToken.balance.amountInETH;
        return fromTokenBalanceInETH >= fromAmountInETH;
      }

      const loopGetEstimateInfo = async () => {
        const fromWallet = await ServiceManagerIns.web3Service.getWeb3Wallet(fromToken.chain);
        const toWallet = await ServiceManagerIns.web3Service.getWeb3Wallet(toToken.chain);
        if (!fromWallet || !toWallet) {
          return;
        }

        if (!(await checkBalance())) {
          onUpdate(<SBEstimateFailed<any>>{
            errorType: SBEstimateErrorType.InsufficientBalance,
            data: {}
          });

          this.loopEstimateInfoTimeout = setTimeout(loopGetEstimateInfo, 10000);
          return;
        }

        const fromAddress = await fromWallet.getWalletAddress();

        const fromAmount = ethers.parseUnits(fromAmountInETH.toString(), fromToken.decimals);
        const toTokenPriceList = await ServiceManagerIns.apiService.web3.getTokenPriceList([{
          address: toToken.address,
          chainId: toToken.chain.id
        }]);

        if (fromToken.chain.id ===  toToken.chain.id) {
          const swapInfo: (SBAPISwapInfo | undefined) = await ServiceManagerIns.apiService.swapBridge.getSwapInfo({
            fromToken: fromToken,
            toToken: toToken,
            fromAmount: fromAmount,
            fromAddress: fromAddress,
            toAddress: toAddress,
            toAmountOutMin: 0n
          });

          if (!swapInfo) {
            onUpdate(<SBEstimateFailed<any>>{
              errorType: SBEstimateErrorType.NoRoute,
              data: {}
            });

            this.loopEstimateInfoTimeout = setTimeout(loopGetEstimateInfo, 10000);
            return;
          }

          const toAmount = BigInt(swapInfo.totalAmountOut);
          const toAmountInETH = Number(ethers.formatUnits(toAmount, toToken.decimals));
          const toAmountQuote = toTokenPriceList && toTokenPriceList.length === 1 ? toAmountInETH * Number(toTokenPriceList[0]) : 0;

          const feeAmount = BigInt(swapInfo.fee);
          const feeAmountInETH = Number(ethers.formatUnits(feeAmount, fromToken.decimals));

          const exchange: (SBAPISwapExchange | undefined) = swapInfo.amounts.length > 0 ? swapInfo.amounts[0] : undefined;
          if (!exchange) {
            onUpdate(<SBEstimateFailed<any>>{
              errorType: SBEstimateErrorType.NoRoute,
              data: {}
            });

            this.loopEstimateInfoTimeout = setTimeout(loopGetEstimateInfo, 10000);
            return;
          }

          const estimateInfo: SBEstimateInfo = {
            amount: {
              amount: toAmount,
              amountInETH: toAmountInETH,
            },
            quote: toAmountQuote,
            exchangeInfo: {
              supplier: {
                type: SBExchangeType.Swap,
                actionType: SBActionType.Swap,
                name: exchange.exchange,
                logo: exchange.icon_url
              },
              feeList: [
                {
                  feeAmount: {
                    amount: feeAmount,
                    amountInETH: feeAmountInETH
                  },
                  feeSymbol: fromToken.symbol
                }
              ],
              estimateAmount: {
                amount: toAmount,
                amountInETH: toAmountInETH
              }
            }
          }

          this.latestInfo = swapInfo;
          onUpdate(estimateInfo);
        } else {
          const crossInfo: (SBAPICrossInfo | undefined) = await ServiceManagerIns.apiService.swapBridge.getCrossInfo({
            fromToken: fromToken,
            toToken: toToken,
            fromAmount:fromAmount,
            fromAddress: fromAddress,
            toAddress: toAddress,
            selectedBridge: "",
            confirmExchange: false
          });

          if (!crossInfo) {
            onUpdate(<SBEstimateFailed<any>>{
              errorType: SBEstimateErrorType.NoRoute,
              data: {}
            });

            this.loopEstimateInfoTimeout = setTimeout(loopGetEstimateInfo, 10000);
            return;
          }

          const targetBridge = crossInfo.bridgeList && crossInfo.bridgeList.length > 0 ? crossInfo.bridgeList[0] : undefined;
          if (!targetBridge) {
            onUpdate(<SBEstimateFailed<SBEstimateFailedOverLimitData>>{
              errorType: SBEstimateErrorType.TooLow,
              data: {
                minAmount: {
                  amount: BigInt(crossInfo.minAmt.amt),
                  amountInETH: Number(crossInfo.minAmt.amtShow)
                },
                maxAmount: {
                  amount: BigInt(crossInfo.maxAmt.amt),
                  amountInETH: Number(crossInfo.maxAmt.amtShow)
                }
              }
            });

            this.loopEstimateInfoTimeout = setTimeout(loopGetEstimateInfo, 10000);
            return;
          }

          const toAmount = BigInt(targetBridge.receiveAmount.amt);
          const toAmountInETH = Number(targetBridge.receiveAmount.amtShow);
          const toAmountQuote = toTokenPriceList && toTokenPriceList.length === 1 ? toAmountInETH * Number(toTokenPriceList[0]) : 0;

          const fromFee: SBExchangeFee | undefined = targetBridge.fee
            ? {
              feeAmount: {
                amount: BigInt(targetBridge.fee.amt),
                amountInETH: Number(targetBridge.fee.amtShow)
              },
              feeSymbol: targetBridge.fee.symbol
            }
            : undefined;

          const toFee: SBExchangeFee | undefined = targetBridge.relayerFee
            ? {
              feeAmount: {
                amount: BigInt(targetBridge.relayerFee.amt),
                amountInETH: Number(targetBridge.relayerFee.amtShow)
              },
              feeSymbol: targetBridge.relayerFee.symbol
            }
            : undefined;

          const feeList: SBExchangeFee[] = [];
          if (fromFee && fromFee.feeAmount.amount > 0n) {
            feeList.push(fromFee);
          }
          if (toFee && toFee.feeAmount.amount > 0n) {
            feeList.push(toFee);
          }

          const estimateInfo: SBEstimateInfo = {
            amount: {
              amount: toAmount,
              amountInETH: toAmountInETH,
            },
            quote: toAmountQuote,
            exchangeInfo: {
              supplier: {
                type: SBExchangeType.Bridge,
                actionType: SBActionType.Deposit,
                name: targetBridge.bridge,
                logo: targetBridge.icon
              },
              feeList: feeList,
              estimateAmount: {
                amount: toAmount,
                amountInETH: toAmountInETH
              }
            }
          }

          this.latestInfo = crossInfo;
          onUpdate(estimateInfo);
        }

        this.loopEstimateInfoTimeout = setTimeout(loopGetEstimateInfo, 10000);
      };

      loopGetEstimateInfo();
      resolve(true);
    });
  }

  stopGettingEstimateInfo(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.latestInfo = undefined;
      clearTimeout(this.loopEstimateInfoTimeout);
      resolve(true);
    });
  }

  startTrackingSBHistory(accountId: number, onUpdate: (sbTransactions: SBCacheTransaction[]) => void): Promise<boolean> {
    this.onUpdateSBHistory = onUpdate;
    return new Promise<boolean>(async resolve => {
      const sbTransactions = await ServiceManagerIns.cacheService.swapBridgeCache.getCacheSBTransactions(accountId);
      this.sbCacheTransactions = sbTransactions;
      onUpdate(sbTransactions);

      this.trackingSBTransactionList = sbTransactions.filter(sbTransaction => sbTransaction.status === SBTransactionStatusType.Processing);
      const loopTrackingSBTransactions = async () => {
        let hasUpdate = false;
        console.log(`[Tracking SB History] Count: ${this.trackingSBTransactionList.length}`);
        for (const [index, sbCacheTransaction] of this.trackingSBTransactionList.entries()) {
          if (sbCacheTransaction.status === SBTransactionStatusType.Failed || sbCacheTransaction.status === SBTransactionStatusType.Success) {
            this.trackingSBTransactionList.splice(index, 1);
            console.log(`[Tracking SB History] Splice ${sbCacheTransaction.status} transaction. ActionId: ${sbCacheTransaction.actionId}`);
            continue;
          }

          if (sbCacheTransaction.status !== SBTransactionStatusType.Processing) {
            continue;
          }

          const transactionDetail = await this._getTransactionDetail(sbCacheTransaction);
          if (!transactionDetail) {
            console.log(`[Tracking SB History] Transaction detail not found. ActionId: ${sbCacheTransaction.actionId}`);
            continue;
          }

          const transactionStatus = transactionDetail.status;
          if (transactionStatus === "Success" || transactionStatus === "Failure") {
            const toTxHash = transactionDetail.targetHash;
            const sourceExplorer = transactionDetail.sourceExplorer;
            const toExplorer = transactionDetail.targetExplorer;
            const endTime = transactionDetail.timestamp;
            const amountOut = transactionDetail.amountOut;
            const tokenToDecimals = sbCacheTransaction.toToken.decimals;

            sbCacheTransaction.status = transactionStatus === "Success" ? SBTransactionStatusType.Success : SBTransactionStatusType.Failed;
            sbCacheTransaction.toTxHash = toTxHash;
            if (sbCacheTransaction.supplier.type === SBExchangeType.Swap) {
              sbCacheTransaction.toAmount = {
                amount: BigInt(amountOut),
                amountInETH: Number(ethers.formatUnits(amountOut, tokenToDecimals))
              };
            } else {
              sbCacheTransaction.toAmount = {
                amount: ethers.parseUnits(amountOut, tokenToDecimals),
                amountInETH: Number(amountOut)
              };
            }

            sbCacheTransaction.fromExplorer = {
              chainId: sbCacheTransaction.fromToken.chain.id,
              explorer: sourceExplorer,
              logo: ""
            };
            sbCacheTransaction.toExplorer = {
              chainId: sbCacheTransaction.toToken.chain.id,
              explorer: toExplorer,
              logo: ""
            };
            sbCacheTransaction.endTime = endTime;

            await ServiceManagerIns.accountService.checkWalletBalance([sbCacheTransaction.fromToken, sbCacheTransaction.toToken]);

            await this._updateTransaction(sbCacheTransaction);
            this.trackingSBTransactionList.splice(index, 1);
            hasUpdate = true;

            console.log(`[Tracking SB History] Transaction complete with status ${sbCacheTransaction.status}. ActionId: ${sbCacheTransaction.actionId}`);
          }
        }

        if (hasUpdate) {
          onUpdate(this.sbCacheTransactions);
        }

        this.loopTrackingTransactionTimeout = setTimeout(loopTrackingSBTransactions, 5000);
      }

      this.loopTrackingTransactionTimeout = setTimeout(loopTrackingSBTransactions, 5000);
      resolve(true);
    });
  }

  stopTrackingSBHistory(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.onUpdateSBHistory = undefined;
      this.sbCacheTransactions = [];
      this.trackingSBTransactionList = [];
      clearTimeout(this.loopTrackingTransactionTimeout);
      resolve(true);
    });
  }

  swap(fromToken: CryptoToken, toToken: CryptoToken, fromAmount: CryptoAmount, toAddress: string): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      const sbCacheTransaction = await this._storeSBCacheTransaction(fromToken, toToken, fromAmount, toAddress);
      this.sbCacheTransactions = [sbCacheTransaction, ...this.sbCacheTransactions];
      this.trackingSBTransactionList.push(sbCacheTransaction);
      if (this.onUpdateSBHistory) {
        this.onUpdateSBHistory(this.sbCacheTransactions);
      }

      if (sbCacheTransaction.supplier.type === SBExchangeType.Swap) {
        await this._swap(sbCacheTransaction);
      } else {
        await this._bridge(sbCacheTransaction);
      }

      resolve(true);
    });
  }

  _swap(sbCacheTransaction: SBCacheTransaction): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      const fromChain = sbCacheTransaction.fromToken.chain;
      const fromWallet = await ServiceManagerIns.web3Service.getWeb3Wallet(fromChain);
      if (!fromWallet) {
        reject({code: -1, message: "Wallet isn't founded"});
        return;
      }
      const fromAddress = await fromWallet.getWalletAddress();
      const latestSwapInfo = this.latestInfo as SBAPISwapInfo;
      const toAmountOutMin = latestSwapInfo.totalAmountOutMin;

      const swapInfo = await ServiceManagerIns.apiService.swapBridge.getSwapInfo({
        fromToken: sbCacheTransaction.fromToken,
        toToken: sbCacheTransaction.toToken,
        fromAmount: sbCacheTransaction.fromAmount.amount,
        fromAddress: fromAddress,
        toAddress: sbCacheTransaction.toAddress,
        toAmountOutMin: BigInt(toAmountOutMin)
      });

      if (!swapInfo) {
        sbCacheTransaction.status = SBTransactionStatusType.Failed;
        await this._updateTransaction(sbCacheTransaction);
        resolve(false);
        return;
      }

      const interactiveAddress = swapInfo.aggregator;
      const fromTokenAddress = sbCacheTransaction.fromToken.address;
      const fromAmount = sbCacheTransaction.fromAmount.amount;
      const isNative = sbCacheTransaction.fromToken.address.length === 0;
      const value = isNative ? fromAmount : 0n;

      if (!isNative) {
        const approveResult = await fromWallet.approve({
          toAddress: interactiveAddress,
          tokenAddress: fromTokenAddress,
          amount: fromAmount
        });

        if (!approveResult) {
          sbCacheTransaction.status = SBTransactionStatusType.Failed;
          await this._updateTransaction(sbCacheTransaction);
          resolve(false);
          return;
        }
      }

      const transactionDataList = await this._getSwapTransactionDataList(fromChain, fromWallet, swapInfo.data);
      for (const transactionData of transactionDataList) {
        await fromWallet.sendTransaction({
          transactionData: transactionData,
          toAddress: interactiveAddress,
          value: value,
          onSent: async (txHash) => {
            sbCacheTransaction.fromTxHash = txHash;
            sbCacheTransaction.status = SBTransactionStatusType.Processing;
            await this._updateTransaction(sbCacheTransaction);
          },
          onFailure: async (error) => {
            sbCacheTransaction.status = SBTransactionStatusType.Failed;
            await this._updateTransaction(sbCacheTransaction);
          }
        });
      }

      resolve(true);
    });
  }

  _bridge(sbCacheTransaction: SBCacheTransaction): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      const fromChain = sbCacheTransaction.fromToken.chain;
      const fromWallet = await ServiceManagerIns.web3Service.getWeb3Wallet(fromChain);
      if (!fromWallet) {
        reject({code: -1, message: "Wallet isn't founded"});
        return;
      }

      const fromAddress = await fromWallet.getWalletAddress();
      const latestCrossInfo = this.latestInfo as SBAPICrossInfo;
      const bridgeInfo = latestCrossInfo.bridgeList[0];

      const crossInfo = await ServiceManagerIns.apiService.swapBridge.getCrossInfo({
        fromToken: sbCacheTransaction.fromToken,
        toToken: sbCacheTransaction.toToken,
        fromAmount: sbCacheTransaction.fromAmount.amount,
        fromAddress: fromAddress,
        toAddress: sbCacheTransaction.toAddress,
        selectedBridge: bridgeInfo.bridge,
        confirmExchange: true
      });

      if (!crossInfo) {
        sbCacheTransaction.status = SBTransactionStatusType.Failed;
        await this._updateTransaction(sbCacheTransaction);
        resolve(false);
        return;
      }

      const fullBridgeInfo = crossInfo.bridgeList[0];
      if (!fullBridgeInfo) {
        sbCacheTransaction.status = SBTransactionStatusType.Failed;
        await this._updateTransaction(sbCacheTransaction);
        resolve(false);
        return;
      }

      const tsTransactionData = fullBridgeInfo.transaction;
      const isNative = sbCacheTransaction.fromToken.address.length === 0;

      sbCacheTransaction.orderId = fullBridgeInfo.orderId;

      if (tsTransactionData.depositAddress) {
        if (isNative) {
          await fromWallet.sendNative({
            toAddress: tsTransactionData.depositAddress,
            amount: sbCacheTransaction.fromAmount.amount,
            onSent: async (txHash) => {
              sbCacheTransaction.fromTxHash = txHash;
              sbCacheTransaction.status = SBTransactionStatusType.Processing;
              await this._updateTransaction(sbCacheTransaction);
            },
            onFailure: async (error) => {
              sbCacheTransaction.status = SBTransactionStatusType.Failed;
              await this._updateTransaction(sbCacheTransaction);
            }
          });
        } else {
          await fromWallet.sendToken({
            tokenAddress: sbCacheTransaction.fromToken.address,
            toAddress: sbCacheTransaction.toAddress,
            amount: sbCacheTransaction.fromAmount.amount,
            decimals: sbCacheTransaction.fromToken.decimals,
            onSent: async (txHash) => {
              sbCacheTransaction.fromTxHash = txHash;
              sbCacheTransaction.status = SBTransactionStatusType.Processing;
              await this._updateTransaction(sbCacheTransaction);
            },
            onFailure: async (error) => {
              sbCacheTransaction.status = SBTransactionStatusType.Failed;
              await this._updateTransaction(sbCacheTransaction);
            }
          });
        }
      } else if (tsTransactionData.swapData && tsTransactionData.routeContract) {
        sbCacheTransaction.supplier.actionType = SBActionType.Swap;

        if (!isNative) {
          const approveResult = await fromWallet.approve({
            toAddress: tsTransactionData.routeContract,
            tokenAddress: sbCacheTransaction.fromToken.address,
            amount: sbCacheTransaction.fromAmount.amount
          });

          if (!approveResult) {
            sbCacheTransaction.status = SBTransactionStatusType.Failed;
            await this._updateTransaction(sbCacheTransaction);
            resolve(false);
            return;
          }
        }

        const transactionDataList = await this._getSwapTransactionDataList(sbCacheTransaction.fromToken.chain, fromWallet, tsTransactionData.swapData);
        for (const transactionData of transactionDataList) {
          await fromWallet.sendTransaction({
            transactionData: transactionData,
            toAddress: tsTransactionData.routeContract,
            value: isNative ? sbCacheTransaction.fromAmount.amount : 0n,
            onSent: async (txHash) => {
              sbCacheTransaction.fromTxHash = txHash;
              sbCacheTransaction.status = SBTransactionStatusType.Processing;
              await this._updateTransaction(sbCacheTransaction);
            },
            onFailure: async (error) => {
              sbCacheTransaction.status = SBTransactionStatusType.Failed;
              await this._updateTransaction(sbCacheTransaction);
            }
          });
        }
      } else {
        sbCacheTransaction.status = SBTransactionStatusType.Failed;
        await this._updateTransaction(sbCacheTransaction);
        resolve(false);
      }

      await this._updateTransaction(sbCacheTransaction);
      resolve(true);
    });
  }

  _updateTransaction(sbTransaction: SBCacheTransaction): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      await ServiceManagerIns.cacheService.swapBridgeCache.updateSBTransaction(sbTransaction);
      switch (sbTransaction.status) {
        case SBTransactionStatusType.Failed:
        case SBTransactionStatusType.Success:
          const trackingIndex = this.trackingSBTransactionList.indexOf(sbTransaction);
          if (trackingIndex !== -1) {
            this.trackingSBTransactionList.splice(trackingIndex, 1);
          }
          break;
        default: break;
      }

      if (this.onUpdateSBHistory) {
        this.onUpdateSBHistory(this.sbCacheTransactions);
      }

      resolve(true);
    });
  }

  _storeSBCacheTransaction(fromToken: CryptoToken, toToken: CryptoToken, fromAmount: CryptoAmount, toAddress: string): Promise<SBCacheTransaction> {
    return new Promise<SBCacheTransaction>(async (resolve, reject) => {
      const accountDetail = await ServiceManagerIns.accountService.getActiveAccountDetail();
      if (!accountDetail) {
        reject(-1);
        return;
      }

      const accountId = accountDetail.id;
      const exchangeType = fromToken.chain.id === toToken.chain.id ? SBExchangeType.Swap : SBExchangeType.Bridge
      let toAmount: CryptoAmount;
      let supplier: SBSupplier;
      if (exchangeType === SBExchangeType.Swap) {
        const swapInfo = this.latestInfo as SBAPISwapInfo;
        const amount = BigInt(swapInfo.totalAmountOut)
        const amountInETH = Number(ethers.formatUnits(amount, toToken.decimals));
        toAmount = {
          amount: amount,
          amountInETH: amountInETH
        };

        const exchange = swapInfo.amounts[0];
        supplier = {
          type: SBExchangeType.Swap,
          actionType: SBActionType.Swap,
          name: exchange.exchange,
          logo: exchange.icon_url
        };
      } else {
        const bridgeInfo = (this.latestInfo as SBAPICrossInfo).bridgeList[0];
        toAmount = {
          amount: BigInt(bridgeInfo.receiveAmount.amt),
          amountInETH: Number(bridgeInfo.receiveAmount.amtShow)
        };

        supplier = {
          type: SBExchangeType.Bridge,
          actionType: SBActionType.Deposit,
          name: bridgeInfo.bridge,
          logo: bridgeInfo.icon
        };
      }

      const sbCacheTransaction: SBCacheTransaction = {
        actionId: crypto.randomUUID(),
        orderId: "",
        accountId: accountId,
        status: SBTransactionStatusType.Pending,
        supplier: supplier,
        createTime: Date.now(),
        endTime: 0,
        toAddress: toAddress,
        fromToken: fromToken,
        toToken: toToken,
        fromAmount: fromAmount,
        toAmount: toAmount,
      }

      await ServiceManagerIns.cacheService.swapBridgeCache.cacheSBTransaction(sbCacheTransaction);
      resolve(sbCacheTransaction);
    });
  }

  _getSwapTransactionDataList(chain: CryptoChain, web3Wallet: ISingleChainWallet, transactionData: TSEVMTransationData | TSSOLTransactionData | TSTONTransactionData): Promise<any[]> {
    return new Promise<any[]>(async resolve => {
      let transactionDataBuilder = null;
      switch (chain.network) {
        case CryptoNetworkType.EVM:
          transactionDataBuilder = new EVMDataBuilder();
          break;
        case CryptoNetworkType.SOL:
          transactionDataBuilder = new SOLDataBuilder();
          break;
        case CryptoNetworkType.TON:
          transactionDataBuilder = new TONDataBuilder();
          break;
        default:
          return [];
          break;
      }

      if (!transactionDataBuilder) {
        return [];
      }

      const signer = await web3Wallet.getSigner();
      const transactionDataList = await transactionDataBuilder.buildTransactionDataList(transactionData, signer);

      resolve(transactionDataList);
    });
  }

  _getTransactionDetail(sbCacheTransaction: SBCacheTransaction): Promise<SBAPITransactionDetail | undefined> {
    switch (sbCacheTransaction.supplier.actionType) {
      case SBActionType.Swap:
        return ServiceManagerIns.apiService.swapBridge.getTransactionDetails(<SBAPIGetSwapTransactionDetailRequest>{
          hash: sbCacheTransaction.fromTxHash,
          chainId: sbCacheTransaction.fromToken.chain.chain_id,
          ns: TransitSwapHelper.convertNsFromNetwork(sbCacheTransaction.fromToken.chain.network)
        });
      case SBActionType.Deposit:
        return ServiceManagerIns.apiService.swapBridge.getTransactionDetails(<SBAPIGetCrossTransactionDetailRequest>{
          orderId: sbCacheTransaction.orderId
        });
    }
  }
}


export default SwapBridgeService;
