import React, {createContext, useState} from "react";

interface AppContextType {
  hasActiveAccount: boolean;
  setHasActiveAccount: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppContext = createContext<AppContextType | undefined>(undefined);
export const AppProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
  const [hasActiveAccount, setHasActiveAccount] = useState<boolean>(false);

  return (
    <AppContext.Provider value={{
      hasActiveAccount,
      setHasActiveAccount,
    }}>
      {children}
    </AppContext.Provider>
  );
}
