import React, {useContext, useEffect, useState} from "react";
import WorkflowScreen, {WorkflowProps} from "../common/WorkflowScreen";
import styled from "styled-components";
import OnboardingTitle from "../../../components/onboarding/OnboardingTitle";
import PassphraseShowing from "../../../components/onboarding/PassphraseShowing/PassphraseShowing";
import {BizContext} from "../../../contexts/BizContext";
import {PassphraseWordAmountType} from "../../../enums/WalletEnums";
import CheckBox from "../../../components/common/CheckBox";

const NewPassphraseScreen: React.FC<WorkflowProps> = ({
                                                        onNext
                                                      }) => {
  const bizContext = useContext(BizContext);
  if (!bizContext) {
    throw new Error("Context isn't existed");
  }

  const { createWalletBizModel } = bizContext;

  const [wordAmount, setWordAmount] = useState<number>(0);
  const [words_12, setWords_12] = useState<string[]>([]);
  const [words_24, setWords_24] = useState<string[]>([]);
  const [savedPharse, setSavedPhrase] = useState<boolean>(false);

  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const _words_12 = await createWalletBizModel.generatePassphrase(PassphraseWordAmountType._12);
      const _words_24 = await createWalletBizModel.generatePassphrase(PassphraseWordAmountType._24);
      setWords_12(_words_12);
      setWords_24(_words_24);

      setTimeout(() => {
        setReady(true);
      }, 1);
    }

    fetchData();
  }, [])

  const handleChangeWordAmount = (wordAmount: PassphraseWordAmountType) => {
    setWordAmount(wordAmount);
  }

  const handleCheckSavePhrase = () => {
    setSavedPhrase(true);
  }

  const handleUncheckSavePhrase = () => {
    setSavedPhrase(false);
  }

  const handleCopyToClipboard = () => {
    alert("Copied!");
    const targetWords = wordAmount === PassphraseWordAmountType._12 ? words_12 : words_24;
    navigator.clipboard.writeText(targetWords.join(' '));
  }

  const handleNext = () => {
    const setData = async () => {
      await createWalletBizModel.setPassphrase(wordAmount === PassphraseWordAmountType._12 ? words_12 : words_24);
      onNext();
    }

    setData();
  }

  return (
    <WorkflowScreen>
      <ContentWrapper visible={ready}>
        <OnboardingTitle
          title={"Secret Recovery Phrase"}
          subtitle={"This phrase is the only way to recover your wallet. Do not share this phrase with anyone."}
        />
        <PassphaseArea>
          <PassphraseShowing
            words_12={words_12}
            words_24={words_24}
            blur={true}
            onChangeWordAmount={handleChangeWordAmount}
          />
        </PassphaseArea>
        <AcceptionArea>
          <CheckBox
            check={savedPharse}
            allowUnCheck={true}
            onCheck={handleCheckSavePhrase}
            onUncheck={handleUncheckSavePhrase}
          />
          <AcceptionTitle>I have saved my secret recovery phrase.</AcceptionTitle>
          <CopyArea onClick={handleCopyToClipboard}>
            <CopyTitle>Copy</CopyTitle>
            <CopyIcon src={'/icons/ic_copy.svg'} />
          </CopyArea>
        </AcceptionArea>
        <NextButton
          disabled={!savedPharse}
          onClick={handleNext}
        >Next</NextButton>
      </ContentWrapper>
    </WorkflowScreen>
  )
}

const ContentWrapper = styled.div<{ visible: boolean | undefined }>`
  display: flex;
  opacity: ${(props) => props.visible ? '1' : '0'};
  flex-direction: column;
  align-items: center;
  gap: 16px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  padding: 24px 16px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
`

const PassphaseArea = styled.div`
  display: flex;
  width: 100%;
  overflow-y: auto;
`

const AcceptionArea = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`

const AcceptionTitle = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #8c8c8c;
`

const CopyArea = styled.div`
  display: flex;
  padding: 8px;
  gap: 6px;
  border-radius: 12px;
  background-color: #f3f3f3;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
`

const CopyTitle = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`

const CopyIcon = styled.img`
  width: 12px;
  height: 12px;
`

const NextButton = styled.button`
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: #262626;
  color: #fff;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  border-style: none;

  &:disabled {
    background-color: #D9D9D9;
    color: #8C8C8C;
    cursor: not-allowed;
  }
`

export default NewPassphraseScreen;
