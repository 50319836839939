import React, {useContext, useEffect, useState} from "react";
import BottomSheet from "../../common/BottomSheet";
import styled from "styled-components";
import {AccountInfo} from "../../../models/home/HomeModel";
import {BizContext} from "../../../contexts/BizContext";

interface AccountSelectionBottomSheetProps {
  onClose: () => void;
}

const AccountSelectionBottomSheet:React.FC<AccountSelectionBottomSheetProps> = ({onClose}) => {
  const bizContext = useContext(BizContext);
  if (!bizContext) {
    throw new Error("Context isn't existed");
  }

  const {homeBizModel} = bizContext;

  const [accountList, setAccountList] = useState<AccountInfo[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<AccountInfo | undefined>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      const _accountList = await homeBizModel.getAllAccountInfos();
      const _selectedAccount = await homeBizModel.getSelectedAccountInfo();
      setAccountList(_accountList);
      setSelectedAccount(_selectedAccount);
    }
    fetchData();
  }, [])

  return (
    <BottomSheet
      scrollableContent={
        <AccountSelectableContainer>
          {
            accountList.map((accountInfo, index) => (
              <AccountSelectableItem
                key={index}
                accountInfo={accountInfo}
                selected={JSON.stringify(accountInfo) === JSON.stringify(selectedAccount)}
              />
            ))
          }
        </AccountSelectableContainer>
      }
      onClose={onClose}
    />
  )
}

const AccountSelectableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px 24px 24px;
  box-sizing: content-box;
  gap: 24px;
`


interface AccountSelectableItemProps {
  accountInfo: AccountInfo;
  selected: boolean;
}

const AccountSelectableItem:React.FC<AccountSelectableItemProps> = ({
                                                                      accountInfo,
                                                                      selected
                                                                    }) => {
  const [showValue, setShowValue] = useState<boolean>(false);

  const handleShowValue = () => {
    setShowValue(showValue => !showValue);
  }

  return (
    <AccountItemContainer>
      <AccountAvatar src={accountInfo.accountAvatar}/>
      <AccountInfoArea>
        <AccountName>{accountInfo.accountName}</AccountName>
        <AccountValueArea>
          <AccountValue>
            {
              (showValue ? `$${parseFloat(accountInfo.totalQuote.toFixed(6))}` : '********')
            }
          </AccountValue>
          <ShowValueIcon src={showValue ? "/icons/ic_show.svg" : "/icons/ic_hide.svg"} onClick={handleShowValue} />
        </AccountValueArea>
      </AccountInfoArea>
      {
        selected &&
        <AccountStatusArea>
          <AccountStatus>Active</AccountStatus>
        </AccountStatusArea>
      }
    </AccountItemContainer>
  )
}

const AccountItemContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
`

const AccountAvatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 100%;
  object-fit: contain;
  object-position: center;
`

const AccountInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  flex: 1;
`

const AccountName = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: #262626;
`

const AccountValueArea = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;
  align-items: center;
`

const AccountValue = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: #8c8c8c;
  text-align: center;
`

const ShowValueIcon = styled.img`
  width: 16px;
  height: 16px;
`

const AccountStatusArea = styled.div`
  display: flex;
  padding: 6px 12px 6px 12px;
  background-color: #ebfaef;
  border-radius: 28px;
  box-sizing: border-box;
`

const AccountStatus = styled.span`
  color: #039732;
  text-align: center;
  alignment: center;
  font: 500 12px 'Plus Jakarta Sans', sans-serif;
`

export default AccountSelectionBottomSheet;
