import {IService} from "../IService";
import AccountCache, {IAccountCache} from "./AccountCache";
import SettingCache, {ISettingCache} from "./SettingCache";
import SwapBridgeCache, {ISwapBridgeCache} from "./SwapBridgeCache";

export interface ICacheService extends IService {
  accountCache: IAccountCache;
  settingCache: ISettingCache;
  swapBridgeCache: ISwapBridgeCache;

  reset(): Promise<boolean>;
}

class CacheService implements ICacheService {
  public accountCache: IAccountCache;
  public settingCache: ISettingCache;
  public swapBridgeCache: ISwapBridgeCache;

  constructor() {
    this.accountCache = new AccountCache();
    this.settingCache = new SettingCache();
    this.swapBridgeCache = new SwapBridgeCache();
  }

  startService(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      resolve(true);
    });
  }

  stopService(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      resolve(true);
    });
  }

  reset(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      await this.accountCache.reset();
      await this.settingCache.reset();
      resolve(true);
    });
  }
}

export default CacheService;
