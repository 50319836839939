import {CryptoChain} from "../../models/web3/Web3Model";
import {CryptoNetworkType} from "../../enums/Web3Enums";
import ServiceManagerIns from "../ServiceManager";
import {ethers, TransactionRequest} from "ethers";
import {clusterApiUrl, Connection, LAMPORTS_PER_SOL, Transaction, VersionedTransaction} from "@solana/web3.js";

export enum Web3GasType {
  Slow = 'Slow',
  Medium = 'Medium',
  Fast = 'Fast',
}

export interface Web3GasInfo {
  type: Web3GasType;
  amountInETH: number;
  unit: string;
  quote: number;
  estimatedTime: number;
}

export interface Web3GasFetcherSendInfo {
  tokenAddress?: string;
  toAddress: string;
  amount: bigint;
}

export interface IWeb3GasFetcher {
  fetchGasInfo(chain: CryptoChain): Promise<Web3GasInfo[]>;
}

export class MultichainGasFetcher implements IWeb3GasFetcher {
  fetchGasInfo(chain: CryptoChain): Promise<Web3GasInfo[]> {
    let fetcher: IWeb3GasFetcher;
    switch (chain.network) {
      case CryptoNetworkType.EVM:
        fetcher = new EVMGasFetcher();
        break;
      case CryptoNetworkType.SOL:
        fetcher = new SOLGasFetcher();
        break;
      case CryptoNetworkType.TON:
        fetcher = new TONGasFetcher();
        break;
      default:
          fetcher = new EVMGasFetcher();
          break;
    }

    return fetcher.fetchGasInfo(chain);
  }
}

class EVMGasFetcher implements IWeb3GasFetcher {
  fetchGasInfo(chain: CryptoChain): Promise<Web3GasInfo[]> {
    return new Promise<Web3GasInfo[]>(async resolve => {
      const web3Config = await ServiceManagerIns.web3Service.getWeb3Config();
      const rpc = web3Config.rpc[chain.id].rpc;
      const provider = new ethers.JsonRpcProvider(rpc);

      const feeData = await provider.getFeeData();
      if (!feeData.gasPrice) {
        resolve([]);
        return;
      }

      // const transaction = this._buildTransaction(sendInfo);
      // const gasLimit = Number(await provider.estimateGas(transaction));
      const feePerGas = parseFloat(ethers.formatUnits(feeData.gasPrice, "gwei"));
      const mediumFeePerGas = feePerGas + (feeData.maxPriorityFeePerGas ? parseFloat(ethers.formatUnits(feeData.maxPriorityFeePerGas, "gwei")) : feePerGas);
      const fastFeePerGas = feePerGas + (feeData.maxPriorityFeePerGas ? parseFloat(ethers.formatUnits(feeData.maxPriorityFeePerGas, "gwei")) * 2 : feePerGas * 2);
      const nativeTokenPrice = Number((await ServiceManagerIns.apiService.web3.getTokenPriceList([{
        address: "",
        chainId: chain.id
      }]))[0]);
      const tokenGweiPrice = nativeTokenPrice / 1e9;

      resolve([
        {
          type: Web3GasType.Slow,
          amountInETH: feePerGas,
          unit: "gwei",
          quote: feePerGas * tokenGweiPrice,
          estimatedTime: 60
        },
        {
          type: Web3GasType.Medium,
          amountInETH: mediumFeePerGas,
          unit: "gwei",
          quote: mediumFeePerGas * tokenGweiPrice,
          estimatedTime: 30
        },
        {
          type: Web3GasType.Fast,
          amountInETH: fastFeePerGas,
          unit: "gwei",
          quote: fastFeePerGas * tokenGweiPrice,
          estimatedTime: 15
        }
      ]);
    });
  }

  _buildTransaction(sendInfo: Web3GasFetcherSendInfo): TransactionRequest {
    if (!sendInfo.tokenAddress) {
      const txData: TransactionRequest = {
        to: sendInfo.toAddress,
        value: sendInfo.amount
      };

      return txData;
    }

    // Nếu là custom token ERC20
    const ERC20_ABI = [
      "function transfer(address recipient, uint256 amount) public returns (bool)"
    ];

    // Tạo interface contract
    const tokenInterface = new ethers.Interface(ERC20_ABI);

    // Encode function call transfer
    const encodedTransferData = tokenInterface.encodeFunctionData(
      "transfer",
      [sendInfo.toAddress, sendInfo.amount]
    );

    // Build transaction cho token transfer
    return {
      to: sendInfo.tokenAddress,  // Địa chỉ contract token
      data: encodedTransferData,  // Encoded function call
      value: 0n  // Không gửi native token
    };
  }
}

class SOLGasFetcher implements IWeb3GasFetcher {
  fetchGasInfo(chain: CryptoChain): Promise<Web3GasInfo[]> {
    return new Promise<Web3GasInfo[]>(async resolve => {
      // const connection = new Connection(clusterApiUrl('mainnet-beta'));
      //
      // const transaction = this._buildTransaction(sendInfo);
      //
      // // Ước tính số lượng compute units
      // const { value: computeUnits } = await connection.simulateTransaction(transaction);
      //
      // const baseFee = 5000;
      // const feePerUnit = 1;
      // const mediumFeePerUnit = 5;
      // const fastFeePerUnit = 10;
      //
      // const slowFeeAmount = (baseFee + feePerUnit * (computeUnits.unitsConsumed || 1)) / LAMPORTS_PER_SOL;
      // const mediumFeeAmount = (baseFee + mediumFeePerUnit * (computeUnits.unitsConsumed || 1)) / LAMPORTS_PER_SOL;
      // const fastFeeAmount = (baseFee + fastFeePerUnit * (computeUnits.unitsConsumed || 1)) / LAMPORTS_PER_SOL;
      //
      // const nativeTokenPrice = Number((await ServiceManagerIns.apiService.web3.getTokenPriceList([{
      //   address: "",
      //   chainId: chain.id
      // }]))[0]);
      //
      // resolve([
      //   {
      //     type: Web3GasType.Slow,
      //     amountInETH: slowFeeAmount,
      //     quote: slowFeeAmount * nativeTokenPrice,
      //     estimatedTime: 30
      //   },
      //   {
      //     type: Web3GasType.Medium,
      //     amountInETH: mediumFeeAmount,
      //     quote: mediumFeeAmount * nativeTokenPrice,
      //     estimatedTime: 15
      //   },
      //   {
      //     type: Web3GasType.Fast,
      //     amountInETH: fastFeeAmount,
      //     quote: fastFeeAmount * nativeTokenPrice,
      //     estimatedTime: 5
      //   }
      // ]);
    });
  }

  // _buildTransaction(sendInfo: Web3GasFetcherSendInfo): VersionedTransaction {
  //   return new VersionedTransaction[];
  // }
}

class TONGasFetcher implements IWeb3GasFetcher {
  fetchGasInfo(chain: CryptoChain): Promise<Web3GasInfo[]> {
    return new Promise<Web3GasInfo[]>(async resolve => {
      const baseFeeInETH = 0.0055;
      const nativeTokenPrice = (await ServiceManagerIns.apiService.web3.getTokenPriceList([{
        address: "",
        chainId: chain.id
      }]))[0];
      const quote = baseFeeInETH * Number(nativeTokenPrice);

      resolve([
        {
          type: Web3GasType.Slow,
          amountInETH: baseFeeInETH, // Convert nanotons to TON
          unit: "nano",
          quote: quote, // Current TON price
          estimatedTime: 60 // 2 minutes
        },
        {
          type: Web3GasType.Medium,
          amountInETH: baseFeeInETH * 2, // Convert nanotons to TON
          unit: "nano",
          quote: quote * 3, // Current TON price
          estimatedTime: 30 // 2 minutes
        },
        {
          type: Web3GasType.Fast,
          amountInETH: baseFeeInETH * 3, // Convert nanotons to TON
          unit: "nano",
          quote: quote * 3, // Current TON price
          estimatedTime: 15 // 2 minutes
        }
      ]);
    });
  }
}
