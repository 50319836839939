import React, { useState } from "react";
import BottomSheet from "../../common/BottomSheet";
import styled from "styled-components";
import { AccountAction } from "../../../enums/AccountEnums";
import AccountActionItem, { AccountActionItemProps } from "../../home/AccountActionItem";

interface WalletActionBottomSheetProps {
  onClickClose?: () => void;
  onClickAction?: (action: AccountAction) => void;
}
const WalletActionBottomSheet: React.FC<WalletActionBottomSheetProps> = ({
  onClickClose,
  onClickAction
}) => {
  const [forceClose, setForceClose] = useState<boolean>(false);

  const handleClickAction = (action: AccountAction) => {
    if (onClickAction) {
      onClickAction(action)
    };

    setForceClose(true);
  }

  return (
    <BottomSheet
      scrollableContent={
        <WalletActionContainer>
          <SendAction
            icon='/icons/ic_send.svg'
            title='Send'
            isCommingSoon={false}
            onClick={() => { handleClickAction(AccountAction.Send) }}
          />
          <ReceiveAction
            icon='/icons/ic_receive.svg'
            title='Receive'
            isCommingSoon={false}
            onClick={() => {
              handleClickAction(AccountAction.Receive)
            }}
          />
          <SwapAction
            icon='/icons/ic_swap.svg'
            title='Swap & Bridge'
            isCommingSoon={false}
            onClick={() => { handleClickAction(AccountAction.Swap) }}
          />
          <EarnAction
            icon='/icons/ic_earn.svg'
            title='Earn'
            isCommingSoon={true}
            onClick={() => { handleClickAction(AccountAction.Earn) }}
          />
          <OTCAction
            icon='/icons/ic_otc.svg'
            title='Buy OTC'
            isCommingSoon={true}
            onClick={() => { handleClickAction(AccountAction.OTC) }}
          />

        </WalletActionContainer>
      }
      forceClose={forceClose}
      onClose={onClickClose}
    />
  )
}

const WalletActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px 24px 24px;
  box-sizing: border-box;
  gap: 24px;
  width: 100%;
`

const BridgeAction: React.FC<WalletActionItemProps> = (props) => {
  return <WalletActionItem {...props} />;
};

const OTCAction: React.FC<WalletActionItemProps> = (props) => {
  return <WalletActionItem {...props} />;
};

const EarnAction: React.FC<WalletActionItemProps> = (props) => {
  return <WalletActionItem {...props} />;
};

const SwapAction: React.FC<WalletActionItemProps> = (props) => {
  return <WalletActionItem {...props} />;
};

const SendAction: React.FC<WalletActionItemProps> = (props) => {
  return <WalletActionItem {...props} />;
};

const ReceiveAction: React.FC<WalletActionItemProps> = (props) => {
  return <WalletActionItem {...props} />;
};

interface WalletActionItemProps {
  icon: string;
  title: string;
  isCommingSoon: boolean;
  onClick?: () => void;
}

const WalletActionItem: React.FC<WalletActionItemProps> = ({
  icon,
  title,
  isCommingSoon,
  onClick
}) => {
  return (
    <ActionItemContainer onClick={() => { if (onClick) onClick() }}>
      <ActionIcon src={icon} />
      <ActionTitle>{title}</ActionTitle>
      {
        isCommingSoon &&
        <ActionStatusArea>
          <ActionStatus>Comming soon</ActionStatus>
        </ActionStatusArea>
      }
    </ActionItemContainer>
  )
}

const ActionItemContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  align-items: center;
  cursor: pointer;
`

const ActionIcon = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  object-fit: none;
  object-position: center;
  background-color: #f3f3f3;
`

const ActionTitle = styled.span`
  font: 600 14px 'Plus Jakarta Sans', sans-serif;
  color: #262626;
  flex: 1;
`

const ActionStatusArea = styled.div`
  display: flex;
  padding: 6px 12px 6px 12px;
  background-color: #e6f7ff;
  border-radius: 28px;
  box-sizing: border-box;
`

const ActionStatus = styled.span`
  color: #262626;
  text-align: center;
  alignment: center;
  font: 500 12px 'Plus Jakarta Sans', sans-serif;
`

export default WalletActionBottomSheet;
