import React, {useContext} from "react";
import WorkflowScreen, {WorkflowProps} from "../common/WorkflowScreen";
import styled from "styled-components";
import OnboardingTitle from "../../../components/onboarding/OnboardingTitle";
import {BizContext} from "../../../contexts/BizContext";
import {PassphraseWordAmountType} from "../../../enums/WalletEnums";

const SuggestBackupScreen: React.FC<WorkflowProps> = ({
                                                        onNext
                                                      }) => {
  const bizContext = useContext(BizContext);
  if (!bizContext) {
    throw new Error("Context isn't existed");
  }
  const { createWalletBizModel } = bizContext;

  const handleNext = (skip: boolean) => {
    if (skip) {
      const setData = async () => {
        const passphrase = await createWalletBizModel.generatePassphrase(PassphraseWordAmountType._24);
        await createWalletBizModel.setPassphrase(passphrase);
        onNext(skip);
      }
      setData();
    } else {
      onNext(skip);
    }
  }

  return (
    <WorkflowScreen>
      <ContentWrapper>
        <BannerContainer>
          <BannerImg src="/imgs/onboard_backup_banner.svg" fetchPriority="high" />
        </BannerContainer>

        <OnboardingTitle
          title={"Secret Backup Phrase"}
          subtitle={"Protect your assets by backing up your recovery phrase now"}
        />
        <ActionButtonArea>
          <AcceptButton onClick={() => {handleNext(false)}}>Manual Backup</AcceptButton>
          <SkipButton onClick={() => {handleNext(true)}}>Skip</SkipButton>
        </ActionButtonArea>
      </ContentWrapper>
    </WorkflowScreen>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  align-items: center;
  font-family: 'Plus Jakarta Sans', sans-serif;
`

const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 85.38%;
  overflow: hidden;
`

const BannerImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const ActionButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`

const AcceptButton = styled.button`
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: #262626;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
`

const SkipButton = styled.button`
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: transparent;
  color: #262626;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #e8e8e8;
  width: 100%;
  cursor: pointer;
`

export default SuggestBackupScreen;
