import React, {EventHandler, SyntheticEvent, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import WorkflowScreen, {WorkflowProps} from "./WorkflowScreen";
import OnboardingTitle from "../../../components/onboarding/OnboardingTitle";
import CheckBox from "../../../components/common/CheckBox";
import {BizContext} from "../../../contexts/BizContext";
import {useLocation} from "react-router-dom";
import {PasswordStrength} from "../../../enums/SettingEnums";

const CreatePasswordScreen: React.FC<WorkflowProps> = ({
                                                         onNext
                                                       }) => {
  const bizContext = useContext(BizContext);
  if (!bizContext) {
    throw new Error("Context isn't existed");
  }

  const { createWalletBizModel, importWalletBizModel } = bizContext;

  const location = useLocation();

  const [inputPwd, setInputPwd] = useState<string>('');
  const [confirmInputPwd, setConfirmInputPwd] = useState<string>('');

  const [inputPwdLevel, setInputPwdLevel] = useState<number>(0);
  const [confirmInputPwdLevel, setConfirmInputPwdLevel] = useState<number>(0);
  const [allowPolicy, setAllowPolicy] = useState<boolean>(false);

  const [allowNext, setAllowNext] = useState<boolean>(false);

  useEffect(() => {
    if (!inputPwd) {
      setInputPwdLevel(PasswordStrength.Idle);
    }
    else {
      const targetBizModel = _getTargetBizModel();
      if (!targetBizModel) {
        setInputPwdLevel(PasswordStrength.Weak);
      }
      else {
        const pwdLevel = targetBizModel.checkPasswordStrength(inputPwd);
        setInputPwdLevel(pwdLevel);
      }
    }

    if (!confirmInputPwd) {
      setConfirmInputPwdLevel(PasswordStrength.Idle);
    } else {
      if (inputPwd === confirmInputPwd) {
        setConfirmInputPwdLevel(inputPwdLevel);
      } else {
        setConfirmInputPwdLevel(PasswordStrength.Weak);
      }
    }
  }, [inputPwd, confirmInputPwd, inputPwdLevel]);

  useEffect(() => {
    const _allowNext = inputPwdLevel >= PasswordStrength.Strong && confirmInputPwdLevel >= PasswordStrength.Strong && allowPolicy;
    setAllowNext(_allowNext);
  }, [inputPwdLevel, confirmInputPwdLevel, allowPolicy])

  const handleNext = () => {
    const createPwd = async () => {
      const targetBizModel = _getTargetBizModel();
      if (!targetBizModel) {
        _showFailed();
        return;
      }

      const result = await targetBizModel.createPassword(inputPwd);
      if (result) {
        onNext();
      } else {
        alert("Something goes wrong!!!");
      }
    }

    createPwd();
  }

  const _showFailed = () => {

  }

  const _getTargetBizModel = () => {
    if (location.pathname.includes("createWallet")) {
      return createWalletBizModel;
    } else if (location.pathname.includes("importWallet")) {
      return importWalletBizModel;
    }
    return undefined;
  }

  return (
    <WorkflowScreen>
      <ContentWrapper>
        <OnboardingTitle
          title={"Create Password"}
          subtitle={"You will use this to unlock your wallet"}
        />
        <InputArea>
          <PasswordInput
            type="password"
            placeholder="Password"
            value={inputPwd}
            onChange={(e) => {setInputPwd(e.currentTarget.value)}}
            level={inputPwdLevel}
          />
          <PasswordInput
            type="password"
            placeholder="Confirm password"
            value={confirmInputPwd}
            onChange={(e) => {setConfirmInputPwd(e.currentTarget.value)}}
            level={confirmInputPwdLevel}
          />
        </InputArea>
        <PolicyAcceptionArea>
          <CheckBox
            check={allowPolicy}
            allowUnCheck={true}
            onCheck={() => {setAllowPolicy(true)}}
            onUncheck={() => {setAllowPolicy(false)}}
          />
          <PolicyInform>
            {'I agree to the '}
            <UnderlineText>
              terms of service
            </UnderlineText>
          </PolicyInform>
        </PolicyAcceptionArea>
        <NextButton onClick={handleNext} disabled={!allowNext} >Next</NextButton>
      </ContentWrapper>
    </WorkflowScreen>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 100%;
  padding: 24px 16px;
  box-sizing: border-box;
`

const InputArea = styled.span`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

const PasswordInput = styled.input<{level: number}>`
  padding: 12px 16px;
  box-sizing: border-box;
  border-radius: 16px;
  border: 1px solid ${(props) => {
    switch (props.level) {
      case PasswordStrength.Weak:
        return '#f5222d';
      case PasswordStrength.Idle:
        return '#e8e8e8';
      case PasswordStrength.Medium:
        return '#FA8C16';
      case PasswordStrength.Strong:
        return '#2eb553';
      default:
        return '#e8e8e8';
    }
  }};
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }
`

const PolicyAcceptionArea = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
`

const PolicyInform = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #8c8c8c;
`

const UnderlineText = styled.span`
  color: #262626;
  text-decoration-line: underline;
`

const NextButton = styled.button`
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: #262626;
  color: #fff;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  border-style: none;

  &:disabled {
    background-color: #D9D9D9;
    color: #8C8C8C;
    cursor: not-allowed;
  }
`

export default CreatePasswordScreen;
