import {AccountInfo} from "../../models/home/HomeModel";
import React, {createContext, useState} from "react";
import {CryptoChain} from "../../models/web3/Web3Model";
import {SBEstimateFailed, SBEstimateInfo, SBTransaction} from "../../models/swap_bridge/SwapBridgeModel";
import {SBBizModelContext} from "./SBBizModelContext";

export interface HomeBizModelContextType {
  accountInfo: AccountInfo | undefined;
  setAccountInfo: React.Dispatch<React.SetStateAction<AccountInfo | undefined>>;

  chainList: CryptoChain[];
  setChainList: React.Dispatch<React.SetStateAction<CryptoChain[]>>;
}

export const HomeBizModelContext = createContext<HomeBizModelContextType | undefined>(undefined);
export const HomeBizModelProvider: React.FC<{ children: React.ReactNode }> = ({
                                                                              children
                                                                            }) => {

  const [accountInfo, setAccountInfo] = useState<AccountInfo | undefined>(undefined);
  const [chainList, setChainList] = useState<CryptoChain[]>([]);

  return (
    <HomeBizModelContext.Provider
      value={{
        accountInfo,
        setAccountInfo,

        chainList,
        setChainList,
      }}
    >
      {children}
    </HomeBizModelContext.Provider>
  );
}
