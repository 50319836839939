import Database, {IDatabase} from "./Database";
import {AccountStore, IAccountStore} from "./stores/AccountStore";
import {ISwapBridgeStore, SwapBridgeStore} from "./stores/SwapBridgeStore";
import {AccountBalanceStore, IAccountBalanceStore} from "./stores/AccountBalanceStore";

export interface IAccountDB extends IDatabase {
  accountStore: IAccountStore;
  swapBridgeStore: ISwapBridgeStore;
  accountBalanceStore: IAccountBalanceStore;

  reset(): Promise<boolean>;
}

enum AccountDBTable {
  Account = "accounts",
  AccountBalance = "account_balances",
  SBTransaction = "sb_transactions",
}

const DatabaseName = "AccountDB";
const DatabaseVersion = 3;

class AccountDB extends Database implements IAccountDB {
  public accountStore: IAccountStore;
  public accountBalanceStore: IAccountBalanceStore;
  public swapBridgeStore: ISwapBridgeStore;

  constructor() {
    super();
    this.accountStore = new AccountStore(AccountDBTable.Account);
    this.accountBalanceStore = new AccountBalanceStore(AccountDBTable.AccountBalance);
    this.swapBridgeStore = new SwapBridgeStore(AccountDBTable.SBTransaction);

    this.init();
  }

  init() {
    const loadDBPromise = new Promise<boolean>((resolve, reject) => {
      const request = indexedDB.open(DatabaseName, DatabaseVersion);

      request.onupgradeneeded = (event) => {
        if (!event.newVersion) {
          return;
        }

        this._setDB((event.target as IDBOpenDBRequest).result);
        if (!this.db) {
          return;
        }

        for(let version = event.oldVersion + 1; version <= event.newVersion; version++) {
          switch (version) {
            case 1:
              const accountObjectStore = this.db.createObjectStore(AccountDBTable.Account, {keyPath: 'id', autoIncrement: true});
              accountObjectStore.createIndex('name', 'name', {unique: false});
              break;
            case 2:
              const swapBridgeObjectStore = this.db.createObjectStore(AccountDBTable.SBTransaction, {keyPath: 'actionId', autoIncrement: true});
              swapBridgeObjectStore.createIndex('accountId', 'accountId', {unique: false});
              break;
            case 3:
              const accountBalanceObjectStore = this.db.createObjectStore(AccountDBTable.AccountBalance, {keyPath: 'accountId', autoIncrement: false});
              accountBalanceObjectStore.createIndex('accountId', 'accountId', {unique: false});
              break;
            default:
              break;
          }
        }
      };

      request.onsuccess = (event) => {
        this._setDB((event.target as IDBOpenDBRequest).result);
        console.log('Database opened successfully');
        resolve(true);
      };

      request.onerror = (event) => {
        console.error('Database error:', (event.target as IDBOpenDBRequest).error);
        reject((event.target as IDBOpenDBRequest).error);
      };
    });
    this._setLoadDBPromise(loadDBPromise);
  }

  reset(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      await this.accountStore.cleanup();
      await this.swapBridgeStore.cleanup();
      await this.accountBalanceStore.cleanup();
      resolve(true);
    });
  }

  _setLoadDBPromise(pm: Promise<boolean>): void {
    this.loadDBPromise = pm;
    this.accountStore.loadDBPromise = pm;
    this.swapBridgeStore.loadDBPromise = pm;
    this.accountBalanceStore.loadDBPromise = pm;
  }

  _setDB(db: IDBDatabase): void {
    this.db = db;
    this.accountStore.db = db;
    this.swapBridgeStore.db = db;
    this.accountBalanceStore.db = db;
  }
}

export default AccountDB;
