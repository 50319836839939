export enum PassphraseWordAmountType {
  _12 = 12,
  _24 = 24,
}

export enum GenerateWalletStepType {
  StartCreate = 1.0,
  StartImport = 1.1,
  Encrypt = 2,
  SuggestBackup = 3,
  Done = 4
}

export enum ImportWalletType {
  Passphrase = 'pass_phrase',
  SecretKey = 'secret_key'
}

export enum WalletType {
  Multichain = 'multichain',
  Singlechain = 'singlechain'
}

export enum WalletRestoreSupplierType {
  Telegram = 'telegram',
}
