import React from "react";
import styled, {css, keyframes} from "styled-components";

interface GenerateWalletStepProps {
  icon: string;
  title: string;
  highlight?: boolean;
  loading?: boolean;
}

const GenerateWalletStepItem: React.FC<GenerateWalletStepProps> = ({
                                                                     icon,
                                                                     title,
                                                                     highlight,
                                                                     loading
                                                                   }) => {
  return (
    <Container highlight={highlight}>
      <StepIcon src={icon} fetchPriority="high"/>
      <StepTitle>{title}</StepTitle>
      {
        loading &&
        (
          <LoadingIcon src="/icons/ic_loading_black.svg" />
        )
      }
    </Container>
  )
}

const Container = styled.div<{ highlight: boolean | undefined }>`
  display: flex;
  flex-direction: column;
  padding: 16px 48px;
  gap: 10px;
  align-items: center;
  background-color: ${(props) => props.highlight ? '#E1F5FF' : '#f9f9f9'};
  border-radius: 24px;
  width: 100%;
  box-sizing: border-box;
  animation: ${() => css`${AppearAnimation} 0.3s ease-in-out forwards`};
`

const StepIcon = styled.img`
  width: 48px;
  height: 48px;
`

const StepTitle = styled.span`
  font: 600 14px 'Plus Jakarta Sans', sans-serif;
  line-height: 22px;
  color: #262626;
  text-align: center;
`

const AppearAnimation = () => keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const LoadingAnimation = () => keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingIcon = styled.img`
  width: 24px;
  height: 24px;
  animation: ${LoadingAnimation} 2s linear infinite;
`;

export default GenerateWalletStepItem;
