import React, {createContext, useState} from "react";
import {SBEstimateFailed, SBEstimateInfo, SBTransaction} from "../../models/swap_bridge/SwapBridgeModel";
import {CryptoToken} from "../../models/web3/Web3Model";

export interface SBBizModelContextType {
  defaultInputToken: CryptoToken | undefined;
  setDefaultInputToken: React.Dispatch<React.SetStateAction<CryptoToken | undefined>>;

  defaultOutputToken: CryptoToken | undefined;
  setDefaultOutputToken: React.Dispatch<React.SetStateAction<CryptoToken | undefined>>;

  estimateInfo: SBEstimateInfo | SBEstimateFailed<any> | undefined;
  setEstimateInfo: React.Dispatch<React.SetStateAction<SBEstimateInfo | SBEstimateFailed<any> | undefined>>;

  sbTransactions: SBTransaction[];
  setSBTransactions: React.Dispatch<React.SetStateAction<SBTransaction[]>>;
}

export const SBBizModelContext = createContext<SBBizModelContextType | undefined>(undefined);
export const SBBizModelProvider: React.FC<{ children: React.ReactNode }> = ({
                                                                              children
                                                                            }) => {

  const [defaultInputToken, setDefaultInputToken] = useState<CryptoToken | undefined>(undefined);
  const [defaultOutputToken, setDefaultOutputToken] = useState<CryptoToken | undefined>(undefined);
  const [estimateInfo, setEstimateInfo] = useState<SBEstimateInfo | SBEstimateFailed<any> | undefined>(undefined);
  const [sbTransactions, setSBTransactions] = useState<SBTransaction[]>([]);

  return (
    <SBBizModelContext.Provider
      value={{
        defaultInputToken,
        setDefaultInputToken,

        defaultOutputToken,
        setDefaultOutputToken,

        estimateInfo,
        setEstimateInfo,

        sbTransactions,
        setSBTransactions,
      }}
    >
      {children}
    </SBBizModelContext.Provider>
  );
}
