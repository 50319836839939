import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import WorkflowScreen from '../onboarding/common/WorkflowScreen';
import { font } from '../../helpers/NomasDesignSystem';
import { QRCodeSVG } from 'qrcode.react';

const ic_copy_relative_path = '/icons/ic_copy.svg';

const ReceiveWalletScreen: React.FC = () => {
  const location = useLocation();
  const accountInfo = location.state?.accountInfo;
  const accountAddress = location.state?.accountAddress;
  const chainInfo = accountAddress.chain;

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(accountAddress?.address ?? "");
    alert('Address Copied!');
  };

  const formatAddress = (address: string, displayAmount: number): string => {
    if (!address || displayAmount <= 0) {
      return '';
    }
    const start = address.slice(0, displayAmount + 2);
    const end = address.slice(-displayAmount);
    return `${start}...${end}`;
  };

  return (
    <WorkflowScreen title='Receive'>
      <ReceiveContainer>
        {/*<AccountContainer>*/}
        {/*  <AccountName>{accountInfo?.accountName ?? "Unknown Account"}</AccountName>*/}
        {/*  <Address>{formatAddress(accountAddress?.address ?? "", 4)}</Address>*/}
        {/*  <ActionIcon src={ic_copy_relative_path} onClick={handleCopyAddress} />*/}
        {/*</AccountContainer>*/}
        <ReceiveCard>
          <ReceiveCardHeader>
            <ReceiveTitle>Receive Assets On</ReceiveTitle>
            <BlockchainIcon src={chainInfo.logo ?? "/icons/logo_eth.svg"} alt="ChainLogo" />
            <BlockchainName>{chainInfo?.symbol ?? "Ethereum"}</BlockchainName>
          </ReceiveCardHeader>

          <SeparatorLine />

          <QRCodeWrapper>
            <QRCodeSVG value={accountAddress?.address ?? ""} size={144} level='H' />
          </QRCodeWrapper>

          <AddressField>
            <FullAddress>{accountAddress?.address ?? "No Address Available"}</FullAddress>
            <CopyButton onClick={handleCopyAddress}>Copy Address
              <ActionIcon src={ic_copy_relative_path} />
            </CopyButton>
            <AddressNote>
              <AddressNoteIcon src="/icons/ic_info.svg" />
              This address can only be used to receive compatible tokens
            </AddressNote>
          </AddressField>

          <AppNameLogo src={"/imgs/app_name_logo.svg"} />
        </ReceiveCard>
        <ReceiveFooter>
          <ShareButton>Share Address</ShareButton>
          <AccountAvatar src={accountInfo.accountAvatar} />
        </ReceiveFooter>
      </ReceiveContainer>
    </WorkflowScreen>
  );
};

const ReceiveContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background-color: #f3f3f3;
  font-family: ${font.main};
  width: 100%;
  padding: 16px;
  gap: 16px;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
`;

const AccountContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
  padding-bottom: 0;
  gap: 2px;
`;

const AccountName = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #595959; 
`;

const Address = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #8C8C8C;
  text-align: center;
`;

const ReceiveCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 16px;
  padding: 24px 16px;
  gap: 16px;
  box-sizing: border-box;
`;

const ReceiveCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ReceiveTitle = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
`;

const BlockchainIcon = styled.img`
  margin-top: 16px;
  width: 48px;
  height: 48px;
`;

const AppNameLogo = styled.img`
  height: 25px;
`;

const BlockchainName = styled.span`
  margin-top: 4px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
`;

const SeparatorLine = styled.div`
  background-color: #f3f3f3;
  height: 1px;
  width: 100%;
`

const QRCodeWrapper = styled.div`
  background-color: #f3f3f3;
  border-radius: 16px;
  padding: 8px;
  padding-bottom: 4px;
`;

const AddressField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 16px;
  border: 1px solid #f3f3f3;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
  gap: 16px;
`;

const FullAddress = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  word-break: break-all;
  color: #262626;
  text-align: center;
`;

const CopyButton = styled.button`
  background-color: #E8E8E8;
  color: #262626;
  padding: 10px 16px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const AddressNote = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #c8c8c8;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  align-items: flex-start;
`;

const AddressNoteIcon = styled.img`
  aspect-ratio: 1;
  width: 12px;
  padding: 4px 1px;
  margin-right: 4px;
`

const ReceiveCardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const FooterTitle = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  color: #d9d9d9;
`;

const ActionIcon = styled.img`
  width: 20px;
  height: 20px;
  padding: 2px;
  box-sizing: border-box;
  object-fit: contain;
  object-position: center;
  margin-left: 10px;
`;

const ReceiveFooter = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const ShareButton = styled.button`
  background-color: #000;
  color: #fff;
  height: 100%;
  border-radius: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  border: none;
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AccountAvatar = styled.img`
  width: 56px;
  height: 56px;
  object-fit: contain;
  object-position: center;
  border-radius: 100%;
  background-color: #fff;
  padding: 4px;
  box-sizing: border-box;
`

export default ReceiveWalletScreen;
