import React from "react";
import styled from "styled-components";
import {AccountAddressInfo} from "../../../models/home/HomeModel";

interface AccountAddressBSItemProps {
  accountAddressInfo: AccountAddressInfo;
  onClickShowQR?: (accountAddress: AccountAddressInfo) => void;
  onClickCopy?: (accountAddress: AccountAddressInfo) => void;
}

const AccountAddressBSItem: React.FC<AccountAddressBSItemProps> = ({
                                                                     accountAddressInfo,
                                                                     onClickShowQR,
                                                                     onClickCopy
                                                                   }) => {
  return (
    <AccountAddressItemContainer>
      <ChainLogo src={accountAddressInfo.chain.logo}/>
      <AddressInfoArea>
        <ChainName>{accountAddressInfo.chain.name}</ChainName>
        <Address>{`${accountAddressInfo.address.slice(0, 6)}...${accountAddressInfo.address.slice(-6)}`}</Address>
      </AddressInfoArea>
      <ActionArea>
        <ActionIcon src="/icons/ic_qrcode.svg" onClick={() => {onClickShowQR && onClickShowQR(accountAddressInfo)}}/>
        <ActionIcon src="/icons/ic_copy.svg" onClick={() => {onClickCopy && onClickCopy(accountAddressInfo)}}/>
      </ActionArea>
    </AccountAddressItemContainer>
  )
}

const AccountAddressItemContainer = styled.div`
  display: flex;
  gap: 12px;
  box-sizing: content-box;
  align-items: center;
`

const ChainLogo = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 100%;
  object-fit: contain;
  object-position: center;
`

const AddressInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Plus Jakarta Sans", sans-serif;
  gap: 4px;
  flex: 1
`

const ChainName = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: #262626
`

const Address = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: #8c8c8c;
`

const ActionArea = styled.div`
  display: flex;
  gap: 24px;
`

const ActionIcon = styled.img`
  width: 24px;
  height: 24px;
  padding: 3px;
  object-fit: contain;
  object-position: center;
  cursor: pointer;
  box-sizing: border-box;
`

export default AccountAddressBSItem;

