import React from "react";
import styled from "styled-components";

export interface SettingSectionProps {
  title: string;
  children: React.ReactNode;
}

export const SettingSection: React.FC<SettingSectionProps> = ({
                                                                title,
                                                                children
                                                              }) => {
  const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px 16px 0 16px;
    box-sizing: border-box;
    font-family: "Plus Jakarta Sans", sans-serif;
  `;

  const Header = styled.span`
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #bfbfbf;
  `;

  const SectionItemArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `;

  return (
    <Container>
      <Header>{title}</Header>
      <SectionItemArea>
        {children}
      </SectionItemArea>
    </Container>
  )
}
