import React, {useContext, useEffect, useState} from "react";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import ImportWalletTypeSelectionScreen from "../import/ImportWalletTypeSelectionScreen";
import {ImportWalletType} from "../../../enums/WalletEnums";
import InputPassphraseScreen from "../import/InputPassphraseScreen";
import InputSecretKeyScreen from "../import/InputSecretKeyScreen";
import CreatePasswordScreen from "../common/CreatePasswordScreen";
import GenerateWalletScreen from "../common/GenerateWalletScreen";
import styled from "styled-components";
import {TGContext} from "../../../contexts/TGContext";
import VerifyOTPScreen from "./verify_otp/VerifyOTPScreen";

const RestoreWalletRoutes: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [startFlow, setStartFlow] = useState<boolean>(false);

  useEffect(() => {
    console.log(location.pathname, location.state);
    if (location.pathname === '/onboarding/importWallet' && startFlow) {
      navigate(-1);
    }
  }, [location]);

  useEffect(() => {
    navigate('verifyOTP');
  }, []);

  return (
    <RestoreWalletRoutesContainer>
      <Routes>
        <Route path='verifyOTP' element={<VerifyOTPScreen onNext={() => {navigate('finish')}} /> } />
        <Route path='finish' element={<GenerateWalletScreen onNext={() => {navigate('/home', { relative: "path"})}}/>}/>
      </Routes>
    </RestoreWalletRoutesContainer>
  )
}

const RestoreWalletRoutesContainer = styled.div`
  width: 100%;
  height: ${() => {
  const {isTelegram, tgVH} = useContext(TGContext);
  return isTelegram ? `${tgVH}px` : '100vh';
}};
  background-color: #fff;
`

export default RestoreWalletRoutes;
