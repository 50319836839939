import React, {createContext, useEffect, useState} from 'react';

interface TGContextType {
  isTelegram: boolean;
  tgVH: number;
  safeAreaBottom: number;
}

export const TGContext = createContext<TGContextType>({
  isTelegram: false,
  tgVH: 0,
  safeAreaBottom: 0
});

export const TGProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
  const [isTelegram, setIsTelegram] = useState<boolean>(false);
  const [tgVH, setTgVH] = useState<number>(0);
  const [safeAreaBottom, setSafeAreaBottom] = useState<number>(0);

  useEffect(() => {
    const _isTelegram = window.Telegram && window.Telegram.WebApp.initData;
    setIsTelegram(_isTelegram);
    setTimeout(() => {
      const _safeAreaBottom = document.getElementById('safe-area-inset-bottom')?.offsetHeight || 0;
      setSafeAreaBottom(_safeAreaBottom);

      const _tgVH = window.Telegram.WebApp.viewportHeight + _safeAreaBottom;
      setTgVH( _tgVH);
    }, 0);
  }, []);

  return (
    <TGContext.Provider value={{
      isTelegram,
      tgVH,
      safeAreaBottom,
    }}>
      {children}
    </TGContext.Provider>
  );
}

