import React, {useContext} from 'react';
import styled from 'styled-components';
import WorkflowScreen, {WorkflowProps} from '../common/WorkflowScreen';
import {font} from '../../../helpers/NomasDesignSystem';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {TGContext} from '../../../contexts/TGContext';
import ImportWalletTypeSelectionScreen from "../import/ImportWalletTypeSelectionScreen";
import {ImportWalletType} from "../../../enums/WalletEnums";
import InputPassphraseScreen from "../import/InputPassphraseScreen";
import InputSecretKeyScreen from "../import/InputSecretKeyScreen";
import CreatePasswordScreen from "../common/CreatePasswordScreen";
import GenerateWalletScreen from "../common/GenerateWalletScreen";
import {RoutePath} from "../../../enums/CommonEnums";

const RocketLaunchScreen: React.FC<WorkflowProps> = ({
                                                       onNext
                                                     }) => {
  const navigate = useNavigate();
  const tgContext = useContext(TGContext);
  const {isTelegram, safeAreaBottom} = tgContext;

  return (
    <Container>
      <RocketContainer>
        <RocketImageContainer>
          <RocketImage src="/imgs/rocket_launch_thumb.svg" alt="Rocket" fetchPriority="high"/>
        </RocketImageContainer>
      </RocketContainer>

      <DetailContainer>
        <DetailTitle>
          <Title>Welcome to</Title>
          <Subtitle>ROCKET LAUNCH</Subtitle>
        </DetailTitle>

        <Sliter src='/imgs/vt_wwww.svg'></Sliter>
        <Description>
          Rocket Launch is a feature that instantly creates a
          <Indam> multichain wallet with just one tap. </Indam>
        </Description>
      </DetailContainer>

      <FooterContainer safeAreaBottom={safeAreaBottom}>
        <AgreementText>
          By tapping the button, you agree to the <UserAgreementLink href="#">User Agreement</UserAgreementLink>
        </AgreementText>
        <LaunchButton onClick={onNext}>Launch !!!</LaunchButton>
      </FooterContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${font.main};
  text-align: center;
  background-color: white;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  scroll-behavior: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const RocketContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const RocketImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 85.38%;
  overflow: hidden;
`

const RocketImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const DetailContainer = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
  gap: 16px;
  padding-left: 16px;
  padding-right: 16px;
`

const DetailTitle = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: #262626;
  line-height: 26px;
`;

const Subtitle = styled.span`
  font-size: 28px;
  font-weight: 800;
  color: #262626;
  line-height: 40px;
`;

const Sliter = styled.img`
  height: 8px;
`;

const Description = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #8C8C8C;
  line-height: 24px;
`;

const Indam = styled.span`
  font-weight: 600;
  color: #262626;
`

const FooterContainer = styled.div<{ safeAreaBottom: number }>`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  gap: 24px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: ${({safeAreaBottom}) => `${safeAreaBottom}px`};
`

const AgreementText = styled.span`
  font-size: 12px;
  color: #8C8C8C;
  line-height: 18px;
  font-weight: 400;
`;

const UserAgreementLink = styled.a`
  color: #262626;
  text-decoration: underline;
`;

const LaunchButton = styled.button`
  background-color: #262626;
  color: white;
  border-radius: 16px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  height: 48px;
`;

export default RocketLaunchScreen;
