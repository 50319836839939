import React, {useEffect, useState} from "react";
import styled, {css, keyframes} from "styled-components";

interface BottomSheetProps {
  stickableContent?: React.ReactNode;
  scrollableContent?: React.ReactNode;
  forceClose?: boolean;
  onClose?: () => void;
}

const BottomSheet: React.FC<BottomSheetProps> = ({
                                                   stickableContent,
                                                   scrollableContent,
                                                   forceClose,
                                                   onClose
                                                 }) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  useEffect(() => {
    document.body.style.overflow = 'hidden'; //
    document.documentElement.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
      document.documentElement.style.overflow = '';
    }
  }, []);

  useEffect(() => {
    if (forceClose) {
      handleOnClose();
    }
  }, [forceClose]);

  const handleOnClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      if (onClose) {
        onClose();
      }
    }, 300);
  }

  return (
    <BottomSheetContainer>
      <DimBackground is_visible={isVisible ? 1 : 0} onClick={handleOnClose}/>
      <BottomSheetArea is_visible={isVisible ? 1 : 0}>
        <TopSeparator/>
        <StickableContent>
          {stickableContent !== null ? stickableContent : <></>}
        </StickableContent>
        <ScrollableContent>
          {scrollableContent !== null ? scrollableContent : <></>}
        </ScrollableContent>
      </BottomSheetArea>
    </BottomSheetContainer>
  )
}

const DimBGFadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const DimBGFadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const BottomSheetAreaTransformIn = keyframes`
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0);
  }
`;

const BottomSheetAreaTransformOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100vh);;
  }
`

const BottomSheetContainer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const DimBackground = styled.div<{ is_visible: number }>`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.8);
  cursor: pointer;
  animation: ${({is_visible}) =>
          is_visible
                  ? css`${DimBGFadeIn} 0.3s ease-in-out forwards`
                  : css`${DimBGFadeOut} 0.3s ease-in-out forwards`
  };
`

const BottomSheetArea = styled.div.attrs({className: 'bottom-sheet-area'})<{ is_visible: number }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  max-height: 60%;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  align-items: center;
  background-color: #fff;
  animation: ${({is_visible}) =>
          is_visible
                  ? css`${BottomSheetAreaTransformIn} 0.3s ease-in-out forwards`
                  : css`${BottomSheetAreaTransformOut} 0.3s ease-in-out forwards`
  };

  @media(max-width: 500px) {
    max-height: 80%;
  }
`

const TopSeparator = styled.div`
  display: flex;
  width: 64px;
  height: 4px;
  border-radius: 2px;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: #d9d9d9;
`

const StickableContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ScrollableContent = styled.div.attrs({className: 'bottom-sheet-scrollable-content'})`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  scroll-behavior: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export default BottomSheet;
