import React, {useContext, useEffect, useState} from "react";
import WorkflowScreen, {WorkflowProps} from "../common/WorkflowScreen";
import OnboardingTitle from "../../../components/onboarding/OnboardingTitle";
import styled from "styled-components";
import {BizContext} from "../../../contexts/BizContext";
import SearchChainBottomSheet from "../../../components/bottomsheets/search_chain/SearchChainBottomSheet";
import {CryptoChain} from "../../../models/web3/Web3Model";

const InputSecretKeyScreen: React.FC<WorkflowProps> = ({
                                                          onNext
                                                        }) => {
  const bizContext = useContext(BizContext);
  if (!bizContext) {
    throw new Error("Context isn't existed");
  }
  const { importWalletBizModel } = bizContext;

  const [importChain, setImportChain] = useState<CryptoChain | undefined>();
  const [privateKey, setPrivateKey] = useState<string>('');
  const [allowImport, setAllowImport] = useState<boolean>(false);

  const [showSearchChainBottomSheet, setShowSearchChainBottomSheet] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const defaultChain = await importWalletBizModel.getImportDefaultChain();
      setImportChain(defaultChain);
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (!importChain || !privateKey) {
      setAllowImport(false);
      return;
    }

    const checkData = async () => {
      const isValid = await importWalletBizModel.checkValidPrivateKey(importChain, privateKey);
      setAllowImport(isValid);
    }

    checkData();
  }, [importChain, privateKey])

  const handleNext = () => {
    const setData = async () => {
      if (!importChain) {
        return;
      }

      await importWalletBizModel.setPrivateKey(privateKey, importChain);
      onNext();
    };
    setData();
  }

  return (
    <WorkflowScreen>
      <ContentWrapper>
        <OnboardingTitle
          title={"Enter a Private Key"}
          subtitle={"Import an existing single-chain wallet"}
        />
        <PrivateKeyInfoArea>
          <ChainSelectionArea onClick={() => {setShowSearchChainBottomSheet(true)}}>
            <ChainSelectionLogo src={importChain && importChain.logo} fetchPriority="high" />
            <ChainSelectionName>{importChain && importChain.name}</ChainSelectionName>
            <ChainSelectionIcon src={'/icons/ic_arrow_down.svg'} />
          </ChainSelectionArea>
          <PrivateKeyInput
            value={privateKey}
            placeholder="Enter Private Key"
            onChange={(e) => {setPrivateKey(e.currentTarget.value)}}
          />
        </PrivateKeyInfoArea>
        <EnterButton
          disabled={!allowImport}
          onClick={handleNext}
        >Enter</EnterButton>
        {
          showSearchChainBottomSheet &&
          <SearchChainBottomSheet
            onClickClose={() => {setShowSearchChainBottomSheet(false)}}
            onSelectChain={(chain) => {setImportChain(chain)}}
          />
        }
      </ContentWrapper>
    </WorkflowScreen>
  )
}

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  gap: 24px;
  width: 100%;
  height: 100%;
  align-items: center;
  font-family: 'Plus Jakarta Sans', sans-serif;
  box-sizing: border-box;
  overflow: hidden;
`

const PrivateKeyInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  overflow-y: auto;
`

const ChainSelectionArea = styled.div`
  display: flex;
  gap: 8px;
  padding: 0 16px;
  border-radius: 16px;
  border: 1px solid #e8e8e8;
  height: 48px;
  align-items: center;
  cursor: pointer;
`

const ChainSelectionLogo = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  object-fit: fill;
  object-position: center;
`

const ChainSelectionName = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  flex: 1;
  color: #262626;
`

const ChainSelectionIcon = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
  object-position: center;
`

const PrivateKeyInput = styled.textarea`
  display: block;
  padding: 16px;
  height: 48px;
  color: #262626;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  min-height: 104px;
  text-align: left;
  font-family: 'Plus Jakarta Sans', sans-serif;
  box-sizing: border-box;
  resize: none;

  border: 1px solid #e8e8e8;
  border-radius: 16px;

  -webkit-tap-highlight-color: transparent;
  &:focus {
    outline: none;
  }
`

const EnterButton = styled.button`
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: #262626;
  color: #fff;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  border-style: none;
  
  &:disabled {
    background-color: #D9D9D9;
    color: #8C8C8C;
    cursor: not-allowed;
  }
`

export default InputSecretKeyScreen;
