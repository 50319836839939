import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {CryptoChain} from "../../../models/web3/Web3Model";

interface ChainListProps {
  chains: CryptoChain[];
  onSelectChain?: (chain: CryptoChain) => void;
}

const ChainList: React.FC<ChainListProps> = ({
                                               chains,
                                               onSelectChain
                                             }) => {
  const [refreshKey, setRefreshKey] = useState<number>(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      setRefreshKey(prevKey => prevKey + 1);
    }, 1);

    return () => clearTimeout(timer); // Clean up the timer if the component unmounts
  }, [chains]);

  return (
    <ChainListContainer key={refreshKey}>
      {
        chains.map(chain => (
          <ChainListItem
            key={chain.id}
            chain={chain}
            onSelect={() => {
            onSelectChain && onSelectChain(chain)
          }}/>
        ))
      }
    </ChainListContainer>
  )
}

const ChainListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  box-sizing: border-box;
`

interface ChainListItemProps {
  chain: CryptoChain;
  onSelect?: () => void;
}

const ChainListItem: React.FC<ChainListItemProps> = ({
                                                       chain,
                                                       onSelect
                                                     }) => {
  return (
    <ChainListItemContainer onClick={onSelect}>
      <ChainLogo src={chain.logo}/>
      <ChainInfoArea>
        <ChainName>{chain.name}</ChainName>
        <ChainSymbol>{chain.symbol}</ChainSymbol>
      </ChainInfoArea>
    </ChainListItemContainer>
  )
}

const ChainListItemContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
`

const ChainLogo = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 100%;
  object-fit: contain;
  object-position: center;
`

const ChainInfoArea = styled.div`
  font-family: 'Plus Jakarta Sans', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  flex: 1;
`

const ChainName = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: #262626;
`

const ChainSymbol = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: #8c8c8c;
`

export default ChainList;
