import React, {useContext, useEffect, useState} from "react";
import WorkflowScreen, {WorkflowProps} from "../common/WorkflowScreen";
import styled from "styled-components";
import OnboardingTitle from "../../../components/onboarding/OnboardingTitle";
import PassphraseShowing from "../../../components/onboarding/PassphraseShowing/PassphraseShowing";
import {PassphraseWordAmountType} from "../../../enums/WalletEnums";
import {BizContext} from "../../../contexts/BizContext";

const InputPassphraseScreen: React.FC<WorkflowProps> = ({
                                                          onNext
                                                        }) => {
  const bizContext = useContext(BizContext);
  if (!bizContext) {
    throw new Error("Context isn't existed");
  }

  const { importWalletBizModel } = bizContext;

  const [ready, setReady] = useState<boolean>(false);

  const [allowImport, setAllowImport] = useState<boolean>(false);

  const [importWords, setImportWords] = useState<string[]>([]);
  const [wordAmount, setWordAmount] = useState<number>(0);

  useEffect(() => {
    setImportWords(Array<string>(PassphraseWordAmountType._24).fill(''));
    setTimeout(() => {
      setReady(true);
    }, 1);
  }, [])

  useEffect(() => {
    _checkValidPassphrase();
  }, [wordAmount])

  const handleChangeWordAmount = (wordAmount: number) => {
    setWordAmount(wordAmount);
  }

  const handleUpdateWord = (index: number, word: string) => {
    importWords.splice(index, 1, word);
    _checkValidPassphrase();
  }

  const handlePasteText = (index: number, text: string) => {
    const words = text.split(' ');
    let increaseIndex = index;
    words.forEach(word => {
      importWords.splice(increaseIndex, 1, word);
      increaseIndex++;
    });

    setImportWords(importWords.slice());
    _checkValidPassphrase();
  }

  const _checkValidPassphrase = async () => {
    let isLengthValid = true;
    for (let i = 0; i < wordAmount; i++) {
      const word = importWords[i];
      if (!word) {
        isLengthValid = false;
        break;
      }
    }

    const isFormatValid = await importWalletBizModel.checkValidPassphrase(importWords);
    setAllowImport(isLengthValid && isFormatValid);
  }

  const handleNext = () => {
    const setData = async () => {
      const targetWords = wordAmount === PassphraseWordAmountType._24 ? importWords : importWords.slice(0, 12);
      await importWalletBizModel.setPassphrase(targetWords);
      onNext();
    };
    setData();
  }

  return (
    <WorkflowScreen>
      <ContentWrapper visible={ready} >
        <OnboardingTitle
          title={"Secret Recovery Phrase"}
          subtitle={"Import an existing wallet using a 12 or 24-word recovery phrase"}
        />
        <PassphaseArea>
          <PassphraseShowing
            words_12={importWords}
            words_24={importWords}
            editable={true}
            onUpdateWord={handleUpdateWord}
            onChangeWordAmount={handleChangeWordAmount}
            onPaste={handlePasteText}
          />
        </PassphaseArea>
        <ImportButton
          disabled={!allowImport}
          onClick={handleNext}
        >Import Wallet</ImportButton>
      </ContentWrapper>
    </WorkflowScreen>
  )
}

const ContentWrapper = styled.div<{ visible: boolean | undefined }>`
  display: flex;
  opacity: ${(props) => props.visible ? '1' : '0'};
  flex-direction: column;
  padding: 24px 16px;
  gap: 24px;
  width: 100%;
  height: 100%;
  align-items: center;
  font-family: 'Plus Jakarta Sans', sans-serif;
  box-sizing: border-box;
  overflow: hidden;
`

const PassphaseArea = styled.div`
  display: flex;
  width: 100%;
  overflow-y: auto;
`

const ImportButton = styled.button`
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: #262626;
  color: #fff;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  border-style: none;
  
  &:disabled {
    background-color: #D9D9D9;
    color: #8C8C8C;
    cursor: not-allowed;
  }
`

export default InputPassphraseScreen;

