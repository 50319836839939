import React, {useContext, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {TGContext} from "../../../contexts/TGContext";
import styled from "styled-components";
import NavigationHeader from "../../../components/common/NavigationHeader";

export interface WorkflowProps {
  onNext: (...props: any | undefined) => void;
}

interface WorkflowScreenProps {
  title?: string;
  children: React.ReactNode;
}

const WorkflowScreen: React.FC<WorkflowScreenProps> = ({
                                                         title,
                                                         children
                                                       }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const tgContext = useContext(TGContext);
  if (!tgContext) {
    throw "Context isn't existed";
  };

  const {isTelegram} = tgContext

  useEffect(() => {
    if (isTelegram) {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(() => {
        handleBack();
      });

      return () => {
        window.Telegram.WebApp.BackButton.hide();
      }
    }
  }, [isTelegram])

  const handleBack = () => {
    location.state = null;
    navigate(-1);
  }

  return (
    <WorkflowScreenContainer>
      {
        !isTelegram &&
        (
          <NavigationArea>
            <NavigationHeader
              title={title ? title : 'Nomas'}
              showBackButton={true}
              onClickBackButton={handleBack}
            />
          </NavigationArea>
        )
      }

      {children}
    </WorkflowScreenContainer>
  )
}

const WorkflowScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${() => {
    const {isTelegram, tgVH} = useContext(TGContext);
    return isTelegram ? `${tgVH}px` : '100vh';
  }};
  background-color: #fff;
  box-sizing: border-box;
  align-items: center;
  overflow-y: hidden;
`

const NavigationArea = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 16px;
  box-sizing: border-box;
`

export default WorkflowScreen;
