import React, {useContext} from "react";
import styled from "styled-components";
import {TGContext} from "../../contexts/TGContext";

const LaunchingScreen: React.FC = () => {
  return (
    <LaunchingScreenComponent>
      <AppLogo src="/imgs/app_logo.svg" />
    </LaunchingScreenComponent>
  )
}

const LaunchingScreenComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${() => {
    const {isTelegram, tgVH} = useContext(TGContext);
    return isTelegram ? `${tgVH}px` : '100vh';
  }};
  font-family: 'Plus Jakarta Sans', sans-serif;
  color: #262626;
  background-color: #fff;
  padding: 16px;
  align-items: center;
  justify-items: center;
  alignment: center;
  align-self: center;
  alignment-baseline: center;
  box-sizing: border-box;
`

const AppLogo = styled.img`
  width: 140px;
  aspect-ratio: 1;
  flex: 1;
`

export default LaunchingScreen;
