import {CryptoChain} from "../models/web3/Web3Model";
import {CryptoNetworkType} from "../enums/Web3Enums";
import {ethers} from "ethers";
import {PublicKey} from "@solana/web3.js";
import { Address } from '@ton/core';

export const checkValidAddress: (chain: CryptoChain, address: string) => boolean = (chain, address) => {
  switch (chain.network) {
    case CryptoNetworkType.EVM:
      return ethers.isAddress(address);
    case CryptoNetworkType.SOL:
      try {
        let publicKey = new PublicKey(address);
        return PublicKey.isOnCurve(publicKey);
      } catch (e) {
        return false;
      }
    case CryptoNetworkType.TON:
      try {
        Address.parse(address);
        return true;
      } catch (e) {
        return false;
      }
    default: return false;
  }
};
