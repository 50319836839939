import React from 'react';

interface SwapFromToIconProps {
   size?: number;
   color?: string;
 }

const SwapFromToIcon: React.FC<SwapFromToIconProps> = ({
   size = 24,
   color = "#000000"
}) => {
  return (
    <svg
      width= {size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <path
          id="Vector"
          d="M3 12H21M21 12L14 5M21 12L14 19"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default SwapFromToIcon;