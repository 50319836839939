import React from "react";
import styled from "styled-components";
import {
   NFTransactionTokenChangeInfo
} from "../../../../../models/notification/TransactionNotifyModel";

const genTokenAmountText = (amount: number, name: string): string => {
   const amountText = (amount > 0) ? `+${amount}` : `${amount} `;
   const result = `${amountText} ${name}`;
   return result;
}

export interface TransactionChangeInfoProps {
   info: NFTransactionTokenChangeInfo;
}

const TransactionChangeInfo: React.FC<TransactionChangeInfoProps> = ({
   info
}) => {
   const { tokenAmount, quoteValue } = info;
   return (
      <ContentWrapper>
         <TokenAmount amount={tokenAmount.amount}>
            {genTokenAmountText(tokenAmount.amount, tokenAmount.tokenName)}
         </TokenAmount>

         <QuoteValue>
            {`$${quoteValue}`}
         </QuoteValue>
      </ContentWrapper>
   )
};

const ContentWrapper = styled.div`
   display: flex;
   flex-direction: column;
   box-sizing: border-box;
   align-items: flex-end;
   max-width: 100%;
   font-family: 'Plus Jakarta Sans', sans-serif;
`;

const TokenAmount = styled.span<{ amount: number }>`
   font-weight: 600;
   font-size: 14px;
   line-height: 22px;
   color: ${(props) => (props.amount > 0 ? '#2EB553' : '#F5222D')};
`;

const QuoteValue = styled.span`
   font-weight: 400;
   font-size: 12px;
   line-height: 18px;
   color: #8C8C8C;
`;

export default TransactionChangeInfo;