import React, {useContext, useEffect, useState} from "react";
import AccountInfoHeader from "../../components/home/AccountInfoHeader";
import styled from "styled-components";
import AccountAsset, {AssetType} from "../../components/home/AccountAsset";
import {BizContext} from "../../contexts/BizContext";
import {AccountAddressInfo, AccountInfo} from "../../models/home/HomeModel";
import NavigationBar from "../../components/common/NavigationBar";
import {TGContext} from "../../contexts/TGContext";
import AccountAddressBottomSheet from "../../components/bottomsheets/address_info/AccountAddressBottomSheet";
import AccountSelectionBottomSheet from "../../components/bottomsheets/account_selection/AccountSelectionBottomSheet";
import SearchAssetTokenBottomSheet from "../../components/bottomsheets/search_asset_token/SearchAssetTokenBottomSheet";
import SearchChainBottomSheet from "../../components/bottomsheets/search_chain/SearchChainBottomSheet";
import WalletActionBottomSheet from "../../components/bottomsheets/wallet_action/WalletActionBottomSheet";
import {AccountAction} from "../../enums/AccountEnums";
import {useLocation, useNavigate} from "react-router-dom";
import {RoutePath} from "../../enums/CommonEnums";
import {CryptoChain} from "../../models/web3/Web3Model";
import {HomeBizModelContext} from "../../contexts/biz_model/HomeBizModelContext";
import { AlertModel } from "../../models/common/CommonModel";

interface HomeScreenState {
  alertModel?: AlertModel
}

const HomeScreen: React.FC = () => {
  const navigate = useNavigate();

  const bizContext = useContext(BizContext);
  const tgContext = useContext(TGContext);
  const homeBizModelContext = useContext(HomeBizModelContext);

  if (!bizContext || !tgContext || !homeBizModelContext) {
    throw new Error("Context isn't existed");
  }
  const location = useLocation();
  let { alertModel } = location.state as HomeScreenState || {};

  const { homeBizModel } = bizContext;
  const { isTelegram, safeAreaBottom } = tgContext;

  const { accountInfo } = homeBizModelContext;
  const [telegramVH, setTelegramVH] = useState<number>(0);

  const [selectedChain, setSelectedChain] = useState<CryptoChain | undefined>(undefined);

  const [showAccountAddressBottomSheet, setShowAccountAddressBottomSheet] = useState<boolean>(false);
  const [showAccountBottomSheet, setShowAccountBottomSheet] = useState<boolean>(false);
  const [showSearchChainBottomSheet, setShowSearchChainBottomSheet] = useState<boolean>(false);
  const [showAssetTokenList, setShowAssetTokenList] = useState<boolean>(false);
  const [showWalletActionBottomSheet, setShowWalletActionBottomSheet] = useState<boolean>(false);

  useEffect(() => {
    if (isTelegram) {
      const tgViewPort = window.Telegram.WebApp.viewportHeight;
      setTelegramVH(tgViewPort + safeAreaBottom);
    }
  }, [isTelegram, safeAreaBottom]);

  useEffect(() => {
    if (!homeBizModel) {
      return;
    }

    homeBizModel.injectContext(homeBizModelContext);
    homeBizModel.loadData();
  }, [homeBizModel]);

  const handleShowAccountAddressList = () => {
    setShowAccountAddressBottomSheet(true);
  }
  const handleHideAccountAddressList = () => {
    setShowAccountAddressBottomSheet(false);
  }
  const handleClickNotifyAction = () => {
    navigate(RoutePath.Notification);
  }

  const handleOpenReceiveScreen = (accountInfo: AccountInfo | undefined, accountAddress: AccountAddressInfo) => {
    navigate("/receive", { state: { accountInfo, accountAddress } });
  }

  const handleAction = (action: AccountAction) => {
    switch (action) {
      case AccountAction.Swap:
        navigate(RoutePath.SwapBridge);
        break;
      case AccountAction.More:
        handleShowWalletActionBottomSheet();
        break;
      case AccountAction.Receive:
        handleShowAccountAddressList();
        break;
      case AccountAction.Send:
        navigate(RoutePath.SendAction, {
          state: {
            accountInfo: accountInfo,
          }
        });
	    break;

      case AccountAction.OTC:
      case AccountAction.Earn:
        alert("Comming soon!");
        break;

      default:
        break;
    }

    console.log(action);
  }

  const handleShowQR = (accountAddress: AccountAddressInfo) => {
    handleOpenReceiveScreen(accountInfo, accountAddress);
  }

  const handleCopyAddress = (accountAddress: AccountAddressInfo) => {
    navigator.clipboard.writeText(accountAddress.address ?? "");
    alert('Address Copied!');
  }

  const navigateToSetting = () => {
    navigate(RoutePath.Setting);
  }

  const handleShowAccountList = () => {
    setShowAccountBottomSheet(true);
  }

  const handleHideAccountList = () => {
    setShowAccountBottomSheet(false);
  }

  const handleChangeAssetTab = (tab: AssetType) => {
    if (tab !== AssetType.Token) {
      alert("Comming soon!");
    }
  }

  const handleShowAssetTokenList = () => {
    setShowAssetTokenList(true);
  }

  const handleHideAssetTokenList = () => {
    setShowAssetTokenList(false);
  }

  const handleShowAddingCustomToken = () => {
    alert("Comming soon!");
  }

  const handleShowSearchChain = () => {
    setShowSearchChainBottomSheet(true);
  }

  const handleHideSearchChain = () => {
    setShowSearchChainBottomSheet(false);
  }

  const handleSelectChainOnMoreChain = (chain: CryptoChain) => {
    setSelectedChain(chain);
  }

  const handleShowWalletActionBottomSheet = () => {
    setShowWalletActionBottomSheet(true);
  }

  const handleHideWalletActionBottomSheet = () => {
    setShowWalletActionBottomSheet(false);
  }

  const handleNavigateToExplorer = () => {
    alert("Comming soon!");
  }

  const handleCloseAlert = () => {
    navigate('/home', {
      state: {
        alertModel: null,
      },
      replace: true
    })
  }

  return (
    <HomeScreenContainer is_telegram={isTelegram ? 1 : 0} tg_height={telegramVH}>
      <HomeScreenDetailWrapper>
        <AccountInfoHeader
          accountName={accountInfo ? accountInfo.accountName : "Account 1"}
          chain={accountInfo ? accountInfo.chain : "Unknown type"}
          balance={accountInfo ? accountInfo.totalQuote : 0}
          changePercentage={accountInfo ? accountInfo.quoteChangePercent : 0}
          changeAmount={accountInfo ? accountInfo.quoteChange : 0}
          alertModel={alertModel}
          onClickCopyAddress={handleShowAccountAddressList}
          onClickNotify={handleClickNotifyAction}
          onClickAction={handleAction}
          onCloseAlert={handleCloseAlert}
        />
        <AccountAsset
          selectedChain={selectedChain}
          onClickTab={handleChangeAssetTab}
          onClickSearchToken={handleShowAssetTokenList}
          onClickAddToken={handleShowAddingCustomToken}
          onClickSearchChain={handleShowSearchChain}
        />
      </HomeScreenDetailWrapper>
      <NavigationBar
        onClickExplore={handleNavigateToExplorer}
        onClickChangeAccount={handleShowAccountList}
        onClickSetting={navigateToSetting}
      />

      {
        showAccountAddressBottomSheet &&
        <AccountAddressBottomSheet
          onClickShowQR={handleShowQR}
          onClickCopy={handleCopyAddress}
          onClose={handleHideAccountAddressList}
        />
      }
      {
        showAccountBottomSheet &&
        <AccountSelectionBottomSheet
          onClose={handleHideAccountList}
        />
      }
      {
        showAssetTokenList &&
        <SearchAssetTokenBottomSheet onClose={handleHideAssetTokenList} />
      }
      {
        showSearchChainBottomSheet &&
        <SearchChainBottomSheet
          onSelectChain={handleSelectChainOnMoreChain}
          onClickClose={handleHideSearchChain}
        />
      }
      {
        showWalletActionBottomSheet &&
        <WalletActionBottomSheet
          onClickClose={handleHideWalletActionBottomSheet}
          onClickAction={handleAction}
        />
      }
    </HomeScreenContainer>
  )
}

interface HomeScreenContainerProps {
  is_telegram: number;
  tg_height: number;
}
const HomeScreenContainer = styled.div<HomeScreenContainerProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: ${(props) => (props.is_telegram ? `${props.tg_height}px` : "100vh")};
  background-color: #f3f3f3;
`

const HomeScreenDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  scroll-behavior: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
`

export default HomeScreen;
