import AccountService, {IAccountService} from "./AccountService/AccountService";
import Web3Service, {IWeb3Service} from "./Web3Service/Web3Service";
import SettingService, {ISettingService} from "./SettingService/SettingService";
import DefaultService, {IDefaultService} from "./DefaultService/DefaultService";
import CacheService, {ICacheService} from "./CacheService/CacheService";
import APIService, {IAPIService} from "./APIService/APIService";
import SessionService, {ISessionService} from "./SessionService/SessionService";
import StartupService, {IStartupService} from "./StartupService/StartupService";
import CryptoService, {ICryptoService} from "./CryptoService/CryptoService";
import CleanupService, {ICleanupService} from "./CleanupService/CleanupService";
import SwapBridgeService, {ISwapBridgeService} from "./SwapBridgeService/SwapBridgeService";
import DatabaseService, {IDatabaseService} from "./DatabaseService/DatabaseService";
import SchedulerService, {ISchedulerService} from "./SchedulerService/SchedulerService";
import ObserverService, {IObserverService} from "./ObserverService/ObserverService";

export interface IServiceManager {
  accountService: IAccountService;
  web3Service: IWeb3Service;
  settingService: ISettingService;
  swapBridgeService: ISwapBridgeService;
  startupService: IStartupService;
  cacheService: ICacheService;
  databaseService: IDatabaseService;
  apiService: IAPIService;
  defaultService: IDefaultService;
  sessionService: ISessionService;
  cryptoService: ICryptoService;
  cleanupService: ICleanupService;
  schedulerService: ISchedulerService;
  observerService: IObserverService;
}

// const ServiceManager: IServiceManager = {
//   accountService: new AccountService(),
//   web3Service: new Web3Service(),
//   settingService: new SettingService(),
//   cacheService: new CacheService(),
//   defaultService: new DefaultService(),
//   sessionService: new SessionService()
// }

class ServiceManager implements IServiceManager {
  public accountService:IAccountService;
  public web3Service: IWeb3Service;
  public settingService: ISettingService;
  public swapBridgeService: ISwapBridgeService;
  public startupService: IStartupService;
  public cacheService: ICacheService;
  public databaseService: IDatabaseService;
  public apiService: IAPIService;
  public defaultService: IDefaultService;
  public sessionService: ISessionService;
  public cryptoService: ICryptoService;
  public cleanupService: ICleanupService;
  public schedulerService: ISchedulerService;
  public observerService: IObserverService;

  constructor() {
    this.accountService = new AccountService();
    this.web3Service = new Web3Service();
    this.settingService = new SettingService();
    this.swapBridgeService = new SwapBridgeService();
    this.startupService = new StartupService();
    this.apiService = new APIService();
    this.cacheService = new CacheService();
    this.databaseService = new DatabaseService();
    this.defaultService = new DefaultService();
    this.sessionService = new SessionService();
    this.cryptoService = new CryptoService();
    this.cleanupService = new CleanupService();
    this.schedulerService = new SchedulerService();
    this.observerService = new ObserverService();
  }
}

const ServiceManagerIns = new ServiceManager();

export default ServiceManagerIns;
