import {DatabaseStore, IDatabaseStore} from "./DatabaseStore";
import {WalletBalanceDetail} from "../../../models/wallet/WalletModel";

export interface IAccountBalanceStore extends IDatabaseStore {
  storeAccountBalance(accountId: number, walletBalance: WalletBalanceDetail): Promise<boolean>;
  getAccountBalance(accountId: number): Promise<WalletBalanceDetail | undefined>;
}

export class AccountBalanceStore extends DatabaseStore implements IAccountBalanceStore {
  storeAccountBalance(accountId: number, walletBalance: WalletBalanceDetail): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      await this.loadDBPromise;
      if (!this.db) {
        reject({code: -1});
        return;
      }

      const transaction = this.db.transaction([this.tableName], 'readwrite');
      const objectStore = transaction.objectStore(this.tableName);

      const getRequest = objectStore.get(accountId);

      const accountBalance = {
        accountId: accountId,
        balance: walletBalance
      };

      getRequest.onsuccess = (event) => {
        const existingRecord = (event.target as IDBRequest).result;

        let innerRequest;

        if (existingRecord) {
          innerRequest  = objectStore.put(accountBalance);
        } else {
          innerRequest = objectStore.add(accountBalance);
        }

        innerRequest.onsuccess = () => {
          resolve(true);
        };

        innerRequest.onerror = (event) => {
          reject((event.target as IDBRequest).error);
        };
      };

      getRequest.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  getAccountBalance(accountId: number): Promise<WalletBalanceDetail | undefined> {
    return new Promise<WalletBalanceDetail | undefined>(async (resolve, reject) => {
      await this.loadDBPromise;
      if (!this.db) {
        reject({code: -1});
        return;
      }

      const transaction = this.db.transaction([this.tableName], 'readonly');
      const objectStore = transaction.objectStore(this.tableName);
      const request = objectStore.get(accountId);

      request.onsuccess = async (event) => {
        const accountBalance = (event.target as IDBRequest).result;
        resolve(accountBalance?.balance);
      };

      request.onerror = (event) => {
        resolve(undefined);
      };
    });
  }
}
