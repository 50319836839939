import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import AssetChainSelector from "./AssetChainSelector";
import AssetTokenList from "./AssetTokenList";
import {BizContext} from "../../contexts/BizContext";
import {TGContext} from "../../contexts/TGContext";
import {AssetToken, CryptoChain} from "../../models/web3/Web3Model";
import {HomeBizModelContext} from "../../contexts/biz_model/HomeBizModelContext";
import {AssetTokenItemSizeType} from "./AssetTokenItem";

export enum AssetType {
  Token = 'token',
  NFTs = 'nft',
  Defi = 'defi'
};

interface AccountAssetProps {
  selectedChain?: CryptoChain;
  onClickTab: (tab: AssetType) => void;
  onClickSearchToken: () => void;
  onClickAddToken: () => void;
  onClickSearchChain: () => void;
}

const AccountAsset: React.FC<AccountAssetProps> = ({
                                                     selectedChain,
                                                     onClickTab,
                                                     onClickSearchToken,
                                                     onClickAddToken,
                                                     onClickSearchChain
                                                   }) => {
  const bizContext = useContext(BizContext);
  const tgContext = useContext(TGContext);
  const homeBizModelContext = useContext(HomeBizModelContext);
  if (!bizContext || !tgContext || !homeBizModelContext) {
    throw new Error("Context isn't existed");
  }

  const {homeBizModel} = bizContext;
  const {safeAreaBottom} = tgContext;
  const {chainList} = homeBizModelContext;

  const [assetTokenList, setAssetTokenList] = useState<AssetToken[]>([]);
  const [innerSelectedChain, setInnerSelectedChain] = useState<CryptoChain | undefined>(selectedChain);

  useEffect(() => {
    setInnerSelectedChain(selectedChain);
  }, [selectedChain]);

  useEffect(() => {
    const fetchAssetTokenData = async () => {
      const _assetTokenList = await homeBizModel.getAssetTokens(innerSelectedChain);
      setAssetTokenList(_assetTokenList);
    }
    fetchAssetTokenData();
  }, [innerSelectedChain, chainList]);

  const handleSelectChain = (chain?: CryptoChain) => {
    setInnerSelectedChain(chain);
  }

  return (
    <AssetContainer padding_bottom={safeAreaBottom / 2 + 99.5}>
      <AssetHeaderWrapper>
        <AssetTitleArea>
          <AssetTitle selected={true} onClick={() => onClickTab(AssetType.Token)}>Token</AssetTitle>
          <AssetTitle selected={false} onClick={() => onClickTab(AssetType.NFTs)}>NFTs</AssetTitle>
          <AssetTitle selected={false} onClick={() => onClickTab(AssetType.Defi)}>Defi</AssetTitle>
        </AssetTitleArea>
        <AssetHeaderIconArea>
          <AssetHeaderIcon src="/icons/ic_find.svg" onClick={onClickSearchToken}/>
          <AssetHeaderIcon src="/icons/ic_plus.svg" onClick={onClickAddToken}/>
        </AssetHeaderIconArea>
      </AssetHeaderWrapper>
      <AssetMainContent>
        <AssetChainSelector
          chains={chainList}
          selectedChain={innerSelectedChain}
          onSelectChain={handleSelectChain}
          onSelectMore={onClickSearchChain}
        />
        <AssetTokenList
          assetTokenList={assetTokenList}
          itemSizeType={AssetTokenItemSizeType.LargeIcon}
        />
      </AssetMainContent>
    </AssetContainer>
  );
}

interface AssetContainerProps {
  padding_bottom: number;
}

const AssetContainer = styled.div<AssetContainerProps>`
  display: flex;
  flex-direction: column;
  padding-top: 36px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: ${(props) => (`${props.padding_bottom}px`)};
`

const AssetHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`

const AssetTitleArea = styled.div`
  display: flex;
  gap: 24px;
  padding-left: 8px;
  padding-right: 8px;
  font: 700 18px 'Plus Jakarta Sans', sans-serif;
`

interface AssetTitleProps {
  selected: boolean;
}

const AssetTitle = styled.span<AssetTitleProps>`
  cursor: pointer;
  color: ${(props) => (props.selected ? '#262626' : '#26262628')};
`

const AssetHeaderIconArea = styled.div`
  display: flex;
  gap: 4px;
`

const AssetHeaderIcon = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  object-fit: none;
  object-position: center;
  background-color: #fff;
  box-shadow: 0 4px 2px #00000005;
  cursor: pointer;
`

const AssetMainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export default AccountAsset;
