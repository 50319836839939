import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import AccountActionList from "./AccountActionList";
import {AccountAction} from "../../enums/AccountEnums";
import { AlertModel } from '../../models/common/CommonModel';
import AlertMessageBar from '../common/AlertMessageBar';

interface AccountInfoProps {
  accountName: string;
  chain: string;
  balance: number;
  changePercentage: number;
  changeAmount: number;
  alertModel?: AlertModel;
  onClickCopyAddress: () => void;
  onClickNotify: () => void;
  onClickAction: (action: AccountAction) => void;
  onCloseAlert:() => void;
}

const AccountInfoHeader: React.FC<AccountInfoProps> = ({
                                                         accountName,
                                                         chain,
                                                         balance,
                                                         changePercentage,
                                                         changeAmount,
                                                         alertModel,
                                                         onClickCopyAddress,
                                                         onClickNotify,
                                                         onClickAction,
                                                         onCloseAlert
                                                       }) => {
  const [mainBalance, setMainBalance] = useState<number>(0);
  const [centsPart, setCentsPart] = useState<number>(0);
  useEffect(() => {
    if (balance === 0) {
      setMainBalance(0);
      setCentsPart(0);
      return;
    }

    const fixedBalance = balance.toFixed(2);
    const parts = fixedBalance.split('.');
    const _mainBalance = parts.length > 0 ? parseInt(parts[0]) : 0;
    const _cents = parts.length > 1 ? parseInt(parts[1]) : 0;
    setMainBalance(_mainBalance);
    setCentsPart(_cents);
  }, [balance]);

  return (
    <AccountInfoContainer>
      <AccountHeader>
        <AccountDisplay>
          <AccountName>{accountName}</AccountName>
          <AccountTypeArea onClick={onClickCopyAddress}>
            <AccountType>{chain}</AccountType>
            <ChainIcon src="/icons/ic_copy.svg"/>
          </AccountTypeArea>
        </AccountDisplay>
        <NotificationArea onClick={onClickNotify}>
          <NotificationIcon src="/icons/ic_notify.svg"/>
          <NotificationBadge/>
        </NotificationArea>
      </AccountHeader>

      {alertModel && (
        <AlertMessageBar alertModel={alertModel} onClose={onCloseAlert}/>
      )}
      
      <BalanceArea>
        <BalanceDisplay>
          <MainBalance>{`$${mainBalance}`}</MainBalance>
          <CentsPart>{`.${centsPart}`}</CentsPart>
        </BalanceDisplay>
        <ChangeInfo>
          <ChangeIcon src="/icons/ic_arrow_up.svg"/>
          <ChangeText>{`$${changeAmount} (${changePercentage}%)`}</ChangeText>
        </ChangeInfo>
      </BalanceArea>
      <AccountActionList onClickAction={onClickAction}/>
    </AccountInfoContainer>
  );
}

const AccountInfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0px 16px 16px 16px;
  box-sizing: border-box;
  gap: 16px;
  background-color: #fff;
`;

const AccountHeader = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
`;

const AccountDisplay = styled.div`
  display: flex;
  flex-direction: column;
`

const AccountName = styled.h1`
  color: #262626;
  font: 700 24px 'Plus Jakarta Sans', sans-serif;
`;

const AccountTypeArea = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

const AccountType = styled.span`
  color: #8c8c8c;
  font: 400 14px 'Plus Jakarta Sans', sans-serif;
`

const NotificationArea = styled.div`
  display: flex;
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

const NotificationIcon = styled.img`
  border-radius: 100%;
  background-color: #eef3f5;
  aspect-ratio: 1;
  object-fit: none;
  object-position: center;
`;

const NotificationBadge = styled.div`
  position: absolute;
  top: 6px;
  right: 0px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  background-color: #2EB553;
`

const ChainIcon = styled.img`
  object-fit: none;
  object-position: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const BalanceArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px
`;

const BalanceDisplay = styled.div`
  display: flex;
  font: 700 36px 'Plus Jakarta Sans', sans-serif;
`

const MainBalance = styled.div`
  color: #262626;
`;

const CentsPart = styled.div`
  color: #bfbfbf;
`;

const ChangeInfo = styled.div`
  display: flex;
  gap: 8px;
  color: #2eb553;

`;

const ChangeIcon = styled.img`
  object-fit: none;
  object-position: center;
`;

const ChangeText = styled.span`
  font: 400 14px 'Plus Jakarta Sans', sans-serif;
`;

export default AccountInfoHeader;
