import React from "react";
import styled from "styled-components";
import { GeneralNotifyModel } from "../../../models/notification/NotifyModel";
import {
   NotifyAlertType,
   NotifyTransactionType,
   NotifyType
} from "../../../enums/NotifyEnum";
import {
   AlertNotifyModel,
   NFAlertAccountSecurityData,
   NFAlertGasFeeData
} from "../../../models/notification/AlertNotifyModel";
import {
   NFTransactionApproveData,
   NFTransactionReceiveData,
   NFTransactionSendData,
   NFTransactionSwapBridgeData,
   NFTransactionTradeData,
   TransactionNotifyModel
} from "../../../models/notification/TransactionNotifyModel";
import AlertAccountSecurityItem from "./alert/AccountSecurityItem";
import AlertGasFeeItem from "./alert/GasFeeItem";
import TransactionApproveItem from "./transaction/ApproveItem";
import TransactionSendReceiveItem from "./transaction/SendReceiveItem";
import TransactionTradeItem from "./transaction/TradeItem";
import TransactionSwapBridgeItem from "./transaction/SwapBridgeItem";

export interface NotifyListProps {
   notifyList?: GeneralNotifyModel<any>[];
}

const NotifyList: React.FC<NotifyListProps> = ({
   notifyList
}) => {

   const renderAlertItem = (item: AlertNotifyModel<any>) => {
      switch (item.alertType) {
         case NotifyAlertType.SecureWallet:
            return <AlertAccountSecurityItem item={item as AlertNotifyModel<NFAlertAccountSecurityData>} />;

         case NotifyAlertType.GasFee:
            return <AlertGasFeeItem item={item as AlertNotifyModel<NFAlertGasFeeData>} />;

         default:
            return null;
      }
   };

   const renderTransactionItem = (item: TransactionNotifyModel<any>) => {
      switch (item.transactionType) {
         case NotifyTransactionType.SwapBridge:
            return <TransactionSwapBridgeItem item={item as TransactionNotifyModel<NFTransactionSwapBridgeData>} />

         case NotifyTransactionType.Approve:
            return <TransactionApproveItem item={item as TransactionNotifyModel<NFTransactionApproveData>} />

         case NotifyTransactionType.Send:
         case NotifyTransactionType.Receive:
            return <TransactionSendReceiveItem item={item as TransactionNotifyModel<NFTransactionSendData | NFTransactionReceiveData>} />

         case NotifyTransactionType.Trade:
            return <TransactionTradeItem item={item as TransactionNotifyModel<NFTransactionTradeData>} />

         default:
            return null;
      }
   }

   return (
      <NotifyListContainer>
         {notifyList && notifyList.map(item => {
            switch (item.notifType) {
               case NotifyType.Alert:
                  return renderAlertItem(item as AlertNotifyModel<any>);

               case NotifyType.Transaction:
                  return renderTransactionItem(item as TransactionNotifyModel<any>);

               default:
                  return null;
            }
         })}
      </NotifyListContainer>
   )
};

const NotifyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  gap: 1px;
`

export default NotifyList;