import {SBCacheTransaction} from "../../CacheService/models/SwapBridgeCacheModel";
import {DatabaseStore, IDatabaseStore} from "./DatabaseStore";

export interface ISwapBridgeStore extends IDatabaseStore {
  storeSBTransaction(sbTransaction: SBCacheTransaction): Promise<boolean>;

  getSBTransactions(accountId: number): Promise<SBCacheTransaction[]>;

  updateSBTransaction(sbTransaction: SBCacheTransaction): Promise<boolean>;
}

export class SwapBridgeStore extends DatabaseStore implements ISwapBridgeStore {
  storeSBTransaction(sbTransaction: SBCacheTransaction): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      await this.loadDBPromise;
      if (!this.db) {
        reject({code: -1});
        return;
      }

      const transaction = this.db.transaction([this.tableName], 'readwrite');
      const objectStore = transaction.objectStore(this.tableName);
      const request = objectStore.add(sbTransaction);

      request.onsuccess = async (event) => {
        resolve((event.target as IDBRequest).result);
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  getSBTransactions(accountId: number): Promise<SBCacheTransaction[]> {
    return new Promise<SBCacheTransaction[]>(async (resolve, reject) => {
      await this.loadDBPromise;
      if (!this.db) {
        reject({code: -1});
        return;
      }

      const transaction = this.db.transaction([this.tableName], "readonly");
      const objectStore = transaction.objectStore(this.tableName);
      const index = objectStore.index("accountId");

      const request = index.openCursor(IDBKeyRange.only(accountId));
      const results: any[] = [];

      request.onsuccess = (event) => {
        const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result;
        if (cursor) {
          results.push(cursor.value);
          cursor.continue();
        } else {
          resolve(results);
        }
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  updateSBTransaction(sbTransaction: SBCacheTransaction): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      await this.loadDBPromise;
      if (!this.db) {
        reject({code: -1});
        return;
      }

      const transaction = this.db.transaction([this.tableName], "readwrite");
      const objectStore = transaction.objectStore(this.tableName);
      const index = objectStore.index("accountId");

      const request = index.openCursor(IDBKeyRange.only(sbTransaction.accountId));

      request.onsuccess = (event) => {
        const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result;
        if (cursor) {
          // Cập nhật dữ liệu
          const updatedRecord = {...cursor.value, ...sbTransaction};
          const updateRequest = objectStore.put(updatedRecord);

          updateRequest.onsuccess = () => {
            resolve(true);
          };

          updateRequest.onerror = (event) => {
            reject((event.target as IDBRequest).error);
          };

          cursor.continue(); // Tiếp tục tìm kiếm các record tiếp theo
        } else {
          resolve(false); // Kết thúc nếu không còn record nào
        }
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }
}
