import { AccountAddressInfo, AccountInfo } from "../models/home/HomeModel";
import { AssetToken, CryptoChain, CryptoToken } from "../models/web3/Web3Model";
import { CryptoNetworkType } from "../enums/Web3Enums";
import { SBSupplier } from "../models/swap_bridge/SwapBridgeModel";
import {SBActionType, SBExchangeType} from "../enums/SwapBridgeEnums";
import { GeneralNotifyModel, NotifyTabModel } from "../models/notification/NotifyModel";
import { NotifyAlertType, NotifyCTAType, NotifyTabType, NotifyTransactionType, NotifyType } from "../enums/NotifyEnum";
import { SendFeeModel } from "../models/send/fee";
import {Web3GasType} from "./Web3Service/MultichainGasFetcher";

export interface IMockService {
  getSupportedChains(): Promise<CryptoChain[]>
  getAssetTokens(chain?: CryptoChain, showNoBalance?: boolean): Promise<AssetToken[]>
  getAccountAddressInfos(): Promise<AccountAddressInfo[]>
  getAllAccountInfos(): Promise<AccountInfo[]>
  getTokenPrice(token: CryptoToken): Promise<number>;
  getQuote(token: CryptoToken, amountInETH: number): Promise<number>;
  getAddress(chain: CryptoChain): Promise<string>;
  getExchangeSuppliers(): Promise<SBSupplier[]>;
  getTxExplorer(chainName: string): Promise<any>;

  getNotifyTabList(): Promise<NotifyTabModel[]>;
  getNotifyList<T>(): Promise<GeneralNotifyModel<T>[]>;
  getAllFees(): Promise<SendFeeModel[]>
}

export class MockService implements IMockService {
  private notifyList: GeneralNotifyModel<any>[];
  constructor() {
    this.notifyList = [];
  }

  getSupportedChains(): Promise<CryptoChain[]> {
    return new Promise<CryptoChain[]>(resolve => {
      const chains: CryptoChain[] = [
        {
          id: 1,
          chain_id: 0,
          name: "Ethereum",
          symbol: "ETH",
          network: "EVM",
          logo: "https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=035"
        },
        {
          id: 2,
          chain_id: 0,
          name: "Solana",
          symbol: "SOL",
          network: "SOL",
          logo: "https://cryptologos.cc/logos/solana-sol-logo.svg?v=035"
        },
        {
          id: 3,
          chain_id: 0,
          name: "Fantom",
          symbol: "FTM",
          network: "EVM",
          logo: "https://cryptologos.cc/logos/fantom-ftm-logo.svg?v=035"
        },
        {
          id: 4,
          chain_id: 0,
          name: "TON",
          symbol: "TON",
          network: "TON",
          logo: "https://cryptologos.cc/logos/toncoin-ton-logo.svg?v=035"
        },
        {
          id: 5,
          chain_id: 0,
          name: "BSC",
          symbol: "BNB",
          network: "EVM",
          logo: "https://cryptologos.cc/logos/bnb-bnb-logo.svg?v=035"
        },
        {
          id: 6,
          chain_id: 0,
          name: "Mantle",
          symbol: "MNT",
          network: "EVM",
          logo: "https://cryptologos.cc/logos/mantle-mnt-logo.svg?v=035"
        },
        {
          id: 7,
          chain_id: 0,
          name: "Polygon",
          symbol: "POL",
          network: "EVM",
          logo: "https://cryptologos.cc/logos/polygon-matic-logo.svg?v=035"
        },
        {
          id: 8,
          chain_id: 0,
          name: "Avalanche",
          symbol: "AVAX",
          network: "EVM",
          logo: "https://cryptologos.cc/logos/avalanche-avax-logo.svg?v=035"
        },
        {
          id: 9,
          chain_id: 0,
          name: "Arbitrum",
          symbol: "ARB",
          network: "EVM",
          logo: "https://cryptologos.cc/logos/arbitrum-arb-logo.svg?v=035"
        },
        {
          id: 10,
          chain_id: 0,
          name: "Optimism",
          symbol: "OP",
          network: "EVM",
          logo: "https://cryptologos.cc/logos/chainlink-link-logo.svg?v=035"
        },
      ]
      resolve(chains);
    });
  }

  getAssetTokens(chain?: CryptoChain, showNoBalance?: boolean): Promise<AssetToken[]> {
    return new Promise<AssetToken[]>(async resolve => {
      let assetTokenList: AssetToken[] = [];
      const chainList = await this.getSupportedChains();
      if (!chain) {
        assetTokenList = [
          {
            chain: chainList[0],
            symbol: "ETH",
            name: "Ethereum",
            address: '0xEth',
            decimals: 18,
            logo: "https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=035",
            balance: {
              amount: 5000000000000000n,
              amountInETH: 0.005
            },
            quote: {
              quote: 80.26,
              beforeQuote: 80.57,
              quoteChangePerc: 1.19,
            }
          },
          {
            chain: chainList[4],
            symbol: "BNB",
            name: "BNB",
            address: '0xBnb',
            decimals: 18,
            balance: {
              amount: 60000000000000000n,
              amountInETH: 0.06
            },
            quote: {
              quote: 80.26,
              beforeQuote: 80.57,
              quoteChangePerc: 1.19,
            },
            logo: "https://cryptologos.cc/logos/bnb-bnb-logo.svg?v=035"
          },
          {
            chain: chainList[0],
            symbol: "POL",
            name: "Polygon",
            address: '0xPol',
            decimals: 18,
            balance: {
              amount: 5000000000000000n,
              amountInETH: 0.005
            },
            quote: {
              quote: 80.26,
              beforeQuote: 80.57,
              quoteChangePerc: 1.19,
            },
            logo: "https://cryptologos.cc/logos/polygon-matic-logo.svg?v=035"
          },
          {
            chain: chainList[0],
            symbol: "AVAX",
            name: "Avalanche",
            address: '0xAvax',
            decimals: 18,
            balance: {
              amount: 500000000000000000n,
              amountInETH: 0.5
            },
            quote: {
              quote: 80.26,
              beforeQuote: 80.57,
              quoteChangePerc: 1.19,
            },
            logo: "https://cryptologos.cc/logos/avalanche-avax-logo.svg?v=035"
          },
          {
            chain: chainList[4],
            symbol: "SHIB",
            name: "Shiba Inu",
            address: '0xShib',
            decimals: 18,
            balance: {
              amount: 500000000000000000n,
              amountInETH: 0.5
            },
            quote: {
              quote: 80.26,
              beforeQuote: 80.57,
              quoteChangePerc: 1.19,
            },
            logo: "https://cryptologos.cc/logos/shiba-inu-shib-logo.svg?v=035"
          },
          {
            chain: chainList[1],
            symbol: "SOL",
            name: "Solana",
            address: '0xSol',
            decimals: 18,
            balance: {
              amount: 20000000000000000n,
              amountInETH: 0.02
            },
            quote: {
              quote: 80.26,
              beforeQuote: 80.57,
              quoteChangePerc: 1.19,
            },
            logo: "https://cryptologos.cc/logos/solana-sol-logo.svg?v=035"
          },
          {
            chain: chainList[0],
            symbol: "ARB",
            name: "Arbitrum",
            address: '0xArb',
            decimals: 18,
            balance: {
              amount: 20000000000000000n,
              amountInETH: 0.02
            },
            quote: {
              quote: 80.26,
              beforeQuote: 80.57,
              quoteChangePerc: 1.19,
            },
            logo: "https://cryptologos.cc/logos/arbitrum-arb-logo.svg?v=035"
          },
          {
            chain: chainList[0],
            symbol: "OP",
            name: "Optimism",
            address: '0xOp',
            decimals: 18,
            balance: {
              amount: 20000000000000000n,
              amountInETH: 0.02
            },
            quote: {
              quote: 80.26,
              beforeQuote: 80.57,
              quoteChangePerc: 1.19,
            },
            logo: "https://cryptologos.cc/logos/optimism-ethereum-op-logo.svg?v=035"
          },
          {
            chain: chainList[1],
            symbol: "LINK",
            name: "Chainlink",
            address: '0xLink',
            decimals: 18,
            balance: {
              amount: 500000000000000000n,
              amountInETH: 0.5
            },
            quote: {
              quote: 80.26,
              beforeQuote: 80.57,
              quoteChangePerc: 1.19,
            },
            logo: "https://cryptologos.cc/logos/chainlink-link-logo.svg?v=035"
          },
          {
            chain: chainList[0],
            symbol: "CRO",
            name: "Cronos",
            address: '0xCro',
            decimals: 18,
            balance: {
              amount: 500000000000000000n,
              amountInETH: 0.5
            },
            quote: {
              quote: 80.26,
              beforeQuote: 80.57,
              quoteChangePerc: 1.19,
            },
            logo: "https://cryptologos.cc/logos/cronos-cro-logo.svg?v=035"
          }
        ]
      }
      else {
        switch (chain.network) {
          case "EVM":
            assetTokenList = [
              {
                chain: chain,
                symbol: "ETH",
                name: "Ethereum",
                address: '0xEth',
                decimals: 18,
                balance: {
                  amount: 5000000000000000n,
                  amountInETH: 0.005
                },
                quote: {
                  quote: 80.26,
                  beforeQuote: 80.57,
                  quoteChangePerc: 1.19,
                },
                logo: "https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=035"
              },
              {
                chain: chain,
                symbol: "DAI",
                name: "MakerDAO",
                address: '0xDai',
                decimals: 18,
                balance: {
                  amount: 60000000000000000n,
                  amountInETH: 0.06
                },
                quote: {
                  quote: 80.26,
                  beforeQuote: 80.57,
                  quoteChangePerc: 1.19,
                },
                logo: "https://cryptologos.cc/logos/multi-collateral-dai-dai-logo.svg?v=035"
              },
              {
                chain: chain,
                symbol: "UNI",
                name: "Uniswap",
                address: '0xUni',
                decimals: 18,
                balance: {
                  amount: 5000000000000000n,
                  amountInETH: 0.005
                },
                quote: {
                  quote: 80.26,
                  beforeQuote: 80.57,
                  quoteChangePerc: 1.19,
                },
                logo: "https://cryptologos.cc/logos/uniswap-uni-logo.svg?v=035"
              },
              {
                chain: chain,
                symbol: "LINK",
                name: "Chainlink",
                address: '0xLink',
                decimals: 18,
                balance: {
                  amount: 500000000000000000n,
                  amountInETH: 0.5
                },
                quote: {
                  quote: 80.26,
                  beforeQuote: 80.57,
                  quoteChangePerc: 1.19,
                },
                logo: "https://cryptologos.cc/logos/chainlink-link-logo.svg?v=035"
              },
              {
                chain: chain,
                symbol: "AAVE",
                name: "Aave",
                address: '0xAave',
                decimals: 18,
                balance: {
                  amount: 20000000000000000n,
                  amountInETH: 0.02
                },
                quote: {
                  quote: 80.26,
                  beforeQuote: 80.57,
                  quoteChangePerc: 1.19,
                },
                logo: "https://cryptologos.cc/logos/aave-aave-logo.svg?v=035"
              }
            ]
            break;
          case "SOL":
            assetTokenList = [
              {
                chain: chain,
                symbol: "SOL",
                name: "Solana",
                address: '0xSol',
                decimals: 18,
                balance: {
                  amount: 20000000000000000n,
                  amountInETH: 0.02
                },
                quote: {
                  quote: 80.26,
                  beforeQuote: 80.57,
                  quoteChangePerc: 1.19,
                },
                logo: "https://cryptologos.cc/logos/solana-sol-logo.svg?v=035"
              }
            ]
            break;
          case "TON":
            assetTokenList = [
              {
                chain: chain,
                symbol: "TON",
                name: "Toncoin",
                address: 'UQ_Ton',
                decimals: 18,
                balance: {
                  amount: 20000000000000000n,
                  amountInETH: 0.02
                },
                quote: {
                  quote: 80.26,
                  beforeQuote: 80.57,
                  quoteChangePerc: 1.19,
                },
                logo: "https://cryptologos.cc/logos/toncoin-ton-logo.svg?v=035"
              }
            ]
            break;
        }
      }

      if (showNoBalance) {
        const noBalanceList: AssetToken[] = [
          {
            chain: chainList[3],
            symbol: "MEW",
            name: "Meo",
            address: 'UQ_Mew',
            decimals: 18,
            balance: {
              amount: 0n,
              amountInETH: 0
            },
            quote: {
              quote: 80.26,
              beforeQuote: 80.57,
              quoteChangePerc: 1.19,
            },
            logo: "https://cryptologos.cc/logos/mew-mew-logo.svg?v=035"
          },
          {
            chain: chainList[1],
            symbol: "SRM",
            name: "Serum",
            address: '0xSrm',
            decimals: 18,
            balance: {
              amount: 0n,
              amountInETH: 0
            },
            quote: {
              quote: 80.26,
              beforeQuote: 80.57,
              quoteChangePerc: 1.19,
            },
            logo: "https://cryptologos.cc/logos/serum-srm-logo.svg?v=035"
          },
        ]
        assetTokenList = [...assetTokenList, ...noBalanceList];
      }
      resolve(assetTokenList);
    });
  }

  getAccountAddressInfos(): Promise<AccountAddressInfo[]> {
    return new Promise<AccountAddressInfo[]>(async resolve => {
      const chainList = await this.getSupportedChains();
      const addressList: AccountAddressInfo[] = [
        {
          chain: chainList[0],
          address: "0x76F0Fb659E5f97067279Bece7e859aF6556C08C1"
        },
        {
          chain: chainList[1],
          address: "8sK5NtxFa7HYXRfhoL85iCFmSUGYVEBtQmqakZoL5WBB"
        },
        {
          chain: chainList[2],
          address: "0x76F0Fb659E5f97067279Bece7e859aF6556C08C1"
        },
        {
          chain: chainList[3],
          address: "UQDt29mK5ix_aJFydPwVB9O8j4WEjp6O4CLJfQIE60K7iViN"
        },
        {
          chain: chainList[4],
          address: "0x76F0Fb659E5f97067279Bece7e859aF6556C08C1"
        },
        {
          chain: chainList[5],
          address: "0x76F0Fb659E5f97067279Bece7e859aF6556C08C1"
        },
        {
          chain: chainList[6],
          address: "0x76F0Fb659E5f97067279Bece7e859aF6556C08C1"
        }
      ]

      resolve(addressList);
    });
  }

  getAllAccountInfos(): Promise<AccountInfo[]> {
    return new Promise<AccountInfo[]>(resolve => {
      const accountInfos: AccountInfo[] = [
        {
          accountName: "Mai Hung",
          accountAvatar: "/mock_imgs/img_account_1.svg",
          chain: "Multichain",
          totalQuote: 120.88,
          quoteChange: 10.3,
          quoteChangePercent: 2.2
        },
        {
          accountName: "Truong Nguyen",
          accountAvatar: "/mock_imgs/img_account_2.svg",
          chain: "Multichain",
          totalQuote: 120.88,
          quoteChange: 10.3,
          quoteChangePercent: 2.2
        },
        {
          accountName: "Tu Pham",
          accountAvatar: "/mock_imgs/img_account_3.svg",
          chain: "Multichain",
          totalQuote: 120.88,
          quoteChange: 10.3,
          quoteChangePercent: 2.2
        }
      ]

      resolve(accountInfos);
    });
  }

  getTokenPrice(token: CryptoToken): Promise<number> {
    return new Promise<number>(resolve => {
      let tokenPrice = 0;
      switch (token.symbol) {
        case 'ETH':
          tokenPrice = 2700;
          break;
        case 'BNB':
          tokenPrice = 600;
          break;
        case 'POL':
          tokenPrice = 0.37;
          break;
        case 'AVAX':
          tokenPrice = 28;
          break;
        case 'SHIB':
          tokenPrice = 0.00001888;
          break;
        case 'ARB':
          tokenPrice = 0.6;
          break;
        case 'OP':
          tokenPrice = 1.7;
          break;
        case 'LINK':
          tokenPrice = 12;
          break;
        case 'CRO':
          tokenPrice = 0.08;
          break;
        case 'UNI':
          tokenPrice = 7.6;
          break;
        case 'AAVE':
          tokenPrice = 158;
          break;
        case 'DAI':
          tokenPrice = 1;
          break;
        case 'SOL':
          tokenPrice = 150;
          break;
        case 'TON':
          tokenPrice = 7;
          break;
        default:
          tokenPrice = 2.3;
          break;
      }

      resolve(tokenPrice);
    });
  }

  getQuote(token: CryptoToken, amountInETH: number): Promise<number> {
    return new Promise<number>(async resolve => {
      const tokenPrice = await this.getTokenPrice(token);
      console.log(tokenPrice);
      resolve(tokenPrice * amountInETH);
    })
  }

  getAddress(chain: CryptoChain): Promise<string> {
    return new Promise<string>(resolve => {
      let address = '';
      switch (chain.network) {
        case CryptoNetworkType.EVM:
          address = '0x76F0Fb659E5f97067279Bece7e859aF6556C08C1';
          break;

        case CryptoNetworkType.SOL:
          address = '8sK5NtxFa7HYXRfhoL85iCFmSUGYVEBtQmqakZoL5WBB';
          break;

        case CryptoNetworkType.TON:
          address = 'UQDt29mK5ix_aJFydPwVB9O8j4WEjp6O4CLJfQIE60K7iViN';
          break;
      }

      resolve(address);
    })
  }

  getExchangeSuppliers(): Promise<SBSupplier[]> {
    return new Promise<SBSupplier[]>(resolve => {
      resolve([{
        type: SBExchangeType.Bridge,
        actionType: SBActionType.Deposit,
        name: 'Omni',
        logo: '/mock_imgs/ic_omni.svg'
      }])
    })
  }

  getTxExplorer(chainName: string): Promise<any> {
    return new Promise<any>(resolve => {
      let explorer = undefined;
      switch (chainName) {
        case 'Ethereum':
          explorer = {
            explorer: "https://etherscan.com",
            logo: "/mock_imgs/etherscan.jpg"
          }
          break;
        case 'BSC':
          explorer = {
            explorer: "https://bscscan.com",
            logo: "/mock_imgs/bscscan.jpg"
          }
          break;
        case 'Fantom':
          explorer = {
            explorer: "https://ftmscan.com",
            logo: "/mock_imgs/ftmscan.jpg"
          }
          break;
        case 'Solana':
          explorer = {
            explorer: "https://solscan.com",
            logo: "/mock_imgs/solscan.jpg"
          }
          break;
        case 'TON':
          explorer = {
            explorer: "https://tonviewer.com",
            logo: "/mock_imgs/tonscan.jpg"
          }
          break;
        default:
          explorer = {
            explorer: "https://etherscan.com",
            logo: "/mock_imgs/etherscan.jpg"
          }
          break;
      }

      resolve(explorer);
    });
  }

  // MARK: - Notify
  getNotifyTabList(): Promise<NotifyTabModel[]> {
    return new Promise<NotifyTabModel[]>((resolve) => {
      const notifyTabList: NotifyTabModel[] = [
        {
          title: "All",
          type: NotifyTabType.All,
        },
        {
          title: "Transaction",
          type: NotifyTabType.Transaction,
        },
        {
          title: "Alert",
          type: NotifyTabType.Alert,
        },
      ];
      resolve(notifyTabList);
    });
  }

  getNotifyList(): Promise<GeneralNotifyModel<any>[]> {
    return new Promise<GeneralNotifyModel<any>[]>((resolve) => {
      if (this.notifyList.length !== 0) {
        return resolve(this.notifyList);
      }

      // Create new || Get server
      const notifyList: GeneralNotifyModel<any>[] = [
        {
          notifType: NotifyType.Alert,
          id: "notify_1",
          logo: "/icons/ic_notify_alert.svg",
          title: "Secure Your Wallet",
          description:
            "You will lose access to your funds forever if your device is lost or stolen",

          alertType: NotifyAlertType.SecureWallet,
          data: {
            avatar: "/mock_imgs/img_account_1.svg",
            address: "0x1e13Fb659E5f97067279Bece7e859aF6556C60Af",
            ctaButton: {
              title: "Backup Now (~1mn)",
              type: NotifyCTAType.Backup,
            },
          }
        },
        {
          notifType: NotifyType.Alert,
          id: "notify_2",
          logo: "/icons/ic_notify_alert.svg",
          title: "High Gas Fees",
          description:
            "Gas fees are currently high, consider waiting to save costs",
          alertType: NotifyAlertType.GasFee
        },
        {
          notifType: NotifyType.Transaction,
          id: "notify_3",
          logo: "/icons/ic_notify_swap.svg",
          title: "Swap & Bridge",
          description: "20-10\u00A0\u00A017:20",

          transactionType: NotifyTransactionType.SwapBridge,
          data: {
            fromToken: {
              name: "Solana",
              symbol: "SOL",
              logo: "/icons/ic_black_solana.svg",
              amount: 356.3634
            },
            toToken: {
              name: "TON",
              symbol: "TON",
              logo: "https://cryptologos.cc/logos/toncoin-ton-logo.svg?v=035",
              amount: 538.779504
            }
          }
        },
        {
          notifType: NotifyType.Transaction,
          id: "notify_4",
          logo: "https://cryptologos.cc/logos/bnb-bnb-logo.svg?v=035",
          title: "Approve",
          description: "21-10\u00A0\u00A009:20",

          transactionType: NotifyTransactionType.Approve,
          data: {
            tokenAmount: {
              amount: "408.4B",
              tokenName: "ZKDC",
            },
          }
        },
        {
          notifType: NotifyType.Transaction,
          id: "notify_5",
          logo: "https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=035",
          title: "Trade",
          description: "21-10\u00A0\u00A009:20",

          transactionType: NotifyTransactionType.Trade,
          data: {
            changeInfo: {
              tokenAmount: {
                amount: 0.8,
                tokenName: "BNB",
              },
              quoteValue: 1.13
            }
          }
        },
        {
          notifType: NotifyType.Transaction,
          id: "notify_6",
          logo: "https://cryptologos.cc/logos/bnb-bnb-logo.svg?v=035",
          title: "Send",
          description: "08-10\u00A0\u00A007:22",

          transactionType: NotifyTransactionType.Send,
          data: {
            changeInfo: {
              tokenAmount: {
                amount: -0.002,
                tokenName: "BNB",
              },
              quoteValue: 1.13
            }
          }
        },
        {
          notifType: NotifyType.Alert,
          id: "notify_7",
          logo: "/icons/ic_notify_alert.svg",
          title: "High Gas Fees",
          description:
            "Gas fees are currently high, consider waiting to save costs",
          alertType: NotifyAlertType.GasFee
        },
        {
          notifType: NotifyType.Transaction,
          id: "notify_8",
          logo: "https://cryptologos.cc/logos/arbitrum-arb-logo.svg?v=035",
          title: "Receive",
          description: "08-10\u00A0\u00A016:20",

          transactionType: NotifyTransactionType.Receive,
          data: {
            changeInfo: {
              tokenAmount: {
                amount: 0.005,
                tokenName: "USDT",
              },
              quoteValue: 0.13
            }
          }
        },

        {
          notifType: NotifyType.Transaction,
          id: "notify_9",
          logo: "/icons/ic_black_solana.svg",
          title: "Receive",
          description: "20-10\u00A0\u00A017:20",

          transactionType: NotifyTransactionType.Receive,
          data: {
            changeInfo: {
              tokenAmount: {
                amount: 0.005,
                tokenName: "USDT",
              },
              quoteValue: 0.13
            }
          }
        }, {
          notifType: NotifyType.Transaction,
          id: "notify_10",
          logo: "https://cryptologos.cc/logos/arbitrum-arb-logo.svg?v=035",
          title: "Receive",
          description: "08-10\u00A0\u00A016:20",

          transactionType: NotifyTransactionType.Receive,
          data: {
            changeInfo: {
              tokenAmount: {
                amount: 0.005,
                tokenName: "USDT",
              },
              quoteValue: 0.13
            }
          }
        }, {
          notifType: NotifyType.Transaction,
          id: "notify_11",
          logo: "https://cryptologos.cc/logos/arbitrum-arb-logo.svg?v=035",
          title: "Receive",
          description: "08-10\u00A0\u00A016:20",

          transactionType: NotifyTransactionType.Receive,
          data: {
            changeInfo: {
              tokenAmount: {
                amount: 0.005,
                tokenName: "USDT",
              },
              quoteValue: 0.13
            }
          }
        }, {
          notifType: NotifyType.Transaction,
          id: "notify_12",
          logo: "https://cryptologos.cc/logos/arbitrum-arb-logo.svg?v=035",
          title: "Receive",
          description: "08-10\u00A0\u00A016:20",

          transactionType: NotifyTransactionType.Receive,
          data: {
            changeInfo: {
              tokenAmount: {
                amount: 0.005,
                tokenName: "USDT",
              },
              quoteValue: 0.13
            }
          }
        },
        {
          notifType: NotifyType.Transaction,
          id: "notify_13",
          logo: "https://cryptologos.cc/logos/arbitrum-arb-logo.svg?v=035",
          title: "Receive",
          description: "08-10\u00A0\u00A016:20",

          transactionType: NotifyTransactionType.Receive,
          data: {
            changeInfo: {
              tokenAmount: {
                amount: 0.005,
                tokenName: "USDT",
              },
              quoteValue: 0.13
            }
          }
        },

      ];
      this.notifyList = notifyList;
      resolve(notifyList);
    });
  }

  getAllFees(): Promise<SendFeeModel[]> {
      return new Promise<SendFeeModel[]>(resolve => {
        const fees: SendFeeModel[] = [
          {
            type: Web3GasType.Slow,
            icon: '/icons/ic_fee_slow.svg',
            title: 'Slow',
            unit: "ETH",
            amountInETH: 0.000014,
            quote: 0.999,
            estimatedTime: 50
          },
          {
            type: Web3GasType.Medium,
            icon: '/icons/ic_fee_medium.svg',
            title: 'Medium',
            unit: "ETH",
            amountInETH: 0.000019,
            quote: 1.009,
            estimatedTime: 20
          },
          {
            type: Web3GasType.Fast,
            icon: '/icons/ic_fee_fast.svg',
            title: 'Fast',
            unit: "ETH",
            amountInETH: 0.000022,
            quote: 2.999,
            estimatedTime: 10
          }
        ];

        resolve(fees);
      })
  }
}
