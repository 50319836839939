import React, { createContext, useState } from 'react';
import { IHomeBizModel } from "../biz/home/HomeBizModel";
import { HomeBizInjector } from "../biz/home/HomeBizInjector";
import { ICreateWalletBizModel } from "../biz/onboarding/create/CreateWalletBizModel";
import { CreateWalletBizInjector } from "../biz/onboarding/create/CreateWalletBizInjector";
import { IImportWalletBizModel } from "../biz/onboarding/import/ImportWalletBizModel";
import { ImportWalletBizInjector } from "../biz/onboarding/import/ImportWalletBizInjector";
import { ISwapBridgeBizModel } from "../biz/swap_bridge/SwapBridgeBizModel";
import { SwapBridgeBizInjector } from "../biz/swap_bridge/SwapBridgeBizInjector";
import {ICommonBizModel} from "../biz/common/CommonBizModel";
import {CommonBizInjector} from "../biz/common/CommonBizInjector";
import {IRocketLaunchBizModel} from "../biz/onboarding/rocket_launch/RocketLaunchBizModel";
import {RocketLaunchBizInjector} from "../biz/onboarding/rocket_launch/RocketLaunchBizInjector";
import { IReceiveWalletBizModel } from '../biz/receive/ReceiveWalletBizModel';
import { ReceiveWalletBizInjector } from '../biz/receive/ReceiveWalletBizInjector';
import { INotifyBizModel } from '../biz/notification/NotifyBizModel';
import { NotifyBizInjector } from '../biz/notification/NotifyBizInjector';
import { ISendWalletBizModel } from '../biz/send/SendWalletBizModel';
import { SendWalletBizInjector } from '../biz/send/SendWalletBizInjector';

interface BizContextType {
  commonBizModel: ICommonBizModel;
  setCommonBizModel: React.Dispatch<React.SetStateAction<ICommonBizModel>>;

  homeBizModel: IHomeBizModel;
  setHomeBizModel: React.Dispatch<React.SetStateAction<IHomeBizModel>>;

  createWalletBizModel: ICreateWalletBizModel;
  setCreateWalletBizModel: React.Dispatch<React.SetStateAction<ICreateWalletBizModel>>;

  importWalletBizModel: IImportWalletBizModel;
  setImportWalletBizModel: React.Dispatch<React.SetStateAction<IImportWalletBizModel>>;

  rocketLaunchBizModel: IRocketLaunchBizModel;
  setRocketLaunchBizModel: React.Dispatch<React.SetStateAction<IRocketLaunchBizModel>>;

  swapBridgeBizModel: ISwapBridgeBizModel;
  setSwapBridgeBizModel: React.Dispatch<React.SetStateAction<ISwapBridgeBizModel>>;

  sendBizModel: ISendWalletBizModel;
  setSendBizModel: React.Dispatch<React.SetStateAction<ISendWalletBizModel>>;
  
  receiveBizModel: IReceiveWalletBizModel;
  setReceiveBizModel: React.Dispatch<React.SetStateAction<IReceiveWalletBizModel>>;
  
  notifyBizModel: INotifyBizModel;
  setNotifyBizModel: React.Dispatch<React.SetStateAction<INotifyBizModel>>;
}

export const BizContext = createContext<BizContextType | undefined>(undefined);
export const BizProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
  const [commonBizModel, setCommonBizModel] = useState<ICommonBizModel>((new CommonBizInjector()).inject());
  const [homeBizModel, setHomeBizModel] = useState<IHomeBizModel>((new HomeBizInjector()).inject());
  const [createWalletBizModel, setCreateWalletBizModel] = useState<ICreateWalletBizModel>(new CreateWalletBizInjector().inject());
  const [importWalletBizModel, setImportWalletBizModel] = useState<IImportWalletBizModel>(new ImportWalletBizInjector().inject());
  const [rocketLaunchBizModel, setRocketLaunchBizModel] = useState<IRocketLaunchBizModel>(new RocketLaunchBizInjector().inject());
  const [swapBridgeBizModel, setSwapBridgeBizModel] = useState<ISwapBridgeBizModel>((new SwapBridgeBizInjector()).inject());
  const [receiveBizModel, setReceiveBizModel] = useState<IReceiveWalletBizModel>((new ReceiveWalletBizInjector()).inject());
  const [notifyBizModel, setNotifyBizModel] = useState<INotifyBizModel>((new NotifyBizInjector()).inject());
  const [sendBizModel, setSendBizModel] = useState<ISendWalletBizModel>((new SendWalletBizInjector()).inject());

  return (
    <BizContext.Provider value={{
      commonBizModel,
      setCommonBizModel,

      homeBizModel,
      setHomeBizModel,

      createWalletBizModel,
      setCreateWalletBizModel,

      importWalletBizModel,
      setImportWalletBizModel,

      rocketLaunchBizModel,
      setRocketLaunchBizModel,

      swapBridgeBizModel,
      setSwapBridgeBizModel,

      sendBizModel,
      setSendBizModel,
      
      receiveBizModel,
      setReceiveBizModel,
      
      notifyBizModel,
      setNotifyBizModel,
    }}>
      {children}
    </BizContext.Provider>
  );
}

