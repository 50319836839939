import React from "react";
import styled from "styled-components";
import { NotifyTabModel } from "../../../models/notification/NotifyModel";
import { NotifyTabType } from "../../../enums/NotifyEnum";

export interface NotifyTabSelectorProps {
   tabs: NotifyTabModel[],
   selectedTab: NotifyTabType
   onSelectedTab: (type: NotifyTabType) => void;
}

const NotifyTabSelector: React.FC<NotifyTabSelectorProps> = ({
   tabs,
   selectedTab,
   onSelectedTab
}) => {
   return (
      <TabContainer>
         {tabs.map(tab => (
            <TabItem
               selected={selectedTab === tab.type}
               onClick={() => onSelectedTab(tab.type)}
            >
               <TabTitle selected={selectedTab === tab.type}>
                  {tab.title}
               </TabTitle>
            </TabItem>
         ))
         }
      </TabContainer>
   );
};

const TabContainer = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   font-family: 'Plus Jakarta Sans', sans-serif;
   box-sizing: border-box;
   width: 100%;
   padding: 0px 16px;
   gap: 10px;
`

const TabItem = styled.div<{ selected: boolean }>`
   padding: 0px 16px;
   height: 32px;
   border-radius: 12px;
   cursor: pointer;
   background-color: ${(props) => (props.selected ? '#262626' : '#F5F5F5')};
   display: flex;
   align-items: center;
   justify-content: center;
`;

const TabTitle = styled.span<{ selected: boolean }>`
   font-weight: 500;
   font-size: 12px;
   color: ${(props) => (props.selected ? '#FFFFFF' : '#595959')};
`;

export default NotifyTabSelector;