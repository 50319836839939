import styled from "styled-components"
import { CryptoChain } from "../../../../models/web3/Web3Model"

export interface SendToPreviewProps {
   chain: CryptoChain;
   toAddress: string;
}

const SendToPreview: React.FC<SendToPreviewProps> = ({
   chain,
   toAddress,
}) => {
   return (
      <Container>
         <PreviewItem>
            <Title>To</Title>
            <DescInfoText>{`${toAddress.slice(0, 8)}...${toAddress.slice(-8)}`}</DescInfoText>
         </PreviewItem>

         <PreviewItem>
            <Title>Chain</Title>
           <ChainRightArea>
             <ChainIcon src={chain.logo} />
             <DescInfoText>{chain.name}</DescInfoText>
           </ChainRightArea>
         </PreviewItem>
      </Container>
   )
}

const Container = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
   gap: 8px;
`;

const PreviewItem = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
`

const Title = styled.span`
   font-weight: 400;
   font-size: 12px;
   line-height: 18px;
   color: #8c8c8c;
`

const DescInfoText = styled.span`
   font-weight: 400;
   font-size: 12px;
   line-height: 18px;
   color: #262626;
`

const ChainRightArea = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const ChainIcon = styled.img`
  aspect-ratio: 1;
  width: 16px;
  object-fit: fill;
  object-position: center;
`

export default SendToPreview;
