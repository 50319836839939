import {ISingleChainWallet} from "./SingleChainWallet";
import {
  beginCell,
  JettonMaster, JettonWallet,
  toNano,
  TonClient,
  WalletContractV4
} from "@ton/ton";
import {KeyPair, keyPairFromSecretKey, mnemonicToPrivateKey} from "@ton/crypto";
import {Buffer} from "buffer";
import {CryptoAmount, TokenBalanceMap} from "../../models/Web3Model";
import TonAPIIns from "../../helpers/TonAPI/TonAPI";
import {getTonAddress} from "../../helpers/TonHelper";
import {Address, internal} from "@ton/core";
import sleep from "sleep-promise";
import {ethers} from "ethers";

class TonWallet implements ISingleChainWallet {
  protected mnemonic?: string;
  protected privateKey?: string;

  protected client: TonClient;

  protected keypair?: KeyPair;
  protected wallet?: WalletContractV4;

  constructor(props: {
    rpc: string,
    mnemonic?: string,
    privateKey?: string
  }) {
    this.mnemonic = props.mnemonic;
    this.privateKey = props.privateKey;

    this.client = new TonClient({
      endpoint: props.rpc
    });
  }

  getSigner(): Promise<any> {
    return new Promise<any>(async resolve => {
      const keypair = await this._getKeypair();
      resolve(keypair);
    });
  }

  getWalletAddress(): Promise<string> {
    return new Promise<string>(async resolve => {
      const wallet = await this._getWallet();
      resolve(wallet ? wallet.address.toString({
        urlSafe: true,
        bounceable: false,
        testOnly: false
      }) : '');
    });
  }

  getBalance(): Promise<CryptoAmount> {
    return new Promise<CryptoAmount>(async resolve => {
      const wallet = await this._getWallet();
      if (!wallet) {
        resolve({
          amount: 0n,
          amountInETH: 0
        });
        return;
      }

      const address = wallet.address.toString({
        urlSafe: true,
        bounceable: false,
        testOnly: false
      });
      const accountInfo = await TonAPIIns.getAccountInfo(address);
      const balance = accountInfo ? accountInfo.balance : 0;
      const balanceInETH = Number(ethers.formatUnits(balance, 9));
      resolve({
        amount: BigInt(balance),
        amountInETH: balanceInETH
      });
    });
  }

  getTokenBalance(tokenInfo: { tokenAddress: string; decimals?: number }): Promise<CryptoAmount> {
    return new Promise<CryptoAmount>(async resolve => {
      try {
        const wallet = await this._getWallet();
        if (!wallet) {
          resolve({
            amount: 0n,
            amountInETH: 0
          });
          return;
        }

        const jettonMasterAddress: Address = Address.parse(tokenInfo.tokenAddress); // Địa chỉ jetton master
        const jettonMaster = new JettonMaster(jettonMasterAddress);
        const jettonWalletAddress = await jettonMaster.getWalletAddress(this.client.provider(jettonMasterAddress), wallet.address);
        const jettonWallet = JettonWallet.create(jettonWalletAddress);
        const jettonWalletContract = this.client.open(jettonWallet);
        const tokenBalance = await jettonWalletContract.getBalance();
        const tokenBalanceInETH = Number(ethers.formatUnits(tokenBalance, tokenInfo.decimals));
        resolve({
          amount: tokenBalance,
          amountInETH: tokenBalanceInETH
        });
      } catch (error) {
        console.error(error);
        resolve({
          amount: 0n,
          amountInETH: 0
        });
      }
    });
  }

  getAllTokenBalances(tokenInfos: { tokenAddress: string; decimals?: number }[]): Promise<TokenBalanceMap> {
    return new Promise<TokenBalanceMap>(async resolve => {
      const wallet = await this._getWallet();
      if (!wallet) {
        resolve({});
        return;
      }

      const tokenBalanceMap: TokenBalanceMap = {};
      const nativeBalance = await this.getBalance();
      tokenBalanceMap[''] = nativeBalance;


      const address = wallet.address.toString({
        urlSafe: true,
        bounceable: false,
        testOnly: false
      });

      const accountJettonInfo = await TonAPIIns.getAccountJettonInfo(address);
      const balances = accountJettonInfo.balances;
      if (!balances || !Array.isArray(balances) || balances.length === 0) {
        resolve(tokenBalanceMap);
        return;
      }

      for (const balanceInfo of balances) {
        const jettonInfo = balanceInfo.jetton;
        if (!jettonInfo) {
          continue;
        }

        const tokenAddressObj = jettonInfo.address;
        const tokenAddress = getTonAddress(tokenAddressObj).bounceable;
        const targetTokenInfo = tokenInfos.find(tokenInfo => tokenInfo.tokenAddress === tokenAddress);
        if (!targetTokenInfo) {
          continue;
        }

        const balance = balanceInfo.balance;
        if (balance === "0") {
          continue;
        }
        const balanceInETH = parseFloat((Number(balance) / (targetTokenInfo.decimals ? Math.pow(10, targetTokenInfo.decimals) : 1e9)).toFixed(6));
        tokenBalanceMap[tokenAddress] = {
          amount: BigInt(balance),
          amountInETH: balanceInETH
        };
      }

      resolve(tokenBalanceMap);
    });
  }

  approve(props: { tokenAddress: string; toAddress: string; amount: bigint }): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      resolve(true);
    });
  }

  sendNative(props: {
    toAddress: string;
    amount: bigint;
    onSent?: (txHash: string) => void;
    onSuccess?: (txHash: string) => void;
    onFailure?: (error: any) => void
  }): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      try {
        const message = internal({
          to: props.toAddress,
          value: props.amount,
          bounce: false
        });

        if (!message) {
          if (props.onFailure) props.onFailure({code: -1});
          resolve(false);
          return;
        }

        const keypair = await this._getKeypair();
        const wallet = await this._getWallet();
        if (!wallet || !keypair) {
          if (props.onFailure) props.onFailure({code: -1});
          resolve(false);
          return;
        }

        const walletContract = this.client.open(wallet)
        const seqno = await walletContract.getSeqno();

        const lastTransactions = await this.client.getTransactions(wallet.address, {limit: 1});
        const lastTxHash = Array.isArray(lastTransactions) && lastTransactions.length > 0 ? lastTransactions[0].hash().toString('hex') : null;

        await walletContract.sendTransfer({
          seqno: seqno,
          secretKey: keypair.secretKey,
          messages: [message]
        });

        // Wait for the transaction to be processed and retrieve the transaction hash
        // wait until confirmed
        let curLastTxHash = null
        while (!curLastTxHash || curLastTxHash === lastTxHash) {
          const curLastTransactions = await this.client.getTransactions(wallet.address, {limit: 1});
          curLastTxHash = Array.isArray(curLastTransactions) && curLastTransactions.length > 0 ? curLastTransactions[0].hash().toString('hex') : null;
          await sleep(1000);
        }

        console.log("Transaction complete: ", curLastTxHash);

        if (props.onSent) props.onSent(curLastTxHash);
        if (props.onSuccess) props.onSuccess(curLastTxHash);
        resolve(true);
      } catch (error) {
        console.error(error);
        if (props.onFailure) props.onFailure({code: -1});
      }
    });
  }

  sendToken(props: {
    tokenAddress: string;
    toAddress: string;
    amount: bigint;
    decimals: number;
    onSent?: (txHash: string) => void;
    onSuccess?: (txHash: string) => void;
    onFailure?: (error: any) => void
  }): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      // Địa chỉ của jetton master
      const keypair = await this._getKeypair();
      const wallet = await this._getWallet();
      if (!keypair || !wallet) {
        if (props.onFailure) props.onFailure({code: -1});
        resolve(false);
        return;
      }

      const jettonMasterAddress: Address = Address.parse(props.tokenAddress); // Địa chỉ jetton master
      const jettonMaster = new JettonMaster(jettonMasterAddress);
      const jettonWalletAddress = await jettonMaster.getWalletAddress(this.client.provider(jettonMasterAddress), wallet.address);
      const destAddress = Address.parse(props.toAddress);
      const messageBody = beginCell()
        .storeUint(0x0f8a7ea5, 32)
        .storeUint(0, 64)
        .storeCoins(props.amount)
        .storeAddress(destAddress)
        .storeAddress(destAddress)
        .storeBit(0)
        .storeCoins(toNano("0"))
        .storeBit(0)
        .endCell();

      const internalMessage = internal({
        to: jettonWalletAddress,
        value: toNano("0.05"),
        bounce: true,
        body: messageBody
      });

      const walletContract = this.client.open(wallet)
      const seqno = await walletContract.getSeqno();

      const lastTransactions = await this.client.getTransactions(wallet.address, {limit: 1});
      const lastTxHash = Array.isArray(lastTransactions) && lastTransactions.length > 0 ? lastTransactions[0].hash().toString('hex') : null;

      await walletContract.sendTransfer({
        seqno: seqno,
        secretKey: keypair.secretKey,
        messages: [internalMessage]
      });

      // Wait for the transaction to be processed and retrieve the transaction hash
      // wait until confirmed
      let curLastTxHash = null
      while (!curLastTxHash || curLastTxHash === lastTxHash) {
        const curLastTransactions = await this.client.getTransactions(wallet.address, {limit: 1});
        curLastTxHash = Array.isArray(curLastTransactions) && curLastTransactions.length > 0 ? curLastTransactions[0].hash().toString('hex') : null;
        await sleep(1000);
      }

      console.log("Transaction complete: ", curLastTxHash);

      if (props.onSent) props.onSent(curLastTxHash);
      if (props.onSuccess) props.onSuccess(curLastTxHash);
      resolve(true);
    });
  }

  sendTransaction(props: {
    transactionData: any;
    toAddress: string;
    value: bigint;
    onSent?: (txHash: string) => void;
    onSuccess?: (txHash: string) => void;
    onFailure?: (error: any) => void
  }): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      try {
        const messages = props.transactionData;

        if (!messages) {
          console.log("Empty messages");
          if (props.onFailure) props.onFailure({code: -1});
          resolve(false);
          return;
        }

        const keypair = await this._getKeypair();
        const wallet = await this._getWallet();
        if (!keypair || !wallet) {
          if (props.onFailure) props.onFailure({code: -1});
          resolve(false);
          return;
        }

        const walletContract = this.client.open(wallet)
        const seqno = await walletContract.getSeqno();

        const lastTransactions = await this.client.getTransactions(wallet.address, {limit: 1});
        const lastTxHash = Array.isArray(lastTransactions) && lastTransactions.length > 0 ? lastTransactions[0].hash().toString('hex') : null;

        await walletContract.sendTransfer({
          seqno: seqno,
          secretKey: keypair.secretKey,
          messages: messages
        });

        // Wait for the transaction to be processed and retrieve the transaction hash
        // wait until confirmed
        let curLastTxHash = null
        while (!curLastTxHash || curLastTxHash === lastTxHash) {
          const curLastTransactions = await this.client.getTransactions(wallet.address, {limit: 1});
          curLastTxHash = Array.isArray(curLastTransactions) && curLastTransactions.length > 0 ? curLastTransactions[0].hash().toString('hex') : null;
          await sleep(1000);
        }

        console.log("Transaction complete: ", curLastTxHash);

        if (props.onSent) props.onSent(curLastTxHash);
        if (props.onSuccess) props.onSuccess(curLastTxHash);
        resolve(true);
      } catch (error) {
        console.error(error);
        if (props.onFailure) props.onFailure({code: -1});
      }
    });
  }

  // Support FUNC
  _getKeypair(): Promise<KeyPair | undefined> {
    return new Promise<KeyPair | undefined>(async resolve => {
      if (this.keypair) {
        resolve(this.keypair);
        return;
      }

      if (this.mnemonic) {
        const mnemonicArray = this.mnemonic.split(' ');
        this.keypair = await mnemonicToPrivateKey(mnemonicArray);
      } else if (this.privateKey) {
        const privateKeyBuffer = Buffer.from(this.privateKey, 'hex');
        this.keypair = await keyPairFromSecretKey(privateKeyBuffer);
      }

      resolve(this.keypair);
    });
  }

  _getWallet(): Promise<WalletContractV4 | undefined> {
    return new Promise<WalletContractV4 | undefined>(async resolve => {
      if (this.wallet) {
        resolve(this.wallet);
        return;
      }

      const keypair = await this._getKeypair();
      if (!keypair) {
        resolve(undefined);
        return;
      }

      this.wallet = WalletContractV4.create({
        publicKey: keypair.publicKey,
        workchain: 0
      });
      resolve(this.wallet);
    });
  }
}

export default TonWallet;
