import {IService} from "../IService";
import {CryptoAmount, CryptoChainInfo} from "../../core/wallet/models/Web3Model";
import {AccountWalletDetail, AccountWalletInfo} from "../../models/wallet/AccountModel";
import {WalletType} from "../../enums/WalletEnums";
import {MultichainInfo, SinglechainInfo, WalletBalanceDetail, WalletInfo} from "../../models/wallet/WalletModel";
import ServiceManagerIns from "../ServiceManager";
import {AssetToken, CryptoChain, CryptoToken} from "../../models/web3/Web3Model";
import {AccountAddressInfo} from "../../models/home/HomeModel";

export interface IAccountService extends IService {
  createAccount(createInfo: {
    name?: string;
    encrMnemonic: string;
  }): Promise<AccountWalletInfo>;

  importMultichainAccount(importInfo: {
    name?: string;
    encrMnemonic: string;
  }): Promise<AccountWalletInfo>;

  importSinglechainAccount(importInfo: {
    name?: string;
    encrPrivateKey: string;
    chain: CryptoChainInfo
  }): Promise<AccountWalletInfo>;

  prepareDetailAndSetActiveAccount(accountInfo: AccountWalletInfo): Promise<boolean>;

  setActiveAccount(accountInfo: AccountWalletInfo): Promise<boolean>;
  getActiveAccountDetail(): Promise<AccountWalletDetail | undefined>;
  getActiveChainList(): Promise<CryptoChain[]>;
  getActiveTokenList(chain?: CryptoChain): Promise<AssetToken[]>;
  getActiveAccountAddresses(): Promise<AccountAddressInfo[]>;
  checkWalletBalance(tokenList: CryptoToken[]): Promise<boolean>;

  getAccountDetailList(): Promise<AccountWalletDetail[] | undefined>;

  // Helper
  findAssetToken(token: CryptoToken): Promise<AssetToken | undefined>;
}

class AccountService implements IAccountService {
  protected activeAccount?: AccountWalletInfo;

  startService(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      resolve(true);
    });
  }

  stopService(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      resolve(true);
    });
  }

  createAccount(createInfo: { name?: string; encrMnemonic: string }): Promise<AccountWalletInfo> {
    return new Promise<AccountWalletInfo>(async resolve => {
      const id = (await ServiceManagerIns.cacheService.accountCache.getAccountMaxId()) + 1;
      const name = createInfo.name ? createInfo.name : `Account ${id}`;
      const walletInfo: WalletInfo<MultichainInfo> = {
        type: WalletType.Multichain,
        info: {
          encrMnemonic: createInfo.encrMnemonic
        }
      };

      const accountWalletInfo: AccountWalletInfo = {
        id: id,
        name: name,
        wallet: walletInfo
      }

      await ServiceManagerIns.cacheService.accountCache.cacheAccount(accountWalletInfo);
      resolve(accountWalletInfo);
    });
  }

  importMultichainAccount(importInfo: { name?: string; encrMnemonic: string }): Promise<AccountWalletInfo> {
    return this.createAccount(importInfo);
  }

  importSinglechainAccount(importInfo: { name?: string; encrPrivateKey: string; chain: CryptoChainInfo }): Promise<AccountWalletInfo> {
    return new Promise<AccountWalletInfo>(async resolve => {
      const id = (await ServiceManagerIns.cacheService.accountCache.getAccountMaxId()) + 1;
      const name = importInfo.name ? importInfo.name : `Account ${id}`;
      const walletInfo: WalletInfo<SinglechainInfo> = {
        type: WalletType.Singlechain,
        info: {
          encrPrivateKey: importInfo.encrPrivateKey,
          chain: importInfo.chain
        }
      };

      const accountWalletInfo: AccountWalletInfo = {
        id: id,
        name: name,
        wallet: walletInfo
      }

      await ServiceManagerIns.cacheService.accountCache.cacheAccount(accountWalletInfo);
      resolve(accountWalletInfo);
    });
  }

  prepareDetailAndSetActiveAccount(accountInfo: AccountWalletInfo): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      await this.setActiveAccount(accountInfo);
      const accountDetail = await this.getActiveAccountDetail();
      if (!accountDetail) {
        resolve(false);
        return;
      }

      await ServiceManagerIns.cacheService.accountCache.cacheAccountBalance(accountInfo.id, accountDetail.walletBalance);
      resolve(true);
    });
  }

  setActiveAccount(accountInfo: AccountWalletInfo): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      try {
        this.activeAccount = accountInfo;
        await ServiceManagerIns.web3Service.setActiveWallet(accountInfo.wallet)
        await ServiceManagerIns.cacheService.accountCache.cacheActiveAccountId(accountInfo.id);
        resolve(true);
      }
      catch (e) {
        console.error(e);
        resolve(false);
      }
    });
  }

  getActiveAccountDetail(): Promise<AccountWalletDetail | undefined> {
    return new Promise<AccountWalletDetail | undefined>(async resolve => {
      if (!this.activeAccount) {
        resolve(undefined);
        return;
      }

      try {
        const ssAccountWalletDetail = ServiceManagerIns.sessionService.getAccountWalletDetail(this.activeAccount.id);
        if (ssAccountWalletDetail) {
          resolve(ssAccountWalletDetail);
          return;
        }
        const walletBalanceDetail = await ServiceManagerIns.web3Service.getWalletBalanceDetail(this.activeAccount.wallet);
        const accountWalletDetail = {
          ...this.activeAccount,
          walletBalance: walletBalanceDetail
        };
        ServiceManagerIns.sessionService.addAccountWalletDetail(accountWalletDetail);
        resolve(accountWalletDetail);
      }
      catch (e) {
        console.error(e);
        resolve(undefined);
      }
    });
  }

  getActiveChainList(): Promise<CryptoChain[]> {
    return new Promise<CryptoChain[]>(async resolve => {
      const accountDetail = await this.getActiveAccountDetail();
      if (!accountDetail) {
        resolve([]);
        return;
      }

      const chainIdList = Object.keys(accountDetail.walletBalance.tokenMap);
      const allChains = await ServiceManagerIns.web3Service.getSupportedChains();
      const activeChains = allChains.filter(chain => chainIdList.includes(chain.id.toString()));
      resolve(activeChains);
    });
  }

  getActiveTokenList(chain?: CryptoChain): Promise<AssetToken[]> {
    return new Promise<AssetToken[]>(async resolve => {
      const accountDetail = await this.getActiveAccountDetail();
      if (!accountDetail) {
        resolve([]);
        return;
      }

      if (!chain) {
        const tokenList = Object.values(accountDetail.walletBalance.tokenMap).flat();
        resolve(tokenList);
      } else {
        const chainId = chain.id;
        const tokenList = accountDetail.walletBalance.tokenMap[chainId];
        resolve(tokenList);
      }
    });
  }

  getActiveAccountAddresses(): Promise<AccountAddressInfo[]> {
    return new Promise<AccountAddressInfo[]>(async resolve => {
      const accountAddressList = await ServiceManagerIns.web3Service.getActiveWalletAddresses();
      resolve(accountAddressList);
    });
  }

  checkWalletBalance(tokenList: CryptoToken[]): Promise<boolean> {
    return new Promise(async resolve => {
      const activeAccount: (AccountWalletDetail | undefined) = await this.getActiveAccountDetail();
      if (!activeAccount) {
        resolve(true);
        return;
      }

      const tokenPriceList = await ServiceManagerIns.apiService.web3.getTokenPriceList(tokenList.map(token => {
        return {
          address: token.address,
          chainId: token.chain.id
        };
      }));

      for (const [index, token] of tokenList.entries()) {
        const chain = token.chain;
        const web3Wallet = await ServiceManagerIns.web3Service.getWeb3Wallet(chain);
        if (!web3Wallet) {
          continue;
        }

        const balance: CryptoAmount = token.address
          ? await web3Wallet.getTokenBalance({
            tokenAddress: token.address,
            decimals: token.decimals
          })
          : await web3Wallet.getBalance();

        const price = Number(tokenPriceList[index]);
        const quote = balance.amountInETH * price;

        const assetTokenList = activeAccount.walletBalance.tokenMap[chain.id];
        let assetToken = assetTokenList.find(assetToken => assetToken.address === token.address);
        if (!assetToken) {
          assetToken = <AssetToken>{
            ...token,
            balance: balance,
            quote: {
              quote: quote,
              beforeQuote: quote,
              quoteChangePerc: 0
            }
          }

          assetTokenList.push(assetToken);
          activeAccount.walletBalance.quoteInfo.quote += quote;
          activeAccount.walletBalance.quoteInfo.beforeQuote += quote;
        } else {
          const oldQuote = assetToken.quote.quote;
          assetToken.balance = balance;
          assetToken.quote.quote = quote;
          assetToken.quote.beforeQuote = quote;
          activeAccount.walletBalance.quoteInfo.quote += (quote - oldQuote);
          activeAccount.walletBalance.quoteInfo.beforeQuote += (quote - oldQuote);
        }
      }

      resolve(true);
    });
  }

  getAccountDetailList(): Promise<AccountWalletDetail[]> {
    return new Promise<AccountWalletDetail[]>(async resolve => {
      try {
        const ssAccountWalletDetail = ServiceManagerIns.sessionService.getAccountWalletDetailList();
        if (ssAccountWalletDetail && ssAccountWalletDetail.length > 0) {
          resolve(ssAccountWalletDetail);
          return;
        }

        const cachedAccountWalletInfoList = await ServiceManagerIns.cacheService.accountCache.getCachedAccounts();
        const accountWalletDetailList: AccountWalletDetail[] = [];
        for (const accountWalletInfo of cachedAccountWalletInfoList) {
          const walletBalanceDetail = await ServiceManagerIns.web3Service.getWalletBalanceDetail(accountWalletInfo.wallet);
          const accountWalletDetail: AccountWalletDetail = {
            ...accountWalletInfo,
            walletBalance: walletBalanceDetail
          };
          accountWalletDetailList.push(accountWalletDetail);
          ServiceManagerIns.sessionService.addAccountWalletDetail(accountWalletDetail);
        }

        resolve(accountWalletDetailList);
      }
      catch (e) {
        console.error(e);
        resolve([]);
      }
    });
  }

  findAssetToken(token: CryptoToken): Promise<AssetToken | undefined> {
    return new Promise<AssetToken | undefined>(async resolve => {
      const accountDetail = await ServiceManagerIns.accountService.getActiveAccountDetail();
      if (!accountDetail) {
        resolve(undefined);
        return;
      }

      const tokenList = accountDetail.walletBalance.tokenMap[token.chain.id];
      if (!tokenList || tokenList.length === 0) {
        resolve(undefined);
        return;
      }

      const assetToken = tokenList.find(assetToken => token.chain.id === assetToken.chain.id && token.address === assetToken.address);
      resolve(assetToken);
    });
  }
}

export default AccountService;
