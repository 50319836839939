import {IService} from "../IService";
import {ITaskExecutor} from "./TaskExecutor/TaskExecutor";

export interface ISchedulerService {
  addTask(taskName: string, taskExecutor: ITaskExecutor): void;
  pauseTask(taskName: string): void;
  resumeTask(taskName: string): void;
  stopTask(taskName: string): void;
}

class SchedulerService implements ISchedulerService {
  protected taskMapping: {
    [taskName: string]: ITaskExecutor
  };

  protected taskTimeoutMapping: {
    [taskName: string]: NodeJS.Timeout
  };

  constructor() {
    this.taskMapping = {};
    this.taskTimeoutMapping = {};
  }

  addTask(taskName: string, taskExecutor: ITaskExecutor) {
    if (this.taskMapping[taskName]) {
      return;
    }

    this.taskMapping[taskName] = taskExecutor;
    this.resumeTask(taskName);
  }

  pauseTask(taskName: string) {
    if (!this.taskTimeoutMapping[taskName]) {
      return;
    }

    const timeout = this.taskTimeoutMapping[taskName];
    clearTimeout(timeout);
    delete this.taskTimeoutMapping[taskName];
  }

  resumeTask(taskName: string) {
    if (this.taskTimeoutMapping[taskName]) {
      return;
    }

    const taskExecutor = this.taskMapping[taskName];
    if (!taskExecutor) {
      return;
    }

    const runTask = async () => {
      await taskExecutor.run();
      const timeout = setTimeout(runTask, taskExecutor.interval);
      this.taskTimeoutMapping[taskName] = timeout;
    }

    const timeout = setTimeout(runTask, taskExecutor.delay);
    this.taskTimeoutMapping[taskName] = timeout;
  }

  stopTask(taskName: string) {
    this.pauseTask(taskName);
    delete this.taskMapping[taskName];
  }
}

export default SchedulerService;
