import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import BottomSheet from "../../common/BottomSheet";
import NavigationHeader from "../../common/NavigationHeader";
import {BizContext} from "../../../contexts/BizContext";
import SearchBar from "../../common/SearchBar";
import AssetChainSelector from "../../home/AssetChainSelector";
import AssetTokenList from "../../home/AssetTokenList";
import ChainList from "../search_asset_token/ChainList";
import {CryptoChain} from "../../../models/web3/Web3Model";

interface SearchChainBottomSheetProps {
  showOnlyHasAsset?: boolean;
  onClickClose?: () => void;
  onSelectChain?: (chain: CryptoChain) => void;
}

const SearchChainBottomSheet: React.FC<SearchChainBottomSheetProps> = ({
                                                                         showOnlyHasAsset,
                                                                         onClickClose,
                                                                         onSelectChain
                                                                       }) => {
  const bizContext = useContext(BizContext);
  if (!bizContext) {
    throw new Error("Context isn't existed");
  }

  const {commonBizModel} = bizContext;
  const [chainList, setChainList] = useState<CryptoChain[]>([]);
  const [filteredChainList, setFilteredChainList] = useState<CryptoChain[]>([]);

  const [searchText, setSearchText] = useState<string>('');
  const [forceClose, setForceClose] = useState<boolean>(false);

  useEffect(() => {
    const fetchChainListData = async () => {
      const _chainList = await commonBizModel.getChains(showOnlyHasAsset);
      setChainList(_chainList);
    };
    fetchChainListData();
  }, []);

  useEffect(() => {
    if (!searchText) {
      setFilteredChainList(chainList);
    } else {
      setFilteredChainList(chainList.filter(chain => chain.name.toUpperCase().startsWith(searchText)));
    }
  }, [searchText, chainList]);

  const handleSelectCloseButton = () => {
    setForceClose(true);
  }

  const handleChangeTextSearch = (searchText: string) => {
    setSearchText(searchText);
  }

  const handleSelectChain = (chain: CryptoChain) => {
    if (onSelectChain) onSelectChain(chain);
    setForceClose(true);
  }

  return (
    <CustomStyles>
      <BottomSheet
        stickableContent={
          <HeaderContainer>
            <NavigationHeader
              title="Search Chain"
              rightBarButtons={[{
                image: "/icons/ic_close.svg"
              }]}
              onClickRightButton={(index) => {
                handleSelectCloseButton()
              }}
            />
            <SearchBar
              placeholder={'Search Chain'}
              onChangeTextSearch={handleChangeTextSearch}
            />
          </HeaderContainer>
        }
        scrollableContent={
          <ChainListContainer>
            <ChainList chains={filteredChainList} onSelectChain={handleSelectChain}/>
          </ChainListContainer>
        }
        forceClose={forceClose}
        onClose={onClickClose}
      />
    </CustomStyles>
  )
}

const CustomStyles = styled.div`
  .chain-selector-container {
    background-color: #f3f3f3;
  }

  .bottom-sheet-area {
    height: 98%;
    max-height: 98%;
  }

  .bottom-sheet-scrollable-content {
    height: calc(99vh - 126px);
  }

  .search-bar-container {
    background-color: #f3f3f3;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px 24px 16px 24px;
  box-sizing: content-box;
`

const ChainListContainer = styled.div`
  padding: 0 24px 24px 24px;
`

export default SearchChainBottomSheet
