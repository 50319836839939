import React from "react";
import styled from "styled-components";
import SwapBridgeAssetSelector from "./SwapBridgeAssetSelector";
import {CryptoToken} from "../../../models/web3/Web3Model";

export interface SwapBridgeOutputProps {
  token?: CryptoToken;
  estimateAmountInETH?: number;
  estimateQuote?: number;
  onSelectTokenArea?: () => void;
}

const SwapBridgeOutput: React.FC<SwapBridgeOutputProps> = ({
                                                             token,
                                                             estimateAmountInETH,
                                                             estimateQuote,
                                                             onSelectTokenArea
                                                           }) => {
  return (
    <Container>
      <HeaderGroup>
        <Label>Receive</Label>
        <EstimateLabel>Estimated amount</EstimateLabel>
      </HeaderGroup>
      <SwapInfoWrapper>
        <TokenOutputArea>
          <SwapBridgeAssetSelector
            token={token}
            onClick={onSelectTokenArea}
          />
          <AmountOutput>{estimateAmountInETH ? parseFloat(estimateAmountInETH.toFixed(6)): 0}</AmountOutput>
        </TokenOutputArea>
        <QuoteAmount>{`$${estimateQuote ? estimateQuote.toFixed(2) : '0.00'}`}</QuoteAmount>
      </SwapInfoWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: #fff;
  width: 100%;
  font-family: 'Plus Jakarta Sans', sans-serif;
  padding: 16px 16px 24px 16px;
  box-sizing: border-box;
  gap: 16px;
`;

const HeaderGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.label`
  color: #262626;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

const EstimateLabel = styled.span`
  color: #262626;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`

const SwapInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const TokenOutputArea = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const AmountOutput = styled.span`
  width: 100%;
  color: #262626;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: right;
`

const QuoteAmount = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #BFBFBF;
  align-self: flex-end;
`

export default SwapBridgeOutput;
