import {CryptoToken} from "../../models/web3/Web3Model";
import {
  SBAPICrossInfo, SBAPIGetCrossTransactionDetailRequest,
  SBAPIGetSwapTransactionDetailRequest,
  SBAPISwapInfo,
  SBAPITransactionDetail
} from "./models/APIModel";
import TransitSwapAPI, {ITransitSwapAPI} from "../TransitSwapService/TransitSwapAPI";
import {TSGetCrossInfoRequest, TSGetSwapInfoRequest} from "../TransitSwapService/models/TransitSwapAPIModel";
import {TransitSwapHelper} from "../TransitSwapService/helpers/TransitSwapHelper";

export interface ISwapBridgeAPI {
  getSwapInfo(request: {
                fromToken: CryptoToken;
                toToken: CryptoToken;
                fromAmount: bigint;
                fromAddress: string;
                toAddress: string;
                toAmountOutMin: bigint;
              }
  ): Promise<SBAPISwapInfo | undefined>;

  getCrossInfo(request: {
                 fromToken: CryptoToken;
                 toToken: CryptoToken;
                 fromAmount: bigint;
                 fromAddress: string;
                 toAddress: string;
                 selectedBridge: string;
                 confirmExchange: boolean;
               }
  ): Promise<SBAPICrossInfo | undefined>;

  getTransactionDetails(request: SBAPIGetSwapTransactionDetailRequest | SBAPIGetCrossTransactionDetailRequest): Promise<SBAPITransactionDetail | undefined>;
}

class SwapBridgeAPI implements ISwapBridgeAPI {
  protected transitSwapAPI: ITransitSwapAPI;
  constructor() {
    this.transitSwapAPI = new TransitSwapAPI();
  }

  getTokenPriceList(tokenAddressInfoList: { address: string; chainId: number }[]): Promise<string[]> {
    return this.transitSwapAPI.getTokenPriceList(tokenAddressInfoList);
  }

  getSwapInfo(request: { fromToken: CryptoToken; toToken: CryptoToken; fromAmount: bigint; fromAddress: string; toAddress: string; toAmountOutMin: bigint }): Promise<SBAPISwapInfo | undefined> {
    const tsRequest: TSGetSwapInfoRequest = {
      fromToken: {
        address: request.fromToken.address,
        decimals: request.fromToken.decimals,
        chain: {
          id: request.fromToken.chain.chain_id,
          name: request.fromToken.chain.name,
          ns: TransitSwapHelper.convertNsFromNetwork(request.fromToken.chain.network)
        }
      },
      toToken: {
        address: request.toToken.address,
        decimals: request.toToken.decimals,
        chain: {
          id: request.toToken.chain.chain_id,
          name: request.toToken.chain.name,
          ns: TransitSwapHelper.convertNsFromNetwork(request.toToken.chain.network)
        }
      },
      fromAmount: request.fromAmount,
      fromAddress: request.fromAddress,
      toAddress: request.toAddress,
      toAmountOutMin: request.toAmountOutMin,
    };

    return this.transitSwapAPI.getSwapInfo(tsRequest);
  }

  getCrossInfo(request: { fromToken: CryptoToken; toToken: CryptoToken; fromAmount: bigint; fromAddress: string; toAddress: string; selectedBridge: string; confirmExchange: boolean }): Promise<SBAPICrossInfo | undefined> {
    const tsRequest: TSGetCrossInfoRequest = {
      fromToken: {
        address: request.fromToken.address,
        decimals: request.fromToken.decimals,
        chain: {
          id: request.fromToken.chain.chain_id,
          name: request.fromToken.chain.name,
          ns: TransitSwapHelper.convertNsFromNetwork(request.fromToken.chain.network)
        }
      },
      toToken: {
        address: request.toToken.address,
        decimals: request.toToken.decimals,
        chain: {
          id: request.toToken.chain.chain_id,
          name: request.toToken.chain.name,
          ns: TransitSwapHelper.convertNsFromNetwork(request.toToken.chain.network)
        }
      },
      fromAmount: request.fromAmount,
      fromAddress: request.fromAddress,
      toAddress: request.toAddress,
      selectedBridge: request.selectedBridge,
      confirmExchange: request.confirmExchange,
    };

    return this.transitSwapAPI.getCrossInfo(tsRequest);
  }

  getTransactionDetails(request: SBAPIGetSwapTransactionDetailRequest | SBAPIGetCrossTransactionDetailRequest): Promise<SBAPITransactionDetail | undefined> {
    return this.transitSwapAPI.getTransactionDetail(request);
  }
}

export default SwapBridgeAPI;
