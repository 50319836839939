import React, { useContext } from "react";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { TGContext } from "../../contexts/TGContext";
import { font } from "../../helpers/NomasDesignSystem";

enum OnboardAction {
  Create = 'create',
  Import = 'import',
  Restore = 'restore',
  Rocket = 'rocket'
}

const OnboardingScreen: React.FC = () => {
  const navigate = useNavigate();
  const tgContext = useContext(TGContext);
  const { isTelegram, tgVH } = tgContext;

  const handleOnClickAction = (action: OnboardAction) => {
    switch (action) {
      case OnboardAction.Create:
        navigate("/onboarding/createWallet");
        break;
      case OnboardAction.Import:
        navigate("/onboarding/importWallet");
        break;
      case OnboardAction.Restore:
        navigate("/onboarding/restoreWallet");
        break;
      case OnboardAction.Rocket:
        navigate("/onboarding/rocket");
        break;
    }
  }

  return (
    <OnboardingScreenContainer isTelegram={isTelegram} tgVH={tgVH}>
      <ContentWrapper>
        <BrandingArea>
          <BrandingLogo src="/imgs/app_logo.svg" alt="App Logo" />
          <BrandingName>Nomas Wallet</BrandingName>
          <BrandingSlogan>Seamless - Securely - Dynamic</BrandingSlogan>
        </BrandingArea>
        <OnboardingActionArea>
          <OnboardingActionItem
            title="Rocket Launch"
            logo="/icons/ic_rocket.svg"
            onClick={() => handleOnClickAction(OnboardAction.Rocket)}
          />
        </OnboardingActionArea>

        <ImportArea onClick={() => handleOnClickAction(OnboardAction.Import)}>
          <ImportText>I already have a Wallet</ImportText>
          <ImportIcon src="/icons/ic_add_exist.svg" />
        </ImportArea>
      </ContentWrapper>
      <PolicyAware>By tapping the button, you agree to the <PolicyHighlight>User Agreement</PolicyHighlight></PolicyAware>
    </OnboardingScreenContainer>
  );
}

const OnboardingScreenContainer = styled.div<{ isTelegram: boolean; tgVH: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${font.main};
  color: #262626;
  background-color: white;
  width: 100%;
  height: ${({ isTelegram, tgVH }) => (isTelegram ? `${tgVH}px` : '100vh')};
  padding: 24px;
  box-sizing: border-box;
`;

const ContentWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  box-sizing: border-box;
  width: 100%;
  transform: translateY(-70%);
  
  @media(max-width: 500px) {
    transform: translateY(-60%);
  }
`;

const BrandingArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BrandingLogo = styled.img`
  width: 104px;
  height: 104px;
  object-fit: contain;
`;

const BrandingName = styled.span`
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  color: #262626;
  margin-top: 24px;
`;

const BrandingSlogan = styled.span`
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 1.69px;
  color: #595959;
  margin-top: 8px;
`;

const OnboardingActionArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  margin-top: 64px;
`;

interface OnboardingActionItemProps {
  title: string;
  logo: string;
  onClick: () => void;
}

const OnboardingActionItem: React.FC<OnboardingActionItemProps> = ({
                                                                     title,
                                                                     logo,
                                                                     onClick
                                                                   }) => {
  return (
    <ActionItemContainer onClick={onClick}>
      <ActionTitle>{title}</ActionTitle>
      <ActionIcon src={logo} alt={`${title} Icon`} />
    </ActionItemContainer>
  )
}

const ActionItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: #262626;
  border-radius: 24px;
  border: 1px solid #E8E8E8;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  height: 80px;
  gap: 8px; 
`;


const ActionTitle = styled.span`
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #fff;
`;

const ActionIcon = styled.img`
  width: 48px;
  height: 48px;
  object-fit: contain;
`;

const ImportArea = styled.div`
  display: flex;
  padding: 12px 16px;
  gap: 8px;
  align-items: center;
  margin-top: 24px;
  cursor: pointer;
`

const ImportText = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: #262626;
  text-decoration-line: underline;
`;

const ImportIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: fill;
  object-position: center;
`;

const PolicyAware = styled.span`
  position: absolute;
  left: 0;
  bottom: 35px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #8c8c8c;
  width: 100%;
  text-align: center;
`

const PolicyHighlight = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #262626;
  text-decoration-line: underline;
`

export default OnboardingScreen;
