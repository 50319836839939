import React, {useContext, useEffect, useState} from "react";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import CreatePasswordScreen from "../common/CreatePasswordScreen";
import NewPassphraseScreen from "./NewPassphraseScreen";
import styled from "styled-components";
import {TGContext} from "../../../contexts/TGContext";
import SuggestBackupScreen from "./SuggestBackupScreen";
import GenerateWalletScreen from "../common/GenerateWalletScreen";
import {RoutePath} from "../../../enums/CommonEnums";

const CreateWalletRoutes: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [startFlow, setStartFlow] = useState<boolean>(false);

  useEffect(() => {
    console.log(location.pathname, location.state);
    if (location.pathname === '/onboarding/createWallet' && startFlow) {
      navigate(-1);
    }
  }, [location]);

  useEffect(() => {
    navigateToStep(1);
  }, []);

  const navigateToStep = (step: number) => {
    if (!startFlow) {
      setStartFlow(true);
    }

    console.log(step);
    if (step > 0) {
      navigate(`step${step}`);
    } else if (step === 0) {
      navigate("finish");
    }
  };

  return (
    <CreateWalletRoutesContainer>
      <Routes>
        <Route path='step1' element={<CreatePasswordScreen onNext={() => {navigateToStep(2)}}/>}/>
        <Route path='step2' element={<SuggestBackupScreen onNext={(skip) => {navigateToStep(skip ? 0 : 3)}}/>}/>
        <Route path='step3' element={<NewPassphraseScreen onNext={() => {navigateToStep(0)}}/>}/>
        <Route path='finish' element={<GenerateWalletScreen onNext={() => {navigate(RoutePath.Home, { relative: "path"})}}/>}/>
      </Routes>
    </CreateWalletRoutesContainer>
  )
}

const CreateWalletRoutesContainer = styled.div`
  width: 100%;
  height: ${() => {
    const {isTelegram, tgVH} = useContext(TGContext);
    return isTelegram ? `${tgVH}px` : '100vh';
  }};
  background-color: #fff;
`

export default CreateWalletRoutes;
