import {TSEVMTransationData, TSSOLTransactionData, TSTONTransactionData} from "../models/TransitSwapAPIModel";
import * as solanaWeb3 from "@solana/web3.js";
import bs58 from "bs58";
import nacl from "tweetnacl";
import {Buffer} from "buffer";
import {Cell, internal} from "@ton/core";

export interface ITransactionDataBuilder {
  buildTransactionDataList(data: TSEVMTransationData | TSSOLTransactionData | TSTONTransactionData, signer: any): Promise<any[]>;
}

export class EVMDataBuilder implements ITransactionDataBuilder {
  buildTransactionDataList(data: TSEVMTransationData | TSSOLTransactionData | TSTONTransactionData, signer: any): Promise<any[]> {
    return new Promise<any[]>(resolve => {
      resolve([data]);
    });
  }
}

export class SOLDataBuilder implements ITransactionDataBuilder {
  buildTransactionDataList(data: TSEVMTransationData | TSSOLTransactionData | TSTONTransactionData, signer: any): Promise<any[]> {
    return new Promise<any[]>(resolve => {
      const transactions: TSSOLTransactionData = data as TSSOLTransactionData;
      if (!transactions || transactions.length === 0) {
        resolve([]);
        return;
      }

      const transactionDataList = [];
      for (const encodeTransaction of transactions) {
        const transactionData = encodeTransaction.transaction;
        const signatures = encodeTransaction.signatures;

        const decodedMessageData = bs58.decode(transactionData);
        const parsedMessage = solanaWeb3.Message.from(decodedMessageData);
        const transaction = solanaWeb3.Transaction.populate(parsedMessage);

        const mySignature = nacl.sign.detached(
          transaction.serializeMessage(),
          signer.secretKey
        );
        transaction.addSignature(signer.publicKey, Buffer.from(mySignature));

        if (signatures && Array.isArray(signatures)) {
          signatures.forEach(signature => {
            transaction.addSignature(new solanaWeb3.PublicKey(signature.publicKey), Buffer.from(bs58.decode(signature.signature)));
          });
        }

        const isSignatureValid = transaction.verifySignatures();
        console.log('Signature validation:', isSignatureValid);

        console.log(transaction);
        const serializedTransaction = transaction.serialize();
        const base58Transaction = bs58.encode(serializedTransaction);
        transactionDataList.push(base58Transaction);
      }

      resolve(transactionDataList);
    });
  }
}

export class TONDataBuilder implements ITransactionDataBuilder {
  buildTransactionDataList(data: TSEVMTransationData | TSSOLTransactionData | TSTONTransactionData, signer: any): Promise<any[]> {
    return new Promise<any[]>(async resolve => {
      const transactionData = data as TSTONTransactionData;
      const messages = Array.isArray(transactionData) ? transactionData.map(messageData => {
        return internal({
          to: messageData.address,
          value: BigInt(messageData.amount),
          body: messageData.payload ? Cell.fromBase64(messageData.payload) : null
        });
      }) : null;

      resolve([messages]);
    });
  }
}
