import React from "react";
import {SBTransaction} from "../../../models/swap_bridge/SwapBridgeModel";
import styled from "styled-components";
import { format } from 'date-fns';
import {SBTransactionStatusType} from "../../../enums/SwapBridgeEnums";
import SwapBridgeHelper from "../../../helpers/SwapBridgeHelper";

interface SwapBridgeHistoryItemProps {
  sbTransaction: SBTransaction;
}

const SwapBridgeHistoryItem: React.FC<SwapBridgeHistoryItemProps> = ({
                                                                       sbTransaction
                                                                     }) => {
  return (
    <Container>
      <StatusArea>
        <Status status={sbTransaction.status}>{sbTransaction.status}</Status>
        <DateTime>{format(sbTransaction.createTS, 'MM-dd HH:mm')}</DateTime>
        <TxScanArea>
          {
            sbTransaction.fromExplorer &&
            (
              <Explorer
                href={sbTransaction.fromExplorer.explorer ? sbTransaction.fromExplorer.explorer : SwapBridgeHelper.getDefaultExplorerScan(sbTransaction.fromToken.chain.id, sbTransaction.fromTxHash ? sbTransaction.fromTxHash : "")}>
                <TxScanIcon
                  src={sbTransaction.fromExplorer.logo ? sbTransaction.fromExplorer.logo : SwapBridgeHelper.getDefaultExplorerLogo(sbTransaction.fromToken.chain.id)}/>
              </Explorer>
            )
          }
          {
            sbTransaction.toExplorer && sbTransaction.fromExplorer && sbTransaction.fromExplorer.chainId !== sbTransaction.toExplorer.chainId &&
            (
              <Explorer
                href={sbTransaction.toExplorer.explorer ? sbTransaction.toExplorer.explorer : SwapBridgeHelper.getDefaultExplorerScan(sbTransaction.toToken.chain.id, sbTransaction.toTxHash ? sbTransaction.toTxHash : "")}>
                <TxScanIcon
                  src={sbTransaction.toExplorer.logo ? sbTransaction.toExplorer.logo : SwapBridgeHelper.getDefaultExplorerLogo(sbTransaction.toToken.chain.id)}/>
              </Explorer>
            )
          }
        </TxScanArea>
      </StatusArea>
      <ExchangeArea>
        <TokenLogoArea>
          <FromTokenLogo src={sbTransaction.fromToken.logo} fetchPriority="high" />
          <ToTokenLogo src={sbTransaction.toToken.logo} fetchPriority="high" />
        </TokenLogoArea>
        <FromTokenInfoArea>
          <TokenSymbol>{sbTransaction.fromToken.symbol}</TokenSymbol>
          <ChainName>{sbTransaction.fromToken.chain.name}</ChainName>
        </FromTokenInfoArea>
        <SwapIcon src="/icons/ic_swap_from_to.svg" fetchPriority="high" />
        <ToTokenInfoArea>
          <TokenSymbol>{sbTransaction.toToken.symbol}</TokenSymbol>
          <ChainName>{sbTransaction.toToken.chain.name}</ChainName>
        </ToTokenInfoArea>
        <SwapAmountArea>
          <ToAmount>{`+${parseFloat(sbTransaction.toAmount.amountInETH.toFixed(6))}`}</ToAmount>
          <FromAmount>{`-${parseFloat(sbTransaction.fromAmount.amountInETH.toFixed(6))}`}</FromAmount>
        </SwapAmountArea>
      </ExchangeArea>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  font-family: 'Plus Jakarta Sans', sans-serif;
`

const StatusArea = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: center;
`

const Status = styled.span<{status: SBTransactionStatusType}>`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => {
    switch (props.status) {
      case SBTransactionStatusType.Pending:
        return '#D9D9D9';
      case SBTransactionStatusType.Processing:
        return '#FA8C16';
      case SBTransactionStatusType.Success:
        return '#2EB553';
      case SBTransactionStatusType.Failed:
        return '#F5222D';
      default:
        return '#2EB553';
    }
  }};
`

const DateTime = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #8C8C8C;
`

const TxScanArea = styled.div`
  display: flex;
  gap: 4px;
  margin-left: auto;
`

const Explorer = styled.a.attrs({
  target: "_blank"
})`
  width: 20px;
  target: '_blank';
  ref: 'noopener noreferrer'
`;

const TxScanIcon = styled.img`
  aspect-ratio: 1;
  width: 16px;
  border-radius: 100%;
`

const ExchangeArea = styled.span`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
`

const TokenLogoArea = styled.div`
  display: flex;
`

const FromTokenLogo = styled.img`
  aspect-ratio: 1;
  width: 32px;
  object-fit: contain;
  object-position: center;
  border: 1px solid #fff;
  border-radius: 100%;
  background-color: #f5f5f5;
`

const ToTokenLogo = styled.img`
  aspect-ratio: 1;
  width: 32px;
  object-fit: contain;
  object-position: center;
  border: 1px solid #fff;
  border-radius: 100%;
  transform: translateX(-6px);
  background-color: #f5f5f5;
`

const TokenInfoArea = styled.div`
  display: flex;
  flex-direction: column;
`

const FromTokenInfoArea = styled(TokenInfoArea)``;
const ToTokenInfoArea = styled(TokenInfoArea)``;

const TokenSymbol = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
`

const ChainName = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #8c8c8c;
`

const SwapIcon = styled.img`
  aspect-ratio: 1;
  width: 24px;
  object-fit: none;
  object-position: center;
`

const SwapAmountArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
`

const FromAmount = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #8c8c8c;
`

const ToAmount = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #2EB553;
`

export default SwapBridgeHistoryItem;
