import * as solanaWeb3 from "@solana/web3.js";
import * as bip39 from "bip39";
import {Buffer} from "buffer";
import {derivePath} from "ed25519-hd-key";
import bs58 from "bs58";
import {Connection, Keypair} from "@solana/web3.js";
import {ISingleChainWallet} from "./SingleChainWallet";
import {CryptoAmount, TokenBalanceMap} from "../../models/Web3Model";
import * as splToken from "@solana/spl-token";
import {ethers} from "ethers";

class SolWallet implements ISingleChainWallet {
  protected connection: Connection;
  protected keypair?: Keypair;

  constructor(props: {
    rpc: string,
    mnemonic?: string,
    privateKey?: string
  }) {
    this.connection = new solanaWeb3.Connection(props.rpc);

    if (props.mnemonic) {
      const seed = bip39.mnemonicToSeedSync(props.mnemonic);
      const seedBuffer = Buffer.from(seed).toString('hex');
      const path44Change = `m/44'/501'/0'/0'`;
      const derivedSeed = derivePath(path44Change, seedBuffer).key;
      this.keypair = solanaWeb3.Keypair.fromSeed(derivedSeed);
    } else if (props.privateKey) {
      const privateKeyArray = bs58.decode(props.privateKey);
      this.keypair = solanaWeb3.Keypair.fromSecretKey(privateKeyArray);
    }
  }

  getSigner(): Promise<any> {
    return new Promise<any>(resolve => {
      resolve(this.keypair);
    });
  }

  getWalletAddress(): Promise<string> {
    return new Promise<string>(resolve => {
      resolve(this.keypair ? this.keypair.publicKey.toBase58() : '');
    });
  }

  getBalance(): Promise<CryptoAmount> {
    return new Promise<CryptoAmount>(async resolve => {
      if (!this.keypair) {
        resolve({
          amount: 0n,
          amountInETH: 0
        });
        return;
      }

      const publicKey = this.keypair.publicKey;
      const balance = await this.connection.getBalance(publicKey);
      const balanceInETH = Number(ethers.formatUnits(balance, 9));
      resolve({
        amount: BigInt(balance),
        amountInETH: balanceInETH
      });
    });
  }

  getTokenBalance(tokenInfo: { tokenAddress: string; decimals?: number }): Promise<CryptoAmount> {
    return new Promise<CryptoAmount>(async resolve => {
      if (!this.keypair) {
        resolve({
          amount: 0n,
          amountInETH: 0
        });
        return;
      }

      const publicKey = this.keypair.publicKey;
      const mintPublicKey = new solanaWeb3.PublicKey(tokenInfo.tokenAddress);
      const tokenAccounts = await this.connection.getTokenAccountsByOwner(publicKey, {
        mint: mintPublicKey
      });

      // Tìm token account tương ứng với token mint
      const tokenAccount = tokenAccounts.value.find(accountInfo => {
        const parsedAccountData = splToken.AccountLayout.decode(accountInfo.account.data);
        return parsedAccountData.mint.toBase58() === mintPublicKey.toBase58();
      });

      if (tokenAccount) {
        const parsedData = splToken.AccountLayout.decode(tokenAccount.account.data);
        const balance = parsedData.amount;
        const balanceInETH = Number(ethers.formatUnits(balance, tokenInfo.decimals));
        resolve({
          amount: balance,
          amountInETH: balanceInETH
        });
      } else {
        resolve({
          amount: 0n,
          amountInETH: 0
        });
      }
    });
  }

  getAllTokenBalances(tokenInfos: { tokenAddress: string; decimals?: number }[]): Promise<TokenBalanceMap> {
    return new Promise<TokenBalanceMap>(async resolve => {
      if (!this.keypair) {
        resolve({});
        return;
      }

      const tokenBalanceMap: TokenBalanceMap = {};

      const nativeBalance = await this.getBalance();
      tokenBalanceMap[''] = nativeBalance;

      const publicKey = this.keypair.publicKey;
      const tokenAccounts = await this.connection.getTokenAccountsByOwner(publicKey, {
        programId: splToken.TOKEN_PROGRAM_ID
      });
      for (const accountInfo of tokenAccounts.value) {
        const parsedAccountData = splToken.AccountLayout.decode(accountInfo.account.data);
        const tokenAddress = parsedAccountData.mint.toBase58();
        const targetTokenInfo = tokenInfos.find(tokenInfo => tokenInfo.tokenAddress === tokenAddress);
        if (!targetTokenInfo) {
          continue;
        }

        const balance = parsedAccountData.amount;
        if (balance === 0n) {
          continue;
        }

        const balanceInETH = parseFloat(Number(Number(balance) / (targetTokenInfo.decimals ? Math.pow(10, targetTokenInfo.decimals) : 1e9)).toFixed(6));
        tokenBalanceMap[tokenAddress] = {
          amount: balance,
          amountInETH: balanceInETH
        };
      }

      resolve(tokenBalanceMap);
    });
  }

  approve(props: { tokenAddress: string; toAddress: string; amount: bigint }): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      resolve(true);
    });
  }

  sendNative(props: {
    toAddress: string;
    amount: bigint;
    onSent?: (txHash: string) => void;
    onSuccess?: (txHash: string) => void;
    onFailure?: (error: any) => void
  }): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      if (!this.keypair) {
        resolve(false);
        return;
      }

      const to = new solanaWeb3.PublicKey(props.toAddress);
      const transaction = new solanaWeb3.Transaction();
      transaction.add(solanaWeb3.SystemProgram.transfer({
        fromPubkey: this.keypair.publicKey,
        toPubkey: to,
        lamports: props.amount
      }));

      transaction.feePayer = this.keypair.publicKey;
      transaction.recentBlockhash = (await this.connection.getLatestBlockhash()).blockhash;
      transaction.sign(this.keypair);

      console.log(transaction);
      const transactionBuffer = transaction.serialize();
      const signature = await this.connection.sendRawTransaction(transactionBuffer, {
        skipPreflight: true
      });

      console.log("Transaction complete: ", signature);
      if (props.onSent) props.onSent(signature);
      if (props.onSuccess) props.onSuccess(signature);
      resolve(true);
    });
  }

  sendToken(props: {
    tokenAddress: string;
    toAddress: string;
    amount: bigint;
    decimals: number;
    onSent?: (txHash: string) => void;
    onSuccess?: (txHash: string) => void;
    onFailure?: (error: any) => void
  }): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      if (!this.keypair) {
        resolve(false);
        return;
      }

      const to = new solanaWeb3.PublicKey(props.toAddress);
      const mint = new solanaWeb3.PublicKey(props.tokenAddress);

      const fromTokenAccount = await splToken.getOrCreateAssociatedTokenAccount(
        this.connection,
        this.keypair,
        mint,
        this.keypair.publicKey
      );

      const toAssAddress = await splToken.getAssociatedTokenAddress(
        mint,
        to
      );

      const transaction = new solanaWeb3.Transaction();

      try {
        await splToken.getAccount(
          this.connection,
          toAssAddress
        );
      } catch (e) {
        console.log("no account");
        const tokenAccountInstruction = splToken.createAssociatedTokenAccountInstruction(
          this.keypair.publicKey,
          toAssAddress,
          to,
          mint
        )

        transaction.add(tokenAccountInstruction);
      }

      const transferInstruction = splToken.createTransferCheckedInstruction(
        fromTokenAccount.address,
        mint,
        toAssAddress,
        this.keypair.publicKey,
        props.amount,
        props.decimals
      )
      transaction.add(transferInstruction)

      transaction.feePayer = this.keypair.publicKey;
      transaction.recentBlockhash = (await this.connection.getLatestBlockhash()).blockhash;
      transaction.sign(this.keypair);

      console.log(transaction);

      const transactionBuffer = transaction.serialize();
      const signature = await this.connection.sendRawTransaction(transactionBuffer, {
        skipPreflight: true
      });

      console.log("Transaction complete: ", signature);
      if (props.onSent) props.onSent(signature);
      if (props.onSuccess) props.onSuccess(signature);
      resolve(true);
    });
  }

  sendTransaction(props: {
    transactionData: any;
    toAddress: string;
    value: bigint;
    onSent?: (txHash: string) => void;
    onSuccess?: (txHash: string) => void;
    onFailure?: (error: any) => void
  }): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      const transactionBuffer = bs58.decode(props.transactionData);
      const txHash = await this.connection.sendRawTransaction(transactionBuffer, {
        skipPreflight: true
      });

      console.log("Transaction complete: ", txHash);
      if (props.onSent) props.onSent(txHash);
      if (props.onSuccess) props.onSuccess(txHash);
      resolve(true);
    });
  }
}

export default SolWallet;
