import React, {useContext} from "react";
import WorkflowScreen, {WorkflowProps} from "../common/WorkflowScreen";
import styled from "styled-components";
import OnboardingTitle from "../../../components/onboarding/OnboardingTitle";
import {ImportWalletType} from "../../../enums/WalletEnums";
import {BizContext} from "../../../contexts/BizContext";

const ImportWalletTypeSelectionScreen: React.FC<WorkflowProps> = ({
                                                                    onNext
                                                                  }) => {
  const bizContext = useContext(BizContext);
  if (!bizContext) {
    throw new Error("Context isn't existed");
  }

  const { importWalletBizModel } = bizContext;

  const handleNext = (importType: ImportWalletType) => {
    const setData = async () => {
      await importWalletBizModel.setImportType(importType);
      onNext(importType);
    };
    setData();
  }

  return (
    <WorkflowScreen>
      <ContentWrapper>
        <OnboardingTitle
          title="Add Wallet"
          subtitle="Import an existing wallet using your secret phrase or private key"
        />
        <ImportTypeArea>
          <ImportTypeItem
            title={"Enter Secret Recovery Phrase"}
            icon={"/icons/ic_import_wallet_passphrase.svg"}
            onClick={() => {handleNext(ImportWalletType.Passphrase)}}
          />
          <ImportTypeItem
            title={"Import private key"}
            icon={"/icons/ic_import_wallet_secret_key.svg"}
            onClick={() => {handleNext(ImportWalletType.SecretKey)}}
          />
        </ImportTypeArea>
      </ContentWrapper>
    </WorkflowScreen>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  gap: 24px;
  width: 100%;
  align-items: center;
  font-family: 'Plus Jakarta Sans', sans-serif;
  box-sizing: border-box;
`

const ImportTypeArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

interface ImportTypeItemProps {
  title: string;
  icon: string;
  onClick: () => void;
}

const ImportTypeItem: React.FC<ImportTypeItemProps> = ({
                                                         title,
                                                         icon,
                                                         onClick
                                                       }) => {
  return (
    <ImportTypeItemContainer onClick={onClick}>
      <ImportTypeTitle>{title}</ImportTypeTitle>
      <ImportTypeIcon src={icon} fetchPriority="high" />
    </ImportTypeItemContainer>
  )
}

const ImportTypeItemContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  border-radius: 24px;
  border: 1px solid #e8e8e8;
  gap: 8px;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
`

const ImportTypeTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
  flex: 1;
`

const ImportTypeIcon = styled.img`
  width: 40px;
  height: 40px;
`

export default ImportWalletTypeSelectionScreen;
