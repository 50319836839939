import React, {useState} from "react";
import styled from "styled-components";

export const FeedbackItem: React.FC = () => {
  const [disableSend, setDisableSend] = useState<boolean>(true);
  const [feedback, setFeedback] = useState<string>('');

  const handleChangeFBInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const fb = e.currentTarget.value;
    setFeedback(fb);
    setDisableSend(fb.length > 0 ? false : true);
  }

  return (
    <Container>
      <InputArea>
        <Input
          placeholder="Enter feedback"
          value={feedback}
          onChange={handleChangeFBInput}
        />

        <InputIcon src="/icons/ic_write_fb.svg"/>
      </InputArea>

      <SendButton disabled={disableSend}>Send</SendButton>
    </Container>
  )
}

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    padding: 12px 16px;
    box-sizing: border-box;
    border-radius: 16px;
    background-color: #f3f3f3;
  `;

const InputArea = styled.div`
    display: flex;
    gap: 16px;
    width: 100%;
    align-items: flex-start;
  `;

const Input = styled.textarea`
    flex: 1;
    height: 48px;
    color: #262626;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    font-family: 'Plus Jakarta Sans', sans-serif;
    box-sizing: border-box;
    resize: none;
    background-color: transparent;

    border: none;

    -webkit-tap-highlight-color: transparent;
    &:focus {
      outline: none;
    }
  `;

const InputIcon = styled.img`
    aspect-ratio: 1;
    width: 16px;
  `;

const SendButton = styled.button`
    padding: 5px 12px;
    border-radius: 8px;
    font: 400 14px "Plus Jakarta Sans", sans-serif;
    line-height: 22px;
    margin-left: auto;
    color: #262626;
    border: none;
    background-color: #fff;
    
    &:disabled {
      background-color: #D9D9D9;
      color: #8C8C8C;
    }
  `;
