import {AccountAddressInfo, AccountInfo} from "../../models/home/HomeModel";
import {AssetToken, CryptoChain} from "../../models/web3/Web3Model";
import ServiceManagerIns from "../../services/ServiceManager";
import {generateAvatarURL} from "@cfx-kit/wallet-avatar";
import {WalletType} from "../../enums/WalletEnums";
import {SinglechainInfo} from "../../models/wallet/WalletModel";
import {AccountWalletDetail} from "../../models/wallet/AccountModel";
import {HomeBizModelContextType} from "../../contexts/biz_model/HomeBizModelContext";

export interface IHomeBizModel {
  injectContext(context: HomeBizModelContextType): void;
  loadData(): Promise<boolean>;

  getSelectedAccountInfo(): Promise<AccountInfo>;
  checkHasNewNotification(): Promise<boolean>;
  getSupportedChains(): Promise<CryptoChain[]>;
  getAssetTokens(chain?: CryptoChain, showNoBalance?: boolean): Promise<AssetToken[]>;
  getSelectedAccountAvatarUrl(): Promise<string>;
  getAccountAddressInfos(): Promise<AccountAddressInfo[]>;
  getAllAccountInfos(): Promise<AccountInfo[]>;
}

class HomeBizModel implements IHomeBizModel{
  protected context: HomeBizModelContextType | undefined;

  constructor() {
    ServiceManagerIns.observerService.accountData.onUptodateAccountDetail(() => {
      this.loadData();
    });
  }

  injectContext(context: HomeBizModelContextType): void {
    this.context = context;
  }

  loadData(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      if (!this.context) {
        resolve(false);
        return;
      }

      const accountInfo = await this.getSelectedAccountInfo();

      const {setAccountInfo, setChainList} = this.context;
      setAccountInfo(accountInfo);

      const chainList = await this.getSupportedChains();
      setChainList(chainList.splice(0));

      resolve(true);
    });
  }

  getSelectedAccountInfo(): Promise<AccountInfo> {
    return new Promise<AccountInfo>(async resolve => {
      const accountDetail = await ServiceManagerIns.accountService.getActiveAccountDetail();
      if (!accountDetail) {
        resolve(<AccountInfo>{
          accountName: 'Unknown',
          accountAvatar: generateAvatarURL('0x'),
          chain: 'Unknown',
          totalQuote: 0,
          quoteChange: 0,
          quoteChangePercent: 0
        });

        return;
      }

      const accountInfo = this._parseAccountInfo(accountDetail);
      resolve(accountInfo);
    });
  }

  checkHasNewNotification(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      resolve(false);
    });
  }

  getSupportedChains(): Promise<CryptoChain[]> {
    return new Promise<CryptoChain[]>(async resolve => {
      const chainList = await ServiceManagerIns.accountService.getActiveChainList();
      resolve(chainList);
    });
  }

  getAssetTokens(chain?: CryptoChain, showNoBalance?: boolean): Promise<AssetToken[]> {
    return new Promise<AssetToken[]>(async resolve => {
      const assetTokenList = await ServiceManagerIns.accountService.getActiveTokenList(chain);
      const sortedAssetTokenList = assetTokenList.sort((tokenA, tokenB) => tokenB.quote.quote - tokenA.quote.quote);
      resolve(sortedAssetTokenList);
    });
  }

  getSelectedAccountAvatarUrl(): Promise<string> {
    return new Promise<string>(async resolve => {
      const accountInfo = await this.getSelectedAccountInfo();
      resolve(accountInfo.accountAvatar);
    });
  }

  getAccountAddressInfos(): Promise<AccountAddressInfo[]> {
    return new Promise<AccountAddressInfo[]>(async resolve => {
      const addressInfoList = await ServiceManagerIns.accountService.getActiveAccountAddresses();
      resolve(addressInfoList);
    });
  }

  getAllAccountInfos(): Promise<AccountInfo[]> {
    return new Promise<AccountInfo[]>(async resolve => {
      const accountDetailList = await ServiceManagerIns.accountService.getAccountDetailList();
      if (!accountDetailList) {
        resolve([]);
        return;
      }

      const accountInfoList = accountDetailList.map(accountDetail => this._parseAccountInfo(accountDetail));
      resolve(accountInfoList);
    });
  }

  // Support FUNC
  _parseAccountInfo(accountDetail: AccountWalletDetail): AccountInfo {
    const accountInfo: AccountInfo = {
      accountName: accountDetail.name,
      accountAvatar: generateAvatarURL(accountDetail.id.toString()),
      chain: accountDetail.wallet.type === WalletType.Multichain ? 'Multichain' : (accountDetail.wallet.info as SinglechainInfo).chain.name,
      totalQuote: accountDetail.walletBalance.quoteInfo.quote,
      quoteChange: accountDetail.walletBalance.quoteInfo.quote - accountDetail.walletBalance.quoteInfo.beforeQuote,
      quoteChangePercent: accountDetail.walletBalance.quoteInfo.quoteChangePerc
    }
    return accountInfo;
  }
}

export default HomeBizModel;
