import {Address} from "@ton/core";

export interface TonAddress {
  hex: string;
  bounceable: string;
  nonBounceable: string;
}

export const getTonAddress: (address: string) => TonAddress = (address) => {
  const tonAddress = Address.parse(address);
  if (!tonAddress) {
    return {
      hex: '',
      bounceable: '',
      nonBounceable: ''
    };
  }

  return {
    hex: tonAddress.toRawString(),
    bounceable: tonAddress.toString({
      urlSafe: true,
      bounceable: true,
      testOnly: false
    }),
    nonBounceable: tonAddress.toString({
      urlSafe: true,
      bounceable: false,
      testOnly: false
    })
  };
};
