import {SBEstimateFailed, SBEstimateInfo, SBTransaction} from "../../models/swap_bridge/SwapBridgeModel";
import {AssetToken, CryptoChain, CryptoToken} from "../../models/web3/Web3Model";
import {CryptoAmount} from "../../core/wallet/models/Web3Model";
import {SBBizModelContextType} from "../../contexts/biz_model/SBBizModelContext";
import ServiceManagerIns from "../../services/ServiceManager";
import {CryptoNetworkType} from "../../enums/Web3Enums";
import {SBCacheTransaction} from "../../services/CacheService/models/SwapBridgeCacheModel";
import {checkValidAddress} from "../../helpers/Web3Helper";

export interface ISwapBridgeBizModel {
  injectContext(context: SBBizModelContextType): void;
  loadData(): Promise<boolean>;
  getBalance(token: CryptoToken): Promise<CryptoAmount>;
  getQuoteValue(token: CryptoToken, amount: number): Promise<number>;
  startGettingEstimateInfo(fromToken: CryptoToken, toToken: CryptoToken, fromAmountInETH: number, toAddress: string): Promise<boolean>;
  stopGettingEstimateInfo(): Promise<boolean>;
  stopTrackingSBHistory(): Promise<boolean>;

  swap(fromToken: CryptoToken, toToken: CryptoToken, fromAmount: CryptoAmount, toAddress: string): Promise<boolean>;

  getDefaultToAddress(chain: CryptoChain): Promise<string>;
  checkValidToAddress(address: string, chain: CryptoChain): Promise<boolean>
}

class SwapBridgeBizModel implements ISwapBridgeBizModel {
  protected context: SBBizModelContextType | undefined;
  protected isTrackingEstimateInfo: boolean;

  constructor() {
    this.isTrackingEstimateInfo = false;
  }

  injectContext(context: SBBizModelContextType): void {
    this.context = context;
  }

  loadData(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      const activeAccount = await ServiceManagerIns.accountService.getActiveAccountDetail();
      if (!activeAccount) {
        resolve(false);
        return;
      }

      if (!this.context) {
        return;
      }

      await ServiceManagerIns.swapBridgeService.startTrackingSBHistory(activeAccount.id, (sbCacheTransactions: SBCacheTransaction[]) => {
        if (!this.context) {
          return;
        }

        const sbTransactions = sbCacheTransactions.map(sbCacheTransaction => {
          return <SBTransaction>{
            id: sbCacheTransaction.actionId,
            status: sbCacheTransaction.status,
            fromToken: sbCacheTransaction.fromToken,
            toToken: sbCacheTransaction.toToken,
            fromAmount: sbCacheTransaction.fromAmount,
            toAmount: sbCacheTransaction.toAmount,
            fromExplorer: sbCacheTransaction.fromExplorer,
            toExplorer: sbCacheTransaction.toExplorer,
            fromTxHash: sbCacheTransaction.fromTxHash,
            toTxHash: sbCacheTransaction.toTxHash,
            createTS: sbCacheTransaction.createTime,
            endTS: sbCacheTransaction.endTime
          };
        });

        const sortedSBTransactions = sbTransactions.sort((transA, transB) => transB.createTS - transA.createTS);

        const {setSBTransactions} = this.context;
        setSBTransactions(sortedSBTransactions);
      });

      const {setDefaultInputToken, setDefaultOutputToken} = this.context;
      const assetTokenList = await ServiceManagerIns.accountService.getActiveTokenList();
      const tokenList = await ServiceManagerIns.web3Service.getSupportedTokens();
      if (assetTokenList.length === 0) {
        const ethToken = await tokenList.find(token => token.symbol === "ETH" && token.chain.name === "Ethereum" && token.chain.network === CryptoNetworkType.EVM);
        setDefaultInputToken(ethToken || tokenList[0])
      } else {
        const sortedList = assetTokenList.sort((tokenA, tokenB) => tokenB.quote.quote - tokenA.quote.quote);
        setDefaultInputToken(sortedList[0]);
      }

      const taikoToken = await tokenList.find(token => token.symbol === "TAIKO" && token.chain.name === "Taiko" && token.chain.network === CryptoNetworkType.EVM);
      setDefaultOutputToken(taikoToken || tokenList[1]);
    });
  }

  getBalance(token: CryptoToken): Promise<CryptoAmount> {
    return new Promise<CryptoAmount>(async resolve => {
      const assetToken = await ServiceManagerIns.accountService.findAssetToken(token);
      if (!assetToken) {
        resolve({
          amount: 0n,
          amountInETH: 0
        });

        return;
      }

      resolve(assetToken.balance);
    });
  }

  getQuoteValue(token: CryptoToken, amount: number): Promise<number> {
    return new Promise<number>(async resolve => {
      const tokenPriceList = await ServiceManagerIns.apiService.web3.getTokenPriceList([{
        address: token.address,
        chainId: token.chain.id
      }]);

      if (!tokenPriceList || tokenPriceList.length === 0) {
        resolve(0);
        return;
      }

      const tokenPrice = Number(tokenPriceList[0]);
      const quote = tokenPrice * amount;
      resolve(quote);
    });
  }

  startGettingEstimateInfo(fromToken: CryptoToken, toToken: CryptoToken, fromAmountInETH: number, toAddress: string): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      this.isTrackingEstimateInfo = true;
      const result = await ServiceManagerIns.swapBridgeService.startGettingEstimateInfo(
        fromToken,
        toToken,
        fromAmountInETH,
        toAddress,
        (estimateInfo) => {
          if (!this.isTrackingEstimateInfo) {
            return;
          }

          if (!this.context) {
            resolve(true);
            return;
          }

          const {setEstimateInfo} = this.context;
          setEstimateInfo(estimateInfo);
        });

      resolve(result);
    });
  }

  stopGettingEstimateInfo(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      this.isTrackingEstimateInfo = false;

      if (!this.context) {
        resolve(true);
        return;
      }

      const {setEstimateInfo} = this.context;
      setEstimateInfo(undefined);
      await ServiceManagerIns.swapBridgeService.stopGettingEstimateInfo();
      resolve(true);
    });
  }

  stopTrackingSBHistory(): Promise<boolean> {
    return ServiceManagerIns.swapBridgeService.stopTrackingSBHistory();
  }

  swap(fromToken: CryptoToken, toToken: CryptoToken, fromAmount: CryptoAmount, toAddress: string): Promise<boolean> {
    return ServiceManagerIns.swapBridgeService.swap(fromToken, toToken, fromAmount, toAddress);
  }

  getDefaultToAddress(chain: CryptoChain): Promise<string> {
    return new Promise<string>(async resolve => {
      const web3Wallet = await ServiceManagerIns.web3Service.getWeb3Wallet(chain);
      if (!web3Wallet) {
        resolve("");
      } else {
        const address = await web3Wallet.getWalletAddress();
        resolve(address);
      }
    });
  }

  checkValidToAddress(address: string, chain: CryptoChain): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      const isValid = checkValidAddress(chain, address);
      resolve(isValid);
    });
  }
}

export default SwapBridgeBizModel;
