import {ethers, TransactionRequest} from "ethers";
import {CryptoAmount, TokenBalanceMap} from "../../models/Web3Model";
import {ISingleChainWallet} from "./SingleChainWallet";

class EVMWallet implements ISingleChainWallet {
  protected provider: ethers.JsonRpcApiProvider;
  protected wallet?: ethers.BaseWallet;
  protected balanceContractAddr?: string;

  constructor(props: {
    rpc: string,
    mnemonic?: string,
    privateKey?: string,
    balanceContractAddr?: string
  }) {

    this.provider = props.rpc.startsWith("wss") ? new ethers.WebSocketProvider(props.rpc) : new ethers.JsonRpcProvider(props.rpc);
    this.balanceContractAddr = props.balanceContractAddr;
    if (props.mnemonic) {
      this.wallet = ethers.Wallet.fromPhrase(props.mnemonic, this.provider);
    } else if (props.privateKey) {
      this.wallet = new ethers.Wallet(props.privateKey, this.provider);
    }
  }

  getSigner(): Promise<any> {
    return new Promise<any>(resolve => {
      resolve(this.wallet);
    });
  }

  getWalletAddress(): Promise<string> {
    return new Promise<string>(resolve => {
      resolve(this.wallet ? this.wallet.address : '');
    });
  }

  getBalance(): Promise<CryptoAmount> {
    return new Promise<CryptoAmount>(async (resolve) => {
      if (!this.wallet) {
        resolve({
          amount: 0n,
          amountInETH: 0
        });
        return;
      }

      try {
        // Lấy số dư dưới dạng BigNumber (wei)
        const balance = await this.provider.getBalance(this.wallet.address);

        // Chuyển đổi từ wei sang ether
        const balanceInEth = ethers.formatEther(balance);
        resolve({
          amount: balance,
          amountInETH: Number(balanceInEth)
        });
      } catch (error) {
        resolve({
          amount: 0n,
          amountInETH: 0
        });
      }
    });
  }

  getTokenBalance(tokenInfo: { tokenAddress: string; decimals?: number }): Promise<CryptoAmount> {
    return new Promise<CryptoAmount>(async (resolve) => {
      if (!this.wallet) {
        resolve({
          amount: 0n,
          amountInETH: 0
        });

        return;
      }

      try {
        const tokenAbi = [
          "function balanceOf(address owner) view returns (uint256)",
        ];

        let validTokenAddress = ethers.isAddress(tokenInfo.tokenAddress) ? tokenInfo.tokenAddress : ethers.getAddress(tokenInfo.tokenAddress);

        // Tạo một đối tượng contract để tương tác với token
        const contract = new ethers.Contract(validTokenAddress, tokenAbi, this.provider);
        const balance = await contract.balanceOf(this.wallet.address);
        const decimals = tokenInfo.decimals ? tokenInfo.decimals : Number(await this._getTokenDecimals(tokenInfo.tokenAddress));
        const balanceInETH = Number(ethers.formatUnits(balance, decimals));
        resolve({
          amount: balance,
          amountInETH: balanceInETH
        });
      } catch (e) {
        console.log(e);
        resolve({
          amount: 0n,
          amountInETH: 0
        });
      }
    });
  }

  getAllTokenBalances(tokenInfos: { tokenAddress: string; decimals?: number }[]): Promise<TokenBalanceMap> {
    return new Promise<TokenBalanceMap>(async (resolve) => {
      try {
        const balanceContractAbi = [
          // Chỉ cần phương thức balanceOf của ERC-20
          "function balances(address[] users, address[] tokens) external view returns (uint[])"
        ];

        if (!this.balanceContractAddr || !this.wallet) {
          resolve({});
          return;
        }

        const users = [this.wallet.address];
        const tokens = tokenInfos.map(tokenInfo => tokenInfo.tokenAddress !== '' ? tokenInfo.tokenAddress : ethers.ZeroAddress);
        const balanceContract = new ethers.Contract(this.balanceContractAddr, balanceContractAbi, this.provider);

        const allBalances: bigint[] = await balanceContract.balances(users, tokens);
        const tokenBalanceMap: TokenBalanceMap = {};
        for (const [index, tokenInfo] of tokenInfos.entries()) {
          const balance = allBalances[index];
          if (balance === 0n && tokenInfo.tokenAddress !== "") {
            continue;
          }

          const decimals = tokenInfo.decimals ? tokenInfo.decimals : (await this._getTokenDecimals(tokenInfo.tokenAddress));
          const balanceInETH = Number(ethers.formatUnits(balance, decimals));
          tokenBalanceMap[tokenInfo.tokenAddress] = {
            amount: balance,
            amountInETH: balanceInETH
          };
        }

        resolve(tokenBalanceMap);
      } catch (e) {
        console.error(e);
        resolve({});
      }
    });
  }

  approve(props: {
    tokenAddress: string;
    toAddress: string;
    amount: bigint;
  }): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      if (!this.wallet) {
        resolve(false);
        return;
      }

      try {
        const tokenContract = new ethers.Contract(props.tokenAddress, [
          "function allowance(address owner, address spender) public view returns (uint256)",
          "function approve(address spender, uint256 amount) public returns (bool)"
        ], this.wallet);

        const allowance = await tokenContract.allowance(this.wallet.address, props.toAddress);
        if (allowance >= props.amount) {
          console.log("Allow bypass");
          resolve(true);
          return;
        }

        const maxAmount = ethers.MaxUint256;
        const tx = await tokenContract.approve(props.toAddress, maxAmount);
        await tx.wait();
        resolve(true);
      } catch (e) {
        console.error(e);
        resolve(false);
      }
    });
  }

  sendNative(props: {
    toAddress: string;
    amount: bigint;
    onSent?: (txHash: string) => void;
    onSuccess?: (txHash: string) => void;
    onFailure?: (error: any) => void;
  }): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      try {
        if (!this.wallet) {
          resolve(false);
          return;
        }

        let balance = (await this.getBalance()).amount;
        const txData: TransactionRequest = {
          from: this.wallet.address,
          to: props.toAddress,
          value: props.amount
        };

        const estimatedGasLimit = await this.provider.estimateGas(txData);
        const gasPrice = (await this.provider.getFeeData()).gasPrice;
        const totalGasFee = gasPrice ? gasPrice : ethers.parseUnits('3', 'gwei') * estimatedGasLimit;
        if (props.amount + totalGasFee > balance) {
          txData.value = balance - totalGasFee;
        }

        txData.gasLimit = estimatedGasLimit;

        const transactionResponse = await this.wallet.sendTransaction(txData);

        if (props.onSent) props.onSent(transactionResponse.hash);

        await transactionResponse.wait();
        console.log("Transaction complete: ", transactionResponse.hash);
        if (props.onSuccess) props.onSuccess(transactionResponse.hash);
        resolve(true);
      } catch (e) {
        console.error(e);
        resolve(false);
      }
    });
  }

  sendToken(props: {
    tokenAddress: string;
    toAddress: string;
    amount: bigint;
    decimals: number;
    onSent?: (txHash: string) => void;
    onSuccess?: (txHash: string) => void;
    onFailure?: (error: any) => void
  }): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      try {
        if (!this.wallet) {
          resolve(false);
          return;
        }

        const tokenABI = [
          'function transfer(address to, uint256 amount) public returns (bool)'
        ];

        const tokenContract = new ethers.Contract(props.tokenAddress, tokenABI, this.wallet);

        const transactionResponse = await tokenContract.transfer(props.toAddress, props.amount);
        if (props.onSent) props.onSent(transactionResponse.hash);
        await transactionResponse.wait();
        console.log("Transaction complete: ", transactionResponse.hash);
        if (props.onSuccess) props.onSuccess(transactionResponse.hash);
      } catch (e) {
        console.error(e);
        if (props.onFailure) props.onFailure(e);
      }
    });
  }

  sendTransaction(props: {
    transactionData: any;
    toAddress: string;
    value: bigint;
    onSent?: (txHash: string) => void;
    onSuccess?: (txHash: string) => void;
    onFailure?: (error: any) => void
  }): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      if (!this.wallet) {
        resolve(false);
        return;
      }

      try {
        const tx: TransactionRequest = {
          from: this.wallet.address,
          to: props.toAddress,
          data: props.transactionData,
          value: props.value ? props.value : ethers.parseEther("0.0")
        };

        const estimatedGasLimit = await this.provider.estimateGas(tx);
        tx.gasLimit = estimatedGasLimit;

        const transactionResponse = await this.wallet.sendTransaction(tx);
        if (props.onSent) props.onSent(transactionResponse.hash)
        await transactionResponse.wait();
        console.log("Transaction complete: ", transactionResponse.hash);
        if (props.onSuccess) props.onSuccess(transactionResponse.hash);
        resolve(true);
      } catch (e) {
        console.error(e);
        if (props.onFailure) props.onFailure(e);
        resolve(false);
      }
    });
  }


  // Support FUNC
  _getTokenDecimals(tokenAddress: string): Promise<number> {
    return new Promise<number>(async (resolve, reject) => {
      try {
        const tokenAbi = [
          "function decimals() view returns (unit256)"
        ];

        let validTokenAddress = ethers.isAddress(tokenAddress) ? tokenAddress : ethers.getAddress(tokenAddress);
        const contract = new ethers.Contract(validTokenAddress, tokenAbi, this.provider);
        const decimals = Number(await contract.decimals());
        resolve(decimals);
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  }
}

export default EVMWallet;
