import {IMockService, MockService} from "../../services/MockService";
import {
  GenerateTypePassphraseData, GenerateTypePrivateKeyData,
  GenerateWalletByType,
  OnboardProcessingInfo
} from "../../models/onboarding/OnboardingModel";
import ServiceManagerIns from "../../services/ServiceManager";
import {passwordStrength} from "check-password-strength";
import {PasswordStrength} from "../../enums/SettingEnums";

export interface IOnboardingBizModel {
  checkPasswordStrength(password: string): number;
  createPassword(password: string): Promise<boolean>;
}

class OnboardingBizModel implements IOnboardingBizModel {
  protected processInfo: OnboardProcessingInfo<GenerateTypePassphraseData | GenerateTypePrivateKeyData>;
  constructor() {
    this.processInfo = {
      type: GenerateWalletByType.Passphrase,
      passwordHash: '',
      info: {
        passphrase: []
      }
    }
  }

  checkPasswordStrength(password: string): PasswordStrength {
    const strength = passwordStrength(password).value;
    switch (strength) {
      case 'Weak':
      case 'Too weak':
        return PasswordStrength.Weak;
      case 'Medium':
        return PasswordStrength.Medium;
      case 'Strong':
        return PasswordStrength.Strong;
      default:
        return 0;
    }
  }

  createPassword(password: string): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      try {
        const pwdHash = await ServiceManagerIns.settingService.createPassword(password);
        if (!pwdHash) {
          resolve(false);
          return;
        }

        this.processInfo.passwordHash = pwdHash;
        resolve(true);
      }
      catch (e) {
        console.error(e);
        resolve(false);
      }
    });
  }
}

export default OnboardingBizModel;

export class MockOnboardingBizModel implements IOnboardingBizModel {
  public mockService: IMockService;

  constructor() {
    this.mockService = new MockService();
  }

  checkPasswordStrength(password: string): PasswordStrength {
    return 2;
  }

  createPassword(password: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      resolve(true);
    });
  }
}
