import React, {useContext, useEffect, useState} from "react";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import GenerateWalletScreen from "../common/GenerateWalletScreen";
import styled from "styled-components";
import {TGContext} from "../../../contexts/TGContext";
import RocketLaunchScreen from "./RocketLaunchScreen";
import {RoutePath} from "../../../enums/CommonEnums";

const RocketLaunchRoutes: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [startFlow, setStartFlow] = useState<boolean>(false);

  useEffect(() => {
    console.log(location.pathname, location.state);
    if (location.pathname === '/onboarding/rocket' && startFlow) {
      navigate(-1);
    }
  }, [location]);

  useEffect(() => {
    navigateTo("finish");
  }, []);

  const navigateTo = (path: string) => {
    if (!startFlow) {
      setStartFlow(true);
    }

    navigate(path);
  };

  return (
    <RocketLaunchRoutesContainer>
      <Routes>
        <Route path='ready' element={<RocketLaunchScreen onNext={() => {navigateTo("finish")}}/>}/>
        <Route path='finish' element={<GenerateWalletScreen onNext={() => {navigate(RoutePath.Home, { relative: "path"})}}/>}/>
      </Routes>
    </RocketLaunchRoutesContainer>
  )
}

const RocketLaunchRoutesContainer = styled.div`
  width: 100%;
  height: ${() => {
  const {isTelegram, tgVH} = useContext(TGContext);
  return isTelegram ? `${tgVH}px` : '100vh';
}};
  background-color: #fff;
`

export default RocketLaunchRoutes;
