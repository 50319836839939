import React from "react";
import styled from "styled-components";
import {
   NFTransactionSwapBridgeData,
   TransactionNotifyModel,
} from "../../../../models/notification/TransactionNotifyModel";
import SwapFromToIcon from "../../../../components/icons/SwapFromToIcon";

const genTokenAmountText = (amount: number, name: string): string => {
   const amountText = (amount > 0) ? `+${amount}` : `${amount} `;
   const result = `${amountText} ${name}`;
   return result;
}

export interface TransactionSwapBridgeItemProps {
   item: TransactionNotifyModel<NFTransactionSwapBridgeData>;
}

const TransactionSwapBridgeItem: React.FC<TransactionSwapBridgeItemProps> = ({
   item
}) => {
   const { fromToken, toToken } = item.data;

   return (
      <ContentWrapper>
         <ContentInfoContainer>
            <TransactionIcon src={item.logo} />

            <TokenActivityContainer>
               <TitleText>{item.title}</TitleText>

               <SBInfoContainer>
                  <SBInfoIconContainer>
                     <SBInfoFromIcon src={fromToken.logo} />
                     <SBInfoToIcon src={toToken.logo} />
                  </SBInfoIconContainer>

                  <SBInfoTokenContainer>
                     <SBInfoTokenText>{fromToken.name}</SBInfoTokenText>
                     <SwapFromToIcon size={16} color="#8C8C8C" />
                     <SBInfoTokenText>{toToken.name}</SBInfoTokenText>
                  </SBInfoTokenContainer>
               </SBInfoContainer>

               <DescriptionText>{item.description}</DescriptionText>
            </TokenActivityContainer>
         </ContentInfoContainer>

         <AmountContainer>
            <SBToTokenText>{genTokenAmountText(toToken.amount, toToken.symbol)}</SBToTokenText>
            <SBFromTokenText>{genTokenAmountText(-fromToken.amount, fromToken.symbol)}</SBFromTokenText>
         </AmountContainer>
      </ContentWrapper>
   )
};

const ContentWrapper = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   max-width: 100%;
   padding: 16px;
   box-sizing: border-box;
   gap: 8px;
   font-family: 'Plus Jakarta Sans', sans-serif;
`;

const ContentInfoContainer = styled.div`
   display: flex;
   gap: 12px;
`;

const TransactionIcon = styled.img`
   width: 40px;
   height: 40px;
   border-radius: 100%;
`;

const TokenActivityContainer = styled.div`
   display: flex;
   flex-direction: column;
   box-sizing: border-box;
   gap: 0px;
`;

const TitleText = styled.span`
   font-weight: 600;
   font-size: 14px;
   line-height: 22px;
   color: #262626;
`;

const DescriptionText = styled.span`
   font-weight: 400;
   font-size: 12px;
   line-height: 18px;
   color: #8C8C8C;
`;

const SBInfoContainer = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 8px;
`;

const SBInfoIconContainer = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
`

const SBInfoFromIcon = styled.img`
   width: 16px;
   height: 16px;
   border-radius: 100%;
   border-style: solid;
   border-width: 1px;
   border-color: #FFFEFE;
`;

const SBInfoToIcon = styled(SBInfoFromIcon)`
   margin-left: -5px;
`;

const SBInfoTokenContainer = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 4px;
`;

const SBInfoTokenText = styled.span`
   font-weight: 500;
   font-size: 12px;
   line-height: 20px;
   color: #8C8C8C;
`;

const AmountContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-end;
`;

const SBToTokenText = styled.span`
   font-weight: 600;
   font-size: 14px;
   line-height: 22px;
   color: #2EB553;
`;

const SBFromTokenText = styled.span`
   font-weight: 400;
   font-size: 12px;
   line-height: 18px;
   color: #8C8C8C;
`;

export default TransactionSwapBridgeItem;