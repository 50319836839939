export interface ISettingCache {
  cachePasswordHash(passwordHash: string): Promise<boolean>;
  getPasswordHash(): Promise<string | undefined>;

  reset(): Promise<boolean>;
}

export enum SettingCacheKey {
  PasswordHash = 'password_hash'
}

class SettingCache implements ISettingCache {
  cachePasswordHash(passwordHash: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      localStorage.setItem(SettingCacheKey.PasswordHash, passwordHash);
      resolve(true);
    });
  }

  getPasswordHash(): Promise<string | undefined> {
    return new Promise<string | undefined>(resolve => {
      const pwdHash = localStorage.getItem(SettingCacheKey.PasswordHash);
      resolve(pwdHash ? pwdHash : undefined);
    });
  }

  reset(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      localStorage.removeItem(SettingCacheKey.PasswordHash);
      resolve(true);
    });
  }
}

export default SettingCache;
