import React, { useState, useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import SearchBar from "../../components/common/SearchBar";
import WorkflowScreen from "../onboarding/common/WorkflowScreen";
import { BizContext } from "../../contexts/BizContext";
import { GeneralNotifyModel, NotifyTabModel } from "../../models/notification/NotifyModel";
import NotifyTabSelector from "./components/NotifyTabSelector";
import { NotifyTabType } from "../../enums/NotifyEnum";
import NotifyList from "./components/NotifyList";

const NotificationScreen: React.FC = () => {
   const bizContext = useContext(BizContext);
   if (!bizContext) {
      throw new Error("Context isn't existed");
   }

   const notifyBizModel = bizContext.notifyBizModel;
   const [notifyTabList, setnotifyTabList] = useState<NotifyTabModel[]>([]);

   const [selectedTab, setSelectedTab] = useState<NotifyTabType>(NotifyTabType.All);
   const [searchText, setSearchText] = useState<string>('');
   const [filteredNotifyList, setFilteredNotifyList] = useState<GeneralNotifyModel<any>[]>([]);

   // Fetch data
   useEffect(() => {
      const fetchNotifyTabList = async () => {
         const _notifyTabList = await notifyBizModel.getNotifyTabList();
         setnotifyTabList(_notifyTabList);
      };

      fetchNotifyTabList();
   }, []);

   useEffect(() => {
      const filterNotifyList = async () => {
         let _filteredNotifyList = await notifyBizModel.getNotifyListByTab(selectedTab);
         if (searchText) {
            _filteredNotifyList = _filteredNotifyList.filter(item => item.title.toUpperCase().startsWith(searchText));
         }
         setFilteredNotifyList(_filteredNotifyList);
      }
      
      filterNotifyList();
      
   }, [selectedTab, notifyTabList, searchText]);

   // Actions
   const handleSelectTab = (type: NotifyTabType) => {
      setSelectedTab(type);
   };

   const handleChangeTextSearch = (searchText: string) => {
      setSearchText(searchText);
   };

   return (
      <WorkflowScreen title="Notifications">
         <SearchContainer>
            <SearchBar
               placeholder={"Search"}
               onChangeTextSearch={handleChangeTextSearch}
            />
         </SearchContainer>

         <ContentWrapper>
            <NotifyTabSelector
               tabs={notifyTabList}
               selectedTab={selectedTab}
               onSelectedTab={handleSelectTab}
            />

            <NotifyList
               notifyList={filteredNotifyList}
            />
         </ContentWrapper>
      </WorkflowScreen>
   )
}

const SearchContainer = styled.div`
   width: 100%;
   box-sizing: border-box;
   padding: 0 16px 16px 16px;

   .search-bar-container {
    background-color: #f3f3f3;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
  gap: 16px;
  align-items: center;
  font-family: 'Plus Jakarta Sans', sans-serif;
`

export default NotificationScreen;