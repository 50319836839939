export interface IReceiveWalletBizModel {
    generateQRCode(walletAddress: string): Promise<string>;
    shareWalletAddress(walletAddress: string): Promise<boolean>;
}

export class MockReceiveWalletBizModel implements IReceiveWalletBizModel {
    generateQRCode(walletAddress: string): Promise<string> {
        return new Promise((resolve) => {
            const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${walletAddress}&size=180x180`;
            resolve(qrCodeUrl);
        });
    }

    shareWalletAddress(walletAddress: string): Promise<boolean> {
        return new Promise((resolve) => {
            resolve(true);
        })
    }
}
