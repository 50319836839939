import {SBCacheTransaction} from "./models/SwapBridgeCacheModel";
import ServiceManagerIns from "../ServiceManager";

export interface ISwapBridgeCache {
  cacheSBTransaction(transaction: SBCacheTransaction): Promise<boolean>;
  updateSBTransaction(transaction: SBCacheTransaction): Promise<boolean>;
  getCacheSBTransactions(accountId: number): Promise<SBCacheTransaction[]>;
  reset(): Promise<boolean>;
}

class SwapBridgeCache implements ISwapBridgeCache {
  cacheSBTransaction(transaction: SBCacheTransaction): Promise<boolean> {
    return ServiceManagerIns.databaseService.accountDB.swapBridgeStore.storeSBTransaction(transaction);
  }

  updateSBTransaction(transaction: SBCacheTransaction): Promise<boolean> {
    return ServiceManagerIns.databaseService.accountDB.swapBridgeStore.updateSBTransaction(transaction);
  }

  getCacheSBTransactions(accountId: number): Promise<SBCacheTransaction[]> {
    return ServiceManagerIns.databaseService.accountDB.swapBridgeStore.getSBTransactions(accountId);
  }

  reset(): Promise<boolean> {
    return ServiceManagerIns.databaseService.accountDB.swapBridgeStore.cleanup();
  }
}

export default SwapBridgeCache;
