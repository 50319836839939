import {CryptoChain} from "../web3/Web3Model";

export enum GenerateWalletFlow {
  Create,
  Import,
  RocketLaunch
}

export enum GenerateWalletByType {
  Passphrase,
  PrivateKey
}

export interface GenerateTypePassphraseData {
  passphrase: string[];
}

export interface GenerateTypePrivateKeyData {
  privateKey: string;
  chain: CryptoChain;
}

export interface OnboardProcessingInfo<T extends GenerateTypePassphraseData | GenerateTypePrivateKeyData> {
  type: GenerateWalletByType;
  passwordHash: string;
  info: T;
}
