import {CryptoChain, CryptoToken, Web3Config} from "../../models/web3/Web3Model";
import {SupportedChains, TransitSwapTokens, Web3ConfigData} from "./mock_data/MockData";
import TransitSwapAPI, {ITransitSwapAPI} from "../TransitSwapService/TransitSwapAPI";

export interface IWeb3API {
  getWeb3Config(): Promise<Web3Config>;
  getTokens(page: number): Promise<CryptoToken[]>;
  getChains(): Promise<CryptoChain[]>;
  getTokenPriceList(tokenAddressInfoList: {
                      address: string;
                      chainId: number;
                    }[]
  ): Promise<string[]>;
}

class Web3API implements IWeb3API {
  protected transitSwapAPI: ITransitSwapAPI;
  constructor() {
    this.transitSwapAPI = new TransitSwapAPI();
  }

  getWeb3Config(): Promise<Web3Config> {
    return new Promise<Web3Config>(resolve => {
      // HM - Test
      resolve(Web3ConfigData);
    });
  }

  getTokens(page: number): Promise<CryptoToken[]> {
    return new Promise<CryptoToken[]>(resolve => {
      // HM - Test
      const tokens = this._mockGetTokens();
      resolve(tokens);
    });
  }

  getChains(): Promise<CryptoChain[]> {
    return new Promise<CryptoChain[]>(resolve => {
      // HM - Test
      resolve(SupportedChains);
    });
  }

  getTokenPriceList(tokenAddressInfoList: { address: string; chainId: number }[]): Promise<string[]> {
    return this.transitSwapAPI.getTokenPriceList(tokenAddressInfoList);
  }

  _mockGetTokens(): CryptoToken[] {
    const tokens: CryptoToken[] = [];
    for (const tsToken of TransitSwapTokens) {
      let chain = SupportedChains.find(chain => chain.id === tsToken.blockchain_id);
      if (!chain) {
        continue;
      }

      const token: CryptoToken = {
        chain: chain,
        name: tsToken.bl_symbol,
        symbol: tsToken.symbol,
        address: tsToken.address,
        decimals: tsToken.decimal,
        logo: tsToken.icon_url,
      }
      tokens.push(token);
    }

    return tokens;
  }
}

export default Web3API;
