import {IService} from "../IService";
import {AssetToken, CryptoChain, CryptoToken, Web3Config} from "../../models/web3/Web3Model";
import {CryptoChainInfo, TokenBalanceMap} from "../../core/wallet/models/Web3Model";
import {WalletType} from "../../enums/WalletEnums";
import MultiChainWallet, {IMultiChainWallet} from "../../core/wallet/multichain_wallet/MultiChainWallet";
import {generateMnemonic} from "bip39";
import {MultichainInfo, SinglechainInfo, WalletBalanceDetail, WalletInfo} from "../../models/wallet/WalletModel";
import {createWallet} from "../../core/wallet/helpers/WalletFactory";
import {ISingleChainWallet} from "../../core/wallet/multichain_wallet/single_wallet/SingleChainWallet";
import ServiceManagerIns from "../ServiceManager";
import {AccountAddressInfo} from "../../models/home/HomeModel";
import {TokenPriceMap, TokenPriceRequest, TokenQuoteMap, TokenQuoteRequest} from "./models/Web3ServiceModel";
import {IWeb3GasFetcher, MultichainGasFetcher} from "./MultichainGasFetcher";

export interface IWeb3Service extends IService, IWeb3Wallet, IWeb3Config {
  gasFetcher: IWeb3GasFetcher;
}

export interface IWeb3Wallet {
  createNewPasspharse(wordAmount?: number): Promise<string>;

  setActiveWallet(walletModel: WalletInfo<MultichainInfo | SinglechainInfo>): Promise<boolean>;
  getActiveWalletAddresses(): Promise<AccountAddressInfo[]>;
  getWeb3Wallet(chain: CryptoChain): Promise<ISingleChainWallet | undefined>;

  getWalletBalanceDetail(walletModel: WalletInfo<MultichainInfo | SinglechainInfo>): Promise<WalletBalanceDetail>;
}

export interface IWeb3Config {
  getWeb3Config(): Promise<Web3Config>;
  getSupportedTokens(chain?: CryptoChain): Promise<CryptoToken[]>;
  getSupportedChains(): Promise<CryptoChain[]>;
}

export interface IWeb3Helper {
  checkValidMnemonic(mnemonic: string): Promise<boolean>;
  checkValidPrivateKey(mnemonic: string): Promise<boolean>;
}

class Web3Service implements IWeb3Service {
  public gasFetcher: IWeb3GasFetcher;
  protected activeWalletInfo?: {
    walletType: WalletType;
    walletInfo: MultichainInfo | SinglechainInfo;
    wallet: IMultiChainWallet | ISingleChainWallet | undefined;
  };

  constructor() {
    this.gasFetcher = new MultichainGasFetcher();
  }

  startService(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      resolve(true);
    });
  }

  stopService(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      resolve(true);
    })
  }

  createNewPasspharse(wordAmount?: number): Promise<string> {
    return new Promise<string>(resolve => {
      console.log(wordAmount);
      const mnemonic = generateMnemonic(wordAmount ? wordAmount/12*128 : 128);
      console.log(mnemonic);
      resolve(mnemonic);
    });
  }

  setActiveWallet(walletModel: WalletInfo<MultichainInfo | SinglechainInfo>): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      try {
        const walletType = walletModel.type;
        const wallet = await this._parseWallet(walletModel);

        this.activeWalletInfo = {
          walletType: walletType,
          walletInfo: walletModel.info,
          wallet: wallet
        };
        resolve(true);
      }
      catch (e) {
        console.error(e);
        resolve(false);
      }
    });
  }

  getActiveWalletAddresses(): Promise<AccountAddressInfo[]> {
    return new Promise<AccountAddressInfo[]>(async resolve => {
      if (!this.activeWalletInfo) {
        resolve([]);
        return;
      }

      if (this.activeWalletInfo.walletType === WalletType.Singlechain) {
        const chain = await this._getCryptoChain((this.activeWalletInfo.walletInfo as SinglechainInfo).chain.id);
        if (!chain) {
          resolve([]);
          return;
        }

        const addressInfo = await this._getWalletAddressInfo(chain, this.activeWalletInfo.wallet as ISingleChainWallet);
        resolve([addressInfo]);
        return;
      }

      const allChains = await this.getSupportedChains();
      const addressInfoList: AccountAddressInfo[] = [];
      const multichainWallet = this.activeWalletInfo.wallet as IMultiChainWallet;
      for (const chain of allChains) {
        const singlechainWallet = multichainWallet.chainWallet(chain);
        if (!singlechainWallet) {
          continue;
        }

        const addressInfo = await this._getWalletAddressInfo(chain, singlechainWallet);
        addressInfoList.push(addressInfo);
      }

      resolve(addressInfoList);
    });
  }

  getWalletBalanceDetail(walletModel: WalletInfo<MultichainInfo | SinglechainInfo>): Promise<WalletBalanceDetail> {
    return new Promise<WalletBalanceDetail>(async resolve => {
      const wallet = await this._parseWallet(walletModel);
      let walletBalanceDetail;
      if (walletModel.type === WalletType.Multichain) {
        walletBalanceDetail = await this._getMultichainWalletBalanceDetail(wallet as IMultiChainWallet);
      }
      else {
        walletBalanceDetail = await this._getWalletBalanceDetail((walletModel.info as SinglechainInfo).chain, wallet as ISingleChainWallet);
      }

      resolve(walletBalanceDetail);
    });
  }

  getWeb3Wallet(chain: CryptoChain): Promise<ISingleChainWallet | undefined> {
    return new Promise<ISingleChainWallet | undefined>(resolve => {
      if (!this.activeWalletInfo) {
        resolve(undefined);
        return;
      }

      if (this.activeWalletInfo.walletType === WalletType.Multichain) {
        const wallet = this.activeWalletInfo.wallet as IMultiChainWallet;
        const singlechainWallet = wallet.chainWallet(chain);
        resolve(singlechainWallet);
      } else {
        const walletInfo = this.activeWalletInfo.walletInfo as SinglechainInfo;
        if (walletInfo.chain.id !== chain.id) {
          resolve(undefined);
        } else {
          const singlechainWallet = this.activeWalletInfo.wallet as ISingleChainWallet;
          resolve(singlechainWallet);
        }
      }
    });
  }

  getWeb3Config(): Promise<Web3Config> {
    return new Promise<Web3Config>(async (resolve, reject) => {
      const ssWeb3Config = ServiceManagerIns.sessionService.getWeb3Config();
      if (ssWeb3Config) {
        resolve(ssWeb3Config);
        return;
      }

      try {
        const web3Config = await ServiceManagerIns.apiService.web3.getWeb3Config();
        ServiceManagerIns.sessionService.setWeb3Config(web3Config);
        resolve(web3Config);
      }
      catch (e) {
        reject(e);
      }
    });
  }

  getSupportedTokens(chain?: CryptoChain): Promise<CryptoToken[]> {
    return new Promise<CryptoToken[]>(async resolve => {
      const ssWeb3Tokens = ServiceManagerIns.sessionService.getWeb3Tokens(chain);
      if (ssWeb3Tokens) {
        resolve(ssWeb3Tokens);
        return;
      }

      const web3Tokens = await ServiceManagerIns.apiService.web3.getTokens(0);
      ServiceManagerIns.sessionService.setWeb3Tokens(web3Tokens);
      const tokens = ServiceManagerIns.sessionService.getWeb3Tokens(chain);
      resolve(tokens ? tokens : []);
    });
  }

  getSupportedChains(): Promise<CryptoChain[]> {
    return new Promise<CryptoChain[]>(async (resolve, reject) => {
      const ssWeb3Chains = ServiceManagerIns.sessionService.getWeb3Chains();
      if (ssWeb3Chains) {
        resolve(ssWeb3Chains);
        return;
      }

      try {
        const web3Chains = await ServiceManagerIns.apiService.web3.getChains();
        ServiceManagerIns.sessionService.setWeb3Chains(web3Chains);
        resolve(web3Chains);
      }
      catch (e) {
        reject(e);
      }
    });
  }

  _getMultichainWalletBalanceDetail(wallet: IMultiChainWallet): Promise<WalletBalanceDetail> {
    return new Promise<WalletBalanceDetail>(async resolve => {
      const allChains = await this.getSupportedChains();
      const walletBalanceDetail: WalletBalanceDetail = {
        quoteInfo: {
          quote: 0,
          beforeQuote: 0,
          quoteChangePerc: 0
        },
        tokenMap: {}
      };

      let tokenQuoteRequests: TokenQuoteRequest[] = [];

      for (const chain of allChains) {
        const singlechainWallet = wallet.chainWallet(chain);
        if (!singlechainWallet) {
          continue;
        }

        const startTime = Date.now();

        const tokenBalanceMap = await this._getTokenBalanceMap(chain, singlechainWallet);

        console.log(`Get balance map of [${chain.name}]. Time: ${Date.now() - startTime}`);

        const tokenAddressList = Object.keys(tokenBalanceMap);
        const chainTokenQuoteRequests = tokenAddressList.map(tokenAddress => {
          const tokenBalance = tokenBalanceMap[tokenAddress];
          return <TokenQuoteRequest>{
            chainId: chain.id,
            address: tokenAddress,
            balance: tokenBalance
          };
        });

        tokenQuoteRequests = tokenQuoteRequests.concat(chainTokenQuoteRequests);
      }

      if (!tokenQuoteRequests) {
        resolve(walletBalanceDetail);
        return;
      }

      const quoteStartTime = Date.now();
      const tokenQuoteMap = await this._getTokenQuoteMap(tokenQuoteRequests);
      console.log(`Get quote. Time: ${Date.now() - quoteStartTime}`);

      await this._buildTokenMap(walletBalanceDetail, tokenQuoteRequests, tokenQuoteMap);
      walletBalanceDetail.quoteInfo.quoteChangePerc = walletBalanceDetail.quoteInfo.beforeQuote === 0 ? 0 : (walletBalanceDetail.quoteInfo.quote - walletBalanceDetail.quoteInfo.beforeQuote) / walletBalanceDetail.quoteInfo.beforeQuote;
      resolve(walletBalanceDetail);
    });
  }

  _getWalletBalanceDetail(chain: CryptoChainInfo, wallet: ISingleChainWallet): Promise<WalletBalanceDetail> {
    return new Promise<WalletBalanceDetail>(async resolve => {
      const walletBalanceDetail: WalletBalanceDetail = {
        quoteInfo: {
          quote: 0,
          beforeQuote: 0,
          quoteChangePerc: 0
        },
        tokenMap: {}
      }

      const tokenBalanceMap = await this._getTokenBalanceMap(chain, wallet);
      const tokenAddressList = Object.keys(tokenBalanceMap);
      const tokenQuoteRequests = tokenAddressList.map(tokenAddress => {
        const tokenBalance = tokenBalanceMap[tokenAddress];
        return <TokenQuoteRequest>{
          chainId: chain.id,
          address: tokenAddress,
          balance: tokenBalance
        };
      });

      if (!tokenQuoteRequests) {
        resolve(walletBalanceDetail);
        return;
      }

      const tokenQuoteMap = await this._getTokenQuoteMap(tokenQuoteRequests);
      await this._buildTokenMap(walletBalanceDetail, tokenQuoteRequests, tokenQuoteMap);
      walletBalanceDetail.quoteInfo.quoteChangePerc = (walletBalanceDetail.quoteInfo.quote - walletBalanceDetail.quoteInfo.beforeQuote) / walletBalanceDetail.quoteInfo.beforeQuote;

      resolve(walletBalanceDetail);
    });
  }

  _buildTokenMap(walletBalanceDetail: WalletBalanceDetail, tokenQuoteRequests: TokenQuoteRequest[], tokenQuoteMap: TokenQuoteMap): Promise<void> {
    return new Promise<void>(async resolve => {
      const tokenMap: {
        [chainId: number]: AssetToken[]
      } = {};
      for (const tokenQuoteRequest of tokenQuoteRequests) {
        const chainId = tokenQuoteRequest.chainId;
        const tokenAddress = tokenQuoteRequest.address;
        const tokenBalance = tokenQuoteRequest.balance;
        const tokenQuote = tokenQuoteMap[`${chainId}_${tokenAddress}`];
        const token = await this._getToken(chainId, tokenAddress);
        if (!token) {
          continue;
        }

        const assetToken: AssetToken = {
          ...token,
          balance: tokenBalance,
          quote: tokenQuote
        }

        let tokenList: AssetToken[] = tokenMap[chainId];
        if (!tokenList) {
          tokenList = [];
          tokenMap[chainId] = tokenList;
        }

        tokenList.push(assetToken);

        walletBalanceDetail.quoteInfo.quote += tokenQuote.quote;
        walletBalanceDetail.quoteInfo.beforeQuote += tokenQuote.beforeQuote;
      }

      walletBalanceDetail.tokenMap = tokenMap;
      resolve();
    });
  }

  _getToken(chainId: number, tokenAddress: string): Promise<CryptoToken | undefined> {
    return new Promise<CryptoToken | undefined>(async resolve => {
      const totalTokens = await this.getSupportedTokens();
      const token = totalTokens.find(token => token.chain.id === chainId && token.address === tokenAddress);
      resolve(token);
    });
  };

  _getTokenBalanceMap(chain: CryptoChainInfo, wallet: ISingleChainWallet): Promise<TokenBalanceMap> {
    return new Promise<TokenBalanceMap>(async resolve => {
      const totalTokens = await this.getSupportedTokens();
      const chainTokens = totalTokens.filter(token => token.chain.id === chain.id);
      const chainTokenInfos = chainTokens.map(token => {
        return {
          tokenAddress: token.address,
          decimals: token.decimals
        };
      });

      const tokenBalanceMap = await wallet.getAllTokenBalances(chainTokenInfos);
      resolve(tokenBalanceMap);
    });
  }

  _getTokenQuoteMap(tokenQuoteRequests: TokenQuoteRequest[]): Promise<TokenQuoteMap> {
    return new Promise<TokenQuoteMap>(async resolve => {
      const tokenPriceMap = await this._getTokenPriceMap(tokenQuoteRequests);
      const tokenQuoteMap: TokenQuoteMap = {};
      tokenQuoteRequests.forEach(tokenQuoteRequest => {
        const chainId = tokenQuoteRequest.chainId;
        const tokenAddress = tokenQuoteRequest.address;
        const tokenBalance = tokenQuoteRequest.balance;
        const tokenPrice = tokenPriceMap[`${chainId}_${tokenAddress}`];
        const quote = tokenBalance.amountInETH * tokenPrice;
        tokenQuoteMap[`${chainId}_${tokenAddress}`] = {
          quote: quote,
          beforeQuote: quote,
          quoteChangePerc: 0
        };
      });
      resolve(tokenQuoteMap);
    });
  }

  _getTokenPriceMap(tokenPriceRequests: TokenPriceRequest[]): Promise<TokenPriceMap> {
    return new Promise<TokenPriceMap>(async resolve => {
      if (!tokenPriceRequests || tokenPriceRequests.length === 0) {
        resolve({});
        return;
      }

      const tokenPriceList = await ServiceManagerIns.apiService.web3.getTokenPriceList(tokenPriceRequests);
      const tokenPriceMap: TokenPriceMap = {};
      tokenPriceList.forEach((tokenPrice, index) => {
        const tokenPriceRequest = tokenPriceRequests[index];
        const chainId = tokenPriceRequest.chainId;
        const tokenAddress = tokenPriceRequest.address;
        tokenPriceMap[`${chainId}_${tokenAddress}`] = Number(tokenPrice);
      });

      resolve(tokenPriceMap);
    });
  }

  _getWalletAddressInfo(chain: CryptoChain, wallet: ISingleChainWallet): Promise<AccountAddressInfo> {
    return new Promise<AccountAddressInfo>(async resolve => {
      const walletAddress = await wallet.getWalletAddress();
      resolve({
        chain: chain,
        address: walletAddress
      });
    });
  }

  _getCryptoChain(chainId: number): Promise<CryptoChain | undefined> {
    return new Promise<CryptoChain | undefined>(async resolve => {
      const allChains = await this.getSupportedChains();
      const chain = allChains.find(chain => chain.id === chainId);
      resolve(chain);
    });
  }

  _parseWallet(walletModel: WalletInfo<MultichainInfo | SinglechainInfo>): Promise<IMultiChainWallet | ISingleChainWallet | undefined> {
    return new Promise<IMultiChainWallet | ISingleChainWallet | undefined>(async resolve => {
      const password = await ServiceManagerIns.settingService.getPasswordHash();
      const web3Config = await this.getWeb3Config();
      const rpcConfig = web3Config.rpc;
      const balAddrConfig = web3Config.bal_address;

      switch (walletModel.type) {
        case WalletType.Multichain:
          const encrMnemonic = (walletModel.info as MultichainInfo).encrMnemonic;
          const mnemonic = await ServiceManagerIns.cryptoService.decryptAES(encrMnemonic, password);
          const multichainWallet = new MultiChainWallet(mnemonic, rpcConfig, balAddrConfig);
          resolve(multichainWallet);
          return;
        case WalletType.Singlechain:
          const walleInfo = walletModel.info as SinglechainInfo;
          const encrPrivateKey = walleInfo.encrPrivateKey;
          const privateKey = await ServiceManagerIns.cryptoService.decryptAES(encrPrivateKey, password);
          const balanceContractAddr = web3Config.bal_address[walleInfo.chain.id].address;
          const singlechainWallet = createWallet(walleInfo.chain, {
            rpc: rpcConfig[walleInfo.chain.id].rpc,
            privateKey: privateKey,
            balanceContractAddr: balanceContractAddr
          })
          resolve(singlechainWallet);
          return;
      }
    });
  }
}

export default Web3Service;
