export enum SBExchangeType {
  Swap = 'swap',
  Bridge = 'bridge'
}

export enum SBActionType {
  Swap = 'swap',
  Deposit = 'deposit'
}

export enum SBExplorerDefaultLogoType {
  ETH = '/imgs/scans/etherscan.svg',
  BSC = '/imgs/scans/bscscan.svg',
  FTM = '/imgs/scans/ftmscan.svg',
  SOL = '/imgs/scans/solscan.png',
  TON = '/imgs/scans/tonscan.png',
  POL = '/imgs/scans/polygonscan.png',
  ARB = '/imgs/scans/arbitrumscan.png',
  OP = '/imgs/scans/opscan.png',
  BASE = '/imgs/scans/basescan.png',
  MANTLE = '/imgs/scans/mantlescan.png',
  SCROLL = '/imgs/scans/scrollscan.png',
  TAIKO = '/imgs/scans/taikoscan.png',
  AVAX = '/imgs/scans/avascan.png',
}

export enum SBTransactionStatusType {
  Pending = 'Pending',
  Processing = 'Processing',
  Success = 'Success',
  Failed = 'Failed'
};

export enum SBEstimateErrorType {
  TooLow = 'too_low',
  InsufficientBalance = 'insufficient_balance',
  NoRoute = 'no_route',
  ServerError = 'server_error',
};
