import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WorkflowScreen from "../../onboarding/common/WorkflowScreen";
import styled, { keyframes } from "styled-components";
import { AssetToken, CryptoChain } from "../../../models/web3/Web3Model";
import SendTokenAmountPreview from "./components/SendTokenAmountPreview";
import SendToPreview from "./components/SendToPreview";
import { AccountInfo } from "../../../models/home/HomeModel";
import ServiceManagerIns from "../../../services/ServiceManager";
import { BizContext } from "../../../contexts/BizContext";
import { AlertModel } from "../../../models/common/CommonModel";
import { AlertType } from "../../../enums/CommonEnums";

interface SendPreviewScreenState {
   accountInfo: AccountInfo,
   chain: CryptoChain,
   assetToken: AssetToken,
   amount: number,
   quote: number,
   toAddress: string,
   comment: string,
}

const SendPreviewScreen: React.FC = () => {
   const bizContext = useContext(BizContext);
   if (!bizContext) {
      throw new Error("Context isn't existed");
   }
   const sendBizModel = bizContext.sendBizModel;
   const navigate = useNavigate();
   const location = useLocation();
   const {
      accountInfo,
      chain,
      assetToken,
      amount,
      quote,
      toAddress,
      comment
   } = location.state as SendPreviewScreenState;

   const [walletAddress, setWalletAddress] = useState<string>('');
   const [isProcessingSend, setIsProcessingSend] = useState<boolean>(false);

   useEffect(() => {
      const fetchData = async () => {
         const web3Wallet = await ServiceManagerIns.web3Service.getWeb3Wallet(chain);
         if (!web3Wallet) {
            return;
         }

         const walletAddress = await web3Wallet.getWalletAddress();
         setWalletAddress(walletAddress);
      };

      fetchData();
   }, [chain]);

   const handleSendAction = async () => {
      setIsProcessingSend(true);
      const success = await sendBizModel.sendWallet(accountInfo, chain, assetToken, amount, quote, toAddress, comment);
      setIsProcessingSend(false);

      const alertModel: AlertModel = {
         type: success ? AlertType.Success : AlertType.Error,
         message: success ? "Success message" : "Error message"
      }
      navigate('/home', {
         state: {
            alertModel: alertModel
         }
      });
   }

   return (
      <WorkflowScreen title="Send">
         <Container>
            <SendPreviewInfo>
               <SendTokenAmountPreview
                  accountInfo={accountInfo}
                  walletAddress={walletAddress}
                  assetToken={assetToken}
                  amount={amount}
                  quote={quote}
               />
               <SendToPreview
                  chain={chain}
                  toAddress={toAddress}
               />
            </SendPreviewInfo>

            <NextButtonWrapper>
               <NextButton onClick={handleSendAction}>
                  <NextButtonText>{isProcessingSend ? "Processing" : "Send"}</NextButtonText>
                  {isProcessingSend && <LoadingIcon src="/icons/ic_loading.svg" />}
               </NextButton>
            </NextButtonWrapper>
         </Container>
      </WorkflowScreen>
   )
}

const Container = styled.div`
   position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 16px 16px 24px 16px;
  gap: 16px;
  box-sizing: border-box;
  background-color: #f3f3f3;
  font-family: 'Plus Jakarta Sans', sans-serif;

  overflow-y: auto;
  scroll-behavior: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const SendPreviewInfo = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   border-radius: 16px;
   box-sizing: border-box;
   padding: 16px;
   gap: 16px;
   background-color: #fff;
`;

const NextButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
`;

const NextButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 12px 24px;
  border-radius: 16px;
  background-color: #262626;
  width: 100%;
  cursor: pointer;
  border-style: none;
`;

const NextButtonText = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
`;

const spinAnim = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingIcon = styled.img`
   width: 24px;
   height: 24px;
   animation: ${spinAnim} 2s linear infinite;
`;

export default SendPreviewScreen;
