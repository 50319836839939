import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {AssetTokenItemFullInfo, AssetTokenItemOnlyBalance, AssetTokenItemSizeType} from "./AssetTokenItem";
import {AssetToken} from "../../models/web3/Web3Model";

export enum AssetTokenItemShowType {
  FullInfo = 0,
  OnlyBalance
}

export interface AssetTokenListProps {
  assetTokenList: AssetToken[];
  itemShowType?: AssetTokenItemShowType;
  itemSizeType?: AssetTokenItemSizeType;
  onSelectToken?: (token: AssetToken) => void;
}

const AssetTokenList: React.FC<AssetTokenListProps> = ({
                                                         assetTokenList,
                                                         itemShowType,
                                                         itemSizeType,
                                                         onSelectToken
                                                       }) => {
  return (
    <TokenListContainer>
      {
        assetTokenList.map((assetToken, index) => {
          switch (itemShowType) {
            case AssetTokenItemShowType.FullInfo:
              return (
                <AssetTokenItemFullInfo
                  key={index}
                  assetToken={assetToken}
                  sizeType={itemSizeType}
                  onSelect={() => {
                    onSelectToken && onSelectToken(assetToken)
                  }}
                />
              )
            case AssetTokenItemShowType.OnlyBalance:
              return (
                <AssetTokenItemOnlyBalance
                  key={index}
                  assetToken={assetToken}
                  sizeType={itemSizeType}
                  onSelect={() => {
                    onSelectToken && onSelectToken(assetToken)
                  }}
                />
              )
            default:
              return (
                <AssetTokenItemFullInfo
                  key={index}
                  assetToken={assetToken}
                  sizeType={itemSizeType}
                  onSelect={() => {
                    onSelectToken && onSelectToken(assetToken)
                  }}
                />
              )
          }
        })
      }
    </TokenListContainer>
  );
}

const TokenListContainer = styled.div.attrs({className: 'token-list-container'})`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export default AssetTokenList;
