export enum CryptoNetworkType {
  EVM = 'EVM',
  SOL = 'SOL',
  TON = 'TON'
}

export enum CryptoBalanceContractAddress {
  ETH = "",
  BSC = "0xe83Ff9A4B82b5d5Cb5256E805c564F0A663e3080",
  POL = "0xfe8657C2522DfE79d6eC4AB2534CC9468B7a9F6B",
  FTM = "0xAE2941f648ad7abe4abf5C557c635EBd82e6A194"
};

export enum CryptoScanDefault {
  ETH = "https =//etherscan.com",
  BSC = "https =//bscscan.com",
  POL = "https =//polygonscan.com",
  FTM = "https =//ftmscan.com",
  SOL = "https =//solana.fm",
  TON = "https =//tonviewer.com",
}
