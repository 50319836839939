import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { postEvent } from '@telegram-apps/sdk';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {BizProvider} from "./contexts/BizContext";
import {TGProvider} from "./contexts/TGContext";
import {AppProvider} from "./contexts/AppContext";
import {SBBizModelProvider} from "./contexts/biz_model/SBBizModelContext";

const checkPostEventTGMiniapp = () => {
  if (window.Telegram && window.Telegram.WebApp.initData) {
    window.Telegram.WebApp.setHeaderColor("#fff");
    postEvent('web_app_expand');
    postEvent('web_app_setup_main_button', { is_visible: false })
    postEvent('web_app_setup_swipe_behavior', { allow_vertical_swipe: false })
    postEvent('web_app_ready');
  }
}

checkPostEventTGMiniapp();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AppProvider>
    <BizProvider>
      <TGProvider>
        <App/>
      </TGProvider>
    </BizProvider>
  </AppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
