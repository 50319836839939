import React from "react";
import styled from "styled-components";
import {
   NFTransactionReceiveData,
   NFTransactionSendData,
   TransactionNotifyModel,
} from "../../../../models/notification/TransactionNotifyModel";
import TransactionChangeInfo from "./sub_items/TransactionChangeInfo";

export interface TransactionSendReceiveItemProps {
   item: TransactionNotifyModel<NFTransactionSendData | NFTransactionReceiveData>;
}

const TransactionSendReceiveItem: React.FC<TransactionSendReceiveItemProps> = ({
   item
}) => {

   return (
      <ContentWrapper>
         <TransactionDetailContainer>
            <TransactionIcon src={item.logo} />

            <DetailContainer>
               <TitleText>{item.title}</TitleText>
               <DescriptionText>{item.description}</DescriptionText>
            </DetailContainer>
         </TransactionDetailContainer>

         <AmountContainer>
            <TransactionChangeInfo info={item.data.changeInfo} />
         </AmountContainer>
      </ContentWrapper>
   )
};

const ContentWrapper = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   max-width: 100%;
   padding: 16px;
   box-sizing: border-box;
   gap: 8px;
   font-family: 'Plus Jakarta Sans', sans-serif;
`;

const TransactionDetailContainer = styled.div`
   display: flex;
   align-items: center;
   gap: 12px;
`;

const TransactionIcon = styled.img`
   width: 40px;
   height: 40px;
   border-radius: 100%;
`;

const DetailContainer = styled.div`
   display: flex;
   flex-direction: column;
   box-sizing: border-box;
`;

const TitleText = styled.span`
   font-weight: 600;
   font-size: 14px;
   line-height: 22px;
   color: #262626;
`;

const DescriptionText = styled.span`
   font-weight: 400;
   font-size: 12px;
   line-height: 18px;
   color: #8C8C8C;
`;

const AmountContainer = styled.div`
   display: flex;
   align-items: center;
`;

export default TransactionSendReceiveItem;