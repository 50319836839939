import React from "react";
import AccountActionItem, {AccountActionItemProps} from "./AccountActionItem";
import styled from "styled-components";
import {AccountAction} from "../../enums/AccountEnums";

interface AccountActionListProps {
  onClickAction: (action: AccountAction) => void;
}

const AccountActionList: React.FC<AccountActionListProps> = ({onClickAction}) => {
  return (
    <ActionList>
      <SendAction src="/icons/ic_send.svg" title="Send" onClick={() => onClickAction(AccountAction.Send)}/>
      <ReceiveAction src="/icons/ic_receive.svg" title="Receive" onClick={() => onClickAction(AccountAction.Receive)}/>
      <SwapAction src="/icons/ic_swap.svg" title="Swap" onClick={() => onClickAction(AccountAction.Swap)}/>
      <OTCAction src="/icons/ic_otc.svg" title="OTC" onClick={() => onClickAction(AccountAction.OTC)}/>
      <MoreAction src="/icons/ic_more.svg" title="More" onClick={() => onClickAction(AccountAction.More)}/>
    </ActionList>
  );
}


const ActionList = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const SendAction: React.FC<AccountActionItemProps> = (props) => {
  return <AccountActionItem {...props} />;
};

const ReceiveAction: React.FC<AccountActionItemProps> = (props) => {
  return <AccountActionItem {...props} />;
};

const SwapAction: React.FC<AccountActionItemProps> = (props) => {
  return <AccountActionItem {...props} />;
};

const OTCAction: React.FC<AccountActionItemProps> = (props) => {
  return <AccountActionItem {...props} />;
};

const MoreAction: React.FC<AccountActionItemProps> = (props) => {
  return <AccountActionItem {...props} />;
};

export default AccountActionList;

