import React from 'react';
import styled from 'styled-components';

export interface AccountActionItemProps {
  src: string;
  title: string;
  onClick: () => void;
}

const AccountActionItem: React.FC<AccountActionItemProps> = ({
                                                               src,
                                                               title,
                                                               onClick
                                                             }) => {
  return (
    <ActionContainer>
      <ActionIconArea onClick={onClick} >
        <ActionIcon src={src} />
      </ActionIconArea>
      <ActionTitle>{title}</ActionTitle>
    </ActionContainer>
  );
}

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
`

const ActionIconArea = styled.div`
  display: flex;
  border-radius: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: #00000014;
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const ActionIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`

const ActionTitle = styled.span`
  color: #262626;
  font: 500 12px 'Plus Jakarta Sans', sans-serif;
`

export default AccountActionItem;
