import React, {useCallback, useContext, useEffect, useState} from "react";
import WorkflowScreen from "../onboarding/common/WorkflowScreen";
import styled, {keyframes} from "styled-components";
import SwapBridgeInOut from "./components/SwapBridgeInOut";
import SwapBridgeExchangeDetail from "./components/SwapBridgeExchangeDetail";
import SwapBridgeReceiver from "./components/SwapBridgeReceiver";
import SwapBridgeHistory from "./components/SwapBridgeHistory";
import {BizContext} from "../../contexts/BizContext";
import {
  SBEstimateFailed,
  SBEstimateFailedOverLimitData,
  SBEstimateInfo,
  SBTransaction
} from "../../models/swap_bridge/SwapBridgeModel";
import SearchAssetTokenBottomSheet from "../../components/bottomsheets/search_asset_token/SearchAssetTokenBottomSheet";
import {SwapBridgeInputProps} from "./components/SwapBridgeInput";
import {SwapBridgeOutputProps} from "./components/SwapBridgeOutput";
import {CryptoToken} from "../../models/web3/Web3Model";
import {SBEstimateErrorType} from "../../enums/SwapBridgeEnums";
import {AssetTokenItemShowType} from "../../components/home/AssetTokenList";
import {CryptoAmount} from "../../core/wallet/models/Web3Model";
import {SBBizModelContext, SBBizModelProvider} from "../../contexts/biz_model/SBBizModelContext";
import {ethers} from "ethers";
import ServiceManagerIns from "../../services/ServiceManager";

enum SwapButtonTitleType {
  Normal = 'Confirm Swap',
  Swapping = 'Swapping',
  Insufficient = 'Insufficient Balance',
  NoRoute = 'Not Supported.'
}

enum SwapState {
  Idle = 'idle',
  Swapping = 'swapping',
  Inactive = 'inactive'
}

const SwapBridgeScreen: React.FC = () => {
  const bizContext = useContext(BizContext);
  const sbBizModelContext = useContext(SBBizModelContext);
  if (!bizContext || !sbBizModelContext) {
    throw new Error("Context isn't existed");
  }
  const { swapBridgeBizModel } = bizContext;

  const {defaultInputToken, defaultOutputToken, estimateInfo, sbTransactions} = sbBizModelContext;

  const [inputToken, setInputToken] = useState<CryptoToken | undefined>(undefined);
  const [inputBalance, setInputBalance] = useState<CryptoAmount | undefined>(undefined);
  const [inputAmountInETH, setInputAmountInETH] = useState<number | undefined>(undefined);
  const [inputAmountText, setInputAmountText] = useState<string | undefined>(undefined);
  const [inputQuote, setInputQuote] = useState<number | undefined>(undefined);
  const [showMinAmount, setShowMinAmount] = useState<boolean>(false);
  const [minAmountInETH, setMinAmountInETH] = useState<number | undefined>(undefined);

  const [outputToken, setOutputToken] = useState<CryptoToken | undefined>(undefined);
  const [outputEstimateAmountInETH, setOutputEstimateAmountInETH] = useState<number | undefined>(undefined);
  const [outputEstimateQuote, setOutputEstimateQuote] = useState<number | undefined>(undefined);
  const [sbEstimateInfo, setSBEstimateInfo] = useState<SBEstimateInfo | undefined>(undefined);

  const [defaultAddress, setDefaultAddress] = useState<string>('Recipient Address');
  const [curToAddressInputText, setCurToAddressInputText] = useState<string>('');
  const [toAddress, setToAddress] = useState<string | undefined>(undefined);

  const [swapButtonTitle, setSwapButtonTitle] = useState<string>(SwapButtonTitleType.Normal);
  const [swapState, setSwapState] = useState<SwapState>(SwapState.Inactive);

  const [showSearchTokenBS_In, setShowSearchTokenBS_In] = useState<boolean>(false);
  const [showSearchTokenBS_Out, setShowSearchTokenBS_Out] = useState<boolean>(false);

  let inputAmountTimeout: NodeJS.Timeout | undefined = undefined;

  const inputProps: SwapBridgeInputProps = {
    token: inputToken,
    balance: inputBalance,
    inputAmountText: inputAmountText,
    quote: inputQuote,
    showMinAmount: showMinAmount,
    minAmountInETH: minAmountInETH,
    onSelectTokenArea: () => handleShowSearchTokenIn(),
    onChangeInputAmountInETH: (amountInETH) => handleChangeAmountInValue(amountInETH),
    onClickBalance: () => handleOnClickBalance()
  }

  const outputProps: SwapBridgeOutputProps = {
    token: outputToken,
    estimateAmountInETH: outputEstimateAmountInETH,
    estimateQuote: outputEstimateQuote,
    onSelectTokenArea: () => handleShowSearchTokenOut(),
  }

  useEffect(() => {
    setInputToken(defaultInputToken);
  }, [defaultInputToken]);

  useEffect(() => {
    setOutputToken(defaultOutputToken);
  }, [defaultOutputToken]);

  useEffect(() => {
    const handleUpdateInputBalance = async () => {
      if (inputToken) {
        const _inputBalance = await swapBridgeBizModel.getBalance(inputToken);
        setInputBalance(_inputBalance);
      }
    };

    ServiceManagerIns.observerService.accountData.onUptodateAccountDetail(handleUpdateInputBalance);
    return () => {
      ServiceManagerIns.observerService.accountData.removeOnUptodateAccountDetail(handleUpdateInputBalance);
    }
  }, [inputToken]);

  useEffect(() => {
    if (!swapBridgeBizModel) {
      return;
    }

    const fetchData = async () => {
      swapBridgeBizModel.injectContext(sbBizModelContext);
      await swapBridgeBizModel.loadData();
    }

    fetchData();

    return () => {
      const clearData = async () => {
        await swapBridgeBizModel.stopGettingEstimateInfo();
        await swapBridgeBizModel.stopTrackingSBHistory();
      }

      window.Telegram.WebApp.BackButton.hide();
      clearData();
    };
  }, [swapBridgeBizModel]);

  useEffect(() => {
    setInputAmountInETH(undefined);
    setInputAmountText(undefined);
    setShowMinAmount(false);

    if (!inputToken) {
      setInputBalance(undefined);
      return;
    }

    const fetchTokenBalance = async () => {
      const balance = await swapBridgeBizModel.getBalance(inputToken);
      setInputBalance(balance);
    }

    fetchTokenBalance();
  }, [inputToken]);

  useEffect(() => {
    if (!inputAmountInETH || !inputToken) {
      setInputQuote(undefined);
      return;
    }

    const fetchInputQuote = async () => {
      const quote = await swapBridgeBizModel.getQuoteValue(inputToken, inputAmountInETH);
      setInputQuote(quote);
    }

    fetchInputQuote();
  }, [inputAmountInETH]);

  useEffect(() => {
    setOutputEstimateAmountInETH(undefined);
    setOutputEstimateQuote(undefined);
    setShowMinAmount(false);

    if (!outputToken) {
      setDefaultAddress('Recipient Address');
      setToAddress(undefined);
      return;
    }

    const fetchDefaultToAddress = async () => {
      const _defaultAddress = await swapBridgeBizModel.getDefaultToAddress(outputToken.chain);
      console.log(_defaultAddress);
      setDefaultAddress(_defaultAddress);

      const checkCurToAddressValid = await swapBridgeBizModel.checkValidToAddress(curToAddressInputText, outputToken.chain);
      if (checkCurToAddressValid) {
        setToAddress(curToAddressInputText);
      } else {
        setToAddress(_defaultAddress);
      }
    }

    fetchDefaultToAddress();
  }, [outputToken])

  useEffect(() => {
    if (!inputToken || !inputAmountInETH || !outputToken || (!toAddress && !defaultAddress)) {
      swapBridgeBizModel.stopGettingEstimateInfo();
      return;
    }

    swapBridgeBizModel.startGettingEstimateInfo(inputToken, outputToken, inputAmountInETH, toAddress ? toAddress : defaultAddress);
  }, [inputToken, inputAmountInETH, outputToken, toAddress, defaultAddress])

  useEffect(() => {
    if (!estimateInfo) {
      setSBEstimateInfo(undefined);
      setShowMinAmount(false);
      setMinAmountInETH(0);
      setSwapState(SwapState.Inactive);
      setSwapButtonTitle(SwapButtonTitleType.Normal)
      return;
    }

    if ((estimateInfo as SBEstimateFailed<any>).errorType) {
      setSBEstimateInfo(undefined);

      const estimateFailedInfo = estimateInfo as SBEstimateFailed<any>;
      switch (estimateFailedInfo.errorType) {
        case SBEstimateErrorType.TooLow:
          setShowMinAmount(true);
          setMinAmountInETH((estimateFailedInfo as SBEstimateFailed<SBEstimateFailedOverLimitData>).data.minAmount.amountInETH);
          setSwapState(SwapState.Inactive);
          setSwapButtonTitle(SwapButtonTitleType.Normal);
          break;

        case SBEstimateErrorType.InsufficientBalance:
          setSwapState(SwapState.Inactive);
          setSwapButtonTitle(SwapButtonTitleType.Insufficient);
          break;

        case SBEstimateErrorType.NoRoute:
          setShowMinAmount(false);
          setSwapState(SwapState.Inactive);
          setSwapButtonTitle(SwapButtonTitleType.NoRoute);
          break;

        default:
          break;
      }

      return;
    }

    setShowMinAmount(false);
    setMinAmountInETH(undefined);

    setSwapState(SwapState.Idle);
    setSwapButtonTitle(SwapButtonTitleType.Normal);

    setSBEstimateInfo(estimateInfo as SBEstimateInfo);
  }, [estimateInfo]);

  useEffect(() => {
    if (!sbEstimateInfo) {
      setOutputEstimateAmountInETH(undefined);
      setOutputEstimateQuote(undefined);
    } else {
      setOutputEstimateAmountInETH(sbEstimateInfo.amount.amountInETH);
      setOutputEstimateQuote(sbEstimateInfo.quote);
    }
  }, [sbEstimateInfo]);

  const handleSelectTokenIn = (token: CryptoToken) => {
    setInputToken(token);
  }

  const handleSelectTokenOut = (token: CryptoToken) => {
    setOutputToken(token);
  }

  const handleChangeAmountInValue = (amountInETH: number) => {
    if (inputAmountTimeout) {
      clearTimeout(inputAmountTimeout);
    }

    inputAmountTimeout = setTimeout(() => {
      setInputAmountInETH(amountInETH);
    }, 1000);
  }

  const handleChangeToAddress = (address: string) => {
    setCurToAddressInputText(address);

    const checkValidAddress = async () => {
      if (!outputToken) {
        return;
      }

      const isValid = await swapBridgeBizModel.checkValidToAddress(address, outputToken?.chain);
      if (isValid) {
        setToAddress(address);
      } else if (!address) {
        setToAddress(defaultAddress);
      } else {
        setToAddress(undefined);
      }
    }

    checkValidAddress();
  }

  const handleOnClickBalance = () => {
    if (!inputBalance) {
      return;
    }

    setInputAmountInETH(inputBalance.amountInETH);
    setInputAmountText(inputBalance.amountInETH.toString());
  }

  const handleOnClickSwap = () => {
    if (swapState !== SwapState.Idle) {
      return;
    }

    setSwapState(SwapState.Swapping);
    setSwapButtonTitle(SwapButtonTitleType.Swapping);

    _startSwap();
  }

  const handleShowSearchTokenIn = () => {
    setShowSearchTokenBS_In(true);
  }

  const handleHideSearchTokenIn = () => {
    setShowSearchTokenBS_In(false);
  }

  const handleShowSearchTokenOut = () => {
    setShowSearchTokenBS_Out(true);
  }

  const handleHideSearchTokenOut = () => {
    setShowSearchTokenBS_Out(false);
  }

  const _startSwap = async () => {
    if (!inputToken || !outputToken || !inputAmountInETH || !sbEstimateInfo || !toAddress || !inputBalance) {
      alert("Something goes wrong");
      return;
    }

    const inputAmount: CryptoAmount = {
      amount: ethers.parseUnits(inputAmountInETH.toString(), inputToken.decimals),
      amountInETH: inputAmountInETH,
    };

    const targetAmount = inputAmount.amount <= inputBalance.amount ? inputAmount : inputBalance;
    await swapBridgeBizModel.swap(inputToken, outputToken, targetAmount, toAddress);

    setInputToken(undefined);
    setOutputToken(undefined);
    setSwapState(SwapState.Inactive);
    setSwapButtonTitle(SwapButtonTitleType.Normal);
  }

  return (
    <WorkflowScreen title={'Swap & Bridge'}>
      <Container>
        <ScrollableWrapper>
          <SwapBridgeInOut
            input={inputProps}
            output={outputProps}
          />
          <SwapBridgeReceiver
            defaultAddress={defaultAddress}
            inputText={curToAddressInputText}
            isValid={toAddress !== undefined || !curToAddressInputText}
            onChangeAddress={handleChangeToAddress}
          />
          <SwapButton
            disabled={swapState !== SwapState.Idle}
            onClick={handleOnClickSwap}
          >
            <SwapButtonText>{swapButtonTitle}</SwapButtonText>
            {
              swapState === SwapState.Swapping &&
              <LoadingIcon src="/icons/ic_loading.svg" />
            }
          </SwapButton>
          <SwapBridgeExchangeDetail sbExchangeInfo={sbEstimateInfo?.exchangeInfo}/>
          <SwapBridgeHistory sbTransactions={sbTransactions} />
        </ScrollableWrapper>
        {
          showSearchTokenBS_In &&
          <SearchAssetTokenBottomSheet
            showNoBalance={true}
            itemShowType={AssetTokenItemShowType.OnlyBalance}
            onSelectToken={handleSelectTokenIn}
            forceCloseWhenSelect={true}
            onClose={handleHideSearchTokenIn}
          />
        }
        {
          showSearchTokenBS_Out &&
          <SearchAssetTokenBottomSheet
            showNoBalance={true}
            itemShowType={AssetTokenItemShowType.OnlyBalance}
            onSelectToken={handleSelectTokenOut}
            forceCloseWhenSelect={true}
            onClose={handleHideSearchTokenOut}
          />
        }
      </Container>
    </WorkflowScreen>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  overflow-y: hidden;
  background-color: #f3f3f3;
`;

const ScrollableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 16px;
  gap: 16px;
  box-sizing: border-box;
  scroll-behavior: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const SwapButton = styled.button`
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: #262626;
  width: 100%;
  cursor: pointer;
  border-style: none;
  align-items: center;
  gap: 8px;
  color: #fff;

  &:disabled {
    cursor: not-allowed;
    background-color: #d9d9d9;
    color: #8c8c8c;
  }
`

const SwapButtonText = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`

const spinAnim = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingIcon = styled.img`
   width: 24px;
   height: 24px;
   animation: ${spinAnim} 2s linear infinite;
`;

export default SwapBridgeScreen;
