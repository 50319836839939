import {GenerateWalletStepType, PassphraseWordAmountType} from "../../../enums/WalletEnums";
import ServiceManagerIns from "../../../services/ServiceManager";
import {AccountWalletInfo} from "../../../models/wallet/AccountModel";
import OnboardingBizModel, {IOnboardingBizModel} from "../OnboardingBizModel";
import * as CryptoJS from 'crypto-js';
import {GenerateTypePassphraseData, OnboardProcessingInfo} from "../../../models/onboarding/OnboardingModel";

export interface ICreateWalletBizModel extends IOnboardingBizModel {
  generatePassphrase(wordAmount: PassphraseWordAmountType): Promise<string[]>;
  setPassphrase(passphrase: string[]): Promise<boolean>;
  createWallet(onStep:(step: GenerateWalletStepType) => void): Promise<boolean>;
}

class CreateWalletBizModel extends OnboardingBizModel implements ICreateWalletBizModel {
  generatePassphrase(wordAmount: PassphraseWordAmountType): Promise<string[]> {
    return new Promise<string[]>(async resolve => {
      const mnemonic = await ServiceManagerIns.web3Service.createNewPasspharse(wordAmount);
      const passphrase = mnemonic.split(' ');
      resolve(passphrase);
    });
  }

  setPassphrase(passphrase: string[]): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      (this.processInfo.info as GenerateTypePassphraseData).passphrase = passphrase;
      resolve(true);
    });
  }

  createWallet(onStep: (step: GenerateWalletStepType) => void): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      onStep(GenerateWalletStepType.StartCreate);

      const encryptFlow = async () => {
        const mnemonic = (this.processInfo.info as GenerateTypePassphraseData).passphrase.join(' ');
        const encrMnemonic = await ServiceManagerIns.cryptoService.encryptAES(mnemonic, this.processInfo.passwordHash);
        const accountWalletInfo = await ServiceManagerIns.accountService.createAccount({
          encrMnemonic: encrMnemonic
        });
        return accountWalletInfo;
      };

      const setActiveFlow = async (accountWalletInfo: AccountWalletInfo) => {
        await ServiceManagerIns.accountService.prepareDetailAndSetActiveAccount(accountWalletInfo);
      }

      setTimeout(async () => {
        onStep(GenerateWalletStepType.Encrypt);
        const accountWalletInfo = await encryptFlow();

        setTimeout(async () => {
          onStep(GenerateWalletStepType.SuggestBackup);
          await setActiveFlow(accountWalletInfo);

          setTimeout(() => {
            console.log(accountWalletInfo);
            onStep(GenerateWalletStepType.Done);
          }, 1000);
        }, 1000);
      }, 1000);

      resolve(true);
    });
  }
}

export default CreateWalletBizModel;
