import AccountDB, {IAccountDB} from "./AccountDB";

export interface IDatabaseService {
  accountDB: IAccountDB;

  reset(): Promise<boolean>;
}

class DatabaseService implements IDatabaseService {
  public accountDB: IAccountDB;
  constructor() {
    this.accountDB = new AccountDB();
  }

  reset(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      await this.accountDB.reset();
      resolve(true);
    });
  }
}

export default DatabaseService;
