import React, {useContext, useEffect, useState} from "react";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import CreatePasswordScreen from "../common/CreatePasswordScreen";
import GenerateWalletScreen from "../common/GenerateWalletScreen";
import styled from "styled-components";
import {TGContext} from "../../../contexts/TGContext";
import ImportWalletTypeSelectionScreen from "./ImportWalletTypeSelectionScreen";
import {ImportWalletType} from "../../../enums/WalletEnums";
import InputPassphraseScreen from "./InputPassphraseScreen";
import InputSecretKeyScreen from "./InputSecretKeyScreen";
import {RoutePath} from "../../../enums/CommonEnums";

const ImportWalletRoutes: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [startFlow, setStartFlow] = useState<boolean>(false);

  useEffect(() => {
    console.log(location.pathname, location.state);
    if (location.pathname === '/onboarding/importWallet' && startFlow) {
      navigate(-1);
    }
  }, [location]);

  useEffect(() => {
    navigateToStep(1);
  }, []);

  const navigateToStep = (step: number) => {
    if (!startFlow) {
      setStartFlow(true);
    }

    console.log(step);
    if (step > 0) {
      navigate(`step${step}`);
    } else if (step === 0) {
      navigate("finish");
    }
  };

  return (
    <ImportWalletRoutesContainer>
      <Routes>
        <Route path='step1' element={<ImportWalletTypeSelectionScreen onNext={(type) => {navigateToStep(type === ImportWalletType.Passphrase ? 2.1 : 2.2)}}/>}/>
        <Route path='step2.1' element={<InputPassphraseScreen onNext={() => {navigateToStep(3)}}/>}/>
        <Route path='step2.2' element={<InputSecretKeyScreen onNext={() => {navigateToStep(3)}}/>}/>
        <Route path='step3' element={<CreatePasswordScreen onNext={() => {navigateToStep(0)}}/>}/>
        <Route path='finish' element={<GenerateWalletScreen onNext={() => {navigate(RoutePath.Home, { relative: "path"})}}/>}/>
      </Routes>
    </ImportWalletRoutesContainer>
  )
}

const ImportWalletRoutesContainer = styled.div`
  width: 100%;
  height: ${() => {
  const {isTelegram, tgVH} = useContext(TGContext);
  return isTelegram ? `${tgVH}px` : '100vh';
}};
  background-color: #fff;
`

export default ImportWalletRoutes;
