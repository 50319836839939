import React, {useContext, useEffect, useState} from "react";
import BottomSheet from "../../common/BottomSheet";
import {BizContext} from "../../../contexts/BizContext";
import {AccountAddressInfo} from "../../../models/home/HomeModel";
import AccountAddressBSItem from "./AccountAddressBSItem";
import styled from "styled-components";

interface AccountAddressBottomSheetProps {
  onClickShowQR?: (accountAddress: AccountAddressInfo) => void;
  onClickCopy?: (accountAddress: AccountAddressInfo) => void;
  onClose: () => void;
}

const AccountAddressBottomSheet: React.FC<AccountAddressBottomSheetProps> = ({
                                                                               onClickShowQR,
                                                                               onClickCopy,
                                                                               onClose
                                                                             }) => {
  const bizContext = useContext(BizContext);
  if (!bizContext) {
    throw new Error("Context isn't existed");
  }

  const {homeBizModel} = bizContext;

  const [accountAddressList, setAccountAddressList] = useState<AccountAddressInfo[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const _addressList = await homeBizModel.getAccountAddressInfos();
      setAccountAddressList(_addressList);
    }
    fetchData();
  }, [])

  return <BottomSheet
    scrollableContent={
      <>
        <AccountAddressContainer>
          {
            accountAddressList.map(addressInfo => (
              <AccountAddressBSItem
                accountAddressInfo={addressInfo}
                onClickShowQR={onClickShowQR}
                onClickCopy={onClickCopy}
              />
            ))
          }
        </AccountAddressContainer>
      </>
    }
    onClose={onClose}
  />
}

const AccountAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px 24px 24px;
  gap: 24px;
  box-sizing: content-box;
`

export default AccountAddressBottomSheet;
