import React from "react";
import styled from "styled-components";
import SwapBridgeHistoryItem from "./SwapBridgeHistoryItem";
import {SBTransaction} from "../../../models/swap_bridge/SwapBridgeModel";

interface SwapBridgeHistoryListProps {
  sbTransactions?: SBTransaction[];
}

const SwapBridgeHistoryList: React.FC<SwapBridgeHistoryListProps> = ({
                                                                       sbTransactions
                                                                     }) => {
  return (
    <Container>
      {
        sbTransactions && sbTransactions.map((sbTransaction, index) => (
          <SwapBridgeHistoryItem
            key={index}
            sbTransaction={sbTransaction}
          />
        ))
      }
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'Plus Jakarta Sans', sans-serif;
  gap: 24px;
`

export default SwapBridgeHistoryList;
