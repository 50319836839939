import React from "react";
import styled from "styled-components";
import {CryptoToken} from "../../../models/web3/Web3Model";

interface SwapBridgeAssetSelectorProps {
  token?: CryptoToken
  onClick?: () => void;
}

const SwapBridgeAssetSelector: React.FC<SwapBridgeAssetSelectorProps> = ({
                                                                           token,
                                                                           onClick
                                                                         }) => {
  return (
    <Container onClick={onClick}>
      <TokenLogo src={token ? token.logo : '/imgs/img_sb_token_select_default.png'} fetchPriority='high'/>
      <TokenInfoArea>
        <TokenNameArea>
          <TokenSymbol>{token ? token.symbol : 'Select'}</TokenSymbol>
          <DropdownIcon src="/icons/ic_arrow_down.svg"/>
        </TokenNameArea>
        {
          token &&
          <ChainName>{token.chain.name}</ChainName>
        }
      </TokenInfoArea>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  gap: 6px;
  border-radius: 16px;
  background-color: #f5f5f5;
  padding: 8px 12px 8px 8px;
  cursor: pointer;
  align-items: center;
`

const TokenLogo = styled.img`
  width: 40px;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  border-radius: 100%;
`;

const TokenInfoArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const TokenNameArea = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const TokenSymbol = styled.span`
  color: #262626;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

const ChainName = styled.span`
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`;

const DropdownIcon = styled.img`
  object-fit: contain;
  object-position: center;
  width: 12px;
  height: 12px;
`;

export default SwapBridgeAssetSelector;

