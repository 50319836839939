export interface IAccountDataObserver {
  notifyOnUptodateAccountDetail(): void;

  onUptodateAccountDetail(observer: () => void): void;

  removeOnUptodateAccountDetail(observer: () => void): void;
}

class AccountDataObserver implements IAccountDataObserver {
  protected onUptodateObservers: (() => void)[];
  constructor() {
    this.onUptodateObservers = [];
  }

  notifyOnUptodateAccountDetail() {
    this.onUptodateObservers.forEach(observer => observer());
  }

  onUptodateAccountDetail(observer: () => void) {
    this.onUptodateObservers.push(observer);
  }

  removeOnUptodateAccountDetail(observer: () => void) {
    const index = this.onUptodateObservers.indexOf(observer);
    if (index === -1) {
      return;
    }

    this.onUptodateObservers.splice(index, 1);
  }
}

export default AccountDataObserver;
