import ServiceManagerIns from "../ServiceManager";

export interface ICleanupService {
  cleanupApp(): Promise<boolean>;
}

class CleanupService implements ICleanupService {
  cleanupApp(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      await ServiceManagerIns.cacheService.reset();
      await ServiceManagerIns.sessionService.reset();
      await ServiceManagerIns.databaseService.reset();
      resolve(true);
    });
  }
}

export default CleanupService;
