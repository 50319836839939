import HomeBizModel, {IHomeBizModel} from "./HomeBizModel";
import {IDI_Injector} from "../DI_Injector";
import {MockHomeBizModel} from "./MockHomeBizModel";

export class HomeBizInjector implements IDI_Injector<IHomeBizModel> {
  inject(): IHomeBizModel {
    const bizModel = new HomeBizModel();
    return bizModel;
  }
}

