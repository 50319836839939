import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import AssetChainSelector, {AssetChainSelectorShowType} from "../../../../components/home/AssetChainSelector";
import {AssetToken, CryptoChain} from "../../../../models/web3/Web3Model";
import {BizContext} from "../../../../contexts/BizContext";
import SwapBridgeAssetSelector from "../../../swap_bridge/components/SwapBridgeAssetSelector";

export interface SendFromInputProps {
  chainList: CryptoChain[];
  selectedChain?: CryptoChain;
  selectedAssetToken?: AssetToken;
  inputQuote: number;
  onClickSearchChain: () => void;
  onClickSearchToken: () => void;
  onSelectChain: (chain?: CryptoChain) => void;
  onSelectAssetToken: (token: AssetToken) => void;
  onChangeInputAmountInETH: (amountInETH: number) => void;
}

const SendFromInput: React.FC<SendFromInputProps> = ({
                                                       chainList,
                                                       selectedChain,
                                                       selectedAssetToken,
                                                       inputQuote,
                                                       onClickSearchChain,
                                                       onClickSearchToken,
                                                       onSelectChain,
                                                       onChangeInputAmountInETH,
                                                     }) => {
  const bizContext = useContext(BizContext);
  if (!bizContext) {
    throw new Error("Context isn't existed");
  }

  const [inputAmountText, setInputAmountText] = useState<string>('');

  useEffect(() => {
    setInputAmountText('');
  }, [selectedAssetToken]);

  const handleChangeAmountValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputAmountText(e.currentTarget.value);
    const newAmountValue = parseFloat(e.currentTarget.value);
    if (onChangeInputAmountInETH) onChangeInputAmountInETH(isNaN(newAmountValue) ? 0 : newAmountValue);
  }

  const handleSelectMax = () =>  {
    if (!selectedAssetToken) {
      return;
    }

    const inputAmount = selectedAssetToken.balance.amountInETH.toFixed(6);
    setInputAmountText(inputAmount);
    if (onChangeInputAmountInETH) onChangeInputAmountInETH(selectedAssetToken.balance.amountInETH);
  }

  return (
    <Container>
      <FromChainWrapper>
        <FromText>From</FromText>
        <AssetChainSelector
          chains={chainList}
          hideAll={true}
          selectedChain={selectedChain}
          showType={AssetChainSelectorShowType.IncludeNameForSelected}
          onSelectChain={onSelectChain}
          onSelectMore={onClickSearchChain}
        />
      </FromChainWrapper>
      <SendTokenInfoWrapper>
        <AssetHeaderArea>
          <AssetHeaderTitle>Asset</AssetHeaderTitle>
          <BalanceArea>
            <Balance>{`Balance: ${selectedAssetToken ? parseFloat(selectedAssetToken.balance.amountInETH.toFixed(6)) : 0}`}</Balance>
            <BalanceMaxButton onClick={handleSelectMax}>Max</BalanceMaxButton>
          </BalanceArea>
        </AssetHeaderArea>
        <TokenInputArea>
          <SwapBridgeAssetSelector
            token={selectedAssetToken}
            onClick={onClickSearchToken}
          />
          <AmountInput
            type="number"
            placeholder='0'
            value={inputAmountText}
            onChange={handleChangeAmountValue}
          />
        </TokenInputArea>
        <QuoteAmount>{`$${inputQuote ? inputQuote.toFixed(2) : '0.00'}`}</QuoteAmount>
      </SendTokenInfoWrapper>

    </Container>
  )
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 16px;
  gap: 16px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  background-color: #fff;
`;

const FromChainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const FromText = styled.span`
  color: #262626;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  align-self: flex-start;
`;

const SendTokenInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

const AssetHeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
  align-items: center;
`;

const AssetHeaderTitle = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #262626;
`;

const BalanceArea = styled.div`
  display: flex;
  gap: 4px;
`;

const Balance = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #8c8c8c;
`;

const BalanceMaxButton = styled.button`
  padding: 2px 8px;
  box-sizing: border-box;
  border-radius: 24px;
  border: 1px solid #E8E8E8;
  background-color: #fff;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #595959;
  cursor: pointer;
`

const TokenInputArea = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const AmountInput = styled.input`
  color: #262626;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  padding: 0;
  background: none;
  border: none;
  text-align: right;
  cursor: pointer;
  width: 100%;
  font-family: 'Plus Jakarta Sans', sans-serif;

  -webkit-tap-highlight-color: transparent;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #BFBFBF;
  }
`;

const QuoteAmount = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #BFBFBF;
  align-self: flex-end;
`;

export default SendFromInput;
