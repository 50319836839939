import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import ReactDOMServer from 'react-dom/server';
import styled from "styled-components";
import {CryptoChain} from "../../models/web3/Web3Model";

export enum AssetChainSelectorShowType {
  OnlyLogo,
  IncludeName,
  IncludeNameForSelected,
}

export interface AssetChainSelectorProps {
  chains: CryptoChain[],
  hideAll?: boolean,
  selectedChain?: CryptoChain | null;
  showType?: AssetChainSelectorShowType;
  onSelectChain: (chain?: CryptoChain) => void;
  onSelectMore?: () => void;

  selected_color?: string;
}


const AssetChainSelector: React.FC<AssetChainSelectorProps> = ({
                                                                 chains,
                                                                 hideAll,
                                                                 selectedChain,
                                                                 showType,
                                                                 onSelectChain,
                                                                 onSelectMore,
                                                                 selected_color
                                                               }) => {
  const parentRef = useRef<HTMLDivElement>(null);

  const [sortedChainList, setSortedChainList] = useState<CryptoChain[]>([]);
  const [visibleChainList, setVisibleChainList] = useState<CryptoChain[]>([]);
  const [showMoreChainIcon, setShowMoreChainIcon] = useState<boolean>(false);

  useEffect(() => {
    setSortedChainList(chains);
  }, [chains]);

  useEffect(() => {
    if (!selectedChain) {
      return;
    }

    const selectedIndex = visibleChainList.findIndex(chain => chain.id === selectedChain.id);
    if (selectedIndex === -1) {
      const newSortChainList = [selectedChain, ...((sortedChainList.length > 0 ? sortedChainList : chains).filter(chain => chain.id !== selectedChain.id))];
      setSortedChainList(newSortChainList);
    }
  }, [chains, selectedChain]);

  useLayoutEffect(() => {
    const calculateVisibleChains = () => {
      if (!parentRef.current) {
        return;
      }

      const newVisibleChains: CryptoChain[] = [];
      const containerWidth = parentRef.current.offsetWidth + 20;
      let currentWidth = -8;

      for(const chain of sortedChainList) {
        const wrapper = document.createElement('div');
        wrapper.innerHTML = ReactDOMServer.renderToStaticMarkup(
          renderChainItem(chain)
        );

        parentRef.current.appendChild(wrapper);
        const itemWidth = wrapper.getBoundingClientRect().width;
        parentRef.current.removeChild(wrapper);
        if ((currentWidth + itemWidth + 8 <= containerWidth) || chain.id === selectedChain?.id) {
          currentWidth += itemWidth + 8;
          newVisibleChains.push(chain);
        } else {
          break;
        }
      }

      setVisibleChainList(newVisibleChains);
      setShowMoreChainIcon(newVisibleChains.length < sortedChainList.length);
    };

    calculateVisibleChains();
  }, [sortedChainList, selectedChain, showType, parentRef]);

  const renderChainItemList = (visibleChainList: CryptoChain[]) => {
    return visibleChainList.map(chain => renderChainItem(chain));
  }

  const renderChainItem = (chain: CryptoChain) => {
    return (
      <ChainSelectableWrapper
        key={chain.id}
        selected={selectedChain != null && JSON.stringify(selectedChain) === JSON.stringify(chain)}
        selected_color={selected_color}
        onClick={() => onSelectChain(chain)}
      >
        {(() => {
          switch (showType) {
            case AssetChainSelectorShowType.OnlyLogo:
              return <ChainIcon src={chain.logo}/>;

            case AssetChainSelectorShowType.IncludeName:
              return (
                <>
                  <ChainIcon src={chain.logo}/>
                  <ChainName>{chain.name}</ChainName>
                </>
              );
            case AssetChainSelectorShowType.IncludeNameForSelected:
              if (selectedChain && chain.id === selectedChain.id) {
                return (
                  <>
                    <ChainIcon src={chain.logo}/>
                    <ChainName>{chain.name}</ChainName>
                  </>
                );
              } else {
                return <ChainIcon src={chain.logo}/>;
              }

            default:
              return <ChainIcon src={chain.logo}/>;
          }
        })()}
      </ChainSelectableWrapper>
    )
  }

  return (
    <ChainSelectorContainer>
      {
        !hideAll &&
        (
          <ChainSelectableWrapper
            selected={!selectedChain}
            selected_color={selected_color}
            onClick={() => onSelectChain()}
          >
            <ChainAll>All</ChainAll>
          </ChainSelectableWrapper>
        )
      }
      <ChainIconArea ref={parentRef}>
        {
          renderChainItemList(visibleChainList)
        }
      </ChainIconArea>
      {
        showMoreChainIcon &&
        <MoreChainIcon src="/icons/ic_arrow_right.svg" onClick={onSelectMore}/>
      }
    </ChainSelectorContainer>
  )
}

const ChainSelectorContainer = styled.div.attrs({className: 'chain-selector-container'})`
  display: flex;
  gap: 4px;
  padding: 8px;
  box-sizing: border-box;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #0000000D;
  border-radius: 16px;
  width: 100%;
  align-items: center;
`

interface ChainSelectableWrapperProps {
  selected: boolean;
  selected_color?: string;
}

const ChainSelectableWrapper = styled.div<ChainSelectableWrapperProps>`
  display: flex;
  border-radius: 12px;
  padding: 4px;
  gap: 4px;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) => (props.selected ? `#${props.selected_color ? props.selected_color : 'f3f3f3'}` : "transparent")};
  cursor: pointer;

  -webkit-tap-highlight-color: transparent;
  outline: none;

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }
`

const ChainAll = styled.span`
  font: 600 12px 'Plus Jakarta Sans', sans-serif;
  line-height: 16px;
  margin: 4px 6px;
`

const ChainIconArea = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  overflow-x: auto;
  scroll-behavior: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const ChainIcon = styled.img`
  border-radius: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  height: 24px;
`

const ChainName = styled.span`
  font: 500 12px "Plus Jakarta Sans", sans-serif;
  line-height: 20px;
  color: #595959;
`

const MoreChainIcon = styled.img`
  object-position: center;
  cursor: pointer;
`

export default AssetChainSelector;
