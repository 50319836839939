import React, {useRef} from "react";
import styled from "styled-components";
import SwapBridgeInput, {SwapBridgeInputProps} from "./SwapBridgeInput";
import SwapBridgeOutput, {SwapBridgeOutputProps} from "./SwapBridgeOutput";

interface SwapBridgeInOutProps {
  input: SwapBridgeInputProps;
  output: SwapBridgeOutputProps;
}

const SwapBridgeInOut: React.FC<SwapBridgeInOutProps> = ({
                                                           input,
                                                           output
                                                         }) => {
  return (
    <Container>
      <SwapInputArea>
        <SwapBridgeInput {...input} />
        <SwappingIcon src='/icons/ic_swap.svg' fetchPriority='high'/>
      </SwapInputArea>
      <SwapBridgeOutput {...output} />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`

const SwapInputArea = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const SwappingIcon = styled.img`
  position: absolute;
  left: 50%;
  top: 100%;
  width: 48px;
  height: 48px;
  border: 1px solid #00000014;
  border-radius: 100%;
  object-fit: none;
  object-position: center;
  background-color: #fff;
  transform: translate(-50%, -50%);
`

export default SwapBridgeInOut;
