import React from "react";
import styled from "styled-components";
import {
   AlertNotifyModel,
   NFAlertGasFeeData,
} from "../../../../models/notification/AlertNotifyModel";

export interface AlertGasFeeItemProps {
   item: AlertNotifyModel<NFAlertGasFeeData>;
}

const AlertGasFeeItem: React.FC<AlertGasFeeItemProps> = ({
   item
}) => {
   return (
      <ContentWrapper>
         <AlertIcon src={item.logo} />

         <DetailContainer>
            <TitleText>{item.title}</TitleText>
            <DescriptionText>{item.description}</DescriptionText>
         </DetailContainer>

      </ContentWrapper>
   )
};

const ContentWrapper = styled.div`
   display: flex;
   flex-direction: row;
   box-sizing: border-box;
   width: 100%;
   padding: 16px;
   gap: 8px;
   background-color: #FFF7E6;
`;

const AlertIcon = styled.img`
   display: flex;
   width: 40px;
   height: 40px;
   border-radius: 100%; 
`;

const DetailContainer = styled.div`
   display: flex;
   flex-direction: column;
   font-family: 'Plus Jakarta Sans', sans-serif;
   gap: 12px;
`;

const TitleText = styled.span`
   font-weight: 600;
   font-size: 14px;
   color: #000000;
`;

const DescriptionText = styled.span`
   font-weight: 400;
   font-size: 12px;
   color: #595959;
`;

export default AlertGasFeeItem;