import React, {useState, useRef, useEffect} from "react";
import styled from "styled-components";
import WorkflowScreen, {WorkflowProps} from "../../common/WorkflowScreen";
import OnboardingTitle from "../../../../components/onboarding/OnboardingTitle";

const VerifyOTPScreen: React.FC<WorkflowProps> = ({
                                                    onNext
                                                  }) => {
  const [values, setValues] = useState<string[]>(new Array(6).fill(""));
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [inputItemSize, setInputItemSize] = useState<number>(0);

  useEffect(() => {
    const handleResize = () => {
      setInputItemSize(calculateInputItemSize());
    };
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const calculateInputItemSize = () => {
    const viewportWidth = window.innerWidth;
    const gap = 10;
    const itemCount = 6;
    const maxSize = 48;

    const calculatedWidth = Math.min((viewportWidth - gap * (itemCount - 1)) / itemCount, maxSize);
    console.log('calculatedWidth: ', calculatedWidth);
    return Math.round(calculatedWidth);
  };

  const handleInputChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {value} = e.target;

    // Only allow input of numbers (0-9)
    if (/^[0-9]$/.test(value)) {
      const newValues = [...values];
      newValues[index] = value;
      setValues(newValues);
      checkAllFieldsFilled(newValues);

      // Move the cursor to the next field
      if (value && index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleInputKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Backspace" && !values[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    } else if (e.key === "ArrowLeft" && index > 0) {
      inputRefs.current[index - 1]?.focus();
    } else if (e.key === "ArrowRight" && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleInputPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData("text").slice(0, 6);
    // Only allow input of numbers (0-9)
    if (/^[0-9]$/.test(pasteData)) {
      const newValues = pasteData.split("");
      setValues(newValues);
      checkAllFieldsFilled(newValues);

      // Move the cursor to the last field
      inputRefs.current[5]?.focus();
    }
  };

  const checkAllFieldsFilled = (newValues: string[]) => {
    const allFieldsFilled = newValues.every((val) => val !== '');
    setIsButtonDisabled(!allFieldsFilled);
  };

  const handleNext = () => {
    onNext();
  }

  return (
    <WorkflowScreen>
      <OTPScreenContainer>
        <LockIcon src="/icons/ic_lock.svg" fetchPriority="high" />

        <TextWrapper>
          <TitleText>Enter OTP</TitleText>
          <DescriptionText>
            Please, enter the code we just sent to
            <EmailText>hungmn@vng.com</EmailText>
          </DescriptionText>
        </TextWrapper>

        <InputFormContainer>
          {values.map((value, index) => (
            <InputForm
              itemSize={inputItemSize}
              key={index}
              type="number"
              pattern="[0-9]*"
              maxLength={1}
              value={value}
              onChange={(e) => handleInputChange(index, e)}
              onPaste={(e) => handleInputPaste(e)}
              onKeyDown={(e) => handleInputKeyDown(index, e)}
              ref={(el) => (inputRefs.current[index] = el!)}
            />
          ))}
        </InputFormContainer>

        <ExpiredTimeContainer>
          <ExpiredTimeText>
            After 30s, the OTP code will be changed.
          </ExpiredTimeText>
          <ExpiredTimeCounter>
            26s
          </ExpiredTimeCounter>
        </ExpiredTimeContainer>

        <EnterOTPButton
          disabled={isButtonDisabled}
          onClick={handleNext}
        >
          Enter OTP
        </EnterOTPButton>
      </OTPScreenContainer>
    </WorkflowScreen>
  );
};

interface OTPScreenContainerProps {
}

const OTPScreenContainer = styled.div<OTPScreenContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 24px 16px;
  background-color: #fff;
  box-sizing: border-box;
  gap: 24px;
`;

const LockIcon = styled.img`
  width: 96px;
  height: 96px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const TitleText = styled.span`
  color: #262626;
  font: 700 24px "Plus Jakarta Sans", sans-serif;
  line-height: 32px;
`;

const DescriptionText = styled.span`
  color: #8c8c8c;
  font: 400 14px "Plus Jakarta Sans", sans-serif;
  line-height: 22px;
  text-align: center;
`;

const EmailText = styled.span`
  display: block;
  color: #262626;
  font: 400 14px "Plus Jakarta Sans", sans-serif;
  margin-top: 4px;
`;

const InputFormContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const InputForm = styled.input<{ itemSize: number }>`
  width: ${(props) => props.itemSize}px;
  height: ${(props) => props.itemSize}px;
  padding: 0px;
  text-align: center;
  font: 700 24px "Plus Jakarta Sans", sans-serif;
  border-radius: 16px;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  outline: none;
  appearance: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &:focus {
    border: 1px solid #262626;
    caret-color: #d9d9d9;
  }
`;

const ExpiredTimeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ExpiredTimeText = styled.span`
  color: #8c8c8c;
  font: 400 14px "Plus Jakarta Sans", sans-serif;
`;

const ExpiredTimeCounter = styled.span`
  color: #ff4d4f;
  font: 600 16px "Plus Jakarta Sans", sans-serif;
`;

const EnterOTPButton = styled.button`
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: #262626;
  color: #fff;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  border-style: none;

  &:disabled {
    background-color: #D9D9D9;
    color: #8C8C8C;
    cursor: not-allowed;
  }
`;

export default VerifyOTPScreen;
