import {ethers} from "ethers";
import {CryptoNetworkType} from "../../../enums/Web3Enums";

export const getNativeAddress = (ns: string) => {
  switch (ns) {
    case "ethereum":
      return ethers.ZeroAddress;
    case "solana":
      return 'So11111111111111111111111111111111111111112'
    case "ton":
      return ethers.ZeroAddress;
    default:
      return '';
  }
}

export const convertNsFromNetwork = (network: string) => {
  switch (network) {
    case CryptoNetworkType.EVM:
      return "ethereum";
    case CryptoNetworkType.SOL:
      return "solana";
    case CryptoNetworkType.TON:
      return "ton";
    default:
      return "";
  }
}

export const TransitSwapHelper = {
  getNativeAddress,
  convertNsFromNetwork,
}
