import React from "react";
import styled from "styled-components";

interface SearchBarProps {
  placeholder: string;
  searchText?: string;
  onChangeTextSearch?: (textSearch: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
                                               placeholder,
                                               searchText,
                                               onChangeTextSearch
                                             }) => {

  const handleChangeTextSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    if (onChangeTextSearch) {
      onChangeTextSearch(searchText.toUpperCase());
    }
  }

  return (
    <SearchContainer>
      <SearchIcon src="/icons/ic_search.svg"/>
      <SearchInput
        type="text"
        value={searchText}
        placeholder={placeholder}
        onChange={handleChangeTextSearch}
      />
    </SearchContainer>
  )
}

const SearchContainer = styled.div.attrs({className: "search-bar-container"})`
  display: flex;
  align-items: center;
  border-radius: 16px;
  background-color: #fff;
  gap: 2px;
  padding: 0px 16px;
  width: 100%;
  box-sizing: border-box;
`;

const SearchIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const SearchInput = styled.input`
  width: 100%;
  background: transparent;
  border: none;
  font: 400 12px 'Plus Jakarta Sans', sans-serif;
  color: #262626;
  height: 40px;
  flex: 1;

  -webkit-tap-highlight-color: transparent;

  &::placeholder {
    color: #8c8c8c;
  }

  &:focus {
    outline: none;
  }
`;

export default SearchBar;
