import React, {useEffect, useState} from "react";
import styled from "styled-components";
import PassphraseWordContainer from "./PassphraseWordContainer";
import CheckBox from "../../common/CheckBox";
import {PassphraseWordAmountType} from "../../../enums/WalletEnums";

interface PassphraseShowingProps {
  words_12?: string[];
  words_24?: string[];
  editable?: boolean;
  blur?: boolean;
  onChangeWordAmount?: (wordAmount: PassphraseWordAmountType) => void;
  onUpdateWord?: (index: number, word: string) => void;
  onPaste?: (index: number, text: string) => void;
  onUnblur?: () => void;
}

const PassphraseShowing: React.FC<PassphraseShowingProps> = ({
                                                               words_12,
                                                               words_24,
                                                               editable,
                                                               blur,
                                                               onChangeWordAmount,
                                                               onUpdateWord,
                                                               onPaste
                                                             }) => {
  const [checkWord_12, setCheckWord_12] = useState<boolean>(true);
  const [checkWord_24, setCheckWord_24] = useState<boolean>(false);
  const [wordAmount, setWordAmount] = useState<number>(PassphraseWordAmountType._12);

  useEffect(() => {
    if (checkWord_12) setWordAmount(PassphraseWordAmountType._12);
    if (checkWord_24) setWordAmount(PassphraseWordAmountType._24);
  }, [checkWord_12, checkWord_24])

  useEffect(() => {
    if (onChangeWordAmount) onChangeWordAmount(wordAmount);
  }, [wordAmount]);

  const handleChangeCheckWordAmount = (wordAmount: PassphraseWordAmountType) => {
    switch (wordAmount) {
      case PassphraseWordAmountType._12:
        setCheckWord_12(true);
        setCheckWord_24(false);
        break;
      case PassphraseWordAmountType._24:
        setCheckWord_12(false);
        setCheckWord_24(true);
        break;
    }
  }

  return (
    <PassphraseContainer>
      <WordAmountSelectionArea>
        <WordAmountSelectionItem
          check={checkWord_12}
          wordAmount={PassphraseWordAmountType._12}
          onCheck={() => {
            handleChangeCheckWordAmount(PassphraseWordAmountType._12)
          }}
        />
        <WordAmountSelectionItem
          check={checkWord_24}
          wordAmount={PassphraseWordAmountType._24}
          onCheck={() => {
            handleChangeCheckWordAmount(PassphraseWordAmountType._24)
          }}
        />
      </WordAmountSelectionArea>
      <PassphraseWordContainer
        wordAmount={wordAmount}
        words={words_12 && words_24 && wordAmount === PassphraseWordAmountType._12 ? words_12 : words_24}
        editable={editable}
        blur={blur}
        onUpdateWord={onUpdateWord}
        onPaste={onPaste}
      />
    </PassphraseContainer>
  )
}

const PassphraseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  flex-shrink: 0;
  font-family: 'Plus Jakarta Sans', sans-serif;
`

const WordAmountSelectionArea = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`


interface WordAmountSelectionItem {
  check?: boolean;
  wordAmount: number;
  onCheck: () => void;
}

const WordAmountSelectionItem: React.FC<WordAmountSelectionItem> = ({
                                                                      check,
                                                                      wordAmount,
                                                                      onCheck
                                                                    }) => {
  return (
    <WordAmountSelectionItemContainer onClick={onCheck}>
      <CheckBox check={check}/>
      <WorldAmountTitle>{`${wordAmount} words`}</WorldAmountTitle>
    </WordAmountSelectionItemContainer>
  )
}

const WordAmountSelectionItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`

const WorldAmountTitle = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`

export default PassphraseShowing;
