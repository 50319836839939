import React, {useContext, useEffect, useState} from "react";
import WorkflowScreen from "../../onboarding/common/WorkflowScreen";
import SendFromInput from "./components/SendFromInput";
import styled from "styled-components";
import {AssetToken, CryptoChain} from "../../../models/web3/Web3Model";
import SendToInput from "./components/SendToInput";
import {BizContext} from "../../../contexts/BizContext";
import {useLocation, useNavigate} from "react-router-dom";
import {AccountInfo} from "../../../models/home/HomeModel";
import SearchChainBottomSheet from "../../../components/bottomsheets/search_chain/SearchChainBottomSheet";
import SearchAssetTokenBottomSheet
  from "../../../components/bottomsheets/search_asset_token/SearchAssetTokenBottomSheet";
import {AssetTokenItemShowType} from "../../../components/home/AssetTokenList";
import ServiceManagerIns from "../../../services/ServiceManager";

enum NextButtonTitleType {
  Normal = 'Next',
  InvalidRecipient = "Invalid recipient",
  Insufficient = 'Insufficient Balance',
}

interface SendActionScreenState {
  accountInfo: AccountInfo
}

const SendActionScreen: React.FC = () => {
  const bizContext = useContext(BizContext);
  if (!bizContext) {
    throw new Error("Context isn't existed");
  }
  const sendBizModel = bizContext.sendBizModel;
  const navigate = useNavigate();
  const location = useLocation();
  const {accountInfo} = location.state as SendActionScreenState;

  const [showSearchBTSToken, setShowSearchBTSToken] = useState<boolean>(false);
  const [showSearchBTSChain, setShowSearchBTSChain] = useState<boolean>(false);
  const [chainList, setChainList] = useState<CryptoChain[]>([]);
  const [selectedChain, setSelectedChain] = useState<CryptoChain | undefined>(undefined);
  const [selectedToken, setSelectedToken] = useState<AssetToken>();
  const [inputAmountInETH, setInputAmountInETH] = useState<number | undefined>(undefined);
  const [inputQuote, setInputQuote] = useState<number>(0);
  const [curToAddressInputText, setCurToAddressInputText] = useState<string>('');
  const [enableNextButton, setEnableNextButton] = useState<boolean>(false);
  const [nextButtonTitle, setNextButtonTitle] = useState<NextButtonTitleType>(NextButtonTitleType.Normal);
  const [isValidToAddress, setIsValidToAddress] = useState<boolean>(false);

  useEffect(() => {
    const fetchChainList = async () => {
      const _chainList = await sendBizModel.getSupportedChains();
      setChainList(_chainList);
    }

    const fetchData = async () => {
      await fetchChainList();
    }

    fetchData();
  }, [])

  useEffect(() => {
    if (chainList.length > 0) {
      const setDefaultChain = async () => {
        const defaultChain = await sendBizModel.getDefaultSelectedChain(chainList);
        setSelectedChain(defaultChain);
      }
      setDefaultChain();
    } else {
      setSelectedChain(undefined);
    }
  }, [chainList]);

  useEffect(() => {
    if (!selectedChain) {
      setSelectedToken(undefined);
      return;
    }

    const setDefaultToken = async () => {
      const defaultToken = await sendBizModel.getDefaultSelectedToken(selectedChain);
      setSelectedToken(defaultToken);
    }
    setDefaultToken();
  }, [selectedChain]);

  useEffect(() => {
    if (!inputAmountInETH || !selectedToken) {
      setInputQuote(0);
      return;
    }

    const fetchQuote = async () => {
      const tokenPriceList = await ServiceManagerIns.apiService.web3.getTokenPriceList([{
        address: selectedToken.address,
        chainId: selectedToken.chain.id
      }]);

      const tokenPrice = Number(tokenPriceList[0]) || 0;
      const inputQuote = inputAmountInETH * tokenPrice;
      setInputQuote(inputQuote);
    };

    fetchQuote();
  }, [inputAmountInETH, selectedToken]);

  useEffect(() => {
    if (!selectedToken || !inputAmountInETH) {
      return;
    }

    if (selectedToken.balance.amountInETH < inputAmountInETH) {
      setNextButtonTitle(NextButtonTitleType.Insufficient);
    } else if (!isValidToAddress) {
      setNextButtonTitle(NextButtonTitleType.InvalidRecipient);
    } else {
      setNextButtonTitle(NextButtonTitleType.Normal);
    }
  }, [selectedToken, inputAmountInETH, isValidToAddress]);

  useEffect(() => {
    if (!selectedChain || !curToAddressInputText) {
      setIsValidToAddress(false);
      return;
    }

    setIsValidToAddress(sendBizModel.checkValidToAddress(selectedChain, curToAddressInputText));
  }, [selectedChain, curToAddressInputText]);

  useEffect(() => {
    const _enableNextButton = Boolean(
      selectedChain
      && selectedToken
      && inputAmountInETH
      && inputAmountInETH <= selectedToken.balance.amountInETH
      && isValidToAddress);

    setEnableNextButton(_enableNextButton);
  }, [selectedChain, selectedToken, isValidToAddress, inputAmountInETH]);

  // BTS State
  const handleShowBTSSearchToken = () => {
    setShowSearchBTSToken(true);
  }
  const handleHideBTSSearchToken = () => {
    setShowSearchBTSToken(false);
  }
  const handleShowBTSSearchChain = () => {
    setShowSearchBTSChain(true);
  }
  const handleHideBTSSearchChain = () => {
    setShowSearchBTSChain(false);
  }

  // From wallet
  const handleSelectChain = (chain?: CryptoChain) => {
    if (chain === selectedChain) {
      return;
    }
    setSelectedToken(undefined);
    setSelectedChain(chain);
  }

  const handleSelectAssetToken = (token: AssetToken) => {
    setSelectedToken(token);
  }

  const handleChangeAmountInValue = (amountInETH: number) => {
    setInputAmountInETH(amountInETH);
  }

  // To wallet
  const handleChangeToAddress = (address: string) => {
    setCurToAddressInputText(address);
  }

  // Next action
  const handleNextAction = () => {
    navigate('/send_preview', {
      state: {
        accountInfo: accountInfo,
        chain: selectedChain,
        assetToken: selectedToken,
        amount: inputAmountInETH,
        quote: inputQuote,
        toAddress: curToAddressInputText,
      }
    });
  }

  return (
    <WorkflowScreen title="Send">
      <Container>
        <ScrollableWrapper>
          <SendFromToContainer>
            <SendFromInput
              chainList={chainList}
              selectedChain={selectedChain}
              selectedAssetToken={selectedToken}
              inputQuote={inputQuote}
              onClickSearchChain={handleShowBTSSearchChain}
              onClickSearchToken={handleShowBTSSearchToken}
              onSelectChain={handleSelectChain}
              onSelectAssetToken={handleSelectAssetToken}
              onChangeInputAmountInETH={handleChangeAmountInValue}
            />
            <SendToInput
              addressText={curToAddressInputText}
              onChangeAddress={handleChangeToAddress}
            />
          </SendFromToContainer>

          <NextButton
            disabled={!enableNextButton}
            onClick={handleNextAction}
          >
            <NextButtonText className={enableNextButton ? 'enable_next_btn' : 'disable_next_btn'}>
              {nextButtonTitle}
            </NextButtonText>
          </NextButton>
        </ScrollableWrapper>

        {
          showSearchBTSChain &&
          <SearchChainBottomSheet
            onSelectChain={handleSelectChain}
            onClickClose={handleHideBTSSearchChain}
          />
        }
        {
          showSearchBTSToken &&
          <SearchAssetTokenBottomSheet
            targetChain={selectedChain}
            showNoBalance={true}
            itemShowType={AssetTokenItemShowType.OnlyBalance}
            onSelectToken={handleSelectAssetToken}
            forceCloseWhenSelect={true}
            onClose={handleHideBTSSearchToken}
          />
        }
      </Container>
    </WorkflowScreen>
  )
};

const Container = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  overflow-y: hidden;
  background-color: #f3f3f3;
`;

const ScrollableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 16px 16px 24px 16px;
  gap: 16px;
  box-sizing: border-box;
  scroll-behavior: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const SendFromToContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`;

const NextButton = styled.button`
  padding: 12px 24px;
  border-radius: 16px;
  background-color: #262626;
  width: 100%;
  cursor: pointer;
  border-style: none;
  gap: 8px;

  &:disabled {
    background-color: #d9d9d9;
    cursor: not-allowed;
  }
`;

const NextButtonText = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fff;

  &.disable_next_btn {
    color: #8c8c8c;
  }
`;

export default SendActionScreen;
