import React from "react";
import styled from "styled-components";
import { NFCTAButton } from "../../../models/notification/NotifyModel";

export interface NotifyCTAButtonProps {
   info: NFCTAButton;
}

const NotifyCTAButton: React.FC<NotifyCTAButtonProps> = ({
   info
}) => {
   return (
      <ContentWrapper>
         <ButtonTitle>{info.title}</ButtonTitle>
      </ContentWrapper>
   )
};

const ContentWrapper = styled.div`
   display: flex;
   align-items: center;
   max-width: fit-content;
   padding: 8px 16px;
   box-sizing: border-box;
   font-family: 'Plus Jakarta Sans', sans-serif;
   background-color: #262626;
   border-radius: 8px;
`;

const ButtonTitle = styled.span`
   font-weight: 500;
   font-size: 12px;
   line-height: 16px;
   color: #FFFFFF;
`;

export default NotifyCTAButton;
