import React, { useRef, useState } from "react";
import styled from "styled-components";

export interface SendToInputProps {
   addressText: string;
   onChangeAddress?: (address: string) => void;
}

const TextAreaIcon = {
   address: {
      default: '/icons/ic_textarea_copy.svg',
      remove: '/icons/ic_close.svg'
   },
   comment: {
      default: '/icons/ic_write_fb.svg',
      remove: '/icons/ic_close.svg'
   }
}

const SendToInput: React.FC<SendToInputProps> = ({
   addressText,
   onChangeAddress,
}) => {
   const addressRef = useRef<HTMLTextAreaElement | null>(null);
   const [addressRightIcon, setAddressRightIcon] = useState<string>(TextAreaIcon.address.default);

   const handleChangeAddress = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const _curAddress = e.currentTarget.value;
      setAddressRightIcon((_curAddress === '') ? TextAreaIcon.address.default : TextAreaIcon.address.remove);
      if (onChangeAddress) onChangeAddress(_curAddress);
   }

   const handleRemoveAddress = () => {
      const removeEvent = { currentTarget: { value: '' } } as React.ChangeEvent<HTMLTextAreaElement>;
      handleChangeAddress(removeEvent);
   }

   return (
      <Container>
         <SendIcon src='/icons/ic_arrow_down_2.svg' fetchPriority='high' />

         <Title>To</Title>
         <TextAreaContainer>
            <TextArea
               ref={addressRef}
               placeholder={`Enter address, domain or Telegram User`}
               value={addressText}
               onChange={handleChangeAddress}
            />
            <TextRightIcon src={addressRightIcon} onClick={handleRemoveAddress} />
         </TextAreaContainer>
      </Container>
   )
}

const Container = styled.div`
   position: relative;
   display: flex;
   flex-direction: column;
   border-radius: 16px;
   background-color: #fff;
   width: 100%;
   font-family: 'Plus Jakarta Sans', sans-serif;
   padding: 16px;
   box-sizing: border-box;
   gap: 8px;
   word-wrap: break-word;
   word-break: break-all;
   border: none;
`;

const SendIcon = styled.img`
   position: absolute;
   width: 48px;
   height: 48px;
   border: 4px solid #00000014;
   border-radius: 100%;
   object-fit: none;
   align-self: center;
   background-color: #fff;
   margin-top: -16px;
   transform: translateY(-50%);
`;

const Title = styled.span`
   font-weight: 600;
   font-size: 14px;
   line-height: 22px;
   width: 100%;
`;

const TextAreaContainer = styled.div`
   position: relative;
   display: flex;
   align-items: center;
   width: 100%;
`;

const TextArea = styled.textarea`
   font-family: 'Plus Jakarta Sans', sans-serif;
   font-weight: 400;
   font-size: 12px;
   line-height: 18px;
   box-sizing: border-box;
   border-radius: 16px;
   border-width: 1px;
   border-color: #E8E8E8;
   color: #595959;
   padding: 13px 32px 13px 16px;
   background: none;
   cursor: pointer;
   width: 100%;
   resize: none;

   -webkit-tap-highlight-color: transparent;

   &::-webkit-outer-spin-button,
   &::-webkit-inner-spin-button {
     -webkit-appearance: none;
   }

   &:focus {
     outline: none;
   }

   &::placeholder {
     color: #8c8c8c;
   }
`;

const TextRightIcon = styled.img`
   position: absolute;
   width: 16px;
   height: 16px;
   right: 12px;
`;

export default SendToInput;
