import React from "react";
import { AssetToken, CryptoChain } from "../../../../models/web3/Web3Model";
import styled from "styled-components";
import { AccountInfo } from "../../../../models/home/HomeModel";

export interface SendTokenAmountProps {
   accountInfo: AccountInfo;
   walletAddress: string;
   assetToken: AssetToken;
   amount: number;
   quote: number;
}

const SendTokenAmountPreview: React.FC<SendTokenAmountProps> = ({
   accountInfo,
  walletAddress,
   assetToken,
   amount,
   quote,
}) => {
   return (
      <Container>
         <TokenInfoContainer>
            <TokenLogo src={assetToken.logo} />
            <TokenName>{assetToken.name}</TokenName>
            <AccountInfoArea>
               <AccountName>{accountInfo.accountName}</AccountName>
               <AccountAddress>{`${walletAddress.slice(0,6)}...${walletAddress.slice(-6)}`}</AccountAddress>
            </AccountInfoArea>
         </TokenInfoContainer>

         <TokenValueContainer>
            <TokenAmount>{parseFloat(amount.toFixed(6))}</TokenAmount>
            <TokenQuote>${parseFloat(quote.toFixed(6))}</TokenQuote>
         </TokenValueContainer>

         <SeparatorLine />
      </Container>
   )
}

const Container = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   gap: 16px;
`;

const TokenInfoContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 4px;
`;

const TokenLogo = styled.img`
   width: 64px;
   height: 64px;
  border-radius: 100%;
`;

const TokenName = styled.span`
   font-weight: 600;
   font-size: 16px;
   line-height: 24px;
   color: #262626;
`;

const AccountInfoArea = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   gap: 4px;
`;

const AccountName = styled.span`
   font-weight: 400;
   font-size: 14px;
   line-height: 22px;
   color: #595959;
`;

const AccountAddress = styled.span`
   font-weight: 400;
   font-size: 12px;
   line-height: 18px;
   color: #8c8c8c;
`;

const TokenValueContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   gap: 8px;
`;

const TokenAmount = styled.span`
   font-weight: 700;
   font-size: 36px;
   line-height: 40px;
   color: #262626;
  text-align: center;
`;

const TokenQuote = styled.span`
   font-weight: 400;
   font-size: 14px;
   line-height: 22px;
   color: #595959;
`;

const SeparatorLine = styled.div`
   width: 100%;
   height: 1px;
   background-color: #f3f3f3;
`;

export default SendTokenAmountPreview;
