import React from "react";
import styled from "styled-components";
import GenerateWalletStepItem from "./GenerateWalletStepItem";
import {GenerateWalletStepType} from "../../../enums/WalletEnums";

interface GenerateWalletStepListProps {
  currentSteps: GenerateWalletStepType[];
}

const GenerateWalletStepList: React.FC<GenerateWalletStepListProps> = ({
                                                                         currentSteps
                                                                       }) => {
  return (
    <Container>
      {
        currentSteps.map((step, index) => {
          switch (step) {
            case GenerateWalletStepType.StartCreate:
              return <GenerateWalletStepItem key={step} icon={'/icons/ic_generate_wallet_step_1.svg'}
                                             title={"Creating your new wallet..."}
                                             loading={index === currentSteps.length - 1}
              />
            case GenerateWalletStepType.StartImport:
              return <GenerateWalletStepItem key={step} icon={'/icons/ic_generate_wallet_step_1.svg'}
                                             title={"Checking your wallet on the blockchain..."}
                                             loading={index === currentSteps.length - 1}
              />
            case GenerateWalletStepType.Encrypt:
              return <GenerateWalletStepItem key={step} icon={'/icons/ic_generate_wallet_step_2.svg'}
                                             title={"Encrypting your wallet..."}
                                             loading={index === currentSteps.length - 1}
              />
            case GenerateWalletStepType.SuggestBackup:
              return <GenerateWalletStepItem key={step} icon={'/icons/ic_generate_wallet_step_3.svg'}
                                             title={"Retrieving data from all blockchain networks..."}
                                             loading={index === currentSteps.length - 1}
              />
            case GenerateWalletStepType.Done:
              return <GenerateWalletStepItem key={step} icon={'/icons/ic_generate_wallet_step_4.svg'}
                                             title={"All done! Your wallet is ready"}
              />
            default:
              return;
          }
        })
      }
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 24px;
  align-items: flex-start;
  overflow-y: auto;
  scroll-behavior: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export default GenerateWalletStepList;
