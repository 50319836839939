import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";

interface PassphraseWordContainerProps {
  wordAmount: number;
  words?: string[];
  editable?: boolean;
  blur?: boolean;
  onUpdateWord?: (index: number, word: string) => void;
  onPaste?: (index: number, text: string) => void;
  onUnblur?: () => void;
}

const PassphraseWordContainer: React.FC<PassphraseWordContainerProps> = ({
                                                                           wordAmount,
                                                                           words,
                                                                           editable,
                                                                           blur,
                                                                           onUpdateWord,
                                                                           onPaste,
                                                                           onUnblur
                                                                         }) => {
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const [wordItemWidth, setWorkItemWidth] = useState<number>(0);
  const [innerBlur, setInnerBlur] = useState<boolean>(false);

  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!parentRef.current) {
      return;
    }
    const parentWidth = parentRef.current.offsetWidth;
    const itemsPerLine = 3;
    const gapItem = 16;
    const _wordItemWidth = (parentWidth - gapItem * (itemsPerLine - 1)) / itemsPerLine;
    setWorkItemWidth(_wordItemWidth);
  }, [parentRef]);

  useEffect(() => {
    setRefreshKey(refreshKey => refreshKey + 1);
  }, [words]);

  useEffect(() => {
    if (blur) setInnerBlur(blur);
  }, [blur]);

  const handleUnblur = () => {
    setInnerBlur(false);
    if (onUnblur) onUnblur();
  }

  return (
    <Container
      key={refreshKey}
      ref={parentRef}
      // className={innerBlur ? "disabled" : ""}
    >
      {
        Array(wordAmount).fill('').map((_, index) => (
          <WordItem
            key={index}
            index={index + 1}
            word={words && words.length > index ? words[index] : ''}
            width={wordItemWidth}
            editable={editable}
            onUpdateWord={(word) => {
              onUpdateWord && onUpdateWord(index, word);
            }}
            onPaste={(text) => {
              onPaste && onPaste(index, text);
            }}
          />
        ))
      }
      {
        innerBlur &&
        <BlurArea onClick={handleUnblur}>
          <BlurBackdrop/>
          <BlurIcon src="/icons/ic_visible.svg"/>
        </BlurArea>
      }
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  row-gap: 16px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
  
  &.disabled {
    overflow-y: hidden;
  }
`

const BlurArea = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  cursor: pointer;
`

const BlurBackdrop = styled.div`
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px); /* Thiết lập độ mờ */
  -webkit-backdrop-filter: blur(3px);
`

const BlurIcon = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 48px;
  height: 48px;
  object-fit: contain;
  object-position: center;
  transform: translate(-50%, -50%);
`

interface WordItemProps {
  index: number;
  word: string;
  width: number;
  editable?: boolean
  onUpdateWord?: (word: string) => void;
  onPaste?: (text: string) => void;
}

const WordItem: React.FC<WordItemProps> = ({
                                             index,
                                             word,
                                             width,
                                             editable,
                                             onUpdateWord,
                                             onPaste
                                           }) => {
  const [innerWord, setInnerWord] = useState<string>(word);
  useEffect(() => {
    setInnerWord(innerWord);
  }, [word])

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _word = e.currentTarget.value;
    setInnerWord(_word);
    console.log(_word);
    if (onUpdateWord) onUpdateWord(_word);
  }

  const handlePasteText = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteText = e.clipboardData.getData('text');
    e.stopPropagation();
    e.preventDefault();
    if (pasteText && onPaste) onPaste(pasteText);
  }

  return (
    <WordItemContainer width={width}>
      <WordIndex>{`${index}.`}</WordIndex>
      <Word
        type="text"
        value={innerWord}
        disabled={!editable}
        onChange={handleChangeText}
        onPaste={handlePasteText}
      />
    </WordItemContainer>
  )
}

const WordItemContainer = styled.div<{ width: number }>`
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #e8e8e8;
  line-height: 22px;
  width: ${props => props.width}px;
  min-width: 108px;
  padding: 14px 12px;
  gap: 2px;
  box-sizing: border-box;
`

const WordIndex = styled.span`
  font: 600 14px 'Plus Jakarta Sans', sans-serif;
  color: #bfbfbf;
`

const Word = styled.input`
  //position: fixed;
  color: #262626;
  width: 100%;
  border: none;
  font: 600 14px 'Plus Jakarta Sans', sans-serif;
  padding: 0;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }
  
  &:disabled {
    color: #262626;
    background-color: transparent;
    opacity: 1;
  }
`

export default PassphraseWordContainer;
