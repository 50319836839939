import React, {useEffect, useRef} from "react";
import styled from "styled-components";

interface SwapBridgeReceiverProps {
  defaultAddress: string;
  inputText: string;
  isValid: boolean;
  onChangeAddress?: (address: string) => void;
}

const SwapBridgeReceiver: React.FC<SwapBridgeReceiverProps> = ({
                                                                 defaultAddress,
                                                                 inputText,
                                                                 isValid,
                                                                 onChangeAddress
                                                               }) => {

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    const textarea = textareaRef.current;

    const handleInput = () => {
      if (textarea) {
        textarea.style.height = 'auto'; // Reset chiều cao
        textarea.style.height = `${textarea.scrollHeight}px`; // Thiết lập chiều cao mới theo nội dung
      }
    };

    if (textarea) {
      textarea.addEventListener('input', handleInput);
    }

    // Cleanup event listener khi component unmounts
    return () => {
      if (textarea) {
        textarea.removeEventListener('input', handleInput);
      }
    };
  }, []);

  const handleChangeAddress = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChangeAddress) onChangeAddress(e.currentTarget.value);
  }

  return (
    <Container className={isValid ? '' : 'invalid'}>
      <Title>Receiver address</Title>
      <ReceiverTextArea
        ref={textareaRef}
        placeholder={defaultAddress}
        value={inputText}
        onChange={handleChangeAddress}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: #fff;
  width: 100%;
  font-family: 'Plus Jakarta Sans', sans-serif;
  padding: 16px;
  box-sizing: border-box;
  gap: 8px;
  word-wrap: break-word;
  word-break: break-all;
  border: none;
  
  &.invalid {
    border: 1px solid #F5222D;
  }
`

const Title = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  width: 100%;
`

const ReceiverTextArea = styled.textarea`
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  resize: none;

  -webkit-tap-highlight-color: transparent;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #BFBFBF;
  }
`


export default SwapBridgeReceiver;
