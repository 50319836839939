import {AccountWalletInfo} from "../../models/wallet/AccountModel";
import ServiceManagerIns from "../ServiceManager";
import {WalletBalanceDetail} from "../../models/wallet/WalletModel";

export interface IAccountCache {
  cacheAccount(accountInfo: AccountWalletInfo): Promise<boolean>;
  cacheAccountBalance(accountId: number, accountBalance: WalletBalanceDetail): Promise<boolean>
  cacheActiveAccountId(accountId: number): Promise<boolean>;
  cacheAccountMaxIndex(maxIndex: number): Promise<boolean>;
  cleanupCachedAccount(): Promise<boolean>;

  getCachedAccounts(): Promise<AccountWalletInfo[]>;
  getCachedAccountBalance(accountId: number): Promise<WalletBalanceDetail | undefined>;
  getAccountActiveId(): Promise<number>;
  getAccountMaxId(): Promise<number>;

  reset(): Promise<boolean>;
}

export enum AccountCacheKey {
  AccountActiveId = 'account_active',
  AccountMaxId = 'account_max_id'
};

class AccountCache implements IAccountCache {
  cacheAccount(accountInfo: AccountWalletInfo): Promise<boolean> {
    return ServiceManagerIns.databaseService.accountDB.accountStore.storeAccount(accountInfo);
  }

  cacheAccountBalance(accountId: number, accountBalance: WalletBalanceDetail): Promise<boolean> {
    return ServiceManagerIns.databaseService.accountDB.accountBalanceStore.storeAccountBalance(accountId, accountBalance);
  }

  cleanupCachedAccount(): Promise<boolean> {
    return ServiceManagerIns.databaseService.accountDB.accountStore.cleanup();
  }

  cacheActiveAccountId(accountId: number): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      localStorage.setItem(AccountCacheKey.AccountActiveId, accountId.toString());
      resolve(true);
    });
  }

  cacheAccountMaxIndex(maxIndex: number): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      localStorage.setItem(AccountCacheKey.AccountMaxId, maxIndex.toString());
      resolve(true);
    });
  }

  getCachedAccounts(): Promise<AccountWalletInfo[]> {
    return ServiceManagerIns.databaseService.accountDB.accountStore.getStoreAccounts();
  }

  getCachedAccountBalance(accountId: number): Promise<WalletBalanceDetail | undefined> {
    return ServiceManagerIns.databaseService.accountDB.accountBalanceStore.getAccountBalance(accountId);
  }

  getAccountActiveId(): Promise<number> {
    return new Promise<number>(resolve => {
      const activeId = Number(localStorage.getItem(AccountCacheKey.AccountActiveId));
      resolve(activeId);
    });
  }

  getAccountMaxId(): Promise<number> {
    return new Promise<number>(resolve => {
      const maxIndex = Number(localStorage.getItem(AccountCacheKey.AccountMaxId));
      resolve(maxIndex);
    });
  }

  reset(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      localStorage.removeItem(AccountCacheKey.AccountMaxId);
      localStorage.removeItem(AccountCacheKey.AccountActiveId);

      await this.cleanupCachedAccount();

      resolve(true);
    });
  }
}

export default AccountCache;
