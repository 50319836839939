import {GenerateWalletStepType, PassphraseWordAmountType} from "../../../enums/WalletEnums";
import ServiceManagerIns from "../../../services/ServiceManager";
import {AccountWalletInfo} from "../../../models/wallet/AccountModel";

export interface IRocketLaunchBizModel {
  startRocketLaunch(onStep:(step: GenerateWalletStepType) => void): Promise<boolean>;
}

class RocketLaunchBizModel implements IRocketLaunchBizModel {
  startRocketLaunch(onStep: (step: GenerateWalletStepType) => void): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      onStep(GenerateWalletStepType.StartCreate);

      const setDefaultPwd = async () => {
        const defaultPwd = await ServiceManagerIns.defaultService.getDefaultPassword();
        const pwdHash = await ServiceManagerIns.settingService.createPassword(defaultPwd);
        return pwdHash;
      }

      const generateMnemonic = async () => {
        const mnemonic = await ServiceManagerIns.web3Service.createNewPasspharse(PassphraseWordAmountType._12);
        return mnemonic;
      }

      const encryptWallet = async (mnemonic: string, pwdHash: string) => {
        const encrMnemonic = await ServiceManagerIns.cryptoService.encryptAES(mnemonic, pwdHash);
        const accountWalletInfo = await ServiceManagerIns.accountService.createAccount({
          encrMnemonic: encrMnemonic
        });
        return accountWalletInfo;
      }

      const setActiveWallet = async (accountWalletInfo: AccountWalletInfo) => {
        await ServiceManagerIns.accountService.prepareDetailAndSetActiveAccount(accountWalletInfo);
      }

      const pwdHash = await setDefaultPwd();
      const mnemonic = await generateMnemonic();
      setTimeout(async () => {
        onStep(GenerateWalletStepType.Encrypt);
        const accountWalletInfo = await encryptWallet(mnemonic, pwdHash);
        setTimeout(async () => {
          onStep(GenerateWalletStepType.SuggestBackup);
          await setActiveWallet(accountWalletInfo);
          setTimeout(() => {
            onStep(GenerateWalletStepType.Done);
          }, 1000);
        }, 1000);
      }, 1000);
    });
  }
}

export default RocketLaunchBizModel;
