import React, {useEffect, useState} from "react";
import styled from "styled-components";
import SwapBridgeAssetSelector from "./SwapBridgeAssetSelector";
import {CryptoToken} from "../../../models/web3/Web3Model";
import {CryptoAmount} from "../../../core/wallet/models/Web3Model";

export interface SwapBridgeInputProps {
  token?: CryptoToken;
  balance?: CryptoAmount;
  inputAmountText?: string;
  quote?: number;
  showMinAmount?: boolean;
  minAmountInETH?: number;
  onSelectTokenArea: () => void;
  onChangeInputAmountInETH: (amountInETH: number) => void;
  onClickBalance: () => void;
}

const SwapBridgeInput: React.FC<SwapBridgeInputProps> = ({
                                                           token,
                                                           balance,
                                                           quote,
                                                           inputAmountText,
                                                           showMinAmount,
                                                           minAmountInETH,
                                                           onSelectTokenArea,
                                                           onChangeInputAmountInETH,
                                                           onClickBalance
                                                         }) => {
  const [innerInputAmountText, setInnerInputAmountText] = useState<string>('');

  useEffect(() => {
    setInnerInputAmountText(inputAmountText ? inputAmountText : '');
  }, [inputAmountText]);


  const handleChangeAmountValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInnerInputAmountText(e.currentTarget.value);
    const newAmountValue = parseFloat(e.currentTarget.value);
    if (onChangeInputAmountInETH) onChangeInputAmountInETH(isNaN(newAmountValue) ? 0 : newAmountValue);
  }

  return (
    <Container>
      <HeaderGroup>
        <Label>You Pay</Label>
        <Balance
          onClick={onClickBalance}>{`Balance: ${balance ? parseFloat(balance.amountInETH.toFixed(6)) : 0}`}</Balance>
        {
          showMinAmount &&
          <MinAmount>{`Min: ${minAmountInETH ? minAmountInETH : 0}`}</MinAmount>
        }
      </HeaderGroup>
      <SwapInfoWrapper>
        <TokenInputArea>
          <SwapBridgeAssetSelector
            token={token}
            onClick={onSelectTokenArea}
          />
          <AmountInput
            type="number"
            placeholder='0'
            value={innerInputAmountText}
            onChange={handleChangeAmountValue}
          />
        </TokenInputArea>
        <QuoteAmount>{`$${quote ? quote.toFixed(2) : '0.00'}`}</QuoteAmount>
      </SwapInfoWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: #fff;
  width: 100%;
  font-family: 'Plus Jakarta Sans', sans-serif;
  padding: 16px 16px 24px 16px;
  box-sizing: border-box;
  gap: 16px;
`;

const HeaderGroup = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.label`
  color: #262626;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

const Balance = styled.span`
  color: #262626;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
`;

const SwapInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const TokenInputArea = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const AmountInput = styled.input`
  color: #262626;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  padding: 0;
  background: none;
  border: none;
  text-align: right;
  cursor: pointer;
  width: 100%;
  font-family: 'Plus Jakarta Sans', sans-serif;

  -webkit-tap-highlight-color: transparent;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #BFBFBF;
  }
`;

const MinAmount = styled.label`
  position: absolute;
  color: #F5222D;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  align-self: flex-end;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
`

const QuoteAmount = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #BFBFBF;
  align-self: flex-end;
`

export default SwapBridgeInput;
