import React, {useContext} from "react";
import WorkflowScreen from "../onboarding/common/WorkflowScreen";
import styled from "styled-components";
import ServiceManagerIns from "../../services/ServiceManager";
import {TGContext} from "../../contexts/TGContext";
import {FeedbackItem} from "./components/FeedbackItem";
import {SettingItem} from "./components/SettingItem";
import {SettingSection} from "./components/SettingSection";

export const SettingScreen: React.FC = () => {
  const tgContext = useContext(TGContext);
  if (!tgContext) {
    throw ("Context isn't existed");
  }
  const {isTelegram} = tgContext;
  const handleClickReset = () => {
    const cleanup = async () => {
      await ServiceManagerIns.cleanupService.cleanupApp();
      window.location.replace('/');
    }
    cleanup();
  };

  return (
    <WorkflowScreen title="Settings">
      <SettingWrapper>
        {
          isTelegram &&
          (
            <SettingTitle>Settings</SettingTitle>
          )
        }
        <SettingSection title="Testing">
          <SettingItem
            icon="/icons/ic_delete.svg"
            title="Delete all Wallets"
            style="critical"
            onClick={handleClickReset}
          />
        </SettingSection>

        <SettingSection title="Feedback">
          <FeedbackItem />
        </SettingSection>

        <SettingSection title="General">
          <SettingItem
            icon="/icons/ic_setting_currency.svg"
            title="Currency"
            rightInfo="Dollar"
            showArrow={true}/>
          <SettingItem
            icon="/icons/ic_setting_lang.svg"
            title="Language"
            rightInfo="English"
            showArrow={true}/>
        </SettingSection>

        <SettingSection title="Security">
          <SettingItem
            icon="/icons/ic_setting_passcode.svg"
            title="Passcode"
            showArrow={true}/>
          <SettingItem
            icon="/icons/ic_setting_auto_lock.svg"
            title="Auto-lock"
            showArrow={true}/>
          <SettingItem
            icon="/icons/ic_setting_recovery.svg"
            title="Recovery Phrase"
            showArrow={true}/>
        </SettingSection>

        <SettingSection title="Smart Wallet">
          <SettingItem
            icon="/icons/ic_setting_smart_link.svg"
            title="Smart Link"
            showArrow={true}/>
          <SettingItem
            icon="/icons/ic_setting_smart_account.svg"
            title="Smart Account Restore"
            showArrow={true}/>
        </SettingSection>

        <SettingSection title="About">
          <SettingItem
            icon="/icons/ic_setting_follow.svg"
            title="Follow us"
            showArrow={true}/>
          <SettingItem
            icon="/icons/ic_setting_term.svg"
            title="Terms & Conditions"/>
        </SettingSection>
      </SettingWrapper>
    </WorkflowScreen>
  );
}

const SettingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: ${() => {
    const {safeAreaBottom} = useContext(TGContext);
    return `${safeAreaBottom}px`;
  }};
  overflow-y: auto;
  scroll-behavior: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
`

const SettingTitle = styled.span`
  font: 700 24px "Plus Jakarta Sans", sans-serif;
  line-height: 32px;
  padding: 16px 16px 6px 16px;
  box-sizing: border-box;
`
