import React, {useEffect, useState} from "react";
import styled from "styled-components";

interface CheckBoxProps {
  check?: boolean;
  allowUnCheck?: boolean;
  onCheck?: () => void;
  onUncheck?: () => void;
}

const CheckBox: React.FC<CheckBoxProps> = ({
                                             allowUnCheck,
                                             check,
                                             onCheck,
                                             onUncheck
                                           }) => {
  const [isCheck, setIsCheck] = useState<boolean>(false);
  useEffect(() => {
    if (check === isCheck) return;
    setIsCheck(check ? check : false);
  }, [check]);

  useEffect(() => {
    if (!isCheck && allowUnCheck) {
      if (onUncheck) onUncheck();
      return;
    }
    if (isCheck && onCheck) onCheck();
  }, [isCheck]);

  const handleCheck = () => {
    if (allowUnCheck) {
      setIsCheck(isCheck => !isCheck);
    } else {
      if (isCheck) return;
      setIsCheck(true);
    }
  }

  return (
    <CheckBoxIcon
      src={isCheck ? "/icons/ic_check.svg" : "/icons/ic_uncheck.svg"}
      fetchPriority="high"
      onClick={handleCheck}
    />
  )
}

const CheckBoxIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`

export default CheckBox;
