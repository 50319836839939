export interface IDatabaseStore {
  db?: IDBDatabase | undefined;
  loadDBPromise?: Promise<boolean>;

  cleanup(): Promise<boolean>;
}

export class DatabaseStore implements IDatabaseStore {
  public db?: IDBDatabase;
  public loadDBPromise?: Promise<boolean>;
  protected tableName: string;

  constructor(tableName: string) {
    this.tableName = tableName;
  }

  cleanup(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      await this.loadDBPromise;
      if (!this.db) {
        reject({code: -1});
        return;
      }

      const transaction = this.db.transaction([this.tableName], 'readwrite');
      const objectStore = transaction.objectStore(this.tableName);
      const request = objectStore.clear();
      request.onsuccess = async (event) => {
        resolve((event.target as IDBRequest).result);
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }
}

