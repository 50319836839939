import React from "react";
import styled from "styled-components";

export interface NavigateItemProps {
  src: string;
  title: string;
  onClick: () => void;
}

const NavigateItem: React.FC<NavigateItemProps> = ({
                                                     src,
                                                     title,
                                                     onClick
                                                   }) => {
  return (
    <NavigateItemContainer onClick={onClick}>
      <NavigateIcon src={src} />
      <NavigateTitle>{title}</NavigateTitle>
    </NavigateItemContainer>
  )
}

const NavigateItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  gap: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const NavigateIcon = styled.img`
  object-fit: none;
  object-position: center;
`

const NavigateTitle = styled.span`
  color: #262626;
  font: 500 12px 'Plus Jakarta Sans', sans-serif;
`

export default NavigateItem;
