import {IService} from "../IService";
import * as CryptoJS from 'crypto-js';
import ServiceManagerIns from "../ServiceManager";

export interface ISettingService extends IService{
  createPassword(password: string): Promise<string>;
  changePassword(oldPassword: string, newPassword: string): Promise<boolean>;

  getPasswordHash(): Promise<string>;
}

class SettingService implements ISettingService {
  startService(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      resolve(true);
    });
  }

  stopService(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      resolve(true);
    });
  }

  createPassword(password: string): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const passwordHash = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
        await ServiceManagerIns.cacheService.settingCache.cachePasswordHash(passwordHash);
        resolve(passwordHash);
      }
      catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      resolve(true);
    });
  }

  getPasswordHash(): Promise<string> {
    return new Promise<string>(async resolve => {
      const cachePasswordHash = await ServiceManagerIns.cacheService.settingCache.getPasswordHash();
      const defaultPasswordHash = ServiceManagerIns.defaultService.getDefaultPassword();
      resolve(cachePasswordHash ? cachePasswordHash : defaultPasswordHash);
    });
  }
}

export default SettingService;
