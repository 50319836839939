import axios from "axios";
import {
  TSCrossInfo, TSGetCrossInfoParam,
  TSGetCrossInfoRequest, TSGetCrossTransactionDetailRequest, TSGetSwapInfoParam,
  TSGetSwapInfoRequest, TSGetSwapTransactionDetailRequest,
  TSSwapInfo,
  TSTransactionDetail
} from "./models/TransitSwapAPIModel";
import {TransitSwapHelper} from "./helpers/TransitSwapHelper";

const TransitSwapAPIEndpont: {
  PRE_HOST: string;
  AGG_HOST: string;
  PATH: {
    TOKEN_PRICE_LIST: string;
    SWAP_INFO: string;
    CROSS_INFO: string;
    DETAILS: string;
  }
} = {
  PRE_HOST: "https://preserver.mytokenpocket.vip",
  AGG_HOST: "https://aggserver.mytokenpocket.vip",
  PATH: {
    TOKEN_PRICE_LIST: "v1/token/token_price_list",
    SWAP_INFO: 'v3/transit/swap',
    CROSS_INFO: 'v5/transit/cross',
    DETAILS: 'v3/transit/details',
  }
}

export interface ITransitSwapAPI {
  getTokenPriceList(tokenAddressInfoList: {
                      address: string;
                      chainId: number;
                    }[]
  ): Promise<string[]>;

  getSwapInfo(request: TSGetSwapInfoRequest): Promise<TSSwapInfo | undefined>;

  getCrossInfo(request: TSGetCrossInfoRequest): Promise<TSCrossInfo | undefined>;

  getTransactionDetail(request: TSGetSwapTransactionDetailRequest | TSGetCrossTransactionDetailRequest): Promise<TSTransactionDetail | undefined>;
}

class TransitSwapAPI implements ITransitSwapAPI {
  getTokenPriceList(tokenAddressInfoList: { address: string; chainId: number }[]): Promise<string[]> {
    return new Promise<string[]>(async resolve => {
      const convertList = tokenAddressInfoList.map(tokenAddressInfo => {
        return {
          address: tokenAddressInfo.address,
          blockchain_id: tokenAddressInfo.chainId
        }
      });
      const endpoint = `${TransitSwapAPIEndpont.PRE_HOST}/${TransitSwapAPIEndpont.PATH.TOKEN_PRICE_LIST}`;
      const response = await axios.post(endpoint, convertList);
      if (response.status !== 200) {
        resolve([]);
        return;
      }

      const tokenPriceList: string[] = response.data.data;
      resolve(tokenPriceList);
    });
  }

  getSwapInfo(request: TSGetSwapInfoRequest): Promise<TSSwapInfo | undefined> {
    return new Promise<TSSwapInfo | undefined>(async (resolve, reject) => {
      try {
        const param: TSGetSwapInfoParam = {
          token0: request.fromToken.address ? request.fromToken.address : TransitSwapHelper.getNativeAddress(request.fromToken.chain.ns),
          token1: request.toToken.address ? request.toToken.address : TransitSwapHelper.getNativeAddress(request.toToken.chain.ns),
          decimal0: request.fromToken.decimals,
          decimal1: request.toToken.decimals,
          impact: 100,
          part: 5,
          amountIn: request.fromAmount,
          amountOutMin: request.toAmountOutMin ? request.toAmountOutMin : 0n,
          to: request.toAddress,
          ns: request.fromToken.chain.ns,
          chainId: request.fromToken.chain.id,
          issuer: request.fromAddress,
          channel: "web",
          liquiditySources: '',
        }


        const endpoint = `${TransitSwapAPIEndpont.AGG_HOST}/${TransitSwapAPIEndpont.PATH.SWAP_INFO}`;
        const response = await axios.get(endpoint, {
          params: param
        });

        if (response.status !== 200) {
          resolve(undefined);
          return;
        }

        const swapInfo: TSSwapInfo = response.data.data;
        resolve(swapInfo);
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  getCrossInfo(request: TSGetCrossInfoRequest): Promise<TSCrossInfo | undefined> {
    return new Promise<TSCrossInfo | undefined>(async (resolve, reject) => {
      try {
        const param: TSGetCrossInfoParam = {
          fromNs: request.fromToken.chain.ns,
          toNs: request.toToken.chain.ns,
          fromChainId: request.fromToken.chain.id,
          toChainId: request.toToken.chain.id,
          fromDecimals: request.fromToken.decimals,
          toDecimals: request.toToken.decimals,
          fromToken: request.fromToken.address ? request.fromToken.address : TransitSwapHelper.getNativeAddress(request.fromToken.chain.ns),
          toToken: request.toToken.address ? request.toToken.address : TransitSwapHelper.getNativeAddress(request.toToken.chain.ns),
          amount: request.fromAmount,
          receiver: request.toAddress,
          channel: "web",
          issuer: request.fromAddress,
          selectedBridge: request.selectedBridge,
          isConfirmedSend: request.confirmExchange,
          liquiditySources: ""
        }

        const endpoint = `${TransitSwapAPIEndpont.AGG_HOST}/${TransitSwapAPIEndpont.PATH.CROSS_INFO}`;
        const response = await axios.get(endpoint, {
          params: param
        });

        if (response.status !== 200) {
          resolve(undefined);
          return;
        }

        const swapInfo: TSCrossInfo = response.data.data;
        resolve(swapInfo);
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  getTransactionDetail(request: TSGetSwapTransactionDetailRequest | TSGetCrossTransactionDetailRequest): Promise<TSTransactionDetail | undefined> {
    return new Promise<TSTransactionDetail | undefined>(async (resolve, reject) => {
      try {
        const endpoint = `${TransitSwapAPIEndpont.AGG_HOST}/${TransitSwapAPIEndpont.PATH.DETAILS}`;
        const response = await axios.get(endpoint, {
          params: request
        });

        if (response.status !== 200) {
          resolve(undefined);
          return;
        }

        const transactionDetail: TSTransactionDetail = response.data.data;
        resolve(transactionDetail);
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }
}

export default TransitSwapAPI;
