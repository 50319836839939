import React, {useContext, useEffect, useRef, useState} from "react";
import BottomSheet from "../../common/BottomSheet";
import styled, {css, keyframes} from "styled-components";
import {AccountInfo} from "../../../models/home/HomeModel";
import {BizContext} from "../../../contexts/BizContext";
import AssetChainSelector from "../../home/AssetChainSelector";
import AssetTokenList, {AssetTokenItemShowType} from "../../home/AssetTokenList";
import SearchBar from "../../common/SearchBar";
import ChainList from "./ChainList";
import NavigationHeader from "../../common/NavigationHeader";
import {AssetToken, CryptoChain} from "../../../models/web3/Web3Model";


interface SearchAssetTokenBottomSheetProps {
  targetChain?: CryptoChain
  showNoBalance?: boolean;
  itemShowType?: AssetTokenItemShowType;
  onSelectToken?: (token: AssetToken) => void;
  forceCloseWhenSelect?: boolean;
  onClose: () => void;
}

const SearchAssetTokenBottomSheet: React.FC<SearchAssetTokenBottomSheetProps> = ({
  targetChain,
  showNoBalance,
  itemShowType,
  onSelectToken,
  forceCloseWhenSelect,
  onClose
}) => {
  const bizContext = useContext(BizContext);
  if (!bizContext) {
    throw new Error("Context isn't existed");
  }

  const {commonBizModel} = bizContext;

  const parentRef = useRef<HTMLDivElement>(null);

  const [chainList, setChainList] = useState<CryptoChain[]>([])
  const [filteredChainList, setFilteredChainList] = useState<CryptoChain[]>([]);

  const [assetTokenList, setAssetTokenList] = useState<AssetToken[]>([]);
  const [filteredAssetTokenList, setFilteredAssetTokenList] = useState<AssetToken[]>([]);

  const [selectedChain, setSelectedChain] = useState<CryptoChain | undefined>(undefined);

  const [showChainList, setShowChainList] = useState<number>(-1);
  const [searchText, setSearchText] = useState<string>('');
  const [pushableOffset, setPushableOffset] = useState<number>(0);
  const [forceClose, setForceClose] = useState<boolean>(false);

  const [pagingAssetTokenList, setPagingAssetTokenList] = useState<AssetToken[]>([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const tokenListRef = useRef<HTMLDivElement | null>(null);

  const tokenListPageItemCount = 20;

  useEffect(() => {
    const handleScroll = () => {
      if (tokenListRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = tokenListRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 5) {
          if (hasMore) {
            setPage((prevPage) => prevPage + 1);
          }
        }
      }
    };

    const currentTokenList = tokenListRef.current;
    if (currentTokenList) {
      currentTokenList.addEventListener('scroll', handleScroll);
      return () => {
        if (currentTokenList) {
          currentTokenList.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [tokenListRef]);

  useEffect(() => {
    setHasMore(true);
    setPagingAssetTokenList(filteredAssetTokenList.slice(0, tokenListPageItemCount));
  }, [filteredAssetTokenList]);

  useEffect(() => {
    if (!filteredAssetTokenList || !hasMore) {
      return;
    }

    const pagingList = filteredAssetTokenList.slice(0, Math.min(filteredAssetTokenList.length, (page+1) * tokenListPageItemCount));
    if (pagingList.length === filteredAssetTokenList.length) {
      setHasMore(false);
    }

    setPagingAssetTokenList(pagingList);
  }, [page]);

  useEffect(() => {
    const fetchChainListData = async () => {
      const _chainList = await commonBizModel.getChains(showNoBalance);
      setChainList(_chainList);
    };
    fetchChainListData();
  }, []);

  useEffect(() => {
    const fetchAssetTokenData = async () => {
      const fetchedChain = targetChain ?? selectedChain;
      const _assetTokenList = await commonBizModel.getTokens(fetchedChain, showNoBalance);
      setAssetTokenList(_assetTokenList);
    }
    fetchAssetTokenData();
  }, [targetChain, selectedChain]);

  useEffect(() => {
    if (showChainList === 1) {
      if (!searchText) {
        setFilteredChainList(chainList);
      } else {
        setFilteredChainList(chainList.filter(chain => chain.name.toUpperCase().startsWith(searchText)));
      }
    } else {
      if (!searchText) {
        setFilteredAssetTokenList(assetTokenList);
      } else {
        setFilteredAssetTokenList(assetTokenList.filter(token => (token.name.toUpperCase().startsWith(searchText)) || token.symbol.toUpperCase().startsWith(searchText)));
      }
    }
  }, [searchText, showChainList, assetTokenList, chainList]);

  useEffect(() => {
    const updatePushableOffset = () => {
      if (parentRef.current) {
        const width = parentRef.current.offsetWidth;
        setPushableOffset(width);
      }
    };

    const observer = new ResizeObserver(updatePushableOffset);
    if (parentRef.current) {
      observer.observe(parentRef.current);
    }

    updatePushableOffset();
    return () => {
      if (parentRef.current) {
        observer.unobserve(parentRef.current);
      }
    };
  }, [parentRef]);

  const handleSelectChain = (chain?: CryptoChain) => {
    setSelectedChain(chain);
  }

  const handleShowChainList = () => {
    setShowChainList(1);
    setSearchText('');
  }

  const handleHideChainList = () => {
    setShowChainList(0);
    setSearchText('');
  }

  const handleSelectChainFromChainListView = (chain: CryptoChain) => {
    setSelectedChain(chain);
    setShowChainList(0);
  }

  const handleChangeTextSearch = (searchText: string) => {
    setSearchText(searchText);
  }

  const handleSelectCloseButton = () => {
    setForceClose(true);
  }

  const handleSelectToken = (token: AssetToken) => {
    if (onSelectToken) onSelectToken(token);
    if (forceCloseWhenSelect) {
      setForceClose(true);
    }
  }

  return (
    <CustomStyles>
      <BottomSheet
        stickableContent={
          <HeaderContainer>
            <NavigationHeader
              title={(showChainList === 1 ? 'Search Chain' : 'Search Token')}
              showBackButton={showChainList === 1}
              rightBarButtons={[{
                image: "/icons/ic_close.svg"
              }]}
              onClickBackButton={handleHideChainList}
              onClickRightButton={(index) => {
                handleSelectCloseButton()
              }}
            />
            <SearchBar
              placeholder={showChainList === 1 ? 'Search Chain' : 'Search Token'}
              searchText={searchText}
              onChangeTextSearch={handleChangeTextSearch}
            />
          </HeaderContainer>
        }
        scrollableContent={
          <MainBodyWrapper ref={parentRef}>
            <PushableContainer
              show_chain_list={showChainList}
              offset={pushableOffset}
            >
              <AssetTokenContainer ref={tokenListRef}>
                {
                  !targetChain &&
                  <AssetChainSelector
                    chains={chainList}
                    selectedChain={selectedChain}
                    selected_color={'fff'}
                    onSelectChain={handleSelectChain}
                    onSelectMore={handleShowChainList}
                  />
                }
                <AssetTokenList
                  itemShowType={itemShowType}
                  assetTokenList={pagingAssetTokenList}
                  onSelectToken={handleSelectToken}
                />
              </AssetTokenContainer>
              <ChainListContainer>
                <ChainList chains={filteredChainList} onSelectChain={handleSelectChainFromChainListView}/>
              </ChainListContainer>
            </PushableContainer>
          </MainBodyWrapper>
        }
        forceClose={forceClose}
        onClose={onClose}
      />
    </CustomStyles>
  )
}

const CustomStyles = styled.div`
  .chain-selector-container {
    background-color: #f3f3f3;
  }

  .bottom-sheet-area {
    height: 98%;
    max-height: 98%;
  }

  .bottom-sheet-scrollable-content {
    height: calc(100% - 126px);
  }

  .search-bar-container {
    background-color: #f3f3f3;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px 24px 16px 24px;
  box-sizing: content-box;
`

const MainBodyWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
`

const PushableContainer = styled.div<{ show_chain_list: number, offset: number }>`
  display: flex;
  height: 100%;

  animation: ${({show_chain_list, offset}) =>
          show_chain_list === 1
                  ? css`${PushableContainerShowChainListAnimIn(offset)} 0.3s ease-in-out forwards`
                  : show_chain_list === 0
                          ? css`${PushableContainerShowChainListAnimOut(offset)} 0.3s ease-in-out forwards`
                          : ''
  };
`

const AssetTokenContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 24px 24px 24px;
  gap: 16px;
  box-sizing: border-box;
  width: 420px;
  height: 100%;
  flex-shrink: 0;

  overflow-y: auto;
  scroll-behavior: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  @media (max-width: 500px) {
    width: calc(100vw);
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

const ChainListContainer = styled.div`
  display: block;
  padding: 0 24px 24px 24px;
  box-sizing: border-box;
  width: 420px;
  flex-shrink: 0;
  height: 100%;

  overflow-y: auto;
  scroll-behavior: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  @media (max-width: 500px) {
    width: 100vw;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

const PushableContainerShowChainListAnimIn = (offset: number) => keyframes`
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(-${offset}px);
  }
`;

const PushableContainerShowChainListAnimOut = (offset: number) => keyframes`
  from {
    transform: translateX(-${offset}px);
  }
  to {
    transform: translateX(0px);;
  }
`

export default SearchAssetTokenBottomSheet;
