import React from "react";
import styled from "styled-components";

interface OnboardingTitleProps {
  title: string;
  subtitle?: string;
}
const OnboardingTitle: React.FC<OnboardingTitleProps> = ({
                                                           title,
                                                           subtitle
                                                         }) => {
  return (
    <TitleWrapper>
      <Title>{title}</Title>
      {
        subtitle &&
        <SubTitle>{subtitle}</SubTitle>
      }
    </TitleWrapper>
  )
}

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #262626
`

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #8c8c8c;
  text-align: center;
  align-self: stretch;
`

export default OnboardingTitle;
