import React from "react";
import { AlertType } from "../../enums/CommonEnums";
import styled from "styled-components";
import { AlertModel } from "../../models/common/CommonModel";

export interface AlertMessageBarProps {
  alertModel: AlertModel,
  onClose: () => void;
}

const alertStyles = (alertType: AlertType) => {
  let icon = '';
  let color = '';
  switch (alertType) {
    case AlertType.Success: {
      icon = '/icons/ic_toast_success.svg';
      color = '#2EB553';
    }
      break;

    case AlertType.Error: {
      icon = '/icons/ic_toast_error.svg';
      color = '#F5222D';
    }
      break;

    default:
      icon = '/icons/ic_toast_default.svg';
      color = '#262626';
  }
  return { icon: icon, color: color };
}

const AlertMessageBar: React.FC<AlertMessageBarProps> = ({
  alertModel,
  onClose
}) => {

  const alertIcon = alertStyles(alertModel.type).icon;
  const alertMsgColor = alertStyles(alertModel.type).color;

  return (
    <Container>
      <AlertIcon src={alertIcon} />
      <AlertMessage color={alertMsgColor}>{alertModel.message}</AlertMessage>
      <CloseIcon src='/icons/ic_close.svg' onClick={onClose} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
  gap: 12px;
  background-color: #fff;
  box-shadow: 0 4px 8px #00000010;
`;

const AlertIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const AlertMessage = styled.span<{ color: string }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.color};
  flex-grow: 1;
`;

const CloseIcon = styled.img`
  width: 16px;
  height: 16px;
`;

export default AlertMessageBar;