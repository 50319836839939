import React, {useContext, useEffect, useState} from "react";
import {WorkflowProps} from "./WorkflowScreen";
import styled from "styled-components";
import GenerateWalletStepList from "../../../components/onboarding/GenerateWalletStepList/GenerateWalletStepList";
import {BizContext} from "../../../contexts/BizContext";
import {GenerateWalletStepType} from "../../../enums/WalletEnums";
import {useLocation} from "react-router-dom";
import {GenerateWalletFlow} from "../../../models/onboarding/OnboardingModel";
import {AppContext} from "../../../contexts/AppContext";

const GenerateWalletScreen: React.FC<WorkflowProps> = ({
                                                         onNext
                                                       }) => {
  const location = useLocation();
  const generateFlow = () => {
    if (location.pathname.includes('rocket')) return GenerateWalletFlow.RocketLaunch;
    if (location.pathname.includes('createWallet')) return GenerateWalletFlow.Create;
    if (location.pathname.includes('importWallet')) return GenerateWalletFlow.Import;
    if (location.pathname.includes('restoreWallet')) return GenerateWalletFlow.Import;
  }

  const bizContext = useContext(BizContext);
  const appContext = useContext(AppContext);
  if (!bizContext || !appContext) {
    throw new Error("Context isn't existed");
  }

  const { rocketLaunchBizModel, createWalletBizModel, importWalletBizModel } = bizContext;
  const { setHasActiveAccount } = appContext;

  const [generateSteps, setGenerateSteps] = useState<GenerateWalletStepType[]>([]);
  const [isDone, setIsDone] = useState<boolean>(false);

  useEffect(() => {
    const generateWallet = async () => {
      const flow = generateFlow();
      const checkStep = (step: GenerateWalletStepType) => {
        generateSteps.push(step);
        setGenerateSteps(generateSteps.slice());
        if (step === GenerateWalletStepType.Done) setIsDone(true);
      }

      if (flow === GenerateWalletFlow.RocketLaunch) {
        await rocketLaunchBizModel.startRocketLaunch((step) => {
          checkStep(step);
        });
      }
      else if (flow === GenerateWalletFlow.Create) {
        await createWalletBizModel.createWallet((step) => {
          checkStep(step);
        });
      } else if (flow === GenerateWalletFlow.Import) {
        await importWalletBizModel.importWallet((step) => {
          checkStep(step);
        });
      }
    }

    generateWallet();
  }, []);

  useEffect(() => {
    if (isDone && generateFlow() === GenerateWalletFlow.RocketLaunch) {
      setTimeout(() => {
        handleNext();
      }, 2000);
    }
  }, [isDone]);

  const handleNext = () => {
    setHasActiveAccount(true);
    onNext();
  }

  return (
    <GenerateWalletScreenContainer>
      <GenerateWalletStepList currentSteps={generateSteps} />
      {
        generateFlow() !== GenerateWalletFlow.RocketLaunch &&
        (
          <FinishButton disabled={!isDone} onClick={handleNext} >Finish</FinishButton>
        )
      }
    </GenerateWalletScreenContainer>
  )
}

const GenerateWalletScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 16px;
  padding: 16px;
  box-sizing: border-box;
`

const FinishButton = styled.button`
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: #262626;
  color: #fff;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  border-style: none;
  margin-top: auto;
  
  &:disabled {
    background-color: #e3e3e3;
    cursor: not-allowed;
  }
`

export default GenerateWalletScreen;
