import React from "react";
import styled from "styled-components";
import {AssetToken} from "../../models/web3/Web3Model";

export enum AssetTokenItemSizeType {
  Normal = 'normal',
  LargeIcon = 'large_icon',
}

export interface AssetTokenItemProps {
  assetToken: AssetToken;
  sizeType?: AssetTokenItemSizeType;
  onSelect?: () => void;
}

export const AssetTokenItemFullInfo: React.FC<AssetTokenItemProps> = ({
                                                                        assetToken,
                                                                        sizeType,
                                                                        onSelect
                                                                      }) => {
  return (
    <AssetTokenContainer onClick={onSelect}>
      <TokenLogo className={sizeType} src={assetToken.logo}/>
      <TokenInfo>
        <TokenName>{assetToken.name}</TokenName>
        <TokenDescArea>
          <ChainLogo src={assetToken.chain.logo}/>
          <TokenBalance>{`${parseFloat(assetToken.balance.amountInETH.toFixed(6))} ${assetToken.symbol}`}</TokenBalance>
        </TokenDescArea>
      </TokenInfo>
      <RightValueArea>
        <QuoteValue>{`$${assetToken.quote.quote.toFixed(2)}`}</QuoteValue>
        <QuoteChange
          positive={assetToken.quote.quoteChangePerc >= 0 ? 1 : 0}>{`${assetToken.quote.quoteChangePerc >= 0 ? '+' : '-'}${assetToken.quote.quoteChangePerc.toFixed(2)}%($${Math.abs(assetToken.quote.quote - assetToken.quote.beforeQuote).toFixed(2)})`}</QuoteChange>
      </RightValueArea>
    </AssetTokenContainer>
  )
}

export const AssetTokenItemOnlyBalance: React.FC<AssetTokenItemProps> = ({
                                                                           assetToken,
                                                                           sizeType,
                                                                           onSelect
                                                                         }) => {
  return (
    <AssetTokenContainer onClick={onSelect}>
      <TokenLogo className={sizeType} src={assetToken.logo}/>
      <TokenInfo>
        <TokenName>{assetToken.name}</TokenName>
        <TokenDescArea>
          <ChainLogo src={assetToken.chain.logo}/>
          {
            assetToken.address !== ''
              ? (<TokenAddress>{`${assetToken.address.slice(0, 6)}...${assetToken.address.slice(-6)}`}</TokenAddress>)
              : (<TokenAddress>{assetToken.chain.name}</TokenAddress>)
          }

        </TokenDescArea>
      </TokenInfo>
      <RightValueArea>
        {
          assetToken.balance.amountInETH
            ? (
              <>
                <RightTokenBalance>{parseFloat(assetToken.balance.amountInETH.toFixed(6))}</RightTokenBalance>
                <RightTokenQuote>{`$${assetToken.quote.quote.toFixed(2)}`}</RightTokenQuote>
              </>
            )
            : (
              <>
                <RightTokenBalance className="inactive">{assetToken.balance.amountInETH}</RightTokenBalance>
              </>
            )
        }

      </RightValueArea>
    </AssetTokenContainer>
  )
}

const AssetTokenContainer = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  font-family: 'Plus Jakarta Sans', sans-serif;
  align-items: center;
  cursor: pointer;
`

const TokenLogo = styled.img`
  width: 48px;
  height: 48px;
  object-position: center;
  object-fit: contain;
  border-radius: 100%;

  &.large_icon {
    width: 56px;
    height: 56px;
  }
;
`

const TokenInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 4px;
  flex: 1;
`

const TokenName = styled.span`
  font-weight: 600;
  font-size: 16px;
  color: #262626;
`

const TokenDescArea = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

const TokenBalance = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #8C8C8C;
`

const TokenAddress = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #8C8C8C;
`

const ChainLogo = styled.img`
  aspect-ratio: 1;
  width: 16px;
  object-fit: contain;
  object-position: center;
  border-radius: 100%;
  border: 1px solid #fff;
`

const RightValueArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
`

const QuoteValue = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: #262626;
  line-height: 22px;
`

interface QuoteChangeProps {
  positive: number;
}

const QuoteChange = styled.span<QuoteChangeProps>`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => (props.positive ? "#2eb553" : "#ff4d4f")};
`

const RightTokenBalance = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #262626;

  &.inactive {
    color: #BFBFBF;
  }
`

const RightTokenQuote = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #BFBFBF;
`


