export enum CryptoNetworkType {
  EVM = 'EVM',
  SOL = 'SOL',
  TON = 'TON'
};

export enum CryptoScanDefaultType {
  ETH = "https =//etherscan.com",
  BSC = "https =//bscscan.com",
  POL = "https =//polygonscan.com",
  FTM = "https =//ftmscan.com",
  SOL = "https =//solana.fm",
  TON = "https =//tonviewer.com",
};
