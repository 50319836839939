import * as CryptoJS from "crypto-js";

export interface ICryptoService {
  encryptAES(string: string, key: string): Promise<string>;
  decryptAES(encrString: string, key: string): Promise<string>;
}

class CryptoService implements ICryptoService{
  encryptAES(string: string, key: string): Promise<string> {
    return new Promise<string>(resolve => {
      const encrypted = CryptoJS.AES.encrypt(string, key);
      resolve(encrypted.toString());
    });
  }

  decryptAES(encrString: string, key: string): Promise<string> {
    return new Promise<string>(resolve => {
      const decrypted = CryptoJS.AES.decrypt(
        encrString,
        key
      );
      const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
      resolve(decryptedString);
    });
  }
}

export default CryptoService;
