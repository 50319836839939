import {SBExplorerDefaultLogoType} from "../enums/SwapBridgeEnums";

const getDefaultExplorerLogo = (chainId: number) => {
  switch (chainId) {
    case 1:
      return SBExplorerDefaultLogoType.ETH;
    case 27:
      return SBExplorerDefaultLogoType.SOL;
    case 71:
      return SBExplorerDefaultLogoType.TON;
    case 12:
      return SBExplorerDefaultLogoType.BSC;
    case 25:
      return SBExplorerDefaultLogoType.FTM;
    case 49:
      return SBExplorerDefaultLogoType.MANTLE;
    case 18:
      return SBExplorerDefaultLogoType.POL;
    case 51:
      return SBExplorerDefaultLogoType.BASE;
    case 24:
      return SBExplorerDefaultLogoType.ARB;
    case 26:
      return SBExplorerDefaultLogoType.OP;
    case 23:
      return SBExplorerDefaultLogoType.AVAX;
    case 56:
      return SBExplorerDefaultLogoType.SCROLL;
    case 57:
      return SBExplorerDefaultLogoType.TAIKO;
  }
}

const getDefaultExplorerScan = (chainId: number, txHash: string) => {
  let explorer = "";
  switch (chainId) {
    case 1:
      explorer = "https://etherscan.com";
      break;
    case 27:
      explorer = "https://explorer.solana.com";
      break;
    case 71:
      explorer = "https://tonscan.com";
      break;
    case 12:
      explorer = "https://bscscan.com";
      break;
    case 25:
      explorer = "https://ftmscan.com";
      break;
    case 49:
      explorer = "https://explorer.mantle.xyz";
      break;
    case 18:
      explorer = "https://polygonscan.com";
      break;
    case 51:
      explorer = "https://base.blockscout.com/";
      break;
    case 24:
      explorer = "https://arbiscan.io";
      break;
    case 26:
      explorer = "https://optimistic.etherscan.com";
      break;
    case 23:
      explorer = "https://avascan.info";
      break;
    case 56:
      explorer = "https://scrollscan.com";
      break;
    case 57:
      explorer = "https://taikoscan.io";
      break;
    default:
      return '';
  }

  explorer += `/tx/${txHash}`;
  return explorer;
}

const SwapBridgeHelper = {
  getDefaultExplorerLogo,
  getDefaultExplorerScan
}

export default SwapBridgeHelper;
