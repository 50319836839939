import { AccountInfo } from "../../models/home/HomeModel";
import { SendFeeModel } from "../../models/send/fee";
import {AssetToken, CryptoChain, CryptoToken} from "../../models/web3/Web3Model";
import { IMockService, MockService } from "../../services/MockService";
import ServiceManagerIns from "../../services/ServiceManager";
import { Web3GasInfo, Web3GasType } from "../../services/Web3Service/MultichainGasFetcher";
import {ethers} from "ethers";
import {checkValidAddress} from "../../helpers/Web3Helper";

export interface ISendWalletBizModel {
   getSupportedChains(): Promise<CryptoChain[]>;
   getDefaultSelectedChain(chainList: CryptoChain[]): Promise<CryptoChain>;
   getDefaultSelectedToken(chain: CryptoChain): Promise<AssetToken | undefined>;
   getAssetTokens(chain: CryptoChain): Promise<AssetToken[]>;
   sendWallet(
      accountInfo: AccountInfo,
      chain: CryptoChain,
      assetToken: AssetToken,
      amount: number,
      quote: number,
      toAddress: string,
      comment: string
   ): Promise<boolean>;

   checkValidToAddress(chain: CryptoChain, toAddress: string): boolean;
}

export class SendWalletBizModel implements ISendWalletBizModel {
   private mockService: IMockService;
   constructor() {
      this.mockService = new MockService();
   }

   sendWallet(accountInfo: AccountInfo, chain: CryptoChain, assetToken: AssetToken, amount: number, quote: number, toAddress: string, comment: string): Promise<boolean> {
      return new Promise<boolean>(async resolve => {
         const web3Wallet = await ServiceManagerIns.web3Service.getWeb3Wallet(chain);
         if (!web3Wallet) {
            resolve(false);
            return;
         }

         if (!assetToken.address) {
            await web3Wallet.sendNative({
               toAddress: toAddress,
               amount: ethers.parseUnits(amount.toString(), assetToken.decimals),
               onSuccess: txHash => {
                  ServiceManagerIns.accountService.checkWalletBalance([assetToken]);
                  resolve(true);
               },
               onFailure: error => {
                  ServiceManagerIns.accountService.checkWalletBalance([assetToken]);
                  resolve(false);
               }
            });
         } else {
            const amountEthers = ethers.parseUnits(amount.toString(), assetToken.decimals);
            const targetAmountEthers = assetToken.balance.amount < amountEthers ? assetToken.balance.amount : amountEthers;
            await web3Wallet.sendToken({
               tokenAddress: assetToken.address,
               toAddress: toAddress,
               amount: targetAmountEthers,
               decimals: assetToken.decimals,
               onSuccess: txHash => {
                  setTimeout(() => {
                     ServiceManagerIns.accountService.checkWalletBalance([assetToken]);
                     resolve(true);
                  }, 1000);
               },
               onFailure: error => {
                  setTimeout(() => {
                     ServiceManagerIns.accountService.checkWalletBalance([assetToken]);
                     resolve(false);
                  }, 1000);
               }
            });
         }
      });
   }

   getSupportedChains(): Promise<CryptoChain[]> {
      return new Promise<CryptoChain[]>(async resolve => {
         const chainList = await ServiceManagerIns.accountService.getActiveChainList();
         resolve(chainList);
      });
   }

   getDefaultSelectedChain(chainList: CryptoChain[]): Promise<CryptoChain> {
      return new Promise<CryptoChain>(resolve => {
         resolve(chainList[0]);
      });
   }

   getDefaultSelectedToken(chain: CryptoChain): Promise<AssetToken | undefined> {
      return new Promise<AssetToken | undefined>(async resolve => {
         const assetTokenList = await ServiceManagerIns.accountService.getActiveTokenList(chain);
         const sortedAssetTokenList = assetTokenList.sort((tokenA, tokenB) => tokenB.quote.quote - tokenA.quote.quote);
         const tokenList = await ServiceManagerIns.web3Service.getSupportedTokens(chain);
         if (chain.name === "Taiko") {
            const taikoAssetToken = sortedAssetTokenList.find(token => token.symbol === "TAIKO");
            let taikoToken = tokenList.find(token => token.symbol === "TAIKO");
            taikoToken = taikoToken || tokenList[0];
            resolve(taikoAssetToken || {
               ...taikoToken,
               balance: {
                  amount: 0n,
                  amountInETH: 0
               },
               quote: {
                  quote: 0,
                  beforeQuote: 0,
                  quoteChangePerc: 0
               }
            });
         } else {
            resolve(sortedAssetTokenList.length > 0 ? sortedAssetTokenList[0] : {
               ...tokenList[0],
               balance: {
                  amount: 0n,
                  amountInETH: 0
               },
               quote: {
                  quote: 0,
                  beforeQuote: 0,
                  quoteChangePerc: 0
               }
            });
         }
      });
   }

   getAssetTokens(chain: CryptoChain): Promise<AssetToken[]> {
      return new Promise<AssetToken[]>(async resolve => {
         const assetTokenList = await ServiceManagerIns.accountService.getActiveTokenList(chain);
         const sortedAssetTokenList = assetTokenList.sort((tokenA, tokenB) => tokenB.quote.quote - tokenA.quote.quote);
         resolve(sortedAssetTokenList);
      });
   }

   checkValidToAddress(chain: CryptoChain, toAddress: string): boolean {
      return checkValidAddress(chain, toAddress);
   }
}
