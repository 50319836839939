import React from "react";
import styled from "styled-components";

interface NavigationBarButtonItem {
  image: string;
}

interface NavigationHeaderProps {
  showBackButton?: boolean;
  title: string;
  rightBarButtons?: NavigationBarButtonItem[]
  onClickBackButton?: () => void;
  onClickRightButton?: (index: number) => void;
}

const NavigationHeader: React.FC<NavigationHeaderProps> = ({
                                                  showBackButton,
                                                  title,
                                                  rightBarButtons,
                                                  onClickBackButton,
                                                  onClickRightButton
                                                }) => {
  const handleClickRightButton = (index: number) => {
    if (onClickRightButton) onClickRightButton(index)
  }

  return (
    <NavHeaderContainer>
      <NavLeftButtonArea>
        {
          showBackButton &&
          <NavBarButton src="/icons/ic_back.svg" onClick={onClickBackButton} />
        }
      </NavLeftButtonArea>
      <NavTitle>{title}</NavTitle>
      <NavRightButtonArea>
        {
          rightBarButtons && rightBarButtons.map((barButtonItem, index) => (
            <NavBarButton src={barButtonItem.image} onClick={() => {handleClickRightButton(index)}} />
          ))
        }
      </NavRightButtonArea>
    </NavHeaderContainer>
  )
}

const NavHeaderContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 24px;
  align-items: center;
`

const NavTitle = styled.span`
  font: 700 24px 'Plus Jakarta Sans', sans-serif;
  line-height: 32px;
  color: #262626;
  flex: 1;
  text-align: center;
  margin: 0 24px;
`

const NavLeftButtonArea = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  gap: 4px
`

const NavRightButtonArea = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  gap: 4px
`

const NavBarButton = styled.img`
  width: 24px;
  height: 24px;
  object-fit: none;
  object-position: center;
  cursor: pointer;
`

export default NavigationHeader;
