import ServiceManagerIns from "../ServiceManager";
import {AccountWalletDetail} from "../../models/wallet/AccountModel";
import {WalletBalanceDetail} from "../../models/wallet/WalletModel";

export interface IStartupLoader {
  loadWeb3Config(): Promise<boolean>;
  loadSupportedChains(): Promise<boolean>;
  loadSupportedTokens(): Promise<boolean>;
  loadCachedAccounts(): Promise<boolean>;
}

export class StartupLoader implements IStartupLoader {
  loadWeb3Config(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      await ServiceManagerIns.web3Service.getWeb3Config();
      resolve(true);
    });
  }

  loadSupportedChains(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      await ServiceManagerIns.web3Service.getSupportedChains();
      resolve(true);
    });
  }

  loadSupportedTokens(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      await ServiceManagerIns.web3Service.getSupportedTokens();
      resolve(true);
    });
  }

  loadCachedAccounts(): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      const accountList = await ServiceManagerIns.cacheService.accountCache.getCachedAccounts();
      if (!accountList || accountList.length === 0) {
        resolve(true);
        return;
      }

      const activeAccountId = await ServiceManagerIns.cacheService.accountCache.getAccountActiveId();

      for (const accountInfo of accountList) {
        let accountBalance: (WalletBalanceDetail | undefined) = await ServiceManagerIns.cacheService.accountCache.getCachedAccountBalance(accountInfo.id);

        if (!accountBalance) {
          accountBalance = {
            quoteInfo: {
              quote: 0,
              beforeQuote: 0,
              quoteChangePerc: 0
            },
            tokenMap: {}
          };
        }

        const accountDetail: AccountWalletDetail = {
          ...accountInfo,
          walletBalance: accountBalance
        };

        await ServiceManagerIns.sessionService.addAccountWalletDetail(accountDetail);

        if (accountInfo.id === activeAccountId) {
          await ServiceManagerIns.accountService.setActiveAccount(accountDetail);
        }
      }

      resolve(true);
    });
  }
}
