import {IService} from "../IService";
import {CryptoChain} from "../../models/web3/Web3Model";
import {AccountWalletInfo} from "../../models/wallet/AccountModel";
import {MultichainInfo, WalletInfo} from "../../models/wallet/WalletModel";
import {WalletType} from "../../enums/WalletEnums";
import * as CryptoJS from "crypto-js";

export interface IDefaultService extends IService {
  getDefaultPassword(): string;
  getDefaultChain(): CryptoChain;
  getDefaultAccount(): AccountWalletInfo;
}

class DefaultService implements IDefaultService {
  startService(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      resolve(true);
    });
  }

  stopService(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      resolve(true);
    });
  }

  getDefaultPassword(): string {
    const defaultPwd = "n0m@s_w@llet_2024";
    const defaultPwdHash = CryptoJS.SHA256(defaultPwd).toString(CryptoJS.enc.Hex);
    return defaultPwdHash;
  }

  getDefaultChain(): CryptoChain {
    return {
      id: -1,
      chain_id: 0,
      name: "Unknown",
      network: "unknown",
      symbol: "UNK",
      logo: ""
    }
  }

  getDefaultAccount(): AccountWalletInfo {
    return {
      id: -1,
      name: "Unknown",
      wallet: <WalletInfo<MultichainInfo>>{
        type: WalletType.Multichain,
        info: {
          encrMnemonic: ''
        }
      }
    }
  }
}

export default DefaultService;
