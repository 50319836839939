import {GenerateWalletStepType, ImportWalletType} from "../../../enums/WalletEnums";
import OnboardingBizModel, {IOnboardingBizModel} from "../OnboardingBizModel";
import {CryptoChain} from "../../../models/web3/Web3Model";
import {
  GenerateTypePassphraseData,
  GenerateTypePrivateKeyData,
  GenerateWalletByType
} from "../../../models/onboarding/OnboardingModel";
import ServiceManagerIns from "../../../services/ServiceManager";
import * as CryptoJS from "crypto-js";
import {AccountWalletInfo} from "../../../models/wallet/AccountModel";

export interface IImportWalletBizModel extends IOnboardingBizModel {
  setImportType(type: ImportWalletType): Promise<boolean>;
  setPassphrase(passphrase: string[]): Promise<boolean>;
  setPrivateKey(privateKey: string, chain: CryptoChain): Promise<boolean>;

  getImportDefaultChain(): Promise<CryptoChain>;
  checkValidPassphrase(passphrase: string[]): Promise<boolean>;
  checkValidPrivateKey(chain: CryptoChain, privateKey: string): Promise<boolean>;

  importWallet(onStep:(step: GenerateWalletStepType) => void): Promise<boolean>;
}

class ImportWaletBizModel extends OnboardingBizModel implements IImportWalletBizModel {
  setImportType(type: ImportWalletType): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      switch (type) {
        case ImportWalletType.Passphrase:
          this.processInfo.type = GenerateWalletByType.Passphrase;
          break;
        case ImportWalletType.SecretKey:
          this.processInfo.type = GenerateWalletByType.PrivateKey;
          break;
        default:
          break;
      }
      resolve(true);
    });
  }

  setPassphrase(passphrase: string[]): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.processInfo.info = <GenerateTypePassphraseData>{
        passphrase: passphrase
      };

      resolve(true);
    });
  }

  setPrivateKey(privateKey: string, chain: CryptoChain): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.processInfo.info = <GenerateTypePrivateKeyData>{
        privateKey: privateKey,
        chain: chain
      };

      resolve(true);
    });
  }

  getImportDefaultChain(): Promise<CryptoChain> {
    return new Promise<CryptoChain>(async resolve => {
      const supportedChains = await ServiceManagerIns.web3Service.getSupportedChains();
      if (!supportedChains) {
        const defaultChain = ServiceManagerIns.defaultService.getDefaultChain();
        resolve(defaultChain);
      } else {
        resolve(supportedChains[0]);
      }
    });
  }

  checkValidPassphrase(passphrase: string[]): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      // HM - Test
      resolve(true);

    });
  }

  checkValidPrivateKey(chain: CryptoChain, privateKey: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      // HM - Test
      resolve(true);
    });
  }

  importWallet(onStep: (step: GenerateWalletStepType) => void): Promise<boolean> {
    return new Promise<boolean>(async resolve => {
      onStep(GenerateWalletStepType.StartImport);

      const createAccount = async () => {
        let accountWalletInfo: AccountWalletInfo | undefined;
        try {
          switch (this.processInfo.type) {
            case GenerateWalletByType.Passphrase: {
              const mnemonic = (this.processInfo.info as GenerateTypePassphraseData).passphrase.join(' ');
              const encrMnemonic = await ServiceManagerIns.cryptoService.encryptAES(mnemonic, this.processInfo.passwordHash);
              accountWalletInfo = await ServiceManagerIns.accountService.importMultichainAccount({
                encrMnemonic: encrMnemonic
              });
            }
              break;
            case GenerateWalletByType.PrivateKey: {
              const privateKey = (this.processInfo.info as GenerateTypePrivateKeyData).privateKey;
              const encrPrivateKey = await ServiceManagerIns.cryptoService.encryptAES(privateKey, this.processInfo.passwordHash);
              accountWalletInfo = await ServiceManagerIns.accountService.importSinglechainAccount({
                encrPrivateKey: encrPrivateKey,
                chain: (this.processInfo.info as GenerateTypePrivateKeyData).chain
              });
            }
              break;
            default:
              break;
          }
        }
        catch (e) {
          console.error(e);
        }

        if (!accountWalletInfo) {
          accountWalletInfo = ServiceManagerIns.defaultService.getDefaultAccount();
        }
        return accountWalletInfo;
      }

      const setActiveFlow = async (accountWalletInfo: AccountWalletInfo) => {
        await ServiceManagerIns.accountService.prepareDetailAndSetActiveAccount(accountWalletInfo);
      }

      setTimeout(async () => {
        onStep(GenerateWalletStepType.Encrypt);
        const accountWalletInfo = await createAccount();

        setTimeout(async () => {
          onStep(GenerateWalletStepType.SuggestBackup);
          await setActiveFlow(accountWalletInfo);

          setTimeout(() => {
            console.log(accountWalletInfo);
            onStep(GenerateWalletStepType.Done);
          }, 1000);
        }, 1000);
      }, 1000);

      resolve(true);
    });
  }
}

export default ImportWaletBizModel;
