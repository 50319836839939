import AccountDataObserver, {IAccountDataObserver} from "./AccountDataObserver";

export interface IObserverService {
  accountData: IAccountDataObserver;
}

class ObserverService implements IObserverService {
  public accountData: IAccountDataObserver;
  constructor() {
    this.accountData = new AccountDataObserver();
  }
}

export default ObserverService;
