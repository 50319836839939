import {AccountWalletInfo} from "../../../models/wallet/AccountModel";
import {DatabaseStore, IDatabaseStore} from "./DatabaseStore";

export interface IAccountStore extends IDatabaseStore {
  storeAccount(accountInfo: AccountWalletInfo): Promise<boolean>;

  getStoreAccounts(): Promise<AccountWalletInfo[]>;
}

export class AccountStore extends DatabaseStore implements IAccountStore {
  storeAccount(accountInfo: AccountWalletInfo): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      await this.loadDBPromise;
      if (!this.db) {
        reject({code: -1});
        return;
      }

      const transaction = this.db.transaction([this.tableName], 'readwrite');
      const objectStore = transaction.objectStore(this.tableName);
      const request = objectStore.add(accountInfo);

      request.onsuccess = async (event) => {
        resolve((event.target as IDBRequest).result);
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }

  getStoreAccounts(): Promise<AccountWalletInfo[]> {
    return new Promise<AccountWalletInfo[]>(async (resolve, reject) => {
      await this.loadDBPromise;
      if (!this.db) {
        reject({code: -1});
        return;
      }

      const transaction = this.db.transaction([this.tableName], 'readonly');
      const objectStore = transaction.objectStore(this.tableName);
      const request = objectStore.getAll();

      request.onsuccess = (event) => {
        resolve((event.target as IDBRequest).result);
      };

      request.onerror = (event) => {
        reject((event.target as IDBRequest).error);
      };
    });
  }
}
