import {AccountWalletDetail} from "../../models/wallet/AccountModel";
import {IService} from "../IService";
import {CryptoChain, CryptoToken, Web3Config} from "../../models/web3/Web3Model";

export interface ISessionService extends IService {
  addAccountWalletDetail(accountWalletDetail: AccountWalletDetail): void;
  removeAccountWalletDetail(accountId: number): boolean;
  replaceAccountWalletDetail(accountWalletDetail: AccountWalletDetail): boolean;
  getAccountWalletDetail(accountId: number): AccountWalletDetail | undefined;
  getAccountWalletDetailList(): AccountWalletDetail[];

  setWeb3Config(web3Config: Web3Config): void;
  getWeb3Config(): Web3Config | undefined;

  setWeb3Tokens(web3Tokens: CryptoToken[]): void;
  getWeb3Tokens(chain?: CryptoChain): CryptoToken[] | undefined;

  setWeb3Chains(web3Chains: CryptoChain[]): void;
  getWeb3Chains(): CryptoChain[] | undefined;

  reset(): Promise<boolean>;
}

class SessionService implements ISessionService {
  protected accountWalletDetailList: AccountWalletDetail[];

  protected web3Config?: Web3Config;
  protected web3Tokens?: CryptoToken[];
  protected web3TokenMap: {
    [chainName: string]: CryptoToken[]
  };
  protected web3Chains?: CryptoChain[];

  constructor() {
    this.accountWalletDetailList = [];
    this.web3TokenMap = {};
  }

  startService(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      resolve(true);
    });
  }

  stopService(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      resolve(true);
    });
  }

  addAccountWalletDetail(accountWalletDetail: AccountWalletDetail) {
    this.accountWalletDetailList.unshift(accountWalletDetail);
  }

  removeAccountWalletDetail(accountId: number): boolean {
    const index = this.accountWalletDetailList.findIndex(accountWalletDetail => accountWalletDetail.id === accountId);
    if (index === -1) {
      return false;
    }

    this.accountWalletDetailList.splice(index,1);
    return true;
  }

  replaceAccountWalletDetail(accountWalletDetail: AccountWalletDetail): boolean {
    const index = this.accountWalletDetailList.findIndex(ssAccountWalletDetail => ssAccountWalletDetail.id === accountWalletDetail.id);
    if (index === -1) {
      return false;
    }

    this.accountWalletDetailList[index] = accountWalletDetail;
    return true;
  }

  getAccountWalletDetail(accountId: number): AccountWalletDetail | undefined {
    const accountWalletDetail = this.accountWalletDetailList.find(accountWalletDetail => accountWalletDetail.id === accountId);
    return accountWalletDetail;
  }

  getAccountWalletDetailList(): AccountWalletDetail[] {
    return this.accountWalletDetailList;
  }

  setWeb3Config(web3Config: Web3Config) {
    this.web3Config = web3Config;
  }

  getWeb3Config(): Web3Config | undefined {
    return this.web3Config;
  }

  setWeb3Tokens(web3Tokens: CryptoToken[]) {
    this.web3Tokens = web3Tokens;
    this.web3TokenMap = {};
    web3Tokens.forEach(token => {
      const chainName = token.chain.name;
      let tokenList: CryptoToken[] = this.web3TokenMap[chainName];
      if (!tokenList) {
        tokenList = [];
        this.web3TokenMap[chainName] = tokenList;
      }

      tokenList.push(token);
    });
  }

  getWeb3Tokens(chain?: CryptoChain): CryptoToken[] | undefined {
    if (!chain) {
      return this.web3Tokens;
    } else {
      return this.web3TokenMap[chain.name];
    }
  }

  setWeb3Chains(web3Chains: CryptoChain[]) {
    this.web3Chains = web3Chains;
  }

  getWeb3Chains(): CryptoChain[] | undefined {
    return this.web3Chains;
  }

  reset(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.accountWalletDetailList = [];
      this.web3Config = undefined;
      this.web3Tokens = undefined;
      this.web3Chains = undefined;
      this.web3TokenMap = {};
      resolve(true);
    });
  }
}

export default SessionService;

