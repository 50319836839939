import {CryptoChainInfo} from "../models/Web3Model";
import {ISingleChainWallet} from "../multichain_wallet/single_wallet/SingleChainWallet";
import {CryptoNetworkType} from "../enums/Web3Enums";
import EVMWallet from "../multichain_wallet/single_wallet/EVMWallet";
import SolWallet from "../multichain_wallet/single_wallet/SolWallet";
import TonWallet from "../multichain_wallet/single_wallet/TonWallet";

export const createWallet: (chain: CryptoChainInfo, createInfo: {
  rpc: string;
  mnemonic?: string;
  privateKey?: string;
  balanceContractAddr?: string;
}) => ISingleChainWallet | undefined = (chain, createInfo) => {
  const network = chain.network;
  let wallet = undefined;
  switch (network) {
    case CryptoNetworkType.EVM:
      wallet = new EVMWallet(createInfo);
      break;
    case CryptoNetworkType.SOL:
      wallet = new SolWallet(createInfo);
      break;
    case CryptoNetworkType.TON:
      wallet = new TonWallet(createInfo);
      break;

    default: break;
  }

  return wallet;
}
