import {TonAPIAccountInfo, TonAPIAccountJettonInfo} from "./models/TonAPIModel";
import axios from "axios";

const TonAPIEndpont: {
  HOST: string;
  PATH: {
    [path: string]: (...args: string[]) => string;
  }
} = {
  HOST: 'https://tonapi.io',
  PATH: {
    ACCOUNT_INFO: (address) => `v2/accounts/${address}`,
    ACCOUNT_JETTON_INFO: (address) => `v2/accounts/${address}/jettons`
  }
}

export interface ITonAPI {
  getAccountInfo(address: string): Promise<TonAPIAccountInfo>;
  getAccountJettonInfo(address: string): Promise<TonAPIAccountJettonInfo>;
}

export class TonAPI implements ITonAPI {
  getAccountInfo(address: string): Promise<TonAPIAccountInfo> {
    return new Promise<TonAPIAccountInfo>(async resolve => {
      const endpoint = `${TonAPIEndpont.HOST}/${TonAPIEndpont.PATH.ACCOUNT_INFO(address)}`;
      const response = await axios.get(endpoint);
      if (response.status !== 200) {
        return {};
      }

      const accountInfo: TonAPIAccountInfo = response.data;
      resolve(accountInfo);
    });
  }

  getAccountJettonInfo(address: string): Promise<TonAPIAccountJettonInfo> {
    return new Promise<TonAPIAccountJettonInfo>(async resolve => {
      const endpoint = `${TonAPIEndpont.HOST}/${TonAPIEndpont.PATH.ACCOUNT_JETTON_INFO(address)}`;
      const response = await axios.get(endpoint);
      if (response.status !== 200) {
        return {};
      }

      const accountJettonInfo: TonAPIAccountJettonInfo = response.data;
      resolve(accountJettonInfo);
    });
  }
}

const TonAPIIns = new TonAPI();

export default TonAPIIns;
