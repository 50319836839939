import React from "react";
import styled from "styled-components";

export interface SettingItemProps {
  icon: string;
  title: string;
  rightInfo?: string;
  showArrow?: boolean;
  style?: string;
  onClick?: () => void;
}

export const SettingItem: React.FC<SettingItemProps> = ({
                                                          icon,
                                                          title,
                                                          rightInfo,
                                                          showArrow,
                                                          style,
                                                          onClick
                                                        }) => {
  return (
    <Container onClick={onClick}>
      <Icon src={icon}/>
      <Title className={style}>{title}</Title>
      <RightArea>
        {
          rightInfo &&
          <RightInfo>{rightInfo}</RightInfo>
        }
        {
          showArrow &&
          <RightArrow src="/icons/ic_arrow_right.svg"/>
        }
      </RightArea>
    </Container>
  )
}

const Container = styled.div`
    display: flex;
    gap: 8px;
    width: 100%;
    font-family: "Plus Jakarta Sans", sans-serif;
    align-items: center;
    padding: 16px 0;
    cursor: pointer;
  `;

const Icon = styled.img`
    width: 24px;
    aspect-ratio: 1;
    object-fit: fill;
  `

const Title = styled.span`
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
    flex: 1;
    
    &.critical {
      color: #F5222D;
    };
  `

const RightArea = styled.div`
    display: flex;
    gap: 1px;
    align-items: center;
  `

const RightInfo = styled.span`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #595959;
  `

const RightArrow = styled.img`
    width: 16px;
    aspect-ratio: 1;
  `
