import {
  NotifyTabType,
  NotifyType,
} from "../../enums/NotifyEnum";
import {
  GeneralNotifyModel,
  NotifyTabModel,
} from "../../models/notification/NotifyModel";
import { IMockService, MockService } from "../../services/MockService";

export interface INotifyBizModel {
  getNotifyTabList(): Promise<NotifyTabModel[]>;
  getNotifyListByTab(tab: NotifyTabType): Promise<GeneralNotifyModel<any>[]>;
}

export class MockNotifyBizModel implements INotifyBizModel {
  private mockService: IMockService;
  constructor() {
    this.mockService = new MockService();
  }

  getNotifyTabList(): Promise<NotifyTabModel[]> {
    return new Promise<NotifyTabModel[]>(async resolve => {
      const tabList = await this.mockService.getNotifyTabList();
      resolve(tabList);
    });
  }

  getNotifyListByTab(tab: NotifyTabType): Promise<GeneralNotifyModel<any>[]> {
    return new Promise<GeneralNotifyModel<any>[]>(async resolve => {
      const notifyList = await this.mockService.getNotifyList();
      let filteredList: GeneralNotifyModel<any>[];

      switch (tab) {
        case NotifyTabType.All:
          filteredList = notifyList;
          break;

        case NotifyTabType.Transaction:
          filteredList = notifyList.filter(item => item.notifType === NotifyType.Transaction);
          break;

        case NotifyTabType.Alert:
          filteredList = notifyList.filter(item => item.notifType === NotifyType.Alert);
          break;

        default:
          filteredList = notifyList;
          break;
      }

      resolve(filteredList);
    });
  }
}
